//in useActions.ts file
import {
  SELECTED_REPORT_FILTER, PAGE_REPORT,SELECTED_FILTER_VALUE,PAGE_REPORT_DATE,SELECTED_DATE_FILTER,HISTORY_LINES_DATA,HISTORY_LINES_FILTER,OUTPUT_DATA,
  OUTPUT_FILTER,
  SAP_FILTER_OBJ
} from "../../types";
import axios from '../../../_helpers/axios';

export const fetchReport= () => (dispatch) => {
  const sapData = {accountSfids : []}
  const userInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");

  userInfo.data.customerInfo.map((rs) => { sapData.accountSfids.push(rs.account_sfid); return null; } )
  axios.post("ordermanagement/productaccessdata", sapData)
  .then((res) => {
      if(res.data.status===200){
          dispatch({
            type: PAGE_REPORT,
            payload:res.data.data
          });
      }
  })
  .catch((err) => {
    console.log(err.message);
      
  });
       
}
export const selectedReportFilter =(data)=>{
    
  return{
    type:SELECTED_REPORT_FILTER,
    payload:data
  }
}

export const sapFilterObj =(data)=>{
    
  return{
    type:SAP_FILTER_OBJ,
    payload:data
  }
}


export const fetchFilterDate =(data)=>{
    
  return{
    type:PAGE_REPORT_DATE,
    payload:data
  }
}
export const selectedFilter =(data)=>{
    
  return{
    type:SELECTED_FILTER_VALUE,
    payload:data
  }
}

export const selectedFilterDate =(data)=>{
    
  return{
    type:SELECTED_DATE_FILTER,
    payload:data
  }
}

export const fetchHistoryLinesData =(data)=>{
  return{
    type:HISTORY_LINES_DATA,
    payload:data
  }
}

export const getHistoryLinesSelectedField =(data)=>{
    
  return{
    type:HISTORY_LINES_FILTER,
    payload:data
  }
}


export const fetchOutputData =(data)=>{
  return{
    type:OUTPUT_DATA,
    payload:data
  }
}

export const getOutputSelectedField =(data)=>{
    
  return{
    type:OUTPUT_FILTER,
    payload:data
  }
}