import Typography from "@mui/material/Typography";
import BayerLogo from "../../assets/images/bayer_logo.svg";
import "../../assets/scss/layout.scss";
import { Link } from 'react-router-dom';
import { useState } from 'react';
import NavHamburger from "../../assets/images/menu_black.svg"
import NavClose from "../../assets/images/close_black.svg"
// import { useMediaQuery } from "react-responsive";
// import { DeviceSize } from "./Responsive";
import  ArrowDown from '../../assets/images/arrow-collapse.svg'
import  ArrowUp from '../../assets/images/arrow-expand.svg'
import  ArrowLeft from '../../assets/images/arrow-left.svg'
import AgencyDropDown from '../common/AgencydropDown';


const NavigationBarResponsive = ()=> {
    const  currentAgency = JSON.parse(localStorage.getItem("SelectedAgency") || "[]");
  const [show, setShow] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [showSales, setShowSales] = useState(false);
  const [showApps, setShowApps] = useState(false);
  const [overlay, setOverlay] = useState(false);
//   const [isActive, setIsActive] = useState('');
  const [menuItem, setMenuItem] = useState('Home');
//   const userInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
//   const UserDetails = userInfo.data.userDetails ? userInfo.data.userDetails : {cont_firstName : 'A', cont_lastName: 'B'};
//   const profile = UserDetails.cont_firstName.trim().charAt(0) + UserDetails.cont_lastName.trim().charAt(0);
  const [showHamburger, setShowHamburger] = useState(true)
//   const isMobile = useMediaQuery({ maxWidth: DeviceSize.tablet });

  const handleClickMenu=()=>{
    setShowHamburger(!showHamburger)
    setOverlay(!overlay)
  }
  const activeStyle = { color: '#FF3162' };
    return (
            <div className="container nav-responsive " >
                 {showHamburger &&
                <div className='nav-logo' style={{marginTop:'10px'}}>
                    <div className='nav-first'>
                    <span>
                        <a href="default.asp">
                        <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                            <img src={BayerLogo} height="70" style={{ paddingRight: "10px" }} alt="Bayer Logo" />
                        </Typography>
                        </a>
                    </span>
                    <span>
                        <h2><b>CropCentre</b></h2>
                    </span>
                    </div>
                    <div>
                        <img src={showHamburger ? NavHamburger : NavClose} className="" alt="hamburger" onClick={handleClickMenu}/>
                    </div>
                </div>}
                {!showHamburger &&<div className='overlay' style={{width:overlay? '99%':'0%'}}>

                <div className='nav-logo'>
                    <div className='nav-header'>
                        <span className='nav-header-arrow'>
                        <img src={ArrowLeft} height="19" style={{ paddingRight: "10px" }} onClick={handleClickMenu} alt="Bayer Logo" />
                        </span>
                        <span className='nav-header-details'>
                        <p style={{color:'#96A8AE'}}>Agency Name</p>
                        <p style={{textTransform:'uppercase',color:'#00617F'}}>{currentAgency.agencyName}</p>
                        <p style={{color:'#00617F'}}>({currentAgency.account_sapAccountNumber})</p>
                        <p style={{color:'#00BCFF'}}><AgencyDropDown showHamburger={showHamburger} /></p>
                        </span>
                    </div>
                </div>
                <ul className="accordion" style={{paddingLeft:'0px',paddingTop:'30px'}}>
                    <li className="accordion_items">
                        <div className='link-row'>
                        <Link  to={"/Dashboard"}  onClick={()=>setMenuItem('home')}><button className="button dropbtn dropbtn-mobile" style={menuItem==='home' ? activeStyle : {}} onClick={handleClickMenu}> Home</button></Link>
                        {/* <span><img src={show ? ArrowDown : ArrowUp} height="15" width="15" ></img></span> */}
                        </div>
                    </li>
                    <li className="accordion_items">
                        <div className='link-row'>
                        <button className="button dropbtn dropbtn-mobile"> Stock Management</button>
                        <span><img src={show ? ArrowDown : ArrowUp} alt='arrow icon' height="15" width="15" onClick={() => setShow(!show)} ></img></span>
                        </div>
                        { show &&<div className="submenu_wrapper" onClick={handleClickMenu}>
                        <Link  to={"/StockManagement/RequestForStock"} style={menuItem==='RequestForStock' ? activeStyle : {}} onClick={()=>setMenuItem('RequestForStock')}>Request For Stock</Link>
                        <Link  to={"/StockManagement/GoodsMovement"} style={menuItem==='GoodsMovement' ? activeStyle : {}} onClick={()=>setMenuItem('GoodsMovement')}>Goods Movement</Link>
                        <Link  to={"/StockManagement/SalesReport"} style={menuItem==='SalesReport' ? activeStyle : {}} onClick={()=>setMenuItem('SalesReport')}>Sales Report</Link>
                        <Link  to={"/StockManagement/StocktakeEntries"} style={menuItem==='StocktakeEntries' ? activeStyle : {}} onClick={()=>setMenuItem('StocktakeEntries')}>Stocktake Entries</Link>
                        </div>}
                    </li>
                    <li className="accordion_items">
                        <div className='link-row'>
                        <button className="button dropbtn dropbtn-mobile"> Reports</button>
                        <span><img src={show ? ArrowDown : ArrowUp} height="15" width="15" alt='arrow icon'  onClick={() => setShowReport(!showReport)} ></img></span>
                        </div>
                        { showReport &&<div className="submenu_wrapper" onClick={handleClickMenu}>
                        <Link  to={"/Reports/BranchAgencySchedule/"} style={menuItem==='BranchAgencySchedule' ? activeStyle : {}} onClick={()=>setMenuItem('BranchAgencySchedule')}>Branch Agency Schedule</Link>
                        <Link  to={"/Reports/BranchAgencyScheduleTracker/"} style={menuItem==='BranchAgencyScheduleTracker' ? activeStyle : {}} onClick={()=>setMenuItem('BranchAgencyScheduleTracker')}>Branch Agency Schedule Tracker</Link>
                        <Link  to={"/Reports/BranchTrainingAgencyStatus/"} style={menuItem==='BranchTrainingAgencyStatus' ? activeStyle : {}} onClick={()=>setMenuItem('BranchTrainingAgencyStatus')}>Branch Training Agency Status</Link>
                        <Link  to={"/Reports/BranchHistory/"} style={menuItem==='BranchHistory' ? activeStyle : {}} onClick={()=>setMenuItem('BranchHistory')}>Branch History</Link>
                        <Link  to={"/Reports/BranchInventoryReport/"} style={menuItem==='BranchInventoryReport' ? activeStyle : {}} onClick={()=>setMenuItem('BranchInventoryReport')}>Branch Inventory Report</Link>
                        <Link  to={"/Reports/BranchKObiolAgentsReport/"} style={menuItem==='BranchKObiolAgentsReport' ? activeStyle : {}} onClick={()=>setMenuItem('BranchKObiolAgentsReport')}>Branch K-Obiol Approved Users Report</Link>
                        <Link  to={"/Reports/BranchProductForecastReport/"} style={menuItem==='BranchProductForecastReport' ? activeStyle : {}} onClick={()=>setMenuItem('BranchProductForecastReport')}>Branch Product Forecast Report</Link>
                        <Link  to={"/Reports/BranchProductPricingAndSOH/"} style={menuItem==='BranchProductPricingAndSOH' ? activeStyle : {}} onClick={()=>setMenuItem('BranchProductPricingAndSOH')}>Branch Product Pricing And SOH</Link>
                        <Link  to={"/Reports/BranchSalesMonthlyReporting/"} style={menuItem==='BranchSalesMonthlyReporting' ? activeStyle : {}} onClick={()=>setMenuItem('BranchSalesMonthlyReporting')}>Branch Sales Monthly Reporting</Link>
                        <Link  to={"/Reports/BranchStocktakeQuarterlyReporting/"} style={menuItem==='BranchStocktakeQuarterlyReporting' ? activeStyle : {}} onClick={()=>setMenuItem('BranchStocktakeQuarterlyReporting')}>Branch Stocktake Quarterly Reporting</Link>
                        <Link  to={"/Reports/BranchUserAccounts/"} style={menuItem==='BranchUserAccounts' ? activeStyle : {}} onClick={()=>setMenuItem('BranchUserAccounts')}>Branch User Accounts</Link>
                        </div>}
                    </li>
                    <li className="accordion_items">
                        <div className='link-row'>
                        <button className="button dropbtn dropbtn-mobile"> Sales District Reports</button>
                        <span><img src={show ? ArrowDown : ArrowUp} alt='arrow icon' height="15" width="15" onClick={() => setShowSales(!showSales)}></img></span>
                        </div>
                        { showSales &&<div className="submenu_wrapper" onClick={handleClickMenu}>
                        <Link  to={"/SalesDistrictReports/SalesDistrictAgencySchedule/"} style={menuItem==='SalesDistrictAgencySchedule' ? activeStyle : {}} onClick={()=>setMenuItem('SalesDistrictAgencySchedule')} >Sales District Agency Schedule</Link>
                        <Link  to={"/SalesDistrictReports/SalesDistrictAgencyScheduleTracker/"} style={menuItem==='SalesDistrictAgencyScheduleTracker' ? activeStyle : {}} onClick={()=>setMenuItem('SalesDistrictAgencyScheduleTracker')}>Sales District Agency Schedule Tracker</Link>
                        <Link  to={"/SalesDistrictReports/SalesDistrictTrainingAgencyStatus/"} style={menuItem==='SalesDistrictTrainingAgencyStatus' ? activeStyle : {}} onClick={()=>setMenuItem('SalesDistrictTrainingAgencyStatus')}>Sales District Training Agency Status</Link>
                        <Link  to={"/SalesDistrictReports/SalesDistrictHistoryQueryViewer/"} style={menuItem==='SalesDistrictHistoryQueryViewer' ? activeStyle : {}} onClick={()=>setMenuItem('SalesDistrictHistoryQueryViewer')}>Sales District History Query Viewer</Link>
                        <Link  to={"/SalesDistrictReports/SalesDistrictInventoryReport/"} style={menuItem==='SalesDistrictInventoryReport' ? activeStyle : {}} onClick={()=>setMenuItem('SalesDistrictInventoryReport')} >Sales District Inventory Report</Link>
                        <Link  to={"/SalesDistrictReports/SalesDistrictPeopleStatusViewer/"} style={menuItem==='SalesDistrictPeopleStatusViewer' ? activeStyle : {}} onClick={()=>setMenuItem('SalesDistrictPeopleStatusViewer')}>Sales District People Status</Link>
                        <Link  to={"/SalesDistrictReports/SalesDistrictProductPricingAndSOH/"} style={menuItem==='SalesDistrictProductPricingAndSOH' ? activeStyle : {}} onClick={()=>setMenuItem('SalesDistrictProductPricingAndSOH')}>Sales District Product Pricing And SOH</Link>
                        <Link  to={"/SalesDistrictReports/SalesDistrictSalesMonthlyReporting/"} style={menuItem==='SalesDistrictSalesMonthlyReporting' ? activeStyle : {}} onClick={()=>setMenuItem('SalesDistrictSalesMonthlyReporting')}>Sales District Sales Monthly Reporting</Link>
                        <Link  to={"/SalesDistrictReports/SalesDistrictSalesAndStockAnalysis/"} style={menuItem==='SalesDistrictSalesAndStockAnalysis' ? activeStyle : {}} onClick={()=>setMenuItem('SalesDistrictSalesAndStockAnalysis')} >Sales District Sales and Stock Analysis Report</Link>
                        <Link  to={"/SalesDistrictReports/SalesDistrictStocktakeQuarterlyReporting/"} style={menuItem==='SalesDistrictStocktakeQuarterlyReporting' ? activeStyle : {}} onClick={()=>setMenuItem('SalesDistrictStocktakeQuarterlyReporting')}>Sales District Stocktake Quarterly Reporting</Link>
                        </div>}
                    </li>
                    <li className="accordion_items">
                        <div className='link-row'>
                        <Link to={`#`} onClick={()=>setMenuItem('ebda')} ><button className="button dropbtn dropbtn-mobile" style={menuItem==='ebda' ? activeStyle : {}}> eBDA</button></Link>
                        {/* <span><img src={show ? ArrowDown : ArrowUp} height="15" width="15" ></img></span> */}
                        </div>
                        
                    </li>
                    <li className="accordion_items">
                        <div className='link-row'>
                        <Link to={`#`} onClick={()=>setMenuItem('training')} ><button className="button dropbtn dropbtn-mobile" style={menuItem==='training' ? activeStyle : {}}> Training</button></Link>
                        {/* <span><img src={show ? ArrowDown : ArrowUp} height="15" width="15" ></img></span> */}
                        </div>
                        
                    </li>
                    <li className="accordion_items">
                        <div className='link-row'>
                        <button className="button dropbtn dropbtn-mobile"> Apps</button>
                        <span><img src={show ? ArrowDown : ArrowUp} alt='arrow icon'  height="15" width="15" onClick={() => setShowApps(!showApps)}></img></span>
                        </div>
                        { showApps &&<div className="submenu_wrapper" onClick={handleClickMenu}>
                        <Link  to={"/StockManagement/RequestForStock"} style={menuItem==='RequestForStock' ? activeStyle : {}} onClick={()=>setMenuItem('RequestForStock')} >Trial Tracker</Link>
                        </div>}
                    </li>
                    <li className="accordion_items">
                        <div className='link-row'>
                        <Link to={`#`} onClick={()=>setMenuItem('help')} ><button className="button dropbtn dropbtn-mobile" style={menuItem==='help' ? activeStyle : {}}> Help</button></Link>
                        {/* <span><img src={show ? ArrowDown : ArrowUp} height="15" width="15" ></img></span> */}
                        </div>
                        
                    </li>
                    <li className="accordion_items">
                        <div className='link-row'>
                        <Link to={`/account/MyAccount`} onClick={()=>setMenuItem('MyAccount')} ><button className="button dropbtn dropbtn-mobile"  style={menuItem==='MyAccount' ? activeStyle : {}} onClick={handleClickMenu}> My Account</button></Link>
                        {/* <span><img src={show ? ArrowDown : ArrowUp} height="15" width="15" ></img></span> */}
                        </div>
                        
                    </li>
                </ul>
                </div>}
            </div>
      );
}
export default NavigationBarResponsive;