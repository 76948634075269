import React , {useState} from "react";
import axios from '../../_helpers/axios';

import * as d3 from "d3";
const LineChart = (props) => {
  var tooltip = d3.select("body").append("div").attr("class", "toolTip");
     const [graphData, setGraphData] = useState([]);
    //  const [graphType , setGraphType] = useState(props.graphType);
     const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
     let windowDynaWidth = 700;
    let windowDynaheight = 300;

    const monthName =  {
      "01": 'Jan',
      "02": 'Feb',
      "03":'Mar',
      "04": 'Apr',
      "05": 'May',
      "06": "Jun",
      "07": 'Jul',
      "08": 'Aug',
      "09": 'Sep',
      "10": 'Oct',
      "11": 'Nov',
      "12" : 'Dec'
    }
     
    React.useEffect(() => {
      
      document.querySelectorAll('.toolTip').forEach(function(el) {
        el.style.display = 'none';
     });
     
      // d3.select("#my_dataviz").selectAll("svg").remove();
      // drawChart();
    
      }, [props.graphType]);

      function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }

      React.useEffect(() => {
      if(windowDimensions.width>=1900){
        windowDynaWidth = 800
        windowDynaheight = 300
        drawChart();
      } else if(windowDimensions.width>=1700 && windowDimensions.width<1900){
        windowDynaWidth = 700
        windowDynaheight = 280
        d3.select("#my_dataviz").selectAll("svg").remove();
        drawChart();
      } else if(windowDimensions.width>=1590 && windowDimensions.width<1700){
        windowDynaWidth = 670
        windowDynaheight = 270
        d3.select("#my_dataviz").selectAll("svg").remove();
       drawChart();
      } else if(windowDimensions.width>=1400 && windowDimensions.width<1590){
        windowDynaWidth = 630
        windowDynaheight = 260
        d3.select("#my_dataviz").selectAll("svg").remove();
       drawChart();
      } else if(windowDimensions.width>=1270 && windowDimensions.width<1400){
        windowDynaWidth = 580
        windowDynaheight = 250
        d3.select("#my_dataviz").selectAll("svg").remove();
       drawChart();
      } else if(windowDimensions.width>=1164 && windowDimensions.width<1270){
        windowDynaWidth = 530
        windowDynaheight = 180
        d3.select("#my_dataviz").selectAll("svg").remove();
       drawChart();
      } else if(windowDimensions.width>=1024 && windowDimensions.width<1164){
        windowDynaWidth = 490
        windowDynaheight = 180
        d3.select("#my_dataviz").selectAll("svg").remove();
       drawChart();
      }else if(windowDimensions.width>=954 && windowDimensions.width<1023){
        windowDynaWidth = 470
        windowDynaheight = 210
        d3.select("#my_dataviz").selectAll("svg").remove();
       drawChart();
      }
        }, [windowDimensions , props.graphType]);

      React.useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

     const getMonthName = (name) => {
       
       return monthName[name];
     }
      
      
      const drawChart = () => {
        const  UserInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
        const custInfo = UserInfo.data.customerInfo[0];
        let data;
        axios.get("util/salesforecast?accountSfid="+custInfo.account_sfid+"&salesGroup="+custInfo.salesArea_salesGroup)
        .then((res) => {
            
            if(res.data.status===200){
             data = res.data.data;
             setGraphData(res.data.data)
        const margin = {top: 10, right: 30, bottom: 20, left: 60},
        // width = 700 - margin.left - margin.right,
        // height = 300 - margin.top ;- margin.bottom;
        width = windowDynaWidth - margin.left - margin.right,
        height = windowDynaheight - margin.top - margin.bottom;
        // var legendRectSize = 18;
        // var legendSpacing = 4;

        // const data = [
        //     {year:'1880' , n: '662', name:'Sale'},
        //     {year:'1900' , n: '883', name:'Forcast'},
        //     {year:'1901' , n: '772' , name:'Forcast'},
        //     {year:'1903' , n: '873' , name:'Sale'},
        //     {year:'1909' , n: '883' , name:'Forcast'}
        // ]
    
    // append the svg object to the body of the page
    const svg = d3.select("#my_dataviz")
      .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
      .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`)
       

    
      // color palette
      const color = d3.scaleOrdinal()
        .range(['#e41a1c','#377eb8','#4daf4a'])
      // group the data: I want to draw one line per group
      const sumstat = d3.group(data, d => d.year); // nest function allows to group the calculation per level of a factor
    
      // Add X axis --> it is a date format
      const x = d3.scaleLinear()
        .domain(d3.extent(data, function(d) { return d.Month; })) 
        //.domain([1,12])
        .range([ 0, width ]);
      svg.append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(x).ticks(12));
     
      // Add Y axis
      const y = d3.scaleLinear()
        .domain([0, d3.max(data, function(d) { 
            if(props.graphType==='Sale') {
              return d.salesListPrice;
            }else {
              return d.plannedForecastListPrice;
            }
            }
         )])
        .range([ height, 0 ]);
       svg.append("g")
        .text("Y Axis")
        .call(d3.axisLeft(y));
        //Atomated legends commented for now
        // svg.selectAll("mydots")
        // .data(sumstat)
        // .enter()
        // .append("circle")
        //   .attr("cx", 700)
        //   .attr("cy", function(d,i){ return 0 + i*25}) // 100 is where the first dot appears. 25 is the distance between dots
        //   .attr("r", 7)
        //   .style("fill", function(d){ return color(d[0])})
        //   svg.selectAll("mylabels")
        //   .data(sumstat)
        //   .enter()
        //   .append("text")
        //     .attr("x", 720)
        //     .attr("y", function(d,i){ return 0 + i*25}) // 100 is where the first dot appears. 25 is the distance between dots
        //     .style("fill", function(d){ return color(d[0].key)})
        //     .text(function(d){ return d[0]})
        //     .attr("text-anchor", "left")
        //     .style("alignment-baseline", "middle")

        // label for Y Axis
           svg.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 - margin.left)
        .attr("x",0 - (height / 2))
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .text(props.graphType==="Sale"?"Sales in Value":"Forecast in Value");
          // Add the text label for the x axis
          svg.append("text")
          .attr("transform", "translate(" + (width / 2) + " ," + (height + margin.bottom) + ")")
          .style("text-anchor", "middle")
          .text("Months");

      // Draw the line
      svg.selectAll(".line")
          .data(sumstat)
          .join("path")
            .attr("fill", "none")
            .attr("stroke", function(d){ return color(d[0]) })
            .attr("stroke-width", 2)
            .attr("d", function(d){
              return d3.line()
                .x(function(d) {   return x(d.Month) })
                .y(function(d) { 
                  if(props.graphType==='Sale') {
                    return y(+ d.salesListPrice); 
                  }else {
                    return y(+ d.plannedForecastListPrice); 
                  }
                  
                
                })
                (d[1])
              });
              svg.selectAll("dot")
              .data(data)
                .enter().append("circle")
                .attr("r", 5)
                .attr("fill", "gray")
                .attr("cx", function(d) { return x(d.Month); })
                .attr("cy", function(d) {
                  if(props.graphType==='Sale') {
                    return y(+ d.salesListPrice);
                  }else {
                    return y(+ d.plannedForecastListPrice); 
                  }
                   
                  
                  })
                .on("mouseover", function(event,d) {
                  tooltip
                  .style("left", event.pageX - 50 + "px")
                  .style("top", event.pageY - 120 + "px")
                  .style("display", "inline-block")
                  .style("opacity", .9)
                  .html(("Months: " +getMonthName(d.Month)) + "<br> Value($): "
                   + (props.graphType==='Sale'?d.salesListPrice:d.plannedForecastListPrice) 
                   + "<br> Volume(Kg/L) :  " + (props.graphType==='Sale'?d.salesQty:d.plannedForecastQty));
                  })
                  .on("mouseleave", function(event,d) {
                    setTimeout(() => {
                      tooltip
                      .style("display", "none")
                      })
                      
                    }, 2000);
                   
                  
            }
            
        })
        .catch((err) => {
           console.log(err.message);
            
        });
      
    
      }

    return (
        <>
        
        {/* <div style={{margin:10}} className="notification-head ">Sales and Forcast </div> */}
         
         {graphData.length === 0 && <div  style={{margin:10,textAlign: 'center',font: 'normal normal bold 20px/24px Helvetica Neue', color: '#96A8AE'}} > No Data To Show</div>}
         <div id="my_dataviz"></div>
         {graphData.length > 0 &&
         <div style={{fontSize: '12px', textAlign: 'center'}}>
        <p style={{fontFamily: 'Helvetica Neue', color: '#96A8AE'}}> 
        <span className="red-circle"></span> &nbsp;2021&nbsp;  
        <span className="blue-circle"></span>&nbsp; 2022  </p></div>
        }
         
        </>
    );
};
export default LineChart;