import { GET_SALES, SET_ERRORS } from "../../types";


const initialState = {
    salesData: [],
    isAgencyChnage:false,
    loading:false,
    errors:[]
}

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_SALES:
			return {
                ...state,
                salesData:action.payload,
                errors:[],
                isAgencyChnage:true
              }
        case SET_ERRORS:
                return {
                    ...state,
                    errors:action.payload
                  }
		default:
			return state;
	}
}
