import {CSVLink} from 'react-csv';
const DownLoadCsv = (props) => {
    const obj1 = props.data;
    
     let data2 =  obj1.map(d=> {
        //data.push(d.reportDataWrapperList);
       return d.salesStkAnalysisWrpList;
      } );
     
     let finalDta = [];
      data2.map(rs=> {
        rs.map(rs2=> {
             let NewData = {
               'BranchName': rs2.accountName,
               'BranchNumber': rs2.branchNumber,
               'SalesGroup':rs2.salesGroup,
               'SAPMaterial':rs2.sapMaterialNbr,
               'SAPDescription':rs2.productName,
               'SupplyGap':rs2.supplyGap,
               'CustomerPlan':rs2.customerPlan,
               'TSOH':rs2.tsoh,
               'SOH':rs2.soh,
               'SITFB':rs2.sitfb,
               'SITFO':rs2.sitfo,
               'SBP':rs2.sbp,
               'RTT':rs2.rtt,
               'SUI':rs2.sui,
               'TSR':rs2.tsr,
               'TSRLY':rs2.tsrly,
               'SOR':rs2.sor,
               'FCTM':rs2.fctm,
               'FCTY':rs2.fcty,
               'STM':rs2.stm,
               'SYTD':rs2.sytd,
               'SLY':rs2.sly
             }
            finalDta.push(NewData);
            return null;
        })
        return null;
      })

   const header = Object.keys(finalDta[0]);

    return (
        <>
        {finalDta.length > 0 && 
         <CSVLink  filename={props.filename} data={finalDta} headers={header}>
         Your file is available here: File - Right click and [save target as] or [save link as].... to download
        </CSVLink>
        }
          {finalDta.length <= 0 && <div style={{color:'red'}}>No records to Load CSV</div>}
        </>
    )
}

export default DownLoadCsv;