import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';

const HistoryQueryViewerList = (props: any) => {
  return (
    <>
      {props.data?.map((row: any, key: any) => {
        return (
          <Container style={{ margin: '100px 0px' }}>
            <Row className='report-list-details'>
              <Col className='result-title'><h5>{row.branchName + ' (' + row.branchNumber + ') '+' (' +row.reportDataWrapperList[0]?.salesGroup+')'}</h5></Col>
              <Col className='right result-title'><h5>{row.SAPDescription + ' (' + row.SAPMaterialNumber + ' )'}</h5></Col>
            </Row>
            <Row className='report-list-header'>
              <Col md="2"><p>Line Type</p></Col>
              <Col md="1"><p>Status</p></Col>
              <Col md="1"><p>Reference Number</p></Col>
              <Col md="1"><p>Date</p></Col>
              <Col md="1" className='right'><p>Qty</p></Col>
              <Col md="1" className='right'><p>Running Balance</p></Col>
              <Col md="1"><p>User Name</p></Col>
              <Col md="1"><p>Tracking Numbers</p></Col>
              <Col md="2"><p>Comments</p></Col>
            </Row>
            <div className='report-fields-content-div'>{
              row.reportDataWrapperList?.map((r: any, k: any) => {
                var bgColor = (k + 1) % 2 === 0 ? 'white' : '#89d3290d';
                return (<>
                  <Row className='report-list-row' style={{ backgroundColor: bgColor }}>
                    <Col md="2"><p>{r.lineType}</p></Col>
                    <Col md="1"><p>{r.status}</p></Col>
                    <Col md="1"><p>{r.referenceNumber}</p></Col>
                    <Col md="1"><p>{r.transactionDate.split(" ")[0]}</p></Col>
                    <Col md="1" className='right'><p>{r.qty}</p></Col>
                    <Col md="1" className='right'><p>{r.runningBalance}</p></Col>
                    <Col md="1"><p>{r.userName}</p></Col>
                    <Col md="1"><p>{r.trackingnumber}</p></Col>
                    <Col md="2"><p>{r.comments}</p></Col>
                  </Row>
                </>)
              })
            }</div>
             <hr />
    <p style={{color:'gray'}}>Report run date: {props.data[0].strTimeInAMorPM}</p>
          </Container>)
          
      })}
    </>
  )
}

export default HistoryQueryViewerList