import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from "./Responsive";
import { Link } from 'react-router-dom';
import React, { useState } from 'react'
import './header.scss'
import pharmaceuticals from '../../assets/images/pharmaceuticals.jpg'
import consumerHealth from '../../assets/images/consumer-health.jpg'
import cropScience from '../../assets/images/crop-science.jpg'
import animalHealth from '../../assets/images/animal-health.jpg'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import ArrowIcon from "../../assets/images/btn_rt_arrow.svg";
import RtLayout from "../../assets/images/rt_layout.svg";
// import SearchIcon from '../../assets/images/search_icon.svg';
import SearchIcon from '@mui/icons-material/Search';

const Header = () => {
    const isMobile = useMediaQuery({ maxWidth: DeviceSize.tablet });
    const [businessDropdownVisible, setBusinessDropdownVisible] = useState(false);
    const [locationVisible, setLocationVisible] = useState(false);
    const [websiteVisible, setWebsiteVisible] = useState(false);
    const [dropdownbtn1, setDropdownbtn1] = useState(false);
    const [dropdown1, setDropdown1] = useState('');




    const handleBusiness=()=>{
        setBusinessDropdownVisible(!businessDropdownVisible)
        setLocationVisible(false)
        setWebsiteVisible(false)
    }
    const handleLocation=()=>{
        setLocationVisible(!locationVisible)
        setBusinessDropdownVisible(false)
        setWebsiteVisible(false)
    }
    const handleWebsite=()=>{
        setWebsiteVisible(!websiteVisible)
        setLocationVisible(false)
        setBusinessDropdownVisible(false)
    }
    const handleClickbtn1 = () =>{
        setDropdownbtn1(!dropdownbtn1)
    }
    const onClickValue = (value) =>{
        setDropdownbtn1(!dropdownbtn1)
        setDropdown1(value)
    }
    

    return (
        <header>
            {isMobile &&<div className="img-list-mobile"> &#47;&#47;&#47;&#47;&#47;&#47; Science for <b style={{marginLeft:'4px'}}> better life</b></div>}
            {!isMobile && <div className="header-row">
                <div className="img-list"> &#47;&#47;&#47;&#47;&#47;&#47; Science for <b style={{marginLeft:'4px'}}> better life</b></div>
                <div className="bayer-link">
                    <div className="menu-list layout link-padding">
                        <Link to={{pathname:"https://www.bayer.com/en/"}} target="_blank">Bayer Global</Link></div>
                    <div className="menu-list" onClick={handleBusiness}  style={{color:businessDropdownVisible ? '#00BCFF':'' }}>
                        <span>Our Businesses</span><span > {businessDropdownVisible ? <KeyboardArrowUpIcon/>:<KeyboardArrowDownIcon />}</span>
                    </div>
                    <div className="menu-list" onClick={handleLocation}  style={{color:locationVisible ? '#00BCFF':'' }}>
                        {/* Our Location <KeyboardArrowDownIcon /> */}
                        <span>Our Location</span><span  > {locationVisible ? <KeyboardArrowUpIcon/>:<KeyboardArrowDownIcon />}</span>

                    </div>
                    <div className="menu-list" onClick={handleWebsite}  style={{color:websiteVisible ? '#00BCFF':'' }}>
                        <span>Our Websites</span><span  > {websiteVisible ? <KeyboardArrowUpIcon/>:<KeyboardArrowDownIcon />}</span>

                    </div>
                    <div className="menu-list link-padding">
                    <Link to={{pathname:"https://www.bayer.com/en/contact-us"}} target="_blank">Contact Us </Link>
                    </div>
                </div>
            </div>}
            {/* {!businessDropdownVisible ? showBusinessdropdwn:''} */}
            {/* {showBusinessdropdwn} */}
            { businessDropdownVisible &&<div className="business-overlay"> <div className="business-dropdown">
                <div className="dropdown-item">
                    <h5>Pharmaceuticals</h5>
                    <Link to={{pathname:"https://www.bayer.com/en/pharma/pharmaceuticals"}} target="_blank"><img src={pharmaceuticals} alt ='dropdown icon' className='dropdown-img'/></Link> 
                    <p>Pharmaceuticals focuses on prescription drugs for the therapeutic areas of cardiology, oncology, gynecology, hematology & ophthalmology.</p>
                    <p className="dropdown-link"><Link to={{pathname:"https://www.bayer.com/en/pharma/pharmaceuticals"}} target="_blank">&#47;&#47;&#47; GLOBAL SITE</Link></p>
                    
                </div>
                <div className="dropdown-item">
                <h5>Consumer Health</h5>
                <Link to={{pathname:"https://consumerhealth.bayer.com/"}} target="_blank"><img src={consumerHealth} alt='dropdown icon' className='dropdown-img'/></Link>
                    <p>Consumer Health brings consumers some of the world’s best-known and most trusted over-the-counter (OTC) medications, nutritional supplements and other self-care products.</p>
                    <p className="dropdown-link"><Link to={{pathname:"https://consumerhealth.bayer.com/"}} target="_blank">&#47;&#47;&#47; GLOBAL SITE</Link></p>
                </div>
                <div className="dropdown-item"> 
                    <h5>Crop Science</h5>
                    <Link to={{pathname:"https://www.bayer.com/en/agriculture"}} target="_blank"><img src={cropScience} alt='dropdown icon' className='dropdown-img'/></Link>
                    <p>Crop Science has businesses in seeds, crop protection and non-agricultural pest control.</p>
                    <p className="dropdown-link"><Link to={{pathname:"https://www.bayer.com/en/agriculture"}} target="_blank">&#47;&#47;&#47; GLOBAL SITE</Link></p>
                </div>
                <div className="dropdown-item">
                <h5>Animal Health</h5> 
                    <Link to={{pathname:"https://www.elanco.com/en-us/welcome"}} target="_blank"><img src={animalHealth} alt='dropdown icon' className='dropdown-img'/></Link>
                    <p>Consumer Health brings consumers some of the world’s best-known and most trusted over-the-counter (OTC) medications, nutritional supplements and other self-care products..</p>
                    <p className="dropdown-link"><Link to={{pathname:"https://www.elanco.com/en-us/welcome"}} target="_blank">&#47;&#47;&#47; GLOBAL SITE</Link></p>
                </div>
                <div className="dropdown-item dropdown-item-link">
                    <div><span className="dropdown-arrow"><KeyboardArrowRightIcon /></span><span><Link to={{pathname:"https://www.bayer.com/en/strategy/profile-and-organization"}} target="_blank">Profile and Organization</Link></span></div>
                    <div><span className="dropdown-arrow"><KeyboardArrowRightIcon /></span><span><Link to={{pathname:"https://www.bayer.com/bayer-organizational-structure-2020-09-01"}} target="_blank">Organization chart (PDF, 174 KB)</Link></span></div>
                    <div><span className="dropdown-arrow"><KeyboardArrowRightIcon /></span><span><Link to={{pathname:"https://www.bayer.com/en/"}} target="_blank">Business Services</Link></span></div>

                </div>
                <span >
                    <CloseIcon className="dropdown-close" onClick={handleBusiness}/>
                </span>
            </div>
            </div>

            }
            { locationVisible &&<div className="business-overlay"> <div className="business-dropdown">
                <div className="dropdown-item dropdown-location">
                    <h2>Select Location</h2>
                    
                </div>
                
                <div className="dropdown-item dropdown-item-link dropdown-location-link">
                    <div className="location-div"><span className="dropdown-arrow"><KeyboardArrowRightIcon /></span><span><Link to={{pathname:"https://www.bayer.com/en/"}} target="_blank">Bayer Global</Link></span></div>
                    <div className="location-div"><span className="dropdown-arrow"><KeyboardArrowRightIcon /></span><span><Link to={{pathname:"https://media.bayer.com/baynews/baynews.nsf/id/news-overview-category-search-en#/search"}} target="_blank">News</Link></span></div>
                    <div className="location-div"><span className="dropdown-arrow"><KeyboardArrowRightIcon /></span><span><Link to={{pathname:"http://www.investor.bayer.com/"}} target="_blank">Investors</Link></span></div>
                    <div className="location-div"><span className="dropdown-arrow"><KeyboardArrowRightIcon /></span><span><Link to={{pathname:"https://www.career.bayer.com/"}} target="_blank">Career</Link></span></div>
                    <div className="location-div"><span className="dropdown-arrow"><KeyboardArrowRightIcon /></span><span><Link to={{pathname:"https://www.bayer.com/en/products/products-overview"}} target="_blank">Products</Link></span></div>
                    <div className="location-div"><span className="dropdown-arrow"><KeyboardArrowRightIcon /></span><span><Link to={{pathname:"https://www.bayer.com/en/sustainability"}} target="_blank">Sustainability</Link></span></div>
                    
                    <Link to={{pathname:"https://www.bayer.com/en/contact-us"}} target="_blank">
                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                        backgroundColor: "#ff3162",
                        boxShadow:'none',
                        "&:hover": {
                            backgroundColor: "#ff3162",
                            boxShadow:'none',
                            p: {
                                paddingLeft: "60px",
                            },
                        },
                        borderRadius: " 0px",
                    }}
                    className="btn-right contact-btn-dimensions"
                >
                    <p  className="contact-btn"> CONTACT US</p>
                    <span>
                        <img src={ArrowIcon} className="arrow-i" alt="Arrow" />
                        <img src={RtLayout} className="contact-rt-layout" alt="layout" />
                    </span>
                </Button>
                </Link>



                </div>
                <span >
                    <CloseIcon className="dropdown-close" onClick={handleLocation}/>
                </span>
            </div>
            </div>

            }
            { websiteVisible &&<div className="business-overlay"> <div className="business-dropdown">
                <div className="dropdown-item dropdown-website">
                    <h3>Search for Bayer Websites worldwide</h3>
                    <div className="website-drpdwn-btn">
                        <div className="dropdown website-btn">
                            <button className="btn header-website" type="button" onClick={handleClickbtn1}>
                                {dropdown1}
                            </button>
                            
                            { dropdownbtn1 && <ul className="dropdown-btn1" >
                                <li className="web-dropdown-item" onClick={()=>{onClickValue('value1')}}>value1</li>
                                <li className="web-dropdown-item" onClick={()=>{onClickValue('value2')}}>value2</li>
                            </ul>}
                        </div>

                        <div className="dropdown website-btn">
                            <button className="btn header-website" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li className="web-dropdown-item" onClick={()=>{onClickValue('')}}></li>

                            </div>
                        </div>

                        <div className="dropdown website-btn">
                            <button className="btn  header-website" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                            </div>
                        </div>

                        <div className="dropdown website-btn">
                            <button className="btn  header-website" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                            </div>
                        </div>

                        <div className="dropdown website-btn">
                            <button className="btn header-website" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                            </div>
                        </div>

                        <div className="dropdown website-btn">
                            <span className="website-search-btn"> 
                            <input type="text" className="search-website" placeholder="Enter your search term" />
                            <button className="btn header-website-search" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                               <SearchIcon className="search-icon-custom"/>
                            </button>
                            </span>
                           
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                            </div>
                        </div>

                    </div>
                    <h3 className="website-result">Search result:</h3>
                    
                        

                    
                </div>
                <p className="dropdown-website-p">
                        Note: all the categories are linked so you will get at least one hit for any query. This does not apply to the free-text search function.
                </p>
                
               
                <span >
                    <CloseIcon className="dropdown-close" onClick={handleWebsite}/>
                </span>
            </div>
            </div>

            }

        </header>
    );
};
export default Header;