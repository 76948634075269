import React from 'react'

const ConditionsOfUse = () => {
  return (
      <div className='conditions-of-use'>
       <article className="grid-col">
					<h1>General Conditions Of Use</h1>
<p className="justify">Access to and use of this website are subject to the following conditions. Please do not use this website unless you agree with these conditions. This website has been developed by Bayer CropScience Pty Ltd (hereinafter to be referred to as BAYER) and is administrated by the same. We reserve the right to discontinue or to make partial or complete modifications to this website or to the General Conditions of Use, to our General Terms and Conditions, and to our Conditions of Sale and Delivery. Please note that we may make such changes at our own discretion and without prior announcement. We must therefore ask you, next time you visit this website, to view the conditions again and to note any changes or amendments that may have been made.</p>
<h3>Surrender of use and benefit</h3>
<p className="justify">All details, documents and illustrations published on this website are the sole property of BAYER. Any permission to use the same is granted on the proviso that the relevant copyright note is displayed on all copies, that such details are only used for personal purposes, that they are not exploited commercially, that the details are not modified in any way and that all illustrations gained from the website are only used in conjunction with the accompanying text.</p>
<h3>Trademarks and Copyright</h3>
<p className="justify">All trademarks on this website are the property of the Bayer Group, unless otherwise noted or in any other way perceivable as third party rights. Any unauthorized use of these trademarks or other materials is expressly prohibited and constitutes a violation of copyright, trademark law or other industrial property rights.</p>
<h3>Limited liability</h3>
<p className="justify">BAYER has compiled the detailed information provided on this website from internal and external sources to the best of its knowledge and belief, using professional diligence. We endeavor to expand and update this range of information on an ongoing basis. The information on this website is purely for the purpose of presenting BAYER and its products and services. However, no representation is made or warranty given, either expressly or tacitly, for the completeness or correctness of the information on this website. Please be aware that this information although accurate on the day it was published may no longer be up to date. We therefore recommend that you check any information you obtain from this website prior to using it in whatever form. Advice given on this website does not exempt you from conducting your own checks on our latest advice - particularly our safety datasheets and technical specifications - and on our products, with a view to their suitability for the intended processes and purposes. Should you require any advice or instructions concerning our products or services, please contact us directly. Users of this website declare that they agree to access the website and its content at their own risk. Neither BAYER nor third parties involved in the writing, production or transmission of this website can be held liable for damage or injury resulting from access or the impossibility of access or from the use or impossibility of use of this website or from the fact that you have relied on information given on this website.</p>
<p>Important disclaimer: The Chief Executive Officer of the Department of Agriculture and Food, Western Australian Agriculture Authority and the State of Western Australia accept no liability whatsoever by reason of negligence or otherwise arising from the use of the Prosaro Scale, Blackleg Sporacle model or this website; the release of this information or any part of it; or the use of any product or procedure recommended by this website.</p>
<h3>Websites of third-party vendors/links</h3>
<p className="justify">This website contains links/references to third-party websites. By providing such links, BAYER does not give its approval to their contents. Neither does BAYER accept any responsibility for the availability or the contents of such websites or any liability for damage or injury resulting from the use of such contents, of whatever form. BAYER offers no guarantee that pages linked to provide information of consistent quality. Links to other websites are provided to website users merely for the sake of convenience. Users access such websites at their own risk. The choice of links should in no way restrict users to the linked pages.</p>
<h3>Details supplied by yourself</h3>
<p className="justify">The user of this website is fully responsible for the content and correctness of details he or she sends to BAYER as well as for the non-violation of any third-party rights that may be involved in such details. The user gives his or her consent for BAYER to store such details and to use the same for the purpose of statistical analysis or for any other specified business purpose, unless the information involves personal details, going beyond master data or usage data. In particular, Bayer is entitled to use the contents of such messages, including ideas, inventions, blueprints, techniques and expertise contained therein, for any purpose, such as the development, production and/or marketing of products or services and to reproduce such information and make it available to third parties without any limitations.</p>
<h3>International users</h3>
<p className="justify">This website is checked, operated and updated by BAYER at Melbourne, Australia. It is exclusively intended for use in Australia. BAYER gives no guarantee that the details presented on this website are also correct in places outside Australia, and, in particular, that products and services will be available with the same appearance, in the same sizes or on the same conditions. Should you call up this website from a place outside Australia or download contents from such a place, please note that it is your own responsibility to ensure that you act in compliance with local legislation applicable in that place.</p>
<p className="justify">Products mentioned on this website may come in different packaging, in different package sizes, or with different lettering or markings, depending on the country.</p>
<p className="justify">In the USA the business of the Bayer Group is conducted by Bayer Corporation. Customers in the USA are requested to address to one of these entities.</p>
<h3>Sale of BAYER Products</h3>
<p className="justify">Our products are sold in accordance with the current version of our General Conditions of Sale and Delivery.</p>
<h3>Site with login: access rights</h3>
<p className="justify">Please note that you are obliged to protect your access rights from unauthorized third-party use and that you must ensure that they cannot be used by others without your authorization. <br />
You must notify BAYER immediately if you learn that security has been violated in respect of any details available on the BAYER website or if unauthorized persons have obtained your access rights or if you have found indications that such access might become possible.</p>
<h3>Forward-Looking Statements</h3>
<p className="justify">This website may contain forward-looking statements based on current assumptions and forecasts made by Bayer Group or subgroup management. Various known and unknown risks, uncertainties and other factors could lead to material differences between the actual future results, financial situation, development or performance of the company and the estimates given here. These factors include those discussed in Bayer’s public reports which are available on the Bayer website at <a href="http://www.bayer.com/">http://www.bayer.com/</a>. The company assumes no liability whatsoever to update these forward-looking statements or to conform them to future events or developments.</p>
<h3>Technical Disclaimer</h3>
<p>Always read the label for full instructions.<br />
The information and recommendations set out on this website are based on tests and data believed to be reliable at the time of publication. Results may vary, as the use and application of the products is beyond our control and may be subject to climatic, geographical or biological variables, and/or developed resistance. Any product preferred to on this website must be used strictly as directed, and in accordance with all instructions appearing on the label for that product and in other applicable reference material. So far as it is lawfully able to do so, Bayer CropScience Pty Ltd accepts no liability or responsibility for loss or damage arising from failure to follow such directions and instructions.<br />
&nbsp;</p>
				</article>
      </div>
  )
}

export default ConditionsOfUse