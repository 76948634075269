import React from 'react'

const SalesTermsConditions = () => {
  return (
    <div>
        <article className="grid-col">
            <h1>Sales Terms And Conditions</h1>
            <h2>1. Definitions and Interpretation</h2>
            <p className="justify">1.1 In these Terms and any related Contract, unless the contrary intention appears:</p>
            <p className="justify"><strong>Bayer </strong>means Bayer Cropscience Pty Ltd (ABN 87 000 226 022).</p>
            <p className="justify"><strong>Business Day </strong>means a day that is not a Saturday, Sunday or public holiday in Victoria or, for deliveries of Products, at  the place of delivery.</p>
            <p className="justify"><strong>Contract</strong> means a contract for sale as referred to in clause 2.5.</p>
            <p className="justify"><strong>Customer </strong>means the person who orders Products from Bayer, whether by telephone, facsimile, email, in person, through Bayer's online ordering system or otherwise.</p>
            <p className="justify"><strong>Force Majeure </strong>means any circumstance beyond the  reasonable control of a party which results in a party being unable to  observe or perform on time an obligation under these Terms.</p>
            <p className="justify"><strong>GST </strong>has the same meaning as in the A New Tax System (Goods &amp; Services Tax) Act 1999 (Cth) (as amended).</p>
            <p className="justify"><strong>Insolvency Event </strong>means circumstances in which  Customer is unable to pay its debts as they fall due or otherwise takes  any corporate action or any steps are taken or legal proceedings are  started for: (a) its winding-up, dissolution, liquidation, or  re-organisation, other than to reconstruct or amalgamate while solvent  on terms approved by Bayer; (b) the appointment of a controller,  receiver, administrator, official manager, trustee or similar officer of  it or of any of its revenues and assets; or (c) seeks protection or is  granted protection from its creditors, under any applicable legislation.</p>
            <p className="justify"><strong>Order </strong>means a purchase order for Products which has  been accepted by Bayer but excluding any terms or conditions printed on  or referred to in Customer’s purchase orders or other documentation unless expressly agreed to in writing by Bayer.</p>
            <p className="justify"><strong>PPSA </strong>means the Personal Properties Securities Act 2009 (Cth) (as amended from time to time).</p>
            <p className="justify"><strong>Products</strong> mean the goods purchased or to be purchased by Customer from Bayer which are the subject of a Contract.</p>
            <p className="justify"><strong>Terms </strong>means these terms and conditions of sale.</p>
            <h2>2. Orders</h2>
            <p className="justify">2.1 All orders for Products must be placed in the manner and form required by Bayer from time to time. <br/>
            2.2 Bayer may in its absolute discretion determine from time to time a  “Specified Order Quantity”, being the minimum order value or quantity  for each order of Products to be supplied to Customer. <br />
            2.3 All orders will be subject to acceptance by Bayer, which may decline an order or accept an order in whole or part in its absolute  discretion. Customer acknowledges that acceptance of an order by Bayer  will not imply that Bayer will accept any future order(s) placed by  Customer. <br />
            2.4 Once accepted by Bayer, an Order may not be cancelled by Customer except with the express consent of Bayer. <br />
            2.5 Upon the acceptance of each Order by Bayer, a separate contract of  sale will arise. Each Contract will comprise the accepted Order and  these Terms. If there is any inconsistency between these Terms and  another provision in a Contract then the provision in the Contract will  prevail only to the extent of the inconsistency. <br />
            2.6 For the avoidance of doubt no terms or conditions of Customer,  including any terms or conditions printed on or referred to in  Customer’s offer to purchase or order will be binding on Bayer or have  any legal effect unless expressly agreed to in writing by Bayer.</p>
            <h2>3. Price and Payment</h2>
            <p className="justify">3.1 The price for the Products is specified in the Contract. Unless  the Contract states otherwise, Bayer may alter the Price at any time  prior to acceptance of an order without prior notice. <br />
            3.2 Customer must pay GST or any other tax duty, levy, tariff or charge  applicable to the supply of the Products in addition to, and at the same  time as, payment of the price. Bayer will provide Customer with a tax  invoice as required by law. <br />
            3.3 Unless the Contract states otherwise, Customer must pay the Price  for Products supplied to it within 30 days of the date of the invoice for such Products. <br />
            3.4 Customer must not withhold payment or make any deduction from the  invoiced price or any other amount owing to Bayer without Bayer’s prior  written consent. <br />
            3.5 Receipt of any amount will not constitute payment until such time as the amount is paid or honoured in full. <br />
            3.6 Bayer may in its discretion allocate a payment that does not  specifically identify the invoice for which such payment is made in  satisfaction for monies owing under any outstanding invoices without  regard to the date of those invoices. <br />
            3.7 Bayer may charge interest on any overdue monies at the rate which is  2% above the rate charged by Bayer’s major banker for commercial  overdraft balances of over $100,000 from time to time, calculated from  the due date for payment of the outstanding amount until the date of  payment by Customer. Any payment made by Customer will be credited first  against any interest that has accrued. <br />
            3.8 Bayer will be entitled to recover from Customer all legal and other  costs incurred by Bayer arising from Customer’s default in payment and  the collection of any overdue monies.</p>
            <h2>4. Delivery Terms</h2>
            <p className="justify">4.1 Any timeframes quoted by Bayer for delivery of the Products are  estimates only. Bayer will use its reasonable endeavours to supply the  Products in the quantities specified in the relevant Contract. <br />
            4.2 Bayer will not be liable for any loss suffered by Customer arising  out of any delay or failure to deliver the Products (or any part of  them) or failure to deliver in the requested quantities. <br />
            4.3 Bayer is entitled to refuse to deliver the Products to Customer if there are any outstanding monies owing to Bayer. <br />
            4.4 Delivery of the Products will be made in the manner and at the place  specified in the relevant Contract or if not specified, delivery will  be made as determined by Bayer. <br />
            4.5 Customer agrees to accept delivery of the Products at any time between 9.00am to 5.00pm on a Business Day. <br />
            4.6 If Bayer fails to deliver some or all of the Products pursuant to a  Contract, Customer will not be entitled to cancel that Contract or any  other order, Contract or delivery. Bayer will not be obliged to accept  any claims for shortages of deliveries or non-conforming Products unless  written notice of the claim is given to Bayer within 10 Business Days  after receipt by Customer of the Products at the delivery destination. <br />
            4.7 If Bayer supplies the Products pre-packed and labelled, Customer  must not sell, supply or otherwise deal with the Products unless the  packaging and labelling remains intact, and Customer must not alter,  remove, conceal or tamper with any batch numbers or other means of  identification used in relation to the Products. <br />
            4.8 If Customer does not, or indicates to Bayer that it will not, take  or accept delivery, then the Products will be deemed to have been  delivered when Bayer was willing to deliver them. <br />
            4.9 Bayer reserves the right to deliver the Products by instalments.  Each instalment may be invoiced separately and will be deemed to be a  separate contract under the same provisions as the main Contract. <br />
            4.10 Bayer may suspend or cancel delivery of the Products if Bayer  reasonably believes that the Products may cause injury or damage  (including for technical, scientific, medical or efficacy reasons) or  may infringe the intellectual property rights of any person, or if  payments owing from Customer to Bayer remain outstanding. No such  suspension or cancellation will in any way constitute admission of  liability or fault on Bayer’s part.</p>
            <h2>5. Containers and Pallets</h2>
            <p className="justify">5.1 Any pallets or containers used for the delivery, storage or  display of the Products are not included in the Price unless otherwise  specified and will remain the property of their owner. <br />
            5.2 Customer must promptly return or exchange the pallets and containers  in its custody on the day of delivery (unless otherwise agreed with  Bayer) or reimburse Bayer the cost of replacement or repair of lost or  damaged pallets or containers. <br />
            5.3 Bayer may charge a deposit to Customer for a pallet or container  which will be credited in full if the pallet or container is returned to  Bayer within 3 months of dispatch and is in good order and condition.</p>
            <h2>6. Risk and Title</h2>
            <p className="justify">6.1 Legal and beneficial ownership in the Products will not pass to  Customer until Customer has paid in full the Price for those Products. <br />
            6.2 Risk of loss of or damage to the Products will remain with Bayer  only until the first of the passing of title to the Products to  Customer, or delivery of the Products by Bayer to Customer in accordance  with clause 4. Thereafter risk of damage to, or loss or deterioration  of, the Products from any cause whatsoever passes to Customer. <br />
            6.3 Until all outstanding monies have been paid to Bayer for Products  delivered to Customer: <br />
            (a) Customer must separately store those Products  in such a way that makes it clear that they are the property of Bayer; <br />
            (b) in the event of a default (specified in clause 13), Bayer or its  representative will be entitled, without the necessity of giving any  notice, to enter premises occupied by Customer to search for and remove  any of those Products without in any way being liable to Customer, and may dispose of or retain such Products as Bayer sees fit without being required to give notice or account to Customer. If the Products or any of them are wholly or partially attached to or incorporated in any other product, Bayer may (when practical) disconnect them in any way necessary to remove the Products; and <br />
            (c) all costs and expenses incurred by Bayer as a result of taking  action in accordance with clause 6.3(b), together with transportation  and storage charges, must be paid by Customer to Bayer on demand. <br />
            6.4 Until title to the Products passes to Customer, Customer  acknowledges and agrees: <br />
            (a) that the Products supplied and not resold  are held by it as a bailee for Bayer; <br />
            (b) Customer may resell the Products without the right of Customer to  bind Bayer to any liability to any third party (whether contractual or  otherwise); <br />
            (c) any resale of the Products must only be made on the condition the purchaser is expressly made aware of the existence of Bayer’s rights under this clause 6; <br />
            (d) if the Products have been resold by Customer, Customer will hold so  much of the proceeds of sale as does not exceed the outstanding monies  on trust for Bayer immediately when they are receivable or received; <br />
            (e) when the proceeds held in trust for Bayer under clause 6.4(d) are  received they must either be paid immediately to Bayer or held in a  separate bank account as trustee for Bayer and they must not be used by  Customer in any other way whatsoever; and <br />
            (f) the authority conferred on Customer by clause <br />
            6.4(b) may be revoked  by written notice from Bayer at any time if Bayer deems the credit of  Customer to be unsatisfactory or if Customer is in default in the  performance of its obligations under any Contract, these Terms or any  other agreement between Bayer and Customer.<br />
            6.5 This clause 6 creates a purchase money security interest in the Products, any goods in which the Products are used as a component, and all proceeds from their respective resale by Customer. The security interest is granted to secure Customer’s proper performance of the Agreement, and comes into effect when the Customer takes possession of the Products.<br />
            For the avoidance of doubt the Customer acknowledges and agrees that it grants to Bayer a security interest in all goods supplied by Bayer to the Customer whether now or in the future and in any proceeds from the sale of those goods.<br />
            6.6 The parties agree that pursuant to sections 115(1) and 115(7) of the PPSA the following sections of the PPSA will not apply to these Terms and any related Contract (to the extent permitted by law): Sections 95, 96, 117, 118, 121(4) 125, 127 129, 130, 132, 134(2) 135, 136(3), 136(4), 136(5), 137, 142 and 143.<br />
            6.7 For the purposes of section 14(6) of the PPSA the parties agree that any payments received by Bayer from the Customer pursuant to or in any way connected with this Agreement will be applied in such order as Bayer deems fit in its absolute discretion.<br />
            6.8 Customer consents and agrees that:<br />
            (a) It must sign all documents and take all steps as Bayer may reasonably require in connection with the registration, perfection and enforcement of this purchase money security interest; and<br />
            (b) The security interest created by this agreement or any other document relating to the subject of this agreement may be registered with the relevant authority or public register; and<br />
            (c) Bayer is not obliged to give any notice or documents under the PPSA unless the relevant obligation cannot be excluded. Customer waives its right to be provided with verification statements pursuant to section 157 of the PPSA.</p>
            <h2>7. Warranties</h2>
            <p className="justify">7.1 Bayer warrants that all Products manufactured by it and supplied  to Customer will, subject to this clause 7, comply with Bayer’s  specifications for those Products (or if no such specifications exist,  will be free of defects in materials and manufacture), for a period of  12 months from the date of delivery of the Products by Bayer to Customer  (the Warranty Period). <br />
            7.2 If a Product does not comply with the warranty set out in clause 7.1  and Customer notifies Bayer in writing of the defect during the  Warranty Period within 10 Business Days of the defect coming to its  notice, Bayer will, at its option, either exchange the Product for a new  Product, or refund the Price paid for the Product. <br />
            7.3 When a refund is given pursuant to clause 7.2, the Product for which  the refund is provided must, at Bayer’s option, be destroyed or  returned to Bayer by Customer, at Customer’s expense, and if returned  becomes the property of Bayer. <br />
            7.4 The warranty in clause 7.1 does not apply: <br />
            (a) as a result of any  acts or omissions by any person other than Bayer or any external cause; <br />
            (b) if the defect is due to the Product being used for purposes other  than for purposes for which it was intended or which do not fall within  the scope of any regulatory approval; <br />
            (c) to a Product that has been modified without the written permission of Bayer; or <br />
            (d) if the Product has not been stored or transported in accordance with Bayer’s recommendations. <br />
            7.5 The benefit of the warranty in clause 7.1 is personal to Customer  and is not assignable without the prior written consent of Bayer. <br />
            7.6 Except as expressly set out in these Terms, the rights set out in  this clause 7 are the sole and exclusive remedies of Customer with  respect to defective Products. <br />
            7.7 Except as expressly set out in these Terms, and subject to any terms, warranties or conditions that by law may not be excluded (including those under sections 51, 52 and 53 of the Australian Consumer Law), all conditions, warranties, terms, and obligations expressed or implied by law or otherwise relating to the performance of Bayer’s obligations under these Terms, or any goods or services supplied, or to be supplied, by Bayer under these Terms, are excluded.</p>
            <h2>8. Credits</h2>
            <p className="justify">8.1 Credits sought for returned Products are at Bayer’s discretion.  Where accepted, Bayer may charge a reasonable administration fee in  respect of all returned Products. For the avoidance of doubt, no  Products for which the shelf life has expired will be accepted for  return. <br />
            8.2 Products, in respect of which a credit is sought and approved by  Bayer, must be returned to Bayer’s premises freight free in good and  saleable condition in the original containers and packaging in which  they were supplied, and accompanied by the number and date of Bayer’s  supplying invoice.</p>
            <h2>9. Assistance and Materials Supplied by Bayer</h2>
            <p className="justify">9.1 Subject to obligations imposed on Bayer by the law which cannot  be excluded or modified by these Terms, and subject to any contrary  provisions in a Contract, any advice, recommendation, information,  assistance or service provided by Bayer in relation to Products and  their use or application is given in good faith but is provided without  liability or responsibility on the part of Bayer and without intention  that Customer should rely thereon. <br />
            9.2 Any material supplied by Bayer for advertising and display is issued  to Customer for use in its own business and is not intended for  circulation or distribution to the public. Such material will remain the  property of Bayer, must be returned by Customer to Bayer upon request  and must not be modified without Bayer’s prior consent.</p>
            <h2>10. Adverse Experience Reporting and Recalls</h2>
            <p className="justify">10.1 If Bayer or Customer becomes aware of any defect, fault or other  condition, actual, potential or threatened, in any Products in which  Customer has taken possession such that they should not be offered for  sale to the public (“adverse experience”) having regard to the nature  and the extent of the threat, then that party must immediately give  notice to the other party of the Products affected by reference to  invoice numbers, product descriptions, batch codes, best before/used by  date codes, APN’s, item code numbers, quantities supplied, date of  possession and any other unique identifier. <br />
            10.2 Customer agrees to co-operate to the fullest extent possible to  diminish any risk to the public from the adverse experience, which  includes the following: <br />
            (a) removing the Products that may be affected  by the adverse experience from offer for sale to the public; <br />
            (b) complying with all laws, regulations and notice requirements in relation to product recalls; <br />
            (c) complying with any directions and corrective action required by Bayer in relation to the adverse experience; <br />
            (d) recalling the Products that may be affected by the adverse experience where they have been sold; and <br />
            (e) disseminating information that has been approved by Bayer and which  in Bayer’s opinion is necessary or desirable to limit any harm, loss or  damage that maybe caused in any way in relation to the adverse  experience.</p>
            <h2>11. Limitation of Liability</h2>
            <p className="justify">11.1 Any provision of these Terms that excludes any terms, conditions  or warranties, or limits the liability of a party will apply only to  the extent permitted by law and these Terms will be construed subject to  such terms, conditions, warranties and limitations. <br />
            11.2 Subject to clause 11.1, where any terms, conditions or warranties  are implied by law into these Terms which the law expressly provides may  not be excluded, restricted or modified, or may be excluded, restricted  or modified only to a limited extent, the liability of Bayer to  Customer under such implied terms, conditions or warranties is limited,  at the option of Bayer, to the repair or replacement of goods, or  payment of the cost of repairing or replacing the goods. <br />
            11.3 Except as expressly provided in these Terms, to the extent  permitted by law, Bayer will have no liability to Customer, however  arising and under any cause of action or theory of liability, in respect  of special, indirect or consequential damages, loss of profit (whether  direct or indirect) or loss of business opportunity.</p>
            <h2>12. Indemnities</h2>
            <p className="justify">12.1 If Customer gives instructions to Bayer with respect to the  manufacture, packaging, sale or supply of the Products, Customer  warrants to Bayer that adherence by Bayer to any such instructions will  not infringe the intellectual property rights of any other person. <br />
            12.2 Customer releases and indemnifies Bayer, its related bodies  corporate, and their respective officers, employees, consultants and  agents from and against all actions, claims, proceedings and demands  (including those brought by third parties) which may be brought against  it or them, whether on their own or jointly with those indemnified, and  whether at common law, in equity or pursuant to statute or otherwise, in  respect of any loss, death, injury, illness or damage (whether personal  or property, and whether direct or consequential, including  consequential financial loss) arising out of a breach of Customer’s  warranties or obligations contained in these Terms, and from and against  all damages, reasonable costs and expenses incurred in satisfying,  defending or settling any such claim, proceeding or demand.</p>
            <h2>13. Default</h2>
            <p className="justify">13.1 If an Insolvency Event occurs in relation to Customer any other  event occurs which gives Bayer reasonable grounds for doubting the  credit of Customer, Bayer may by notice to Customer, at its option and  without prejudice to any other right it may have, suspend or terminate a  Contract or require payment before or on delivery of the Products  (notwithstanding the terms of payment applicable to the Products), or  cancel any undelivered or uncompleted Products under a Contract, and may  retain any monies paid by Customer in relation to the Contract and  apply such monies against any loss or damage incurred by it in relation  to the default by Customer.</p>
            <h2>14. Force Majeure&nbsp;</h2>
            <p className="justify">14.1 Neither party will be liable for any delay or failure to perform  its obligations under these Terms (other than payment obligations) if  such delay is due to Force Majeure. <br />
            14.2 If a delay of a party to perform its obligations is caused or  anticipated due to Force Majeure, the performance of that party’s  obligations will be suspended.</p>
            <h2>15. Confidentiality</h2>
            <p className="justify">15.1 These Terms and the provisions of all Contracts are confidential and must not be disclosed by Customer to any third party without Bayer’s prior written consent unless such disclosure is required by law (other than section 275(1) of the PPSA). <br />
            15.2 The parties agree that the provisions of this clause 15 amount to a “confidentiality agreement” referred to in section 275 (6) of the PPSA. <br />
            15.3 The restrictions contained within this clause 15 do not apply to any of these Terms or provisions of Contracts that Bayer has itself placed in the public domain. </p>
            <h2>16. Privacy </h2>
            <p className="justify">16.1 Bayer has a published Privacy Policy which is incorporated into these Terms by reference and can be viewed at www.bayer.com.au. <br />
            16.2 Bayer’s collection and use of your personal information may for purposes including: <br />
            (a) to process and administer your dealings as a customer, including assessing your credit worthiness; <br />
            (b) to provide you with the Products and services you have requested and assisting you with further relevant information including Product related information ; and <br />
            (c) to administer the transactions contemplated by the Terms. <br />
            16.3 Bayer will generally: <br />
            (a) use personal Information provided to it for the purposes relating to the terms of this agreement; <br />
            (b) use personal information collected by it in accordance with its Privacy Policy and the Privacy Act 1988 (Cth) (the “Privacy Act”); and <br />
            (c) not sell, trade, give or pass on to any third party any personal information unless such a disclosure is contemplated by and directly related to the purpose outlined by the terms of this Agreement, or the Customer consents to such a disclosure or such disclosure is required to do so by law. <br />
            16.4 Customer therefore authorises Bayer to disclose Customer’s personal information to third party contractors and service providers that assist Bayer operate its business and assist Bayer fulfil the terms of this agreement such as contractors and service providers involved in services including but not limited to the processing of orders, order fulfilment and the collection of outstanding debts. <br />
            16.5 By entering into this agreement Customer: <br />
            (a) acknowledges that it has read Bayer’s Privacy Policy and consents to the terms thereof; <br />
            (b) acknowledges that Bayer is a global organisation and some of the activities necessary to fulfil the terms of this agreement may be conducted by Bayer entities located outside of Australia such as in Singapore, Philippines or Germany and as such Customer consents to the overseas transfer of its Personal Information, its employees, consultants and agents provided by Customer to Bayer. <br />
            16.6 Customer warrants that it shall comply with the provisions of the Privacy Act and shall not (as far as practicable) knowingly do anything or permit anything to be done which might lead to a breach of any such legislation.</p>
            <h2>17. General</h2>
            <p className="justify">17.1 Customer must comply with all applicable laws, regulations, industry standards and codes of conduct in Australia and any other relevant jurisdiction in relation to all matters contemplated (whether expressly or implicitly) by these Terms. <br />
            17.2 In these Terms and any Contract, unless the contrary intention appears: <br />
            (a) a person includes a corporation, unincorporated association, partnership, joint venture or public, statutory or governmental association or agency; <br />
            (b) a statute or regulation includes an amendment, replacement or re-enactment of that statute or regulation; <br />
            (c) a reference to dollars is to Australian Dollars; <br />
            (d) the word “including” and similar expressions are not words of limitation; <br />
            (e) a reference to conduct includes any omission and any statement or undertaking, whether or not in writing; and <br />
            (f) where an act is to be performed on a day that is not a Business Day, the act will be required to be performed on the following Business Day. <br />
            17.3 Any notice given by a party in connection with these Terms or any Contract shall be in writing and will be deemed to have been given when delivered or sent by post, facsimile or email to the party to whom such notice is intended to be given at the address or facsimile number of that party provided in the Contract or as otherwise notified in writing to the other party. A notice is deemed to be given and received: <br />
            (a) if hand delivered, on delivery; <br />
            (b) if sent by prepaid post, 3 Business Days after the date of posting, or 6 Business Days if sent to or from another country; or <br />
            (c) if sent by facsimile or email, when the sender’s facsimile or email system generates a message confirming successful transmission of the message. <br />
            17.4 If any provision of these Terms is invalid, illegal or unenforceable, these Terms take effect (where possible) as if they did not include that provision. <br />
            17.5 Any failure by Bayer to insist upon strict performance by Customer of any provision in these Terms will not be taken to be a waiver of any existing or future rights of Bayer in relation to the provision. <br />
            17.6 Customer must not assign or otherwise deal with its rights or obligations under these Terms or a Contract without the prior written consent of Bayer. <br />
            17.7 These Terms and Contracts are governed by the laws of New South Wales, Australia. The parties agree to submit to the non-exclusive jurisdiction of the courts of New South Wales, Australia. <br />
            17.8 These Terms (together with the Contracts) contain the entire agreement of the parties with respect to its subject matter and may only be amended in writing. <br />
            17.9 These Terms do not create a relationship of agency, partnership, joint venture or employment between the parties. Neither party has any authority to act for or incur any liability or obligation on behalf of the other party in any manner. <br />
            17.10 The parties agree that subject to the provisions of these Terms, the United Nations Convention on Contracts for the International Sale of Goods adopted at Vienna, Austria on 10 April 1980 do not apply to the supply of Products under these Terms.</p>
            </article>
            </div>
  )
}

export default SalesTermsConditions