import React, { useState , useEffect} from 'react'
import '../report.scss'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { selectedReportFilter,sapFilterObj ,selectedFilterDate,getHistoryLinesSelectedField,getOutputSelectedField} from "../../../redux/actions/reports/reportActions";
import { useDispatch, useSelector} from 'react-redux';

const SelectFilterPopup = props => {
    document.body.style.overflow = "hidden"
    const [selectedTab, setSelectedTab] = useState('All');
    const [data , setData ] = useState([]);

    const [prodData , setProdData ] = useState(props.agencyMaterialFilter);
    const [prodDataDate , setProdDataDate ] = useState(props.agencyMaterialDate);

    const [filterType,setFilterType] = useState(props.filterType)
    // const [filteredData , setfilteredData ] = useState([]);
    const [tabData , setTabData ] = useState([]);
    const [selectedFilter , setSelectedFilter ] = useState([]);
    // const [isChecked, setIsChecked] = useState();
    const dispatch  = useDispatch(); 
    const selectedFilterData = useSelector((state) => state.ReportHistory.selectedFilter); // sap filter data from redux
    const reportDataObj = useSelector((state) => state.ReportHistory.sapFilterObj);
    const selectedFilterName= useSelector((state) => state.ReportHistory.selectedFilterValue);
    const slectedDateRange = useSelector((state) => state.ReportHistory.selectedFilterDate);
    const selectedDate = useSelector((state) => state.ReportHistory.selectedFilterDate);
    const historyFilterData = useSelector((state) => state.ReportHistory.historyLinesData);
    const historyFilterSelected = useSelector((state) => state.ReportHistory.SelectedhistoryLinesFilter);
    const outputData = useSelector((state) => state.ReportHistory.outputData);
    const outputDataFilter = useSelector((state) => state.ReportHistory.outputFilter);

    const handleSelect = (e) => {
      setSelectedTab(e);
  }

  const handleClick =()=>{
    props.selectedFilterValues(selectedFilter)
    props.handleClose()
  }
  const getSections = (words) => {
    if(filterType==="SAP" || filterType==="DateRange"){
    return Object.values(
      words.reduce((acc, word) => {
        let firstLetter;
        if (filterType==="SAP"){
        firstLetter = Array.from(word['prod_name'])[0];

        }else if(filterType==="DateRange"){
        firstLetter = word['prod_name'].slice(0,4)

        }
        if (!acc[firstLetter]) {
          acc[firstLetter] = { title: firstLetter, data: [word] };
        } else {
          acc[firstLetter].data.push(word);
        }
        return acc;
      }, {})
    );
    }
 }

const handleChangeFilter =(filterValue, accountValue)=>{
  
  if (selectedFilterName==="SAP"){
    let objFilterValue = {filterValue,accountValue}
    // dispatch(selectedReportFilter([...selectedFilterData,filterValue]))
    dispatch(sapFilterObj([...reportDataObj,objFilterValue]))

    const removeItems = selectedFilterData.filter(item => item !== filterValue)
    dispatch(selectedReportFilter(selectedFilterData.includes(filterValue) ? removeItems: [...selectedFilterData,filterValue]))

  } else if (selectedFilterName==="DateRange"){
  dispatch(selectedFilterDate(filterValue))

  } else if (selectedFilterName==="history"){
   // dispatch(getHistoryLinesSelectedField([...historyFilterSelected,filterValue]))
    const removeItems = historyFilterSelected.filter(item => item !== filterValue)
    dispatch(getHistoryLinesSelectedField(historyFilterSelected.includes(filterValue) ? removeItems: [...historyFilterSelected,filterValue]))
  
  }else if (selectedFilterName==="output"){
    dispatch(getOutputSelectedField(filterValue))
    }
}

const selectAllChecks = (event) => {
  let isChecked = event.target.checked===true?true:false;
  let  materailAllObj = []
  let selectedMaterial = [];
  var checkboxes=document.getElementsByClassName("select_all");
  for (let checkbox of checkboxes) {
    let item  = data.find(item => item.prodAcc_product2Sfid === checkbox.value); 
    let materialName = item.prod_name+' ('+item.prod_sapMaterialNumber+')';
    let prod_name = item.prod_sfid;
    let objFilterValue =  {filterValue:materialName , accountValue:prod_name };
    materailAllObj.push(objFilterValue);
     selectedMaterial.push(materialName)
  } 

  if(isChecked){
     dispatch(sapFilterObj(materailAllObj));
     dispatch(selectedReportFilter(selectedMaterial));
    
  }else{
     dispatch(sapFilterObj([]));
     dispatch(selectedReportFilter([]));

  }
  }


useEffect(() => {
  props.selectedFilterValues(selectedFilter)
 }, [selectedFilter])


  useEffect(() => {
    if(selectedFilterName==='SAP'){
      const allData =  getSections(prodData);
      setTabData(allData)
      setData(prodData)

    }else if(selectedFilterName==='DateRange'){
      const allData =  getSections(prodDataDate);
      setTabData(allData)
      setData(prodDataDate)
    }else if(selectedFilterName==='history'){
      setData(historyFilterData)
      
    }else if(selectedFilterName==='outlines'){
      setData(outputData)
      
    }
  //  setSelectedFilter([selectedFilter])
  }, [])

  // checked={selectedFilter.includes(filterData.prod_name) ? true :false}
  return (
    <div className="popup-box">
        <div className="box">
          <div className='popup-title'>
          {(() => {
                        switch (filterType) {
                   
                            case 'SAP':
                                return <h4>Agency SAP Materials</h4>;
                            case 'DateRange':
                                return <h4>From Year-Month</h4>;
                            case 'history':
                                return <h4>Hide History Lines</h4>;
                            case 'output':
                                return <h4>Output format</h4>;
                            default:
                                return null;
                        }
                    })()}
            <span className='closeX' onClick={handleClick}>X</span>
          </div>

            <div style={{}} className="popup-head">
                <p>Note: use Ctrl + F to search within the results</p>
            </div>
            <div className="filter-box" >
            {(filterType === "SAP")&&<Tabs
              activeKey={selectedTab ? selectedTab : '2'}
              transition={false}
              textcolor="#838383"
              onSelect={(e) => handleSelect(e)}>
                {tabData.map((e, k)=>{
                  return (
                    <Tab  eventKey={e.title} title={e.title} key={k+''} >
                      <ul  className='list-report'>
                        {   e.data.filter((value, index, self) =>
                      index === self.findIndex((t) => (
                        t.prod_name === value.prod_name && t.prod_sfid === value.prod_sfid

                      ))).map((filterData, key)=>{
                        let materialName = filterData.prod_name+' ('+filterData.prod_sapMaterialNumber+')';
                          return(
                            
                          <li key={key+''}><label className='report-label'><input className='check-report' type="checkbox" value={materialName} onChange={()=>{filterType === "SAP" ? handleChangeFilter(materialName,filterData.prodAcc_product2Sfid) :handleChangeFilter(filterData.prod_name,'na')}} checked={(selectedFilterData?.includes(materialName)||selectedDate?.includes(materialName)) ? true :false}/>{materialName}</label></li>
                          )
                        })}
                      </ul>
                    </Tab>
                );})}
              <Tab  eventKey="All" title="All" >
                <ul  className='list-report'>
                <li><label className='report-label'><input className='check-report' onChange={selectAllChecks} type="checkbox"   />Select All</label></li>
                  {
                   data.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                      t.prod_name === value.prod_name && t.prod_sfid === value.prod_sfid
                    ))).map((allData, key)=>{
                      let materialName = allData.prod_name+' ('+allData.prod_sapMaterialNumber+')';
                      return(
                  // <li><label className='report-label'><input className='check-report' type="checkbox" value={allData.prod_name} onChange={()=>{filterType == "SAP" ? handleChangeFilter(allData.prod_name,allData.prodAcc_accountSfid) :handleChangeFilter(allData.prod_name,'na')}} checked={(selectedFilterData.includes(allData.prod_name)|| selectedDate.includes(allData.prod_name)) ? true :false}/>{allData.prod_name}</label></li>
                  <li key={key+''}><label className='report-label'><input className='check-report select_all' type="checkbox" value={allData.prodAcc_product2Sfid} onChange={()=>{filterType == "SAP" ? handleChangeFilter(materialName,allData.prodAcc_product2Sfid) :handleChangeFilter(materialName,'na')}} checked={(selectedFilterData?.includes(materialName)|| selectedDate?.includes(materialName)) ? true :false}/>{materialName}</label></li>
                      )
                    })
                  }
                </ul>
              </Tab>
            </Tabs>}
            
            {(filterType === "DateRange")&&<Tabs
              activeKey={selectedTab ? selectedTab : '2'}
              transition={false}
              textcolor="#838383"
              onSelect={(e) => handleSelect(e)}>
                {tabData.map((e)=>{
                  return (
                    <Tab  eventKey={e.title} title={e.title} >
                      <ul  className='list-report'>
                        {e.data.map((filterData, key)=>{
                          return(
                          <li key={key+''}><label className='report-label'><input className='check-report' type="radio" value={filterData.prod_name} onChange={()=>{ handleChangeFilter(filterData.prod_name)}} checked={(slectedDateRange===filterData.prod_name) ? true :false}/>{filterData.prod_name}</label></li>
                          )
                        })}
                      </ul>
                    </Tab>
                );})}
              <Tab  eventKey="All" title="All" >
                <ul  className='list-report'>
                  {
                    data.map((allData, key)=>{
                      return(
                  <li key={key+''}><label className='report-label'><input className='check-report' type="radio" value={allData.prod_name} onChange={()=>{ handleChangeFilter(allData.prod_name)}} checked={(slectedDateRange===allData.prod_name) ? true :false}/>{allData.prod_name}</label></li>
                      )
                    })
                  }
                </ul>
              </Tab>
            </Tabs>}
            {filterType ==='history' &&<ul className='history-filter'>
                  {
                    historyFilterData.map((allData, key)=>{
                      return(
                        <li key={key+''}><label className='report-label'><input className='check-report' type="checkbox" value={allData} checked={historyFilterSelected?.includes(allData) ? true :false} onChange={()=>handleChangeFilter(allData)}/>{allData}</label></li>
                      )
                    })
                  }
              </ul>
            }
            {filterType==='output' &&<ul className='history-filter'>
                  {
                    outputData.map((allData, key)=>{
                      return(
                        <li key={key+''}><label className='report-label'><input className='check-report' type="radio" name="outPutFilter" value={allData} checked={(outputDataFilter===allData) ? true :false} onChange={()=>handleChangeFilter(allData)}/>{allData}</label></li>
                      )
                    })
                  }
              </ul>
            }
            
            </div>
            <div style={{}}></div>
            <div className='popup-bottom'>
            <button className='closebtnreport' onClick={handleClick}>Close</button>
          </div>
        </div>
    </div>
  )
}

export default SelectFilterPopup