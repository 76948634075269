import { Container } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import BayerLogo from "../../assets/images/bayer_logo.svg";
import "../../assets/scss/layout.scss";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import store from "../../redux/stores";
import { logoutUser } from "../../redux/actions/userActions";
import { runTour } from "../../redux/actions/tourGuideActions";
import { sideUrl } from "../../_helpers/axios";
import tourIcon from "../../assets/images/tourIcon.svg";
import axios from "../../_helpers/axios";

const NavigationBar = () => {
  const [show, setShow] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");

  const UserDetails = userInfo.data.userDetails
    ? userInfo.data.userDetails
    : { cont_firstName: "A", cont_lastName: "B" };
  const profile =
    UserDetails.cont_firstName.trim().charAt(0) +
    UserDetails.cont_lastName.trim().charAt(0);
  const handleClickTour = () => {
    const currentPage = localStorage.getItem("currentPage") || "null";
    let data = {
      value: true,
      currentPage: currentPage,
    };
    store.dispatch(runTour(data));
  };
  const getReport = (param: any, uType: any) => {
    axios
      .get("oauth/sfdctoken")
      .then((res) => {
        if (res.data.status === 200) {
          const access_token = res.data.access_token;
          //const rootUrl = "https://anz-cropcentre-api-dev.herokuapp.com/services/api/reportmanagement/"
          const rootUrl = process.env.REACT_APP_BASE_URL
            ? process.env.REACT_APP_BASE_URL +
              "reportmanagement/cropCentreAgencyReport"
            : "https://anz-cropcentre-api-int.herokuapp.com/services/api/reportmanagement/cropCentreAgencyReport";
          var reportCrmUrl =
            process.env.REACT_CRMUrl ||
            "https://monsanto-anz--test.lightning.force.com";
          const url =
            rootUrl +
            "?access_token=" +
            access_token +
            "&conId=" +
            userInfo.data.userDetails["user_contactSfid"] +
            "&accId=" +
            userInfo.data.userDetails["cont_accSfid"] +
            "&uType=" +
            uType +
            "&dName=" +
            param +
            "&CRMUrl=" +
            reportCrmUrl;
          window.open(url, "_blank");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <Container className="nav-container">
      <div className="nav-header-logo">
        <a href="default.asp">
          <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
            <img src={BayerLogo} className="bayer-logo-img" alt="Bayer Logo" />
          </Typography>
        </a>
      </div>
      <div className="nav-header-content">
        <div className="nav-title">
          <h2>
            <b className="bayer-title">CropCentre</b>
          </h2>
        </div>
        <div className="nav-title-route">
          <div className="dropdown" style={{ display: "inline" }}>
            <button className="dropbtn">
              {" "}
              <Link to={"/Dashboard"}>Home</Link>
            </button>
            <p
              className="nav-slash"
              style={{ display: "inline", color: "#DCE8EB" }}
            >
              /
            </p>
          </div>
          {(userInfo.data.userRole.includes("CropCentre - Agency Admin") ||
            userInfo.data.userRole.length <= 0) && (
            <div className="dropdown" style={{ display: "inline" }}>
              <button className="dropbtn" onMouseEnter={() => setShow(true)}>
                Stock Management
              </button>
              <div className="dropdown-content drop-new" id="drop-new">
                {show && (
                  <ul
                    className="nav-dropdown"
                    style={{ marginLeft: "0px", paddingBottom: "0px" }}
                    onClick={() => setShow(false)}
                  >
                    <li>
                      <Link to={"/StockManagement/RequestForStock"}>
                        Request For Stock
                      </Link>
                    </li>
                    <hr />
                    <li>
                      <Link to={"/StockManagement/GoodsMovement"}>
                        Goods Movement
                      </Link>
                    </li>
                    <hr />
                    <li>
                      <Link to={"/StockManagement/SalesReport"}>
                        Sales Report
                      </Link>
                    </li>
                    <hr />
                    <li>
                      <Link to={"/StockManagement/StocktakeEntries"}>
                        Stocktake Entries
                      </Link>
                    </li>
                    <hr />
                  </ul>
                )}
              </div>
              <p
                className="nav-slash"
                style={{ display: "inline", color: "#DCE8EB" }}
              >
                /
              </p>
            </div>
          )}
          {(userInfo.data.userRole.includes("CropCentre - Agency Admin") ||
            userInfo.data.userRole.length <= 0) && (
            <div className="dropdown" style={{ display: "inline" }}>
              <button className="dropbtn">Reports</button>
              <div className="dropdown-content drop-new" id="drop-new">
                <ul
                  className="nav-dropdown"
                  style={{ marginLeft: "0px", paddingBottom: "0px" }}
                >
                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() => getReport("BranchAgencyScheduleCMP", "1")}
                    >
                      Branch Agency Schedule
                    </button>
                  </li>
                  <hr />
                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() =>
                        getReport("BranchAgencyCaseHistoryCMP", "1")
                      }
                    >
                      Case History
                    </button>
                  </li>
                  <hr />
                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() =>
                        getReport("BranchAgencyTrainingCoverageCMP", "1")
                      }
                    >
                      Traning Agency Status(Coverage Report)
                    </button>
                  </li>
                  <hr />
                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() =>
                        getReport("BranchAgencyScheduleTrackerCMP", "1")
                      }
                    >
                      Branch Agency Schedule Tracker
                    </button>
                  </li>
                  <hr />
                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() =>
                        getReport("BranchTrainingAgencyStatusCMP", "1")
                      }
                    >
                      Branch Training Agency Status
                    </button>
                  </li>
                  <hr />
                  <li>
                    <Link to={"/reports/branchHistory"}>Branch History</Link>
                  </li>
                  <hr />

                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() => getReport("BranchInventoryReportCMP", "1")}
                    >
                      Branch Inventory Report
                    </button>
                  </li>
                  <hr />
                  {/* <li><a href="/Reports/BranchKObiolAgentsReport/">Branch K-Obiol Approved Users Report</a></li><hr /> */}
                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() => getReport("BranchProductForecast", "1")}
                    >
                      Branch Product Forecast Report
                    </button>
                  </li>
                  <hr />
                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() =>
                        getReport("BranchProductPricingSOHCMP", "1")
                      }
                    >
                      Branch Product Pricing And SOH
                    </button>
                  </li>
                  <hr />
                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() =>
                        getReport("BranchMonthlyReportingCMP", "1")
                      }
                    >
                      Branch Sales Monthly Reporting
                    </button>
                  </li>
                  <hr />
                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() =>
                        getReport("BranchStocktakeQuarterlyCMP", "1")
                      }
                    >
                      Branch Stocktake Quarterly Reporting
                    </button>
                  </li>
                  <hr />
                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() => getReport("BranchUserAccountCMP", "1")}
                    >
                      Branch User Accounts
                    </button>
                  </li>
                  <hr />
                </ul>
              </div>
              <p
                className="nav-slash"
                style={{ display: "inline", color: "#DCE8EB" }}
              >
                /
              </p>
            </div>
          )}
          {(userInfo.data.userRole.includes(
            "CropCentre - Sales District Admin"
          ) ||
            userInfo.data.userRole.length <= 0) && (
            <div className="dropdown" style={{ display: "inline" }}>
              <button className="dropbtn">Sales District Reports</button>
              <div className="dropdown-content drop-new" id="drop-new">
                <ul
                  className="nav-dropdown"
                  style={{ marginLeft: "0px", paddingBottom: "0px" }}
                >
                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() => getReport("BranchAgencyScheduleCMP", "2")}
                    >
                      Sales District Agency Schedule
                    </button>
                  </li>
                  <hr />
                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() =>
                        getReport("BranchAgencyScheduleTrackerCMP", "2")
                      }
                    >
                      Sales District Agency Schedule Tracker
                    </button>
                  </li>
                  <hr />
                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() =>
                        getReport("BranchTrainingAgencyStatusCMP", "2")
                      }
                    >
                      Sales District Training Agency Status
                    </button>
                  </li>
                  <hr />
                  <li>
                    <Link
                      to={
                        "/salesDistrictReports/salesDistrictHistoryQueryViewer"
                      }
                    >
                      Sales District History Query Viewer
                    </Link>
                  </li>
                  <hr />
                  {/* <li><a href="/SalesDistrictReports/SalesDistrictHistoryQueryViewer/">Sales District History Query Viewer</a></li><hr /> */}
                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() => getReport("BranchInventoryReportCMP", "2")}
                    >
                      Sales District Inventory Report
                    </button>
                  </li>
                  <hr />
                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() =>
                        getReport("SalesDistrictPeopleStatusCMP", "2")
                      }
                    >
                      Sales District People Status
                    </button>
                  </li>
                  <hr />
                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() =>
                        getReport("BranchProductPricingSOHCMP", "2")
                      }
                    >
                      Sales District Product Pricing And SOH
                    </button>
                  </li>
                  <hr />
                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() =>
                        getReport("BranchMonthlyReportingCMP", "2")
                      }
                    >
                      Sales District Sales Monthly Reporting
                    </button>
                  </li>
                  <hr />
                  <li>
                    <Link to={"/reports/salesStockReports"}>
                      Sales District Sales and Stock Analysis Report
                    </Link>
                  </li>
                  <hr />
                  <li>
                    <button
                      className="false-btn-link"
                      onClick={() =>
                        getReport("BranchStocktakeQuarterlyCMP", "2")
                      }
                    >
                      Sales District Stocktake Quarterly Reporting
                    </button>
                  </li>
                  <hr />
                </ul>
              </div>
              <p
                className="nav-slash"
                style={{ display: "inline", color: "#DCE8EB" }}
              >
                /
              </p>
            </div>
          )}
          {(userInfo.data.userRole.includes("CropCentre - eBDA") ||
            userInfo.data.userRole.includes("CropCentre - eBDA RM") ||
            userInfo.data.userRole.length <= 0) && (
            <div className="dropdown" style={{ display: "inline" }}>
              <a
                href={"https://ebda.bayer.com.au/Login/?"}
                rel="noreferrer"
                target="_blank"
              >
                <button className="dropbtn">eBDA</button>
              </a>
              <p
                style={{
                  display: "inline",
                  fontSize: "24px",
                  paddingLeft: "4px",
                  color: "#DCE8EB",
                }}
              >
                /
              </p>
            </div>
          )}
          {(userInfo.data.userRole.includes("CropCentre - Agronomist") ||
            userInfo.data.userRole.length <= 0) && (
            <div className="dropdown" style={{ display: "inline" }}>
              <a href={sideUrl + "Training/"} rel="noreferrer" target="_blank">
                <button className="dropbtn">Training</button>
              </a>
              {/* <p style={{display: 'inline', fontSize: '24px', paddingLeft: '4px', color: '#DCE8EB'}}>/</p> */}
              <p
                className="nav-slash"
                style={{ display: "inline", color: "#DCE8EB" }}
              >
                /
              </p>
            </div>
          )}
          {(userInfo.data.userRole.includes("CropCentre - Trial Tracker") ||
            userInfo.data.userRole.length <= 0) && (
            <div className="dropdown" style={{ display: "inline" }}>
              <button className="dropbtn">Apps</button>
              <div className="dropdown-content drop-new" id="drop-new">
                <ul style={{ marginLeft: "0px", paddingBottom: "0px" }}>
                  <li>
                    {" "}
                    <a
                      href={sideUrl + "Apps/TrialTracker/"}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {" "}
                      Trial Tracker
                    </a>
                  </li>
                  <hr />
                </ul>
              </div>
              <p
                className="nav-slash"
                style={{ display: "inline", color: "#DCE8EB" }}
              >
                /
              </p>
            </div>
          )}
          {/* <div className="dropdown" style={{display: 'inline'}}>
              <button className="dropbtn">Help</button>
                            <p style={{display: 'inline', fontSize: '24px', paddingLeft: '4px', color: '#DCE8EB'}}>/</p>
              </div> */}

          <div className="dropdown" style={{ display: "inline" }}>
            <button className="dropbtn">
              {" "}
              <Link to={`/account/MyAccount`}>My Account</Link>{" "}
            </button>
            <p
              className="nav-slash"
              style={{ display: "inline", color: "#DCE8EB" }}
            >
              /
            </p>
          </div>
        </div>
      </div>
      <div className="nav-profile">
        <img
          className="tour-icon tour-explore tour-icon-nav"
          style={{ marginRight: "10px" }}
          alt="tour explore icon"
          src={tourIcon}
          onClick={handleClickTour}
        />
        {/* <img src={NotificationIcon} height="25" style={{ paddingRight: "10px" }} className="float-end" alt="Notification" /> */}
        <Link to={`/account/MyAccount`}>
          <div className="profile float-end">
            {profile}
            <button
              type="button"
              className="logoutbtn"
              onClick={() => {
                store.dispatch(logoutUser());
              }}
            >
              Logout
            </button>
          </div>
        </Link>
      </div>
    </Container>
  );
};
export default NavigationBar;
