import * as React from "react";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { displayError } from "../../utils/helper";
import CustomInput from "../../components/input";
import ArrowIcon from "../../assets/images/btn_rt_arrow.svg";
import RtLayout from "../../assets/images/rt_layout.svg";
import Visibility from "../../assets/images/visibility.svg";
import VisibilityOff from "../../assets/images/visibility_off.svg";
import "../../assets/scss/login.scss";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { loginUser } from "../../redux/actions/userActions";
// import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
interface State {
  username: "";
  password: string;
  remember: boolean;
  showPassword: boolean;
  usernameError: string;
  passwordError: string;
}
const label = { inputProps: { "aria-label": "Checkbox demo" } };
export default function Login() {
  const RememberData = JSON.parse(localStorage.getItem("RememberData") || "[]");
  const history = useHistory();
  const dispatch = useDispatch();
  // const location = useLocation();
  const error = useSelector((state: RootStateOrAny) => state.UI.errors);
  const loading = useSelector((state: RootStateOrAny) => state.user.loading);
  const passwordHint = useSelector(
    (state: RootStateOrAny) => state.user.passwordHint
  );
  const [isRemember, setRemember] = React.useState(false);
  const [userNameVal, setUserName] = React.useState("");
  const [passwordVal, setPassword] = React.useState("");
  const inputRefUser = React.useRef<HTMLInputElement | null>(null);
  const inputRefPass = React.useRef("");
  const [fields, setFields] = React.useState<State>({
    username: "",
    password: "",
    remember: false,
    showPassword: false,
    usernameError: "",
    passwordError: "",
  });

  React.useEffect(() => {
    if (RememberData.remember === true) {
      let obj: any = { ...fields };
      setRemember(true);
      inputRefUser.current?.focus();
      setUserName(RememberData.userName);
      setPassword(RememberData.password);
      obj.username = RememberData.userName;
      obj.usernameError = "";
      obj.password = RememberData.password;
      obj.passwordError = "";
      setFields({ ...obj });
    }
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let formValid = checkValidation();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const userData = {
      email: data.get("username"),
      password: data.get("password"),
      isRemember: isRemember,
    };
    const { from } = { from: { pathname: "/dashboard" } };
    if (formValid) {
      dispatch(loginUser(userData, from, history));
    }
  };

  const [isCapsLockOn, setCapsLock] = React.useState(false);
  const [isShowHint, setShowHint] = React.useState(false);
  // const {
  //  register,
  //  handleSubmit,
  //  watch,
  //  formState: { errors },
  // } = useForm();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let obj: any = { ...fields };

    if (e.target.name === "username") {
      setUserName(e.target.value);
      if (e.target.value === "" || e.target.value === null) {
        const errMsg = "Email ID is required.";
        obj.username = e.target.value;
        obj.usernameError = errMsg;
      } else {
        obj.username = e.target.value;
        obj.usernameError = "";
      }
    } else if (e.target.name === "remember") {
      obj.remember = e.target.checked;
      setRemember(e.target.checked);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
      if (e.target.value === "" || e.target.value === null) {
        const errMsg: any = "Password is required.";
        obj.password = e.target.value;
        obj.passwordError = errMsg;
      } else {
        obj.password = e.target.value;
        obj.passwordError = "";
      }
    }
    setFields({ ...obj });
  };
  const handleClickShowPassword = () => {
    setFields({
      ...fields,
      showPassword: !fields.showPassword,
    });
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  // Checks Login validation
  const checkValidation = () => {
    let formValid = true;
    const { username, password } = { ...fields };
    let obj = { ...fields };
    if (username === "") {
      const errMsg = "Email ID is required.";
      obj.usernameError = errMsg;
      formValid = false;
    }
    if (password === "") {
      const errMsg: any = "Password is required.";
      obj.passwordError = errMsg;
      formValid = false;
    }
    setFields({ ...obj });
    return formValid;
  };
  /**
   * Detect caps lock being on when typing.
   * @param keyEvent On key down event.
   */
  const checkKeyDown = (keyEvent: any) => {
    if (keyEvent.getModifierState("CapsLock")) {
      setCapsLock(true);
    } else {
      setCapsLock(false);
    }
  };
  console.log(error);
  return (
    <div className="login-responsive">
      <Typography
        component="h1"
        variant="h6"
        sx={{ color: "#00617F" }}
        className="login-typo"
      >
        Log In
      </Typography>

      {loading && (
        <div className="centeredSpin">
          {" "}
          <Spinner animation="border" role="status"></Spinner>{" "}
        </div>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ mt: 1, display: "flex", flexDirection: "column" }}
        className="login-creds-mt"
      >
        <FormControl
          variant="standard"
          style={{ marginLeft: "0px" }}
          className="login-form-ml"
          sx={{ m: 1, width: "50ch" }}
        >
          {error === "User Data not available"
            ? displayError(
                "Entered Email ID is not associated with any account"
              )
            : ""}
          {error === "User is not Active in the system"
            ? displayError("User is not Active in the system")
            : ""}
          {error === "No active branch assigned to this user"
            ? displayError("No active branch assigned to this user")
            : ""}
          <CustomInput
            ref={inputRefUser}
            type="text"
            value={userNameVal}
            placeHolder="Enter your Email ID/ Username"
            label="Email ID/ Username"
            id="component-error"
            name="username"
            onChange={(e: any) => handleChange(e)}
            error={fields.usernameError}
          />
          {fields.usernameError && displayError("Email ID is required.")}
          {/* <span style={{visibility:!fields.usernameError?'hidden':'visible',height:'10px'}}>{fields.usernameError && displayError("Email ID is required.")}</span> */}
        </FormControl>
        <FormControl
          variant="standard"
          style={{ marginLeft: "0px" }}
          className="login-form-ml login-form-pwd"
          sx={{ m: 1, width: "50ch" }}
        >
          <CustomInput
            ref={inputRefPass}
            name="password"
            type={fields.showPassword ? "text" : "password"}
            placeHolder="Enter your Password"
            label="Password"
            value={passwordVal}
            id="standard-adornment-password"
            onChange={(e: any) => handleChange(e)}
            error={fields.passwordError}
            onKeyDown={(e: any) => checkKeyDown(e)}
            endAdornment={
              <>
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {fields.showPassword ? (
                      <img
                        src={VisibilityOff}
                        alt="off"
                        className="visiblityEye"
                      />
                    ) : (
                      <img src={Visibility} className="visiblityEye" alt="on" />
                    )}
                  </IconButton>
                  {isCapsLockOn && (
                    <span className="warn-caps hidden">
                      <p>Caps Lock is on</p>
                    </span>
                  )}
                </InputAdornment>
              </>
            }
          />
          {fields.passwordError && displayError("Password is required.")}
          {error === "Invalid Password" &&
            !fields.passwordError &&
            displayError("Password is incorrect")}
          {/* <span style={{visibility:!fields.usernameError?'hidden':'visible',height:'10px'}}>{(error==="Invalid Password" && !fields.passwordError ) && displayError("Password is incorrect")}</span> */}
        </FormControl>
        <div className="login-forget">
          <span>
            <FormControlLabel
              sx={{ mt: 5, color: "#89D329" }}
              style={{ marginTop: "0px", color: "#89D329" }}
              control={
                <Checkbox
                  {...label}
                  value="remember"
                  checked={isRemember}
                  onChange={(e: any) => handleChange(e)}
                  name="remember"
                  className="remember-login"
                  sx={{
                    p: 0,
                    m: 1,
                    color: "#89D329",
                    "&.Mui-checked": {
                      color: "#89D329",
                    },
                    checked: {},
                  }}
                />
              }
              label=""
            />
            <span className="remember-txt">Remember Me</span>
          </span>
          <Link href="/forgot-password" variant="body2">
            <span>Forgot password?</span>
          </Link>
          {passwordHint && passwordHint.message === "Invalid Password" && (
            <span onClick={() => setShowHint(!isShowHint)}>{`Show Hint: ${
              isShowHint ? passwordHint.data.userDetails.passwordHint : ""
            }`}</span>
          )}
        </div>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: "#ff3162",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#ff3162",
              boxShadow: "none",
              p: {
                paddingLeft: "60px",
              },
            },
            borderRadius: " 0px",
          }}
          className="btn-right login-btn-dimensions"
        >
          <p className="login-btn-fs"> Log In</p>
          <span>
            <img src={ArrowIcon} className="arrow-i" alt="Arrow" />
            <img src={RtLayout} className="submit-rt-layout" alt="layout" />
          </span>
        </Button>
      </Box>
      {/* <Grid container component="main" sx={{ height: "100vh" }}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7}>
                    <Carousel />
                </Grid>
                <Grid item xs={12} sm={8} md={5}>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            <img src={BayerLogo} height="70" style={{ paddingRight: "10px" }} />
                            CropCentre
                        </Typography>
                        <Typography component="h1" variant="h6">
                            Log in
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, display: "flex", flexDirection: "column" }}>
                            <FormControl variant="standard" sx={{ m: 1, width: "50ch" }}>
                                <CustomInput
                                    type="text"
                                    placeHolder="Enter your Bayer email-ID Username"
                                    label="Email ID / Username"
                                    id="component-error"
                                    isMultiline
                                    name="username"
                                    onChange={(e: any) => handleChange(e)}
                                    error={fields.usernameError}
                                />
                                {fields.usernameError && displayError("Email ID is required.")}
                            </FormControl>
                            <FormControl variant="standard" sx={{ m: 1, width: "50ch" }}>
                                <CustomInput
                                    name="password"
                                    type={fields.showPassword ? "text" : "password"}
                                    placeHolder="Enter your Password"
                                    label="Password"
                                    id="standard-adornment-password"
                                    onChange={(e: any) => handleChange(e)}
                                    error={fields.passwordError}
                                    onKeyDown={(e: any) => checkKeyDown(e)}
                                    endAdornment={
                                        <>
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {fields.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                                {isCapsLockOn && (
                                                    <span className="warn-caps hidden">
                                                        <p>Caps Lock is on</p>
                                                    </span>
                                                )}
                                            </InputAdornment>
                                        </>
                                    }
                                />
                                {fields.passwordError && displayError("Password is required.")}
                            </FormControl>
                            <div className="login-forget">
                                <Link href="#" variant="body2">
                                    <label>Forgot password?</label>
                                </Link>
                                <label>Show hint</label>
                            </div>
                            <FormControlLabel
                                sx={{ mt: 5 }}
                                control={<Checkbox value="remember" color="primary" sx={{ p: 0, m: 1 }} />}
                                label="Keep me logged in"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    backgroundColor: "#ff3162",
                                    "&:hover": {
                                        backgroundColor: "#ff3162",
                                    },
                                }}
                                className="cust-btn"
                            >
                                Log In
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Footer />
            </Grid> */}
    </div>
  );
}
