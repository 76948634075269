import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTasks } from '@fortawesome/free-solid-svg-icons'
import '../../reports/report.scss';
import '../../../assets/scss/common.scss';
import SubmitButton from '../../../components/common/SubmitButton';
import SalesStockFilterPopup from './SalesStockFilterPopup';
import { useDispatch, useSelector} from 'react-redux';
import { fetchOutputData,salesStockFilterSelected,fetchGroupData,fetchStockData,
    fetchFkaData, fetchSORData, fetchSOHData, fetchYearData, fetchStartMonthData,
    fetchEndMonthData,fetchSAPReport, selectedSAPReportFilter,setSapFilterObjSS,updateStockFilter,updateGroupFilter,
    updateOutputFilter,updateFkaFilter,updateEndMonthFilter,updateStartMonthFilter,updateYearFilter,
    updateSORFilter, updateSOHFilter, updateBranchFilter, setAgencyBranchObj, fetchAgencyBranchData
} from "../../../redux/actions/salesStockReport/salesStockActions";
import axios from '../../../_helpers/axios';
import {  Spinner } from 'react-bootstrap';
import SalesStockList from './salesStockList';
import DownLoadCsv from '../../SalesDistrictReports/SalesDistrictSalesAndStockReports/SalesStockCsv';
import NotificationPopup from '../../../components/common/NotificationPopup';
// import { selectedReportFilter,fetchReport,} from "../../redux/actions/reports/reportActions";

const SalesStockReport = () => {
    const  SelectedAgency = JSON.parse(localStorage.getItem("SelectedAgency") || "[]");
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isShowCsv , setisShowCsv] =  useState(false);
    const [ShowResult , setShowResult ] = useState(false);
    const [response , setResponse]=useState([]);
    const warning = [{notificationType: 'warning', header: 'Warning',message: 'Please enter the Agnecy Branch and SAP materials'}];
    // const [notification , setNotification] = useState(warning);
    const [filterTitle, setFilterTitle] = useState('');
    const outputFilterSales = useSelector((state) => state.SalesStock.outputDataFilter); //to show selected value of filter
    const salesSORfilterData = useSelector((state) => state.SalesStock.sorDataFilter); //to show selected value of filter
    const salesSOHFilterData = useSelector((state) => state.SalesStock.sohDataFilter); //to show selected value of filter
    const salesYearFilterData = useSelector((state) => state.SalesStock.yearDataFilter); //to show selected value of filter
    const salesStartMonthFilterData = useSelector((state) => state.SalesStock.startMonthDataFilter); //to show selected value of filter
    const salesEndMonthFilterData = useSelector((state) => state.SalesStock.endMonthDataFilter); //to show selected value of filter
    const salesFkaFilterData = useSelector((state) => state.SalesStock.fkaDataFilter); //to show selected value of filter
    const salesStocksFilterData = useSelector((state) => state.SalesStock.stockDataFilter); //to show selected value of filter
    const groupFilterSales = useSelector((state) => state.SalesStock.groupDataFilter); //to show selected value of filter
    const selectedFilterData = useSelector((state) => state.SalesStock.materialsDataFilter); // sap filter data from redux
    // const selectedFilter = useSelector((state) => state.SalesStock.selectedFilter); // to feth selected filter
    const [showError , setshowError] = useState(false);
    const [loading , setLoading] = useState(false);
    // const [showNotiPopup, setShowNotiPopup] = useState(true);
    const branchData = useSelector((state) => state.SalesStock.branchData);
    const branchDataFilter = useSelector((state) => state.SalesStock.branchDataFilter);
    const agencyBranchObj = useSelector((state) => state.SalesStock.agencyBranchObj);
    const sapMaterialsObj = useSelector((state) => state.SalesStock.sapMaterialsObj);
    const [errMsg , setErrorMsg ]= useState([]);
    const d = new Date();
    const Cyear = d.getFullYear();
    const Byear = Cyear-1;
    

    // const monthsLong = {
    //     January: '01',
    //     February: '02',
    //     March: '03',
    //     April: '04',
    //     May: '05',
    //     June: '06',
    //     July: '07',
    //     August: '08',
    //     September: '09',
    //     October: '10',
    //     November: '11',
    //     December: '12',
    //   };


    
    
    const dispatch  = useDispatch(); 

    useEffect(() => {
        dispatch(fetchAgencyBranchData(SelectedAgency))
      },[]);
    
    const  outputData = () => {
        dispatch(salesStockFilterSelected('output'))
        setFilterTitle("Output Format")
        const outputData=[
            'View in browser','Save as CSV file'
        ]
        dispatch(fetchOutputData(outputData))
        // setAgencyMaterial(outputData)
           setIsPopupOpen(true)
    }

    const resetReport = () =>  {
        dispatch({
            type: "RESET_STOCK_DATA",
            payLoad:[]
        });
        setShowResult(false);
        setisShowCsv(false);
        setResponse([]);
        dispatch(fetchAgencyBranchData(SelectedAgency));
    }

    const  groupData = () => {
        dispatch(salesStockFilterSelected('group'))
        setFilterTitle("Group By")

        const groupData=[
            'Product','Branch'
        ]
        dispatch(fetchGroupData(groupData))
        setIsPopupOpen(true)
    }

    const  stockData = () => {
        dispatch(salesStockFilterSelected('stocks'))
        setFilterTitle("Show Stocks/Sales Columns")

        const stockData=[
            'Sales Only','Stock Only', 'Sales and Stock'
        ]
        dispatch(fetchStockData(stockData))
        setIsPopupOpen(true)
    }

    const  fkaData = () => {
        dispatch(salesStockFilterSelected('fka'))
        setFilterTitle("Include Packs FKA")
        // alert(salesFkaFilterData)
        const fkaData=[
            'Yes','No'
        ]
        dispatch(fetchFkaData(fkaData))
        setIsPopupOpen(true)
    }

    const  sorData = () => {
        dispatch(salesStockFilterSelected('sor'))
        setFilterTitle("SOR Value")

        const sorData=[
            'SOR > 0','SOR >= 0'
        ]
        dispatch(fetchSORData(sorData))
        setIsPopupOpen(true)
    }

    const  sohData = () => {
        dispatch(salesStockFilterSelected('soh'))
        setFilterTitle("SOH Value")

        const sohData=[
            'SOH > 0','SOH >= 0'
        ]
        dispatch(fetchSOHData(sohData))
        setIsPopupOpen(true)
    }

    const  yearData = () => {
        dispatch(salesStockFilterSelected('year'))
        setFilterTitle("Report Year")
        // alert(salesYearFilterData)

        const yearData=[
            '2019', '2020', '2021', '2022', '2023'
        ]
        dispatch(fetchYearData(yearData))
        setIsPopupOpen(true)
    }

    const  startMonthData = () => {
        dispatch(salesStockFilterSelected('sMonth'))
        setFilterTitle("Start Month")

        const sMonthData=[
            'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
        ]
        dispatch(fetchStartMonthData(sMonthData))
        setIsPopupOpen(true)
    }

    const  endMonthData = () => {
        dispatch(salesStockFilterSelected('eMonth'))
        setFilterTitle("End Month")

        const eMonthData=[
            'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'

        ]
        dispatch(fetchEndMonthData(eMonthData))
        setIsPopupOpen(true)
    }

    const  getMaterialData = () => {
        dispatch(salesStockFilterSelected('material'))
        setFilterTitle("Agency SAP Materials")
        dispatch(fetchSAPReport(agencyBranchObj))
        setIsPopupOpen(true)
    }

    const  getBranchData = () => {
        dispatch(salesStockFilterSelected('branch'))
        setFilterTitle("Agency Branch")
        dispatch(fetchAgencyBranchData(SelectedAgency))
        if(branchData.length === 0){
            // setNotification([{notificationType: 'warning', header: 'Agency Branch Data not available'}])
            setshowError(true);
            window.scrollTo({top: 0, behavior: 'auto'});
        }else{
            setshowError(false);
            setIsPopupOpen(true)
        }
    }

    // const FilterTitleData =()=>{
    //     setFilterTitle()
    // }
    

    const togglePopup = (row) => {
        if(isPopupOpen){
            document.body.style.overflow = "scroll";
        }
        setIsPopupOpen(!isPopupOpen);
      }
    
      const saleStockSelectedFilter=(data)=>{

        // dispatch(fetchSAPReport())
        // if(filterType=="SAP"){
        //     console.log("ALLData" , data)
        //     setSelectedSapMaterial([...selectedSapMaterial ,data])
        // }else if(filterType=="DateRange"){
        //     setSelectedDateRange(data)

        // }else if(filterType=="history"){
        //     setSelectedHistoryLines(data)

        // }else if(filterType=="output"){
        //     setSelectedOutputFormat(data)
        // }
    }
    
    const getReport = () =>{
        // get date range filter data
        let prodId = "";
         let startMonth = salesStartMonthFilterData;
        let endMonth = salesEndMonthFilterData;
        let sohValue = salesSOHFilterData;
        let sorValue = salesSORfilterData;
        let salesFka = salesFkaFilterData;
        let accId="";
        let FormError = false;
        let errorMsg = [];
        if(sapMaterialsObj.length <= 0 || agencyBranchObj.length <=0){
            FormError = true
            errorMsg.push("Please enter the Agnecy Branch and SAP materials");
        }
         if(!startMonth || !endMonth){
            FormError  = true;
            errorMsg.push("Month Start and Month End is required and must not be blank.");
        }
         if(!salesFka){
            FormError  = true;
            errorMsg.push("PackFKA is required and must not be blank");
        }
         if(!groupFilterSales){
            FormError  = true;
            errorMsg.push("Group By is required and must not be blank");
        }
         if(!salesYearFilterData){
            FormError  = true;
            errorMsg.push("Report Year is required and must not be blank");
        }
        if(!outputFilterSales){
            FormError  = true;
            errorMsg.push("Output required and must not be blank");
        }


        if(FormError===true){
            window.scrollTo({top: 0, behavior: 'auto'});
            setshowError(true);
            setErrorMsg(errorMsg);
            console.log("errMsg" , errMsg);
            return false;
        }
        

        setLoading(true);
        sapMaterialsObj.map(rs=> {
           prodId += rs.accountValue + ";";
            return null;
        })
        prodId = prodId.slice(0, -1);
        agencyBranchObj.map(rs=> {
            accId += rs.accountValue + ";";
            return null;
        })

       const sapData = {
        "salesOffice" : "",
       "salesDist" : "",
       "salesGrp" : "",
       "accountId" : accId,
       "prodId" : prodId,
       "reportYr" : salesYearFilterData,
       "startMonth" : startMonth,
       "endMonth" : endMonth,
       "sorValue" : sorValue,
       "sohValue" : sohValue,
       "includePackFKA" : salesFka,
       "groupBy" : groupFilterSales
       }

       axios.post("ordermanagement/salesstockdata", sapData)
       .then((res) => {
           if(res.data.status===200){
               setLoading(false);
               setshowError(false)
               if(res.data.data.length===0){
                    // setNotification([{notificationType: 'warning', header: 'Info',message: 'Data Not Available for the selected filters'}])
                    setshowError(true);
                    window.scrollTo({top: 0, behavior: 'auto'});
                }
               setResponse(res.data.data);
               if(outputFilterSales==="View in browser"){
                   setShowResult(true)
                   setisShowCsv(false)
                }else{
                   setisShowCsv(true)
                   setShowResult(false)
                }
           }
       })
       .catch((err) => {
          console.log(err.message);
          setLoading(false);
       });
   
     }
    


    const removeSelectedFilter = ( filter,key,accountValue)=> {
        if(filter==='materialremove'){
            dispatch(selectedSAPReportFilter(selectedFilterData.filter(item => item !== key)))
                let myArray = sapMaterialsObj.filter(function( obj ) {
                    return obj.filterValue !== key;
                });
              dispatch(setSapFilterObjSS(myArray))
        }
        else if(filter==='outputremove'){
            dispatch(updateOutputFilter())
        }else if(filter==='groupremove'){
            dispatch(updateGroupFilter());
        }else if(filter==='stocksremove'){
            dispatch(updateStockFilter())
        }else if(filter==='fkaremove'){
            dispatch(updateFkaFilter())
        }else if(filter==='emonthremove'){
            dispatch(updateEndMonthFilter())
        }else if(filter==='smonthremove'){
            dispatch(updateStartMonthFilter())
        }else if(filter==='yearremove'){
            dispatch(updateYearFilter())
        }else if(filter==='sohremove'){
            dispatch(updateSOHFilter())
        }else if(filter==='sorremove'){
            dispatch(updateSORFilter())
        }else if (filter === 'selectedAgencyBranch') {
            let arr = agencyBranchObj.filter((temp)=>temp.filterValue!==key)
            dispatch(updateBranchFilter(branchDataFilter.filter(item => item !== key)))
            dispatch(setAgencyBranchObj(arr))
            if(arr.length===0){
                dispatch(selectedSAPReportFilter([]));
                dispatch(setSapFilterObjSS([]));
            }
            dispatch(fetchSAPReport(arr))
        }
      }

  return (
    <div>
        <p className='report-title'>Sales and Stock Analysis Report</p>
        {showError && 
         errMsg.map(err => {
             const warning = [{notificationType: 'warning', header: 'Warning',message: err}];
           return <NotificationPopup showNotiPopup={true} notifications={warning} className="notification_popup mb-3" /> 
         } )
         } 
        {loading &&  <div className="centeredSpin"><Spinner animation="border" role="status" ></Spinner></div> }
        <div style={{border: '1px solid', boxShadow: '0px 0px 1px 1px #aaaaaa', margin: "30px"}} className='report-filters-form'>
            <p style={{margin: '-12px 20px', fontSize: '14px', background: 'white', padding: '0px 8px', width: '110px', fontWeight: '600'}}>Select Options</p>
            <div className='report-filters'>
                <div className='filter-label required'><label className='pfilter-label'>Agency Branch</label></div>
                <div className='filter-select'><button onClick={getBranchData}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                <div className='selected-filter'>
                    {branchDataFilter.map((key, value) => {
                            return <div key={key} ><span className="remove-filter-btn" onClick={() => removeSelectedFilter('selectedAgencyBranch', key)}>x&nbsp;</span>{key} </div>
                        })}
                    </div>
            </div>

            <div className='report-filters'>
                <div className='filter-label required'><label className='pfilter-label'>Agency SAP Materials</label></div>
                <div className='filter-select'><button onClick={getMaterialData}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                <div className='selected-filter'>
                    { sapMaterialsObj.map((key, value) => { 
                    return <div key={key.filterValue} ><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('materialremove',key.filterValue)}>x&nbsp;</span>{key.filterValue} </div>
                    })}
                </div>
            </div>


            <div className='report-filters'>
                <div className='filter-label required'><label className='pfilter-label'>Report Year</label></div>
                <div className='filter-select'><button onClick={yearData}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                <div className='selected-filter'>
                    {
                    salesYearFilterData &&
                    <div ><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('yearremove',salesYearFilterData)} >x&nbsp;</span>{salesYearFilterData} </div>
                    }

                    {/* { selectedFilterData.map((key, value) => { 
                    return <div key={key} style={{fontSize: '18px', margin: '5px'}}><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('selectedSapMaterial',key)}>x&nbsp;</span>{key} </div>
                    })} */}
                </div>
            </div>

            <div className='report-filters'>
                <div className='filter-label required'><label className='pfilter-label'>Start Month</label></div>
                <div className='filter-select'><button onClick={startMonthData}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                <div className='selected-filter'>
                    { salesStartMonthFilterData &&
                    <div ><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('smonthremove',salesStartMonthFilterData)} >x&nbsp;</span>{salesStartMonthFilterData} </div>

                    }

                </div>
            </div>

            <div className='report-filters'>
                <div className='filter-label required'><label className='pfilter-label'>End Month</label></div>
                <div className='filter-select'><button onClick={endMonthData}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                <div className='selected-filter'>
                { salesEndMonthFilterData &&
                    <div><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('emonthremove',salesEndMonthFilterData)}>x&nbsp;</span>{salesEndMonthFilterData} </div>
                }

                </div>
            </div>

            <div className='report-filters'>
                <div className='filter-label'><label className='pfilter-label'>SOH Value</label></div>
                <div className='filter-select'><button onClick={sohData}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                <div className='selected-filter'>
                { salesSOHFilterData &&
                    <div ><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('sohremove',salesSOHFilterData)}>x&nbsp;</span>{salesSOHFilterData} </div>
                }
                    {/* { historyFilterSelected.map((key, value) => { 
                    return <div key={key} style={{fontSize: '18px', margin: '5px'}}><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('selectedHistoryLines',key)}>x&nbsp;</span>{key} </div>
                    })} */}
                    </div>
            </div>

            <div className='report-filters'>
                <div className='filter-label'><label className='pfilter-label'>SOR Value</label></div>
                <div className='filter-select'><button onClick={sorData}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                <div className='selected-filter'>
                { salesSORfilterData &&
                    <div><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('sorremove',salesSORfilterData)}>x&nbsp;</span>{salesSORfilterData} </div>
                }
                    {/* { historyFilterSelected.map((key, value) => { 
                    return <div key={key} style={{fontSize: '18px', margin: '5px'}}><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('selectedHistoryLines',key)}>x&nbsp;</span>{key} </div>
                    })} */}
                    </div>
            </div>

            <div className='report-filters'>
                <div className='filter-label required'><label className='pfilter-label'>Include Pack FKA</label></div>
                <div className='filter-select'><button onClick={fkaData}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                <div className='selected-filter'>
                { salesFkaFilterData !=null && 
                <div><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('fkaremove',salesFkaFilterData)}>x&nbsp;</span>{salesFkaFilterData} </div>
                }
                </div>
            </div>

            <div className='report-filters'>
                <div className='filter-label'><label className='pfilter-label'>Show Stocks/Sales Columns</label></div>
                <div className='filter-select'><button onClick={stockData}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                <div className='selected-filter'>
                    {salesStocksFilterData && <div key={salesStocksFilterData} ><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('stocksremove',salesStocksFilterData)}>x&nbsp;</span>{salesStocksFilterData} </div>}
                </div>
            </div>

            <div className='report-filters'>
                <div className='filter-label required'><label className='pfilter-label'>Group By</label></div>
                <div className='filter-select'><button onClick={groupData}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                <div className='selected-filter'>
                { groupFilterSales !=null && 
                <div><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('groupremove',groupFilterSales)}>x&nbsp;</span>{groupFilterSales} </div>
                }
                    
                </div>
            </div>

            <div className='report-filters'>
                <div className='filter-label required'><label className='pfilter-label'>Output format</label></div>
                <div className='filter-select'><button onClick={outputData}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                <div className='selected-filter'>

                { outputFilterSales !=null && 
                <div><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('outputremove',outputFilterSales)}>x&nbsp;</span>{outputFilterSales} </div>
                }
                  
                </div>
            </div>

            <div className="report-btn" style={{paddingTop: '20px', marginBottom:'50px', justifyContent: 'left'}}>
                <div style={{marginLeft:'20px'}}>
                    <SubmitButton onClick= {getReport} title='SUBMIT'/>
                   
                </div>
                <div style={{marginLeft:'20px'}}>
                    <SubmitButton onClick={resetReport} title='RESET' />
                </div>
            </div>
            
            {isShowCsv && <div style={{margin:20, fontSize:22}}><DownLoadCsv data={response} filename="SalesStockReport.csv" /></div> }
            
        </div>
        { ShowResult && 
        <div style={{marginLeft:30, fontSize:14}}><p>*Sales Year-to-Date is from {Cyear}-Jan-01 to  {Cyear}-Dec-31</p>
        <p>*Sales Last-Year is from {Byear}-Jan-01 to  {Byear}-Dec-31</p>
        </div>
        }
        
        {ShowResult && <SalesStockList data={response} />}
        {isPopupOpen && <SalesStockFilterPopup handleClose={togglePopup} saleStockSelectedFilter={saleStockSelectedFilter} branchData={branchData} title={filterTitle}/>}

    </div>
  )
}

export default SalesStockReport