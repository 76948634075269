//in store.ts
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import userReducer from "./reducers/userReducers";
import uiReducer from "./reducers/uiReducers";
import dashboardReducer from "./reducers/dashboardReducers";
import addRequestReducers from "./reducers/StockManagementReducers/addRequestReducers";
import salesReducer from "./reducers/StockManagementReducers/salesReducer";
import cancelRequestReducers from "./reducers/StockManagementReducers/cancelRequestReducers";
import stocktakeEntriesReducer from "./reducers/StockManagementReducers/stocktakeEntriesReducer";
import goodsMovmtSITFBReducer from "./reducers/StockManagementReducers/goodsMovmtSITFBReducer";
import goodsMovmtSITFOReducer from "./reducers/StockManagementReducers/goodsMovmtSITFOReducer";
import tourGuideReducer from './reducers/tourGuideReducer'
import reportHistoryReducer from './reducers/reports/reportsReducers'
import salesStockReducer from './reducers/salesStockReport/salesStockReducer'
import historyViewerReducers from "./reducers/reports/historyViewerReducers";
const initialState = {};
const middleware = [thunk];

const reducer = combineReducers({
  user: userReducer,
  dashboard:dashboardReducer,
  UI: uiReducer,
  AddStocks: addRequestReducers,
  CancelStcoks: cancelRequestReducers,
  Sales:salesReducer,
  StocktakeEntries: stocktakeEntriesReducer,
  GoodsMovementSITFB: goodsMovmtSITFBReducer,
  GoodsMovementSITFO: goodsMovmtSITFOReducer,
  TourGuide:tourGuideReducer,
  ReportHistory:reportHistoryReducer,
  SalesStock:salesStockReducer,
  HistoryQueryViewer: historyViewerReducers
});

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
