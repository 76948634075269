import React, { useState , useEffect , useRef} from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import "../../../App.css";
import './salesReport.css'
// import salesData from '../../data/salesreport.json';
import ErrorPopup from '../../../components/common/ErrorPopup';
import ScrollButton from '../../../components/common/ScrollButton';
// import info_black_24dp from "../../../assets/images/info_black_24dp.png"
// import filter_alt_black_24dp from "../../../assets/images/filter_alt_black_24dp.png"
import axios from '../../../_helpers/axios';
import SaveDraftButton from '../../../components/common/SaveDraftButton';
import SubmitButton from '../../../components/common/SubmitButton';
import ResetButton from '../../../components/common/ResetButton';
import NotificationPopup from '../../../components/common/NotificationPopup';
import CinfirmSales from './ConfirmSale';
import SuccessIcon from '../../../assets/images/success_noti.svg';
import { useDispatch, useSelector , RootStateOrAny} from 'react-redux';
// import { getSalesData} from "../../../redux/actions/stockManagementActions/salesActions";
import {runTour } from "../../../redux/actions/tourGuideActions";

import { Steps } from 'intro.js-react';
import '../../../assets/scss/tourguide.scss'
import "intro.js/introjs.css";


const SalesReport = () => {
    const dispatch  = useDispatch(); 
    
    const nameRef = useRef<HTMLInputElement | null>(null);
    
    //const PoNumberFocus  = useRef<HTMLInputElement | null>(null);
    
    var sales : any[] = [];
    const  SelectedAgency = JSON.parse(localStorage.getItem("SelectedAgency") || "[]");
    const  userInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
    const isreder = useSelector((state: RootStateOrAny) => state.user.renderCom);
    const [data, setStocksData] = useState(sales);
    
    const [isSalesShow, setSalesReport] = useState(true);
    // const [rederCom , setrender]=useState(isreder);
    
    var str: any ={};
    var infoNotifications = [{notificationType: 'info', header: 'Message',message: 'All reporting functions are measured and calculated based on AEST (Australian Eastern Standard Time). Please be mindful on this to\nensure complaint reporting.\nEnter the number of packs sold in the boxes below and your SOH will calculate automatically.'},
    {notificationType: 'info', header: 'General Reminder',message: 'Please ensure that you have sold the old packs first prior to selling new ones.'}];

    var wrningNot = [{notificationType: 'warning', header: 'Warning',message: ''}];
    var draftMsg  = [{notificationType: 'success', header: 'Draft Saved', message: `Your draft has been saved successfully`}]

    // var SucNo = [{notificationType: 'success', header: 'Your Sales Report has been successfully submitted', message: `Do you want to submit another Sales Report today?. `}]
   
    //var sales = salesData.data.customerSales;
    const [list, setList] = useState<any[]>([]);
    const [warning, setWarning] = useState<any[]>([]);
    const [showConfirmForm , setConfirmFarm ] = useState(false);
    const [isButtonDisabled , setButtonDisabled ] = useState(true);
    const [isDraftDis , setDraftDis ] = useState(true);

    const [isCheckAll , setIsCheckAll] = useState(true);
    
    const [showSucPopup, setSucPopup] = useState(false);
    const [isCheck, setIsCheck] = useState<any[]>(['SITFB','SITFO','RTT']);

   
    const [salesAlldata , setSaleAllData ]= useState(str);
    const [nilSaleData , setNilsaleData] = useState(sales)
    const [poMessage, setPoMessage ] = useState("");
    const [submitError , setSubmitError] = useState(false);
    // const [showSOHErr , setSohError] = useState(false)
    const [submitData , setSubmitData] = useState(sales);
    const [resubLoading ,setresubLoading] = useState(false);
    

    const Column= [{
        id:'0',
        name:"SITFB"
    },
    {
        id:'1',
        name:"SITFO"
    },
    {
        id:'2',
        name:"RTT"
    }
]
   

    
    const [showFilter, setFilter] = useState(false);
    const [pOrderdNo, setPOrderNo] = useState("");
    const [nilSales, setNilSales] = useState(false);
    const [notifications, setNotifications]= useState(infoNotifications);
    const [isDraft , setDraft] = useState(false);
    const [showNotiPopup, setShowNotiPopup] = useState(true);

    const [modalShow, setModalShow] = React.useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorHeading, setErrorHeading] = useState('');
    const [errorMsgBlue, setErrorMsgBlue] = useState('');
    const [showOkBtn, setShowOkBtn] = useState(false);
    const [showYesBtn, setShowYesBtn] = useState(false);
    const [showNoBtn, setShowNoBtn] = useState(false);
    const [errorProd , setErrorPrd ] = useState<any[]>([])
    const [loading, setLoading] = useState(true);
    const [confLoading, setconfLoading] = useState(false);
    const [showResubmt , setshowResubmt] = useState(false);
    
    
    const errorArry:any=[]
    //dispatch(getSalesData(SelectedAgency));
    const salesData = useSelector((state: RootStateOrAny) => state.Sales);
    const errors = useSelector((state: RootStateOrAny) => state.Sales.errors);
    const handleFocus = (e:any) => e.target.select();
    // const myRef = useRef();
    const tourRunSalesReport = useSelector((state:RootStateOrAny) => state.TourGuide.tourInfo.value)
    const currentPage = useSelector((state:RootStateOrAny) => state.TourGuide.tourInfo.currentPage)
    
    useEffect(() => {
        
        setList(Column);
        if(errors.status > 0){
            setSalesReport(false);
            wrningNot[0].header=errors.message;
            setWarning(wrningNot)
            setLoading(false);
        }
        if(salesData.salesData){
            if(salesData.salesData.status===200){
            setLoading(false);
            setStocksData(salesData.salesData.data.customerSales);
            setNilsaleData(salesData.salesData.data.customerSales)
            setPOrderNo(salesData.salesData.data.purchaseOrderNumber)
            setSaleAllData(salesData.salesData)
            setSalesReport(true);
            }else if(salesData.salesData.status===201 || salesData.salesData.status===208){
            setSalesReport(false);
            setLoading(false);
            wrningNot[0].header=salesData.salesData.message;
            setWarning(wrningNot)
            if(salesData.salesData.message.indexOf("already submitted")!==-1){
                setshowResubmt(true);
            }
            
            }
            
        }

    }, [salesData]);
    
    // const [run, setRun] = useState(true);
    // const [SalesReportvisited, setsalesReportvisited] = useState('')
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [initialStep, setInitialStep] = useState(0);
    // const [hintsEnabled, setHintsEnabled] = useState(true);
    // const [addStock, setStatus] = useState(true);

    const [tour, setTour] = useState({
        steps: [
            {
                element: '.stocktake-tg1',
                title:'Step 01',
                intro: <h6 className='guide-text'>Let's start by putting purchase order number here</h6>,
                position: 'right',
                tooltipClass: 'myTooltipClass',
                highlightClass: 'myHighlightClass',

              },
              {
                element: '.stocktake-tg2',
                title:'Step 02',
                intro: <h6  className='guide-text'>You can check this box if there has been no sales</h6>,
                position: 'left',
              },
              {
                element: '.stocktake-tg3',
                title:'Step 03',
                intro:  <h6  className='guide-text'>By writing here, you can search for the product pack directly</h6>,
              },
              {
                element: '.stocktake-tg4',
                title:'Step 04',
                intro:  <h6  className='guide-text'>Feed your sales entry here</h6>,
              },
              {
                element: '.stocktake-tg5',
                title:'Step 05',
                intro:  <h6  className='guide-text'>You cannot enter a sales number greater that the closing SOH here</h6>,
              },
              {
                element: '.stocktake-tg6',
                title:'Step 06',
                intro:  <h6  className='guide-text'>Reset button will clear out all the inputs made</h6>,
              },
              {
                element: '.stocktake-tg7',
                title:'Step 07',
                intro:  <h6  className='guide-text'>Save a draft and come back to finish where you left</h6>,
              },
              {
                element: '.stocktake-tg8',
                title:'Step 08',
                intro:  <h6  className='guide-text'>Press the submit button to view the receipt of the report. Success message would be displayed once the report is submitted</h6>,
              },
              {
                element: '.tour-explore',
                intro:  <h6  className='guide-text'>Click on the explore icon if you need to access the guided tour again</h6>,
              },
      ]
    })

    useEffect(() => {
        const  SalesReportvisited = JSON.parse(localStorage.getItem("SalesReportvisited")|| "false");
        localStorage.setItem("currentPage", 'salesReport');
        setTimeout(
            () => {
                if(SalesReportvisited===true){
                    setStepsEnabled(false);
                }else{
                    if(data){
                    setStepsEnabled(true);
                }
                    localStorage.setItem("SalesReportvisited", 'true');
                }
            }, 
            1000
          );
	}, []);

    const errorModalClose = ()=> {
        setModalShow(false)
    }
    
 function fetchData(submitAgain=false) {
    axios.get(`ordermanagement/salesreport?accountSfid=${SelectedAgency.account_sfid}&submitAgain=${submitAgain}`)
    .then((res:any) => {
        if(res.status===200){
        
            setLoading(false);
            setStocksData(res.data.data.customerSales);
            setNilsaleData(res.data.data.customerSales)
            setPOrderNo(res.data.data.purchaseOrderNumber)
            setSaleAllData(res.data)
            setSalesReport(true);
            setresubLoading(false);
        }else{
            setSalesReport(false);
            setLoading(false);
            wrningNot[0].header=res.data.message;
            setWarning(wrningNot)
            setresubLoading(false);
        }
     })
  .catch((err) => {
    setLoading(false);
    console.log(err);
    setresubLoading(false);
 
  });
  
}
// show sales report after suceesfully saved
const showSalesReport =()=>{
    setNilSales(false);
    setSucPopup(false);
    setConfirmFarm(false)
    setPOrderNo("");
    setresubLoading(true)
    //dispatch(getSalesData(SelectedAgency));
    fetchData(true);
}

const canCelSubmitHandller = ()=> {
    setConfirmFarm(false);
}
// check for PO order validation 

const checkPOorderNumber = (event:any)=> {
    
    const pattern = /\b[a-zA-Z]{2}\d{4}\d?\b/;
    let elgroupName = SelectedAgency.salesArea_salesDistrictName.indexOf("ELDERS");
    let lawbGroupName = SelectedAgency.salesArea_salesDistrictName.indexOf("LAWB");
 
    if((!pOrderdNo || !pattern.test(pOrderdNo)) && pOrderdNo!=='NIL SALES' && elgroupName !== -1){
        setPoMessage("Elders Sales Order Number is not in the correct format:\n  The format should be two letters, and four or five numbers. eg. VA1234; AB54321\n\n") 
  
    }else if(!pOrderdNo  && pOrderdNo!=='NIL SALES' && lawbGroupName !== -1 && pOrderdNo.length !== 10 ){
        setPoMessage("Landmark Sales Order Number is not in the correct format:\n     The format should be either 10 numbers eg 1234567890 or\n     two letters or numbers, forward slash and six numbers. eg. 9X/123456\n\n") 
    }
    else {
        setPoMessage("")
    const params = {
        accountSfid: SelectedAgency.account_sfid,
        purchaseOrderNumber:event.target.value
      }
    axios.get("ordermanagement/salesreport/validateponumber", {params})
        .then((res) => {
                if(res.status===201){
                    setPoMessage(res.data.message) 
                }else{
                    setPoMessage("") 
                }
                
        })
        .catch((err) => {
            console.log(err);
        });
    }
  
    
 }  

    // const handleSelectAll = ()=> {
    
    //     setIsCheckAll(!isCheckAll);
    //     if (isCheckAll) {
    //         setIsCheck([]);
    //     }else{
            
    //         setIsCheck(list.map(li => li.name));
    //     }
    //   };

    //   function getSectedColumn (e:any)  {
    //     const { id, checked } = e.target;
    //     setIsCheck([...isCheck, id]);
    //     if (!checked) {
    //       setIsCheck(isCheck.filter(item => item !== id));
    //     }
    //   }
    
    const handleSubmit = (event: any, type:string) => {
        const pattern = /\b[a-zA-Z]{2}\d{4}\d?\b/;
        // const lawbPattren = /^[0-9a-zA-Z]{2}\\d{6}$/;
        var temp:any = [];
       // event.preventDefault()
        // Submit with purchase order 
        let showPOerrors = false;
        let showSOHErrSub = false
        
       // Submit with purchase order 
    //    var re = new RegExp("^[a-zA-Z]{2}\\d{4,5}.$"); 
    //    let result:string = SelectedAgency.salesArea_salesDistrictName.indexOf("AGLINK");
       let elgroupName = SelectedAgency.salesArea_salesDistrictName.indexOf("ELDERS");
       let lawbGroupName = SelectedAgency.salesArea_salesDistrictName.indexOf("LAWB");
    
       if((!pOrderdNo || !pattern.test(pOrderdNo)) && pOrderdNo!=='NIL SALES' && elgroupName !== -1){
           setErrorHeading('Please enter a Purchase Order Reference');
           setErrorMsg('Elders Sales Order Number is not in the correct format:\n  The format should be two letters, and four or five numbers. eg. VA1234; AB54321\n\n');
           setErrorMsgBlue('');
           setShowOkBtn(true);
           setShowYesBtn(false);
           setShowNoBtn(false);
           setModalShow(true);
           showPOerrors = true;
           setSubmitError(true)
           nameRef.current?.focus();
           window.scrollTo({
            top: 0, 
            behavior: 'smooth'
          });
     
       }
       else if(!pOrderdNo  && pOrderdNo!=='NIL SALES' && lawbGroupName !== -1 && pOrderdNo.length !== 10 ){
        setErrorHeading('Please enter a Purchase Order Reference');
        setErrorMsg('Landmark Sales Order Number is not in the correct format:\n     The format should be either 10 numbers eg 1234567890 or\n     two letters or numbers, forward slash and six numbers. eg. 9X/123456\n\n');
        setErrorMsgBlue('');
        setShowOkBtn(true);
        setShowYesBtn(false);
        setShowNoBtn(false);
        setModalShow(true);
        showPOerrors = true;
        setSubmitError(true)
        nameRef.current?.focus();
        window.scrollTo({
         top: 0, 
         behavior: 'smooth'
       });
  
    }else if(!pOrderdNo && pOrderdNo!=='NIL SALES'){
        setErrorHeading('Please enter a Purchase Order Reference');
        setErrorMsg(' Purchase Order is mandatory field');
        setErrorMsgBlue('');
        setShowOkBtn(true);
        setShowYesBtn(false);
        setShowNoBtn(false);
        setModalShow(true);
        showPOerrors = true;
        setSubmitError(true)
        nameRef.current?.focus();
        window.scrollTo({
         top: 0, 
         behavior: 'smooth'
       });
    }
     if(poMessage!=="" && pOrderdNo!=='NIL SALES'){
        setErrorHeading("PO Number already exists");
        setErrorMsg("This PO number already exist please change the PO Number");
        setErrorMsgBlue('');
        setShowOkBtn(true);
        setShowYesBtn(false);
        setShowNoBtn(false);
        setModalShow(true);

       }else if (pOrderdNo!=='NIL SALES') {
         //check for clossing SOH validations
           setErrorHeading('You cannot sell more stock than you have on closing SOH');
           let msg:any;
           data.map(o => {
                   if (errorProd.includes(o.productSfid)) {
                    showSOHErrSub= true;
                       setSubmitError(true)
                       //errorArry.push(o.productSfid)
                       //setErrorPrd(errorArry)
                   msg = <p>{msg} {o.productName} - {o.productSAPMaterialNumber} </p>
                   }
                   return null;
               });
               if(showSOHErrSub){
                   setErrorMsg(msg);
                   setErrorMsgBlue('');
                   setShowOkBtn(true);
                   setShowYesBtn(false);
                   setShowNoBtn(false);
                   setModalShow(true);
               }


       }
       if(!showPOerrors && !showSOHErrSub && poMessage==="")
       {
        setSubmitError(false);
        salesAlldata.data.purchaseOrderNumber=pOrderdNo;
           // setConfirmFarm(true)
           
            if(pOrderdNo!=='NIL SALES') {
                
               // salesAlldata.data.customerSales = [];
                for(let i=0; i<data.length; i++){
                  //  if(temp[i].productSfid == data[i].productSfid){
                       // temp[i].sales != data[i].sales
                        if( data[i].sales > 0){
                            temp.push(data[i]);
                        }
                    //}
                } 
                setSubmitData(temp)
            }else{
                setSubmitData(nilSaleData)
                //salesAlldata.data.customerSales = nilSaleData;
                salesAlldata.data.salesReportNotAvailable= true;
            }
   
        //send request for submit
        if(type==='Draft' && temp.length > 0){
            salesAlldata.data.userDetails = userInfo.data.userDetails;
            salesAlldata.data.customerSales = temp;
            setconfLoading(true)
            axios.post(`ordermanagement/salesreport?accountSfid=${SelectedAgency.account_sfid}&type=${type}` , salesAlldata)
            .then((res) => {
                if(res.data.status===200)
                {
                 fetchData();
                 setconfLoading(false)
                 setDraft(true);
                 window.scrollTo({
                    top: 0, 
                    behavior: 'smooth'
                  });
                }
               
              })
              .catch((err) => {
                setconfLoading(false)
                console.log(err);
              });

        }else{
            if(temp.length===0 && pOrderdNo!=='NIL SALES'){
                setErrorHeading('Update Sales');
                setErrorMsg('Please update atleast one sale');
                setErrorMsgBlue('');
                setShowOkBtn(true);
                setShowYesBtn(false);
                setShowNoBtn(false);
                setModalShow(true);
                

            }else{
                setConfirmFarm(true)
            }      
        }
      
       }
      

    };

   //submit confirmed data
   const submitSalesHandller = ()=> {
       setconfLoading(true)
    salesAlldata.data.userDetails = userInfo.data.userDetails;
     salesAlldata.data.customerSales = submitData;
    if(pOrderdNo==='NIL SALES') {
        setPoMessage("")
    }
    axios.post(`ordermanagement/salesreport?accountSfid=${SelectedAgency.account_sfid}&type=Submitted` , salesAlldata)
    .then((res) => {
        if(res.data.status===200)
        {
         setSucPopup(true)
         setconfLoading(false)
         fetchData();
         window.scrollTo({
            top: 0, 
            behavior: 'smooth'
          });
        
        }
       
      })
      .catch((err) => {
        setconfLoading(false)
        console.log(err);
      });

   }

    const closeErrorPopup = () => {
          
      setShowNoBtn(false)
      setShowOkBtn(false)
      setShowYesBtn(false)
      setModalShow(false)
     
    };

    const setPurchaseOrderNo = (e:any) => {
        const checked = e.target.checked;
        if(checked){
            setNilSales(true);
            setPOrderNo('NIL SALES');
            setDraftDis(true);
            setErrorPrd([]);
            setButtonDisabled(false)
            // const sales = data.map(o => {
            //     if (o.sales > 0) {
            //        o.sales = 0;
            //     }
            // });
           // setStocksData(salesAlldata.data.customerSales);
           setPoMessage("")
        }else{
            setNilSales(false);
            setPOrderNo("");

        }
        
    };

    const changePOrderNo = (event: any) => {
        if(null!==event.target.value){
            setPOrderNo(event.target.value);
        }
    };

    // const toggleFilter =()=> {
    //     const isShow = showFilter==true?false:true;
    //     setFilter(isShow)
    // }

    const changeSalesQty = (event: any) => {    
        if(null!==event.e.target.value && !isNaN(event.e.target.value)){
            setDraft(false);
            if(event.e.target.value < 0){
                return false;
            }
            let index = 0;
            sales = data.map((o) => {
                var temp = Object.assign({}, o);
                if (temp.productSfid === event.row.productSfid) {
                    temp.sales = event.e.target.value;
                    let OrgSoh = nilSaleData[index].closingSOH ;
                    setButtonDisabled(false)
                    // let v;
                    // if (isNaN(parseInt(o.stockIN))) v = parseInt(temp.sales);
                    //  else v = parseInt(o.stockIN) + parseInt(temp.sales);
                     //temp.closingSOH = v.toString();
                    //  const cSoh = temp.closingSOH=="" || temp.closingSOH==0?0:temp.closingSOH;
                     temp.closingSOH = OrgSoh - event.e.target.value;
                   if (event.e.target.value > OrgSoh && event.e.target.value!==0 ){
                    errorArry.push(temp.productSfid);
                    setDraftDis(true);
                    //setErrorPrd(errorArry)
                    setErrorPrd(errorProd => [...errorProd, ...errorArry]);
                   }else{
                       console.log(errorProd)
                       const newAr = errorProd.filter(ar => ar!==temp.productSfid);
                       if(newAr.length===0)
                       {
                        setDraftDis(false)
                       }
                       setErrorPrd(newAr);
                   }
                
                }
                index++;
                return temp;
            });
            setStocksData(sales);
        }
    };
    const tourSteps= tour.steps;
    const onExit = () => {
        setStepsEnabled(false);
      };
    // const startTour =()=>{
    //     if(data && tourRunSalesReport){
    //         setStepsEnabled(true);

    //     }
    // }
    useEffect(() => {
        let data={
            value:false,
            currentPage:'salesReport'
          }
        if(data && tourRunSalesReport && currentPage==="salesReport"){
            setStepsEnabled(true);
        }
        dispatch(runTour(data))

	}, [tourRunSalesReport]);
    // setStepsEnabled(tourRunSalesReport)
        

    

    return (
        <>
        <Steps
                enabled={stepsEnabled}
                steps={tourSteps}
                initialStep={initialStep}
                onExit={onExit}
                options={{
                    // nextLabel: 'test next',
                    prevLabel: 'Prev',
                    skipLabel: 'Skip',
                    showStepNumbers:true
                  }}
                />
         {showSucPopup && 
        <Container className='notification_popup'>
            <Row className='close_btn_row'>
                <Col md="11"></Col>
                <Col md="1"><button className='notification_popup_close' onClick={() => setSucPopup(false)}>close x</button></Col>
            </Row>
            <Row>
                <Col md="1"><img className='float-end' src={SuccessIcon} alt="success icon" height={20} width={20}></img></Col> 
                <Col><p style={{color: '#89D329'}}><span  style={{whiteSpace: 'pre-wrap'}}>Your Sales Report has been successfully submitted</span></p></Col>
            </Row>
                <Row>
                <Col md="1"></Col>
                <Col><p><span  style={{whiteSpace: 'pre-wrap'}}>Do you want to submit another Sales Report today? </span><span onClick={showSalesReport} style={{color:"#00BCFF"}}>Yes Please</span></p></Col>
            </Row>
            
        </Container>
        }

        
        {isDraft &&  <NotificationPopup showNotiPopup={showNotiPopup} notifications={draftMsg} className="noti-popup mb-3" />} 
      {!isSalesShow && <div>  <NotificationPopup showNotiPopup={showNotiPopup} notifications={warning} className="noti-popup mb-3" /> </div>} 
    {showResubmt && !isSalesShow && <span onClick={showSalesReport} style={{color:"#00BCFF", cursor:"pointer"}}>Click Here to Submit Again</span> }
      {isSalesShow &&  <NotificationPopup showNotiPopup={showNotiPopup} notifications={notifications} className="noti-popup mb-3" />} 
      {resubLoading &&  <div className="centeredSpin"><Spinner animation="border" role="status" ></Spinner></div> }
        {/* <NotificationPopup showNotiPopup={showSucPopup} notifications={SucNo} className="noti-popup mb-3" /> */}
        {!showSucPopup && isSalesShow &&
        <form >

        
          { showConfirmForm &&  <CinfirmSales data={submitData} purchaseNumber={pOrderdNo} submitSales={submitSalesHandller} cancelSubmit={canCelSubmitHandller}  />}  
          {confLoading &&  <div className="centeredSpin"><Spinner animation="border" role="status" ></Spinner></div> }
          
          { !showConfirmForm &&   <Container style={{marginTop: '10px'}}>
                
                <Row>
             {/* <img className='tour-icon' src={tourIcon} height={20} width={20} /> */}

                <div className='purchase-order-number stocktake-tg1'>
                <div className='purchase-title'><b>Purchase Order Number</b></div>
                <div className='puchase-input'><input type="text" disabled={nilSales} onBlur={(e)=> checkPOorderNumber(e)} value={pOrderdNo} className="purchase-num" style={{textAlign:'left', fontSize: '12px', border: '1px solid #89D329', borderRadius: '2px' , height:'30px'}}
                onChange={e => changePOrderNo(e)}  ref={nameRef} />
                {poMessage && <div className="errorMsg">{poMessage}</div> }
                </div>
                </div>

            {/* <Col  className="colFilter" style={{width:175}}>
                   
                    <div className="filter" onClick={toggleFilter}>
                     <img style={{width:15,height:15}} src={filter_alt_black_24dp}  /> Filter Column</div>
                        {showFilter &&
                        <div className="filterOptions"  style={{textAlignLast:'start',marginTop:5}} > 
                        <p className="filterNottext">Uncheck the columns that you do not want to se on table</p>
                    <ul className="items" >
                            <li>
                                
                                <span className='unstyled centered'>
                                    <input className="batch-styled-checkbox" id='deselect-all' onChange={handleSelectAll} type="checkbox"/>
                                <label htmlFor='deselect-all'></label></span>
                            Deselect All </li>
                            { list.map(item => <li key={item.name}>  
                               
                                <span className='unstyled centered'>
                                    <input className="batch-styled-checkbox" name={item.name} id={item.name} onChange={ e =>getSectedColumn(e)} checked={isCheck.includes(item.name)} type="checkbox" value="value1"/>
                                <label htmlFor={item.name}></label></span>
                                {item.name}  </li>) }
                        </ul>
                    </div>
                    }
                 
                    
                    </Col> */}
                </Row>
                <Row style={{marginTop: '5px'}}>
                <div className='purchase-order-number stocktake-tg2'>

                    <div className='nil-title'><b>Nil Sales</b></div>
                    <div className='nil-input'>
                    {/* <input type="checkbox" onClick={e => setPurchaseOrderNo()} style={{border: '1px solid #89D329', borderRadius: '2px'}} /> */}
                    <span className='unstyled centered'>
            <input className="batch-styled-checkbox" id='nil-sale' onChange={e => setPurchaseOrderNo(e)} checked={nilSales} type="checkbox" value="value1"/>
                    <label htmlFor='nil-sale'></label></span>
                    </div>
                    </div>
                </Row>
                <Row className='table-group-header floating-header-one' style={{marginTop: '20px'}}>
                    <Col md="4"></Col>
                    <Col md="3" style={{textAlign: 'center'}}><u>Outstanding</u></Col>
                    <Col md="1" style={{textAlign: 'center'}}><u>Opening</u></Col>
                    <Col md="2" style={{textAlign: 'center'}}><u>Sales</u></Col>
                    <Col md="1"></Col>
                    <Col md="1" style={{textAlign: 'center'}}><u>Closing</u></Col>
                </Row>
                <Row className='floating-header-two'>
                    <Col md="1" style={{textAlign: 'center'}}><p><b>Sr. No.</b></p></Col>
                    <Col md="3" className="stocktake-tg3" style={{textAlign: 'center'}}><p><b>Product Pack</b></p></Col>
                   {isCheck.includes('SITFB') &&  <Col md="1" style={{textAlign: 'center'}}><p title="Stock In Transit From Bayer"><b>SITFB</b></p></Col> }
                   {isCheck.includes('SITFO') &&  <Col md="1" style={{textAlign: 'center'}}><p title="Stock In Transit From Other Branch"><b>SITFO</b></p></Col> }
                   {isCheck.includes('RTT') &&  <Col md="1" style={{textAlign: 'center'}}><p title="Request To Transfer"><b>RTT</b></p></Col> }
                    <Col md="1" style={{textAlign: 'center'}}><p title="Openig Stock On Habd"><b>SOH</b></p></Col>
                    <Col md="1" style={{textAlign: 'center'}}><p title="Stock In"><b>IN</b></p></Col>
                    <Col md="1" style={{textAlign: 'center'}}><p title="Stock Out"><b>OUT</b></p></Col>
                    <Col md="1" style={{textAlign: 'center'}}><p><b>SALES</b></p></Col>
                    <Col md="1" style={{textAlign: 'center'}}><p title="Clossing Stock On Hand"><b>SOH</b></p></Col>
                </Row>
                <Row className='table-u floating-header-three'>
                    <Col md="1" style={{textAlign: 'center'}}><p><b>              </b></p></Col>
                    <Col md="3" style={{textAlign: 'center'}}><p><b>                                                        </b></p></Col>
                    <Col md="3" style={{textAlign: 'center'}}><p><b>                                                               </b></p></Col>
                    <Col md="1" style={{textAlign: 'center'}}><p><b>              </b></p></Col>
                    <Col md="2" style={{textAlign: 'center'}}><p><b>                                   </b></p></Col>
                    <Col md="1" style={{textAlign: 'center'}}><p><b>              </b></p></Col>
                    <Col md="1" style={{textAlign: 'center'}}><p><b>              </b></p></Col>
                </Row>
                {loading ? 
                <Row className='loader'>
                    <Spinner animation="border" role="status" ></Spinner>
                </Row> : 
                <>
                { data.map((row, key) => {
                    var bgColor = (key+1)%2===0 ? 'white':'#89d3290d';
                    return (
                        <>
                        <Row style={{backgroundColor: bgColor}} className='table-content' key={key+''}>
                            <Col md="1"><p>{key+1}.</p></Col>
                            <Col md="3"><p>{row.productName} - {row.productSAPMaterialNumber}</p></Col>
                
                    {isCheck.includes('SITFB') && <Col  md="1" style={row.outstandingSITFB > 0 ? {color:'#0091DF'} : {}}><p style={{textAlign: 'center'}}>{row.outstandingSITFB}</p></Col> }
                    {isCheck.includes('SITFO') && <Col md="1" style={row.outstandingSITFB > 0 ? {color:'#0091DF'} : {}}><p style={{textAlign: 'center'}}>{row.outstandingSITFO}</p></Col> }
                    {isCheck.includes('RTT') &&  <Col md="1" style={row.outstandingSITFB > 0 ? {color:'#0091DF'} : {}}><p style={{textAlign: 'center'}}>{row.outstandingRTT}</p></Col> }
                    
                            <Col md="1"><p style={{textAlign: 'center'}}>{row.openingSOH}</p></Col>
                            <Col md="1"><p style={{textAlign: 'center'}}>{row.stockIN}</p></Col>
                            <Col md="1"><p style={{textAlign: 'center'}}>{row.stockOUT}</p></Col>
                            <Col md="1" className="stocktake-tg4 stocktake-tg5" style={{textAlign: 'center'}}>
                                <input className={ errorProd.includes(row.productSfid)?"salesInputError":"salsInput"} onFocus={(e)=>handleFocus(e)} type="text" disabled={nilSales} value={row.sales} 
                                onChange={e => changeSalesQty({row, key, e})}
                                // onBlur={e => updateStock({row, key, e})}
                                />
                                { errorProd.includes(row.productSfid) &&
                                <><p className='WarningMsg'>Sales should not be greater than SOH</p></>}   
                            </Col>
                            <Col md="1"><p style={{textAlign: 'center' , color:row.closingSOH<0?"red":""}}>{row.closingSOH===""?0:row.closingSOH}</p></Col>
                                
                        </Row>
                        </>    
                    )
                } )}</>}
                <Row style={{paddingTop: '20px', justifyContent: 'right'}}>
                    <Col md="3" className="stocktake-tg6"> 
                        <ResetButton customClasssName="reset-btn-resposive" onClick={fetchData} title='RESET'/>
                    </Col>
                    <Col md="2"></Col>
                    <Col md="3" className="stocktake-tg7">
                        <SaveDraftButton  customClasssName="draft-btn-resposive" isButtonDisabled={isDraftDis} onClick={(e:any)=>handleSubmit(e,'Draft')}  />
                    </Col>
                    <Col md="4" className="stocktake-tg8">
                        <SubmitButton customClasssName="btn-resposive" isButtonDisabled={isButtonDisabled} onClick={(event:any)=>handleSubmit(event,'Submitted')} title='SUBMIT SALES REPORT'/>
                    </Col>
                </Row>
                <Row style={{marginTop: '60px', justifyContent: 'right'}}>
                    <Col md="11"></Col>
                    <Col md="1"><ScrollButton /></Col>
                </Row>
            </Container> }
            <ErrorPopup
                show={modalShow}
                onhide={() => closeErrorPopup()}
                okbtn={showOkBtn}
                errormsg={errorMsg}
                yesbtn={showYesBtn}
                nobtn={showNoBtn}
                errormsgblue={errorMsgBlue}
                heading={errorHeading}
                infoSign={false}
                onclickno={() => errorModalClose() }
                onclickyes={() => alert('on submit')}
            />
        </form>
    }
        </>
    )
}

export default SalesReport
