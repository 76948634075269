import React, { useState, useEffect } from 'react';
import { debounce } from './helpers';
import NavigationBar from './Navbar';
import NavigationBarResponsive from './NavbarHamburger';
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from "./Responsive";

const Nav = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(false);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;

    setVisible(currentScrollPos > 42);

    setPrevScrollPos(currentScrollPos);
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);

  }, [prevScrollPos, visible, handleScroll]);

  const navbarStyles = {
    position: 'fixed',
    height: '111px',
    width: '100%',
    backgroundColor: 'white',
    textAlign: 'center',
    // transition: 'top 0s',
    bottom:'1px',
    // boxShadow: '0px 0px 8px 2px #000000'
  }

  const isMobile = useMediaQuery({ maxWidth: DeviceSize.tablet });

  return (
    <div style={{ ...navbarStyles, top: visible ? '0' : '-200px' , zIndex: '1000'}}>
       {isMobile ? <NavigationBarResponsive /> : <NavigationBar />}
    </div>
  );
};

export default Nav;