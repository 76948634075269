//in PublicRoute.tsx
import React from "react";
import { Route, Redirect } from "react-router-dom";
//redux stuff
import { connect } from "react-redux";
const PublicRoute = ({
  component: Component,
  authenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authenticated ? <Redirect to="/Dashboard" /> : <Component {...props} />
    }
  />
);
const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
});
export default connect(mapStateToProps)(PublicRoute);
