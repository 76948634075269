import React, { useEffect, useState } from 'react';
import type { IData } from "./types";
import { BarChart } from "./BarChart";
import axios from '../../_helpers/axios';
import './salesAndforecast.scss'

const SalesAndForecast = () => {

  const [renderBar, setRenderBar] = useState(false);

  let barChartData: IData[] =[];
  const [chartData, setChartData] = useState(barChartData);
  
  useEffect(() => {
    const UserInfo = JSON.parse(localStorage.getItem("UserInfo")||"[]");
    const custInfo = UserInfo.data.customerInfo[0];
    let data: any[] = [];
    
    
    axios.get("util/salesforecast?accountSfid="+custInfo.account_sfid+"&salesGroup="+custInfo.salesArea_salesGroup)
    .then((res) => {
      if(res.data.status===200){
        data = res.data.data;
        const d = new Date();
        let currentMonth = d.getMonth() +1;
        let currentYear = d.getFullYear();

        var filteredArray = data.filter(function (el)
        {
          return parseInt(el.Month)===currentMonth && parseInt(el.year) === currentYear;
        }
        );
        if(filteredArray[0]) barChartData.push({label: "This Month",salesVolume :filteredArray[0]?.salesQty, salesValue: filteredArray[0]?.salesListPrice, forecastVolume: filteredArray[0]?.plannedForecastQty, forecastValue: filteredArray[0]?.plannedForecastListPrice});

     let thisYearSalesVolume = 0;
     let thisYearSalesValue = 0;
     let thisYearForecastVolume = 0;
     let thisYearForecastValue = 0;
     let yearToGoforecastVolume = 0;
     let yearToGoforecastValue = 0;
     data.map((i,k)=> {
      if(i.year === currentYear){
        if(parseInt(i.Month)>= currentMonth){
          yearToGoforecastVolume=yearToGoforecastVolume+i.plannedForecastQty;
          yearToGoforecastValue=yearToGoforecastValue+i.plannedForecastListPrice
        }
        thisYearSalesVolume = thisYearSalesVolume + i.salesQty;
        thisYearSalesValue = thisYearSalesValue + i.salesListPrice;
        thisYearForecastVolume = thisYearForecastVolume + i.plannedForecastQty;
        thisYearForecastValue = thisYearForecastValue + i.plannedForecastListPrice;
      }
      return null;  
     })

     barChartData.push({label: "This Year", salesVolume:  thisYearSalesVolume, salesValue: thisYearSalesValue, forecastVolume: thisYearForecastVolume, forecastValue: thisYearForecastValue});
     barChartData.push({label: "Year To Go", salesVolume: 0 , salesValue: 0, forecastVolume: yearToGoforecastVolume, forecastValue: yearToGoforecastValue});
    
       setChartData(barChartData);
       setRenderBar(true);
      } 
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <>
    <div style={{margin:10, width: '100%'}} className="notification-head-saf "><p>Sales and Forecast</p>
    <section>
        {renderBar && <BarChart data={chartData}/>}
      </section>
      {chartData.length>0? <><div style={{fontSize: '12px', textAlign: 'center'}}>
        <p style={{fontFamily: 'Helvetica Neue', color: '#96A8AE'}}> 
        <span className="grey-circle"></span> &nbsp;Forecast&nbsp;  
        <span className="purple-circle"></span>&nbsp; Sales  </p></div></>
        : <div><p style={{textAlign: 'center', color: 'rgb(150, 168, 174)'}}>No Data to show</p></div>}
      </div></>
  )
}

export default SalesAndForecast