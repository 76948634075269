//in useActions.ts file
import { GET_SALES, LOADING_UI, SET_ERRORS } from "../../types";
import axios from "../../../_helpers/axios";

export const getSalesData = (data: any) => async (dispatch: any) => {
  dispatch({ type: LOADING_UI });
  axios
    .get(
      `ordermanagement/salesreport?accountSfid=${data.account_sfid}&submitAgain=false`
    )
    .then((res: any) => {
      if (res.status === 200 || res.status === 208 || res.status === 201) {
        dispatch({
          type: GET_SALES,
          payload: res.data,
        });
      } else {
        dispatch({
          type: SET_ERRORS,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      const status = err.response?.status ? err.response?.status : 400;
      let message = "Something went wrong please contact Administrator";
      if (err.response?.status === 500) {
        message = "Fetch Failed , Please check resource";
      } else if (err.response?.status === 404) {
        message = "Requested resource not found";
      } else {
        message = "Something went wrong";
      }
      dispatch({
        type: SET_ERRORS,
        payload: { status: status, message: message },
      });
    });
};
