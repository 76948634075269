import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SuccessIcon from '../../assets/images/success_noti.svg';
import NotiIcon from '../../assets/images/info_noti.svg';
import WarningIcon from '../../assets/images/warning_noti.svg';
// import CustomInput from "../../components/input";
import { Markup } from 'interweave';

const NotificationPopup = (props: any) => {

    useEffect (()=>{
        setShowNotiPopup(props.showNotiPopup);
    }, [props.showNotiPopup]);
    
    const [showNotiPopup, setShowNotiPopup] = useState(props.showNotiPopup);

    return (
        showNotiPopup ? <Container className='notification_popup'>
            <Row className='close_btn_row'>
                <Col md="10"></Col>
                <Col md="2"><button className='notification_popup_close' onClick={() => setShowNotiPopup(false)}>close x</button></Col>
            </Row>
            { props.notifications.map((row: any, key: any)=>{
                if(row && row.notificationType){
                    // var parser = new DOMParser();
                    // var htmlDoc = parser.parseFromString(row.message, 'text/html');
                    var type = row.notificationType;
                    // var color;
                    var style;
                    var icon = NotiIcon
                    if(type==='success') {
                        icon=SuccessIcon; 
                        style = 'successNotification';}
                    else if(type==='info') {
                        icon=NotiIcon; 
                        style = 'infoNotification'; }
                    else if(type==='warning') {
                        icon = WarningIcon;
                        style = 'warningNotification';
                    }
                    return (
                        <div style={{margin: '20px 0px'}} key={key+''}>
                        <Row>
                            <Col md="1"><img className='float-end warningmsg-img' alt='warning icon' src={icon} ></img></Col> 
                            {/* <Col><p style={style}><span  style={{whiteSpace: 'pre-wrap'}}>{row.header}</span></p></Col> */}
                            <Col md='10'><Markup className={style} content={row.header} /></Col>
                        </Row>
                        {row.message && <Row>
                            <Col md="1"></Col>
                            {/* <Col><p><span className='stock-msg' style={{whiteSpace: 'pre-wrap'}}>{htmlDoc}</span></p></Col> */}
                            <Col md='10'>
                            <Markup className={style} content={row.message} />
                            </Col>
                            
                        </Row>}
                        </div>
                    )
                }
                return null;
            })}
            
        </Container>: <></>
    )
}

export default NotificationPopup