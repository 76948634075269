import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap';
// import Button from "@mui/material/Button";
import 'bootstrap/dist/css/bootstrap.css';
import { getCancelStocks } from '../../../redux/actions/stockManagementActions/requestActions';
import ErrorPopup from '../../../components/common/ErrorPopup';
import ScrollButton from '../../../components/common/ScrollButton';
// import ArrowIcon from "../../../assets/images/btn_rt_arrow.svg";
// import RtLayout from "../../../assets/images/rt_layout_submit.svg";
// import ResetIcon from "../../../assets/images/reset_icon.svg";
// import ResetRtLayout from "../../../assets/images/rt_layout_reset.svg";
import NoCancelRequestImg from "../../../assets/images/no_cancel_req.svg";
import NotificationPopup from '../../../components/common/NotificationPopup';
import ResetButton from '../../../components/common/ResetButton';
import SubmitButton from '../../../components/common/SubmitButton';
import axios from '../../../_helpers/axios';
import SearchIcon from '../../../assets/images/search_icon.svg';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import backIcon from '../../../assets/images/back-icon.svg'
import {runTourCancelRequest } from "../../../redux/actions/tourGuideActions";
import { Steps } from 'intro.js-react';
// import SubmitButtonNew from '../../../components/common/SubmitButtonNew';

const CancelRequest = (props: any) => {

    const dispatch = useDispatch();
    const currentAgency = JSON.parse(localStorage.getItem("SelectedAgency") || "[]");
    const userInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
    const accountSfid = currentAgency['account_sfid'];
    // const url = `ordermanagement/stockrequest?type=adjust&accountSfid=`.concat(accountSfid);
    const isrender = useSelector((state: RootStateOrAny) => state.user.renderCom);

    // const [renderCom, setrender] = useState(isrender);
    const stocksData = useSelector((state: RootStateOrAny) => state.CancelStcoks);
    const errors = useSelector((state: RootStateOrAny) => state.CancelStcoks.errors);

    var stock: any[] = [];
    var str: any = {};
    var arr: {}[] = [];

    const [data, setStocksData] = useState(stock);
    const [stockRequestData, setStockRequestData] = useState(str);
    const [modalShow, setModalShow] = useState(false);
    const [notifications, setNotifications] = useState(arr);

    const [errorMsg, setErrorMsg] = useState('');
    const [errorHeading, setErrorHeading] = useState('');
    const [errorMsgBlue, setErrorMsgBlue] = useState('');
    const [showOkBtn, setShowOkBtn] = useState(false);
    const [showYesBtn, setShowYesBtn] = useState(false);
    const [showNoBtn, setShowNoBtn] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [showNotiPopup, setShowNotiPopup] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searchForProduct, setSearchForProduct] = useState(false);
    const [productToSearch, setProductToSearch] = useState('');
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [initialStep, setInitialStep] = useState(0);
    const tourRunCancelRequest = useSelector((state:RootStateOrAny) => state.TourGuide.tourInfo.value)
    const currentPage = useSelector((state:RootStateOrAny) => state.TourGuide.tourInfo.currentPage)
    

    useEffect(() => {
        localStorage.setItem("currentPage", 'cancelRequest');
        if (errors.status > 0) {
            setNotifications([{ notificationType: 'warning', header: errors.message }]);
            setLoading(false);
            setShowNotiPopup(true);
        }
        if (stocksData.stocksData) {
            if (stocksData.stocksData.status === 200) {
                setLoading(false);
                setStocksData(stocksData.stocksData.data.customerOrders);
                setStockRequestData(stocksData.stocksData);
            } else if (stocksData.stocksData.status === 201 || stocksData.stocksData.status === 208) {
                setNotifications([{ notificationType: 'warning', header: stocksData.stocksData.message }]);
                setLoading(false);
                setShowNotiPopup(true);
            }
        }
    }, [stocksData]);

    useEffect(() => {
        localStorage.setItem("currentPage", 'cancelRequest');
    });

    const [tour, setTour] = useState({
        steps: [
            {
                element: '.cancel-request-tg1',
                title:'Step 01',
                intro: <h6 className='guide-text'>you can start by feeding your desired quantity in the input fields.</h6>,
                position: 'right',
                tooltipClass: 'myTooltipClass',
                highlightClass: 'myHighlightClass',
              },
              {
                element: '.cancel-request-tg2',
                intro: <h6 className='guide-text'>You can check the box to CANCEL the entire requested quantity</h6>,
              },
              {
                element: '.cancel-request-tg3',
                intro: <h6 className='guide-text'>You can only feed a value that is less than the requested quantity for cancellation.</h6>,
              },
              {
                element: '.cancel-request-tg4',
                intro: <h6 className='guide-text'>you can also choose to search for the product you want by typing here</h6>,
              },
              {
                element: '.cancel-request-tg5',
                intro: <h6 className='guide-text'>Reset button will clear out all the inputs made.</h6>,
              },
              {
                element: '.cancel-request-tg6',
                intro: <h6 className='guide-text'>Press the submit button once you finish making the changes. Success message would be displayed once the stock request is cancelled</h6>,
              },
              {
                element: '.cancel-request-tg7',
                intro: <h6 className='guide-text'>Press here for seeing the previous page.</h6>,
              },
              {
                element: '.cancel-request-tg8',
                intro: <h6 className='guide-text'>You can also tap on the Heading text to go back to the main page.</h6>,
              },
              {
                element: '.tour-explore',
                intro: <h6 className='guide-text'>Click on the explore icon if you need to access the guided tour again.</h6>,
              },
      ]
    })

    async function submitAndfetchData() {
        if (submitClicked) submitStockRequest();
        else fetchData();
        closeErrorPopup();
        setUnsavedChanges(false);
        setSubmitClicked(false);
    }

    async function fetchData() {
        setLoading(true);
        // await axios.get(url)
        //  .then((res: any) => {
        //      console.log(res);

        //      if(res.data.message=='Success'){
        //         setLoading(false);
        //         setStockRequestData(res.data); 
        //         setStocksData(res.data.data.customerOrders);
        //      }else if(res.data.status==208){
        //         setLoading(false);
        //         setNotifications([{notificationType: 'warning', header: res.data.message}]);
        //         setShowNotiPopup(true);
        //      }
        //  })
        //  .catch((err) => {
        //     console.log(err.message);
        //     let errorNoti = {notificationType: 'warning', header: err.message};
        //      setLoading(false)
        //      setNotifications([errorNoti]);
        //      
        //  });
        dispatch(getCancelStocks(currentAgency));
        window.scrollTo({ top: 0, behavior: 'auto' });
    }

    const handleSubmit = () => {
        if (unsavedChanges) {
            setSubmitClicked(true);
            setErrorHeading('Confirmation to Cancel Request');
            setErrorMsg('Are you sure you want to submit the cancel request?');
            setErrorMsgBlue('');
            setShowOkBtn(false);
            setShowYesBtn(true)
            setShowNoBtn(true)
            setModalShow(true);
        }
    };

    function submitStockRequest() {
        setShowNotiPopup(false);
        var temp = stockRequestData.data.customerOrders;
        stockRequestData.data.customerOrders = [];
        for (let i = 0; i < data.length; i++) {
            if (temp[i].productSfid === data[i].productSfid) {
                if (temp[i].adjustedQty !== data[i].adjustedQty) {
                    var tempData = data[i];
                    if (data[i].totalRequestQty === 0) {
                        tempData = { ...data[i], orderItmStatus: 'Cancelled' };
                    }
                    stockRequestData.data.customerOrders.push(tempData);
                }
            }
        }
        stockRequestData.data.userDetails = userInfo.data.userDetails;
        postData(`ordermanagement/stockrequest?type=adjust`, stockRequestData);

    }

    async function postData(url = '', data = {}) {
        await axios.post(url, data).then((res) => {
            console.log("user data", res.data);
            if (res.data.status === 200) {
                let to = (res.data.data.emailReceipents && res.data.data.emailReceipents.TO.length>0) ? res.data.data.emailReceipents.TO : '';
                let cc = (res.data.data.emailReceipents && res.data.data.emailReceipents.CC.length>0) ? res.data.data.emailReceipents.CC : '';
                let msg = '';
                if(to === userInfo.data.userDetails.cont_email) msg = 'Email was sent to you';
                else msg = 'Email was sent to '.concat(to);
                if (cc !== '') msg = msg + ' and a copy to ' + cc;
                let noti = [{ notificationType: 'success', header: msg },
                { notificationType: 'success', header: 'Your Request For Stock Cancellation has been processed.' }];
                setNotifications(noti);
                setShowNotiPopup(true);
                fetchData();
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleReset = () => {
        if (unsavedChanges) {
            setSubmitClicked(false);
            setErrorHeading('Reset filters');
            setErrorMsg('All your selected options will be cleared.');
            setErrorMsgBlue('');
            setShowOkBtn(false);
            setShowYesBtn(true);
            setShowNoBtn(true);
            setModalShow(true);
            setShowNotiPopup(false);
        }
    };

    const handleFocus = (e: any) => e.target.select();

    const closeErrorPopup = () => {
        setShowNoBtn(false)
        setShowOkBtn(false)
        setShowYesBtn(false)
        setModalShow(false)
    };

    const changeAdjQty = (event: any) => {
        if (null !== event.e.target.value && !isNaN(parseInt(event.e.target.value))) {
            stock = data.map(o => {
                var temp = Object.assign({}, o);
                if (temp.productSfid === event.row.productSfid) {
                    if (!unsavedChanges) setUnsavedChanges(true);
                    temp.adjustedQty = parseInt(event.e.target.value);
                    if(event.e.target.value===temp.currentQty){
                        temp.totalRequestQty = 0;
                    }
                }
                return temp;
            });
            setStocksData(stock);
        }
    };

    const cancelTotal = (event: any) => {
        if (null !== event.e.target.checked) {
            stock = data.map(o => {
                var temp = Object.assign({}, o);
                if (temp.productSfid === event.row.productSfid) {
                    let tempNote = "Quantity Changed from " + temp.totalRequestQty + " to " ;
                    if (event.e.target.checked) {
                        setUnsavedChanges(true);
                        temp.adjustedQty = temp.currentQty;
                        temp.totalRequestQty = 0;
                    }
                    else {
                        temp.adjustedQty = 0;
                        temp.totalRequestQty = temp.currentQty;
                    }
                    tempNote = tempNote + temp.totalRequestQty + " by " + userInfo.data.userDetails.cont_firstName + " " + userInfo.data.userDetails.cont_lastName;
                    temp.notes = tempNote;
                }
                return temp;
            });
            props.setSwitchEnable(false);
            setStocksData(stock);
        }

    };

    const goBAck = () => {
        props.handleGoBack()

    }

    const updateStock = (event: any) => {
        if (null !== event.e.target.value && !isNaN(event.e.target.value)) {
            const value = parseInt(event.e.target.value);
            stock = data.map(o => {
                var temp = Object.assign({}, o);
                if (temp.productSfid === event.row.productSfid) {
                    let tempNote = "Quantity Changed from " + temp.totalRequestQty + " to " ;
                    if(value> o.currentQty){
                        {
                            event.e.target.style.border = "1px solid red";
                            event.e.target.style.color = "red";
                            setShowYesBtn(false);
                            setShowNoBtn(false);
                            setErrorHeading('Qty to cancel cannot be greater than Current Requested Qty');
                            setErrorMsgBlue(temp.productName);
                            setShowOkBtn(true);
                            setModalShow(true);
                            setTimeout(() => {
                                event.e.target.style.border = "1px solid #89D329";
                                event.e.target.style.color = "black";

                            }, 2000);
                            temp.adjustedQty = o.currentQty;
                            temp.totalRequestQty = 0;
                        }
                    }
                    else if (value % temp.shipperQty === 0) {
                        
                        temp.adjustedQty = value;
                        if (!unsavedChanges) setUnsavedChanges(true);
                        if (value <= o.currentQty) {
                            
                            temp.adjustedQty = value;
                            temp.totalRequestQty = temp.currentQty - value;
                            
                        }
                    }
                    else {
                        if (event.e.target.value === "") {
                            temp.adjustedQty = 0;
                            temp.totalRequestQty = temp.currentQty;
                        }
                        else {
                            event.e.target.style.border = "1px solid red";
                            event.e.target.style.color = "red";
                            setShowYesBtn(false);
                            setShowNoBtn(false);
                            setErrorHeading('The Shipper Quantity for this product is ' + (temp.shipperQty));
                            setErrorMsgBlue((event.key + 1) + '. ' + temp.productName);
                            setErrorMsg('Requested Quantity must be a multiple of Shipper Quantity. Your Requested quantity has been rounded up to the nearest complete shipper.(You may still adjust the requested quantity if you wish).');
                            setShowOkBtn(true);
                            setModalShow(true);
                            if (!unsavedChanges) setUnsavedChanges(true);

                            setTimeout(() => {
                                event.e.target.style.border = "1px solid #89D329";
                                event.e.target.style.color = "black";

                            }, 2000);

                            var i = Math.floor(value / temp.shipperQty);
                            temp.adjustedQty = temp.shipperQty * (i + 1);
                            temp.totalRequestQty = o.currentQty >= temp.adjustedQty ? o.currentQty - temp.adjustedQty : o.currentQty;

                        }
                    }
                    tempNote = tempNote + temp.totalRequestQty + " by " + userInfo.data.userDetails.cont_firstName + " " + userInfo.data.userDetails.cont_lastName;
                    temp.notes = tempNote;
                }
                props.setSwitchEnable(false);
                return temp;
            });
            setStocksData(stock);
        }
    };
    const tourSteps= tour.steps;
    const onExit = () => {
        setStepsEnabled(false);
      };
    // const startTour =()=>{
    //     setStepsEnabled(true);
    // }
    useEffect(() => {
        let data={
            value:false,
            currentPage:'cancelRequest'
            }
        if(tourRunCancelRequest && currentPage==="cancelRequest"){
            setStepsEnabled(true);
        }
        dispatch(runTourCancelRequest(data))

    }, [tourRunCancelRequest]);

    return (
        <>
        <div style={{display:'flex' , justifyContent:'space-between'  }}>
        <div><h5 className='addreqtitle'>Cancel Request</h5></div>
        <div><button className='go-back-btn cancel-request-tg7' onClick={goBAck} ><img className="back-btn-icon" alt="back btn icon" src={backIcon}/>Go Back</button></div>
        </div>
            {(data.length === 0 && loading === false) ?
                <div className="no-cancel-req" >
                    <div>
                        <img src={NoCancelRequestImg} alt="no existing stock requests for cancellation" />
                        <p><b>There are no existing stock requests for cancellation.</b></p>
                        <p>You can add stock to current requests by using the <p style={{ color: '#0091DF' }}>'Add to my current request'</p> tab.</p>
                    </div>
                </div>
                :
                <form onSubmit={handleSubmit}>
                    <Steps
                enabled={stepsEnabled}
                steps={tourSteps}
                initialStep={initialStep}
                onExit={onExit}
                options={{
                    // nextLabel: 'test next',
                    prevLabel: 'Prev',
                    skipLabel: 'Skip',
                    showStepNumbers:true
                  }}
                />
                    <NotificationPopup showNotiPopup={showNotiPopup} notifications={notifications} />
                    <Container>
                        <div className='table-header table-responsive'>
                            <span className='wrapping'><p><b>Sr. No.</b></p></span>
                            <span className='search-product-main wrapping cancel-request-tg4'>
                                {searchForProduct ?
                                    <div><textarea rows={1} className='d-inline-block search-product' onChange={e => { setProductToSearch(e.target.value); }} />
                                        <button className='close_search d-inline-block' onClick={() => { setSearchForProduct(false); setProductToSearch('') }}>x</button>
                                    </div> :
                                    <p className='d-inline-block'><b>Product Pack &emsp;
                                        <img className='d-inline-block' alt="search icon" src={SearchIcon} onClick={() => { setSearchForProduct(true); }
                                        }></img> &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b></p>}
                            </span>
                            <span className='wrapping'><p><b>Requested Qty</b></p></span>
                            <span className='wrapping'><p><b>Qty to Cancel</b></p></span>
                            <span className='wrapping'><p><b>Cancel Total Qty</b></p></span>
                            <span className='wrapping'><p><b>Total Request Qty</b></p></span>
                        </div>
                        {loading ?
                            <Row className='loader'><Spinner animation="border" role="status" ></Spinner></Row> :
                            <>
                                {
                                    data.filter(function (item: any) {
                                        return item.productName.toLowerCase().includes(productToSearch.toLowerCase());
                                    }).map((row, key) => {
                                        var bgColor = (key + 1) % 2 === 0 ? 'white' : '#89d3290d';

                                        let currentQty = "0";
                                        if (row.currentQty !== null) {
                                            currentQty = row.currentQty;
                                        }
                                        return (
                                            <>
                                                <Row style={{ backgroundColor: bgColor, padding: '0.5%' }} className='table-content table-row-req' key={key+''} >
                                                    <Col md="1"  style={{paddingLeft:'4px'}}><span  className="req-product-name">{key + 1}.</span></Col>
                                                    <Col md="4"><span className="req-product-name">{row.productName}</span></Col>
                                                    <Col md="1" style={{ textAlign: 'center'}}><span  className="req-product-name" >{currentQty}</span></Col>
                                                    <div className='cancel-request-tg3'>
                                                        <Col md="3"></Col>
                                                        <Col md="3" className="cancel-request-tg1"  style={{ textAlign: 'end' }}>
                                                            <input id={'input' + key + 1} type="text" disabled={row.currentQty === row.adjustedQty} value={row.adjustedQty} placeholder={"0"} className="cancel-product-input" style={{ textAlign: 'end', border: '1px solid #89D329', borderRadius: '2px' }}
                                                                onChange={e => changeAdjQty({ row, e })}
                                                                onBlur={e => updateStock({ row, e })}
                                                                onFocus={(e) => handleFocus(e)}
                                                            />
                                                        </Col>
                                                        <Col  md="5" className='cancel-request-tg2' style={{ textAlign: 'end' }}>
                                                            {/* <input type="checkbox" onClick={e => cancelTotal({row, e})} style={{border: '1px solid #89D329', borderRadius: '2px', color: '#89D329'}} /> */}
                                                            <span className='unstyled centered req-product-name'>
                                                                <input className="batch-styled-checkbox" id={row.productSfid} onChange={e => cancelTotal({ row, e })} checked={row.currentQty === row.adjustedQty} type="checkbox" value="value1" />
                                                                <label htmlFor={row.productSfid}></label></span>
                                                        </Col>
                                                    </div>

                                                    <Col md="2"  style={{ textAlign: 'end' }}><span  className="req-product-name" style={{marginRight:'5px'}}>{row.totalRequestQty}</span></Col>
                                                </Row>
                                            </>

                                        )
                                    })} </>}
                        <Row style={{ paddingTop: '20px', justifyContent: 'right' }}>
                            {/* <Col md="3" className='cancel-request-tg5'>
                                <ResetButton onClick={() => handleReset()} isButtonDisabled={!unsavedChanges} />
                            </Col>
                            <Col md="5"></Col>
                            <Col md="4" className='cancel-request-tg6'>
                                <SubmitButton onClick={() => handleSubmit()} title='SUBMIT CANCEL REQUEST' isButtonDisabled={!unsavedChanges} />
                            </Col> */}
                            <div className='btn-reset-submit'>
                            <ResetButton  customClasssName="reset-btn-resposive cancel-request-tg5" onClick={() => handleReset()} isButtonDisabled={!unsavedChanges} title='RESET'/>
                            {/* <SubmitButtonNew classsName="submit-request-stock cancel-request-tg6" customClasssName="btn-resposive cancel-request-tg6" arrowClass="btn-req-media" onClick={() => handleSubmit()} title='SUBMIT CANCEL REQUEST' isButtonDisabled={!unsavedChanges} /> */}

                            <SubmitButton classsName="submit-request-stock cancel-request-tg6" customClasssName="btn-resposive cancel-request-tg6" arrowClass="btn-req-media" onClick={() => handleSubmit()} title='SUBMIT CANCEL REQUEST' isButtonDisabled={!unsavedChanges} />
                            </div>
                        </Row>
                        <Row style={{ paddingTop: '60px', justifyContent: 'right' }}>
                            <Col md="11"></Col>
                            <Col md="1"><ScrollButton /></Col>
                        </Row>
                    </Container>
                    <ErrorPopup
                        show={modalShow}
                        onhide={() => closeErrorPopup()}
                        okbtn={showOkBtn}
                        errormsg={errorMsg}
                        yesbtn={showYesBtn}
                        nobtn={showNoBtn}
                        errormsgblue={errorMsgBlue}
                        heading={errorHeading}
                        infoSign={true}
                        onclickno={() => setModalShow(false)}
                        onclickyes={() => submitAndfetchData()}
                    />
                </form>}
        </>

    )
}

export default CancelRequest

