import axios from 'axios';
 //var baseUrl = process.env.BASE_URL || 'http://localhost:5000/services/api/';
//  var baseUrl = process.env.REACT_APP_BASE_URL || 'https://anz-cropcentre-api-int.herokuapp.com/services/api/';
 var baseUrl = process.env.REACT_APP_BASE_URL || 'https://anz-cropcentre-api-dev.herokuapp.com/services/api/';

//  var reportCrmUrl = process.env.REACT_CRMUrl || 'https://monsanto-anz--test.lightning.force.com';
export const sideUrl = "https://qa-cropcentre.bayer.com.au/";
//var baseUrl = process.env.BASE_URL || 'https://api01-np.agro.services:443/cropcentreapis/services/api/';
const instance = axios.create({ baseURL: baseUrl });
export default instance;
