import { RUN_TOUR_SALES,RUN_TOUR_GOODS,RUN_TOUR_REQUEST_MAIN,RUN_TOUR_ADD_REQUEST,RUN_TOUR_CANCEL_REQUEST,RUN_TOUR_STOCK_TAKE} from "../types";


const initialState = {
    salesData: [],
    isAgencyChnage:false,
    loading:false,
    errors:[],
    runTourSales:false,
    runTourGoods:false,
    runTourAddRequest:false,
    runTourCancelRequest:false,
    runTourRequestMain:false,
    runTourGoodsMovement:false,
    // runTourSales:false,
    tourInfo:''

}

export default function (state = initialState, action) {
	switch (action.type) {
		
        case RUN_TOUR_SALES:
          return {
            ...state,
            tourInfo:action.payload
          }
        case RUN_TOUR_GOODS:
          return {
            ...state,
            tourInfo:action.payload
          }
          case RUN_TOUR_REQUEST_MAIN:
            return {
              ...state,
              tourInfo:action.payload
            }
          case RUN_TOUR_ADD_REQUEST:
            return {
              ...state,
              tourInfo:action.payload
            }
          case RUN_TOUR_CANCEL_REQUEST:
            return {
              ...state,
              tourInfo:action.payload
            }
          case RUN_TOUR_STOCK_TAKE:
            return {
              ...state,
              tourInfo:action.payload
            }
		default:
			return state;
	}
}
