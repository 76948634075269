import { Fragment } from "react";
import Login from "./Login";
import ForgetPassword from "./ForgetPassword";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Carousel from "../../components/layouts/Carousel";
import BayerLogo from "../../assets/images/bayer_logo.svg";
// import Header from "../../components/layouts/Header";


const FrontComponent = (props: any) => {
	return (
		<Fragment>
			
			<Grid container component="main" className="login-form-responsive" sx={{ height: "100vh" }}>
				<CssBaseline />
				<Grid item xs={false} sm={4} md={6}>
					<Carousel />
				</Grid>
				<Grid className="login-mt" item xs={12} sm={8} md={5}>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-start",
						}}
					>
						<Typography component="h1" variant="h5" sx={{ mb: 2 }} className="login-title">
							<img src={BayerLogo} className="bayer-login-img" style={{ paddingRight: "10px" }} alt="Bayer Logo" />
							CropCentre
						</Typography>
						{(props.history.location.pathname === "/login" || props.history.location.pathname === "/") && <Login />}
						{props.history.location.pathname === "/forgot-password" && <ForgetPassword />}
					</Box>
				</Grid>
			</Grid>
		</Fragment>
	);
};

export default FrontComponent;
