import React from 'react';
import {Container } from 'react-bootstrap';
import { useSelector} from 'react-redux';

const SalesStockList = (props: any) => {
    
  const salesStocksFilterData = useSelector((state: any) => state.SalesStock.stockDataFilter); //to show selected value of filter
  // const groupFilterSales = useSelector((state: any) => state.SalesStock.groupDataFilter); //to show selected value of filter
  let showSales = salesStocksFilterData==='Sales Only'|| salesStocksFilterData==='Sales and Stock';
  let showStocks = salesStocksFilterData==='Stock Only'|| salesStocksFilterData==='Sales and Stock';

  return (
    <>
      {props.data?.map((row: any, key: any) => {
        return (
          <Container style={{ margin: '50px 0px' }}>
             
                <div className="report-display-container">
                    <div className="report-result-name report-fields-title stock-sales-font">{row.Name } </div>
                    <div className="report-fields report-fields-title stock-sales-font" title="Supply Gap"> SG</div>
                    <div className="report-fields report-fields-title stock-sales-font" title="Customer Plan"> CP</div>
                    {showStocks && <div className="report-fields report-fields-title stock-sales-font" title="Total Stock-on-Hand including in-transit">TSOH</div>}
                    {showStocks && <div className="report-fields report-fields-title stock-sales-font" title="Stock on Hand"> SOH</div>}
                    {showStocks && <div className="report-fields report-fields-title stock-sales-font" title="Stock-in-transit from Bayer"> SITFB</div>}
                    {showStocks && <div className="report-fields report-fields-title stock-sales-font" title="Stock-in-transit from Other Branch"> SITFO</div>}
                    {showStocks && <div className="report-fields report-fields-title stock-sales-font" title="Stock being picked"> SBP</div>}
                    {showStocks && <div className="report-fields report-fields-title stock-sales-font"  title="Request to transfer"> RTT</div>}
                    {showStocks && <div className="report-fields report-fields-title stock-sales-font"  title="Stock Under Investigation"> SUI</div>}
                    {showStocks && <div className="report-fields report-fields-title stock-sales-font"  title="Total Stock Received (net SOH + net Sales)"> TSR</div>}
                    {showStocks && <div className="report-fields report-fields-title stock-sales-font"  title="Total Stock Received Last Year"> TSRLY</div>}
                    {showStocks && <div className="report-fields report-fields-title stock-sales-font" title="Stock On Request"> SOR</div>}
                    {showSales && <div className="report-fields report-fields-title stock-sales-font" title="Forecast this Month"> FCTM</div>}
                    {showSales && <div className="report-fields report-fields-title stock-sales-font" title="Forecast this Year"> FCTY</div>}
                    {showSales && <div className="report-fields report-fields-title stock-sales-font" title="Sales this Month"> STM</div>}
                    {showSales && <div className="report-fields report-fields-title stock-sales-font" title="Sales this Year"> SYTD</div>}
                    {showSales && <div className="report-fields report-fields-title stock-sales-font" title="Sales Last Year" > SLY</div>}
                    </div>
                    <div className='report-fields-content-div'>
                    {
              row.salesStkAnalysisWrpList?.map((r: any, k: any) => {
                var bgColor = (k + 1) % 2 === 0 ? 'white' : '#89d3290d';
                return (<>
                    <div className="report-display-container stock-sales-font" style={{ backgroundColor: bgColor }}>
                        <div className="report-result-name stock-sales-font">{r.childName } </div>
                        <div className="report-fields report-fields-content stock-sales-font">{r.supplyGap}</div>
                        <div className="report-fields report-fields-content stock-sales-font"> {r.customerPlan}</div>
                        {showStocks && <div className="report-fields report-fields-content stock-sales-font"> {r.tsoh}</div>}
                        {showStocks && <div className="report-fields report-fields-content stock-sales-font"> {r.soh}</div>}
                        {showStocks && <div className="report-fields report-fields-content stock-sales-font"> {r.sitfb}</div>}
                        {showStocks && <div className="report-fields report-fields-content stock-sales-font"> {r.sitfo}</div>}
                        {showStocks && <div className="report-fields report-fields-content stock-sales-font"> {r.sbp}</div>}
                        {showStocks && <div className="report-fields report-fields-content stock-sales-font"> {r.rtt}</div>}
                        {showStocks && <div className="report-fields report-fields-content stock-sales-font"> {r.sui}</div>}
                        {showStocks && <div className="report-fields report-fields-content stock-sales-font"> {r.tsr}</div>}
                        {showStocks && <div className="report-fields report-fields-content stock-sales-font"> {r.tsrly}</div>}
                        {showStocks && <div className="report-fields report-fields-content stock-sales-font"> {r.sor}</div>}
                        {showSales && <div className="report-fields report-fields-content stock-sales-font"> {r.fctm}</div>}
                        {showSales && <div className="report-fields report-fields-content stock-sales-font"> {r.fcty}</div>}
                        {showSales && <div className="report-fields report-fields-content stock-sales-font"> {r.stm}</div>}
                        {showSales && <div className="report-fields report-fields-content stock-sales-font"> {r.sytd}</div>}
                        {showSales && <div className="report-fields report-fields-content stock-sales-font"> {r.sly}</div>}

                        </div>
                        </>) })}
                    </div>

                  <div className="report-display-container stock-sales-font" style={{margin: '20px 1px 20px -6px'}}>
                    <div className="report-result-name report-fields-total stock-sales-font">Stock Sold( {row.pcSales}% ) vs Stock Sold LY( {row.pcSalesLY}% )</div>
                    <div className="report-fields report-fields-total stock-sales-font">{row.supplyGapTotal}</div>
                    <div className="report-fields report-fields-total stock-sales-font"> {row.customerPlanTotal}</div>
                    {showStocks && <div className="report-fields report-fields-total stock-sales-font"> {row.tsohTotal}</div>}
                    {showStocks && <div className="report-fields report-fields-total stock-sales-font"> {row.sohTotal}</div>}
                    {showStocks && <div className="report-fields report-fields-total stock-sales-font"> {row.sitfbTotal}</div>}
                    {showStocks && <div className="report-fields report-fields-total stock-sales-font"> {row.sitfoTotal}</div>}
                    {showStocks && <div className="report-fields report-fields-total stock-sales-font"> {row.sbpTotal}</div>}
                    {showStocks && <div className="report-fields report-fields-total stock-sales-font"> {row.rttTotal}</div>}
                    {showStocks && <div className="report-fields report-fields-total stock-sales-font"> {row.suiTotal}</div>}
                    {showStocks && <div className="report-fields report-fields-total stock-sales-font"> {row.tsrTotal}</div>}
                    {showStocks && <div className="report-fields report-fields-total stock-sales-font"> {row.tsrlyTotal}</div>}
                    {showStocks && <div className="report-fields report-fields-total stock-sales-font"> {row.sorTotal}</div>}
                    {showSales && <div className="report-fields report-fields-total stock-sales-font"> {row.fctmTotal}</div>}
                    {showSales && <div className="report-fields report-fields-total stock-sales-font"> {row.fctyTotal}</div>}
                    {showSales && <div className="report-fields report-fields-total stock-sales-font"> {row.stmTotal}</div>}
                    {showSales && <div className="report-fields report-fields-total stock-sales-font"> {row.stydTotal}</div>}
                    {showSales && <div className="report-fields report-fields-total stock-sales-font"> {row.slyTotal}</div>}
                  </div>         
          </Container>)
      })}
           <hr />
         {props.data[0].strTimeInAMorPM!=="" && <p style={{color:'gray'}}> Report run date: {props.data[0].strTimeInAMorPM} </p> }
    </>
  )
}

export default SalesStockList;