import { Fragment, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import { displayError } from "../../utils/helper";
import Link from "@mui/material/Link";
import CustomInput from "../../components/input";
import ArrowIcon from "../../assets/images/btn_rt_arrow.svg";
import RtLayout from "../../assets/images/rt_layout.svg";
import LeftLayout from "../../assets/images/left_layout.svg";
import LeftArrowIcon from "../../assets/images/btn_lt_arrow.svg";
import SuccessIcon from '../../assets/images/success_noti.svg';
import axios from '../../_helpers/axios';
import {  Col, Container, Row, Spinner } from 'react-bootstrap';
import ErrorPopup from '../../components/common/ErrorPopup';
interface State {
	username: "";
	usernameError: string;
}
const ForgetPassword = () => {
	const [fields, setFields] = useState<State>({
		username: "",
		usernameError: "",
	});
	const [loading , setLoading ] = useState(false);
	const [showSucPopup, setSucPopup] = useState(false);
	const [showError, setShowError] = useState(false);
//   const [okBtn, setokbtn] = useState(true);
//   const [yesBtn , setYesBtn ] = useState(true);
//   const [closeBtn , setCloseBtn ] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorHeading, setErrorHeading] = useState('');
 
	
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		let formValid = checkValidation();

		const data = new FormData(event.currentTarget);
		// eslint-disable-next-line no-console
		if(formValid){
			requestOTP(data.get("username"))
		}
		console.log({
			email: data.get("username")
		});
	};

	
const requestOTP = (userName:any) => {
	const newPassword ="";
	let username= {
'userName':userName
}
	setLoading(true)
    postDataOTP('usermanagement/generateOneTimePassword?newPassword'+newPassword, username);
  
  }

async function postDataOTP(url = '', data:any) {
  await axios.post(url, data).then((res:any) => {
	if(res.status===200)
	  {
		setSucPopup(true);
	  }else if(res.status===208)
	  {
		setErrorHeading('Error');
		setErrorMsg(res.data.message);
		setShowError(true)

	  }
	  setLoading(false)
	  
    })
    .catch((err) => {
	  console.log(err);
	  setLoading(false)
	  setSucPopup(false);
	  setErrorHeading('Error');
	  setErrorMsg("Something went wrong please contact administrator");
	  setShowError(true)
    });
} 
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let obj: any = { ...fields };
		if (e.target.name === "username") {
			if (e.target.value === "" || e.target.value === null) {
				const errMsg = "Email ID is required.";
				obj.username = e.target.value;
				obj.usernameError = errMsg;
			} else {
				obj.username = e.target.value;
				obj.usernameError = "";
			}
		}
		setFields({ ...obj });
	};
	// Checks EMail validation
	const checkValidation = () => {
		let formValid = true;
		const { username } = { ...fields };
		let obj = { ...fields };
		if (username === "") {
			const errMsg = "Email ID is required.";
			obj.usernameError = errMsg;

			formValid = false;
		}

		setFields({ ...obj });
		return formValid;
	};

	return (
		<Fragment>
			      {showSucPopup && 
         <Container className='notification_popup'>
            <Row className='close_btn_row'>
                <Col md="11"></Col>
                <Col md="1"><button className='notification_popup_close' onClick={() => setSucPopup(false)}>close x</button></Col>
            </Row>
                        <Row>
                            <Col md="1"><img className='float-end' alt='success icon' src={SuccessIcon} height={20} width={20}></img></Col> 
                            <Col><p style={{color: '#89D329'}}><span  style={{whiteSpace: 'pre-wrap'}}>Password Reset</span></p></Col>
                        </Row>
                         <Row>
                            <Col md="1"></Col>
                            <Col><p><span  style={{whiteSpace: 'pre-wrap'}}>Your password has been reset successfully.Updated password email to your register email address </span><span  style={{color:"#00BCFF"}}>
								<Link href="/login" variant="body2">
							    Click here to login
						       </Link>
								</span></p></Col>
                        </Row>
            
        </Container>
        }
			{loading && <div className="centeredSpin">	<Spinner animation="border" role="status" ></Spinner> </div> }
			<Typography component="h1" variant="h6" sx={{ color: "#00617F" }}>
				Password Reset
			</Typography>
			<p style={{ color: "#1B384D" }}>Enter your email address below and a password reset email will be sent to you.</p>
			<Box component="form" sx={{ mt: 1, display: "flex", flexDirection: "column" }} onSubmit={handleSubmit}>
				<FormControl variant="standard" sx={{ m: 1, width: "50ch" }}>
					<CustomInput
						type="text"
						placeHolder="Enter your Bayer email-ID"
						label="Email ID "
						id="component-error"
						isMultiline
						name="username"
						onChange={(e: any) => handleChange(e)}
						error={fields.usernameError}
					/>

					{fields.usernameError && displayError("Email ID is required.")}
				</FormControl>
				<Stack spacing={10} direction="row" sx={{ mt: 2 }} className="forget-pwd">
					<Button
						fullWidth
						variant="contained"
						sx={{
							backgroundColor: "#fff",
							textTransform: "none",
							maxWidth: "160px",
							border: "1px solid #FF3162",

							"&:hover": {
								//you want this to be the same as the backgroundColor above
								backgroundColor: "#fff",
								label: {
									paddingRight: "25px",
								},
							},
						}}
						className="cust-btn-left"
					>
						<span>
							<img src={LeftArrowIcon} className="arrow-i" alt="Arrow" />
							<img src={LeftLayout} className="left-layout" alt="layout" />
						</span>
						<Link href="/login" variant="body2">
							<label style={{ paddingLeft: "35px", color: "#DE0043", font: "italic normal bold 18px Helvetica Neue" }}>
								Go Back
							</label>
						</Link>
					</Button>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{
							backgroundColor: "#ff3162",
							textTransform: "none",
							maxWidth: "160px",
							"&:hover": {
								backgroundColor: "#ff3162",
								//you want this to be the same as the backgroundColor above
								p: {
									paddingLeft: "25px",
								},
							},
						}}
						className="btn-right"
					>
						<p style={{ paddingRight: "30px", font: "italic normal bold 20px Helvetica Neue" }}>Submit</p>
						<span>
							<img src={ArrowIcon} className="arrow-i" alt="arrow" />
							<img src={RtLayout} className="rt-layout" alt="layout" />
						</span>
					</Button>
				</Stack>
			</Box>
			<ErrorPopup
            show={showError}
            okbtn={true}
			onhide={() =>  setShowError(false)}
            errormsg={errorMsg}
            heading={errorHeading}
            infoSign={true}
            

            />
		</Fragment>
	);
};

export default ForgetPassword;
