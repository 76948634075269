//in userReducer.ts
import {
  SELECTED_REPORT_FILTER,PAGE_REPORT,SELECTED_FILTER_VALUE,PAGE_REPORT_DATE,SELECTED_DATE_FILTER,HISTORY_LINES_DATA,HISTORY_LINES_FILTER,OUTPUT_FILTER,
  OUTPUT_DATA,SAP_FILTER_OBJ
} from "../../types";

const initialState = {
  ReportData:[],
  DateFilterDate:[],
  selectedFilter:[], //to get selected filter on first button
  sapFilterObj:[],
  selectedFilterValue:'',
  selectedFilterDate:'2022 January', //to get selected filter on date button
  historyLinesData:[], //to get history lines data
  SelectedhistoryLinesFilter:[], //to get selected history lines filter
  outputData:[], //to get history lines data
  outputFilter:'View in browser' //to get selected history lines filter


};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case SELECTED_REPORT_FILTER:  //to get checked filter for first btn
      return {
        ...state,
        selectedFilter: action.payload,
      };
      case SAP_FILTER_OBJ:  //to get checked filter obj data for first btn
      return {
        ...state,
        sapFilterObj: action.payload,
      };
      
    case SELECTED_DATE_FILTER: //to get checked filter for date range
    return {
      ...state,
      selectedFilterDate: action.payload,
    };
      case PAGE_REPORT:
        return {
          ...state,
          ReportData: action.payload,
        };
        case PAGE_REPORT_DATE: // to get aal filter values for date
          return {
            ...state,
            DateFilterDate: action.payload,
          };
        case SELECTED_FILTER_VALUE:
          return {
            ...state,
            selectedFilterValue: action.payload,
          };
        case HISTORY_LINES_DATA:  //to get history lines all data
          return {
            ...state,
            historyLinesData: action.payload,
          };
          case HISTORY_LINES_FILTER: // to get hhistory lines seelcted filed
            return {
              ...state,
              SelectedhistoryLinesFilter: action.payload,
            };

            case OUTPUT_DATA:  //to get output all data
            return {
              ...state,
              outputData: action.payload,
            };
            case OUTPUT_FILTER: // to get output seelcted filed
              return {
                ...state,
                outputFilter: action.payload,
              };
      
    default:
      return state;
  }
}
