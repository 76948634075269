//Finally in App.tsx
import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PublicRoute from "./utils/PublicRoute";
import PrivateRoute from "./utils/PrivateRoute";

import { CheckAuthentication } from "./utils/CheckAuthentication";
import StockManagementHome from "./containers/stockManagement/StockManagementHome";
import Dashboard from "./containers/dashoard/Dashboard";
import "./assets/scss/common.scss";
import FrontComponent from "./containers/auth";
import MainHeader from "./components/layouts/MainHeader";
import { Container } from "react-bootstrap";
import MyAccount from "./containers/myAccount/myAccount";
import ProfileEdit from "./containers/myAccount/ProfileEdit";
import SalesStockReport from "./containers/SalesDistrictReports/SalesDistrictSalesAndStockReports/SalesStockReport";

import Footer from "./components/layouts/Footer";
import LegalHome from "./components/layouts/legalPages/LegalHome";
import Report from "./containers/reports/branchHistory/report";
import HistoryQueryViewer from "./containers/SalesDistrictReports/SalesDistrictHistoryQueryViewer/HistoryQueryViewer";
//action functions
// end here
const App: React.FC = () => {
  useEffect(() => {
    CheckAuthentication();
  }, []);

  return (
    <React.Fragment>
      <div className="App App-main-container">
        <Router>
          <MainHeader />
          <Container className="bayer-main-container">
            <Switch>
              <PrivateRoute exact path="/" component={Dashboard} />
              <PrivateRoute
                exact
                path="/home"
                component={StockManagementHome}
              />
              <PublicRoute exact path="/login" component={FrontComponent} />
              <PublicRoute
                exact
                path="/forgot-password"
                component={FrontComponent}
              />
              <PrivateRoute exact path="/Dashboard" component={Dashboard} />
              <PrivateRoute exact path="/myaccount" component={MyAccount} />
              <PrivateRoute
                exact
                path="/StockManagement/:iniState"
                component={() => <StockManagementHome />}
              />
              <PrivateRoute exact path="/profileEdit" component={ProfileEdit} />
              <PrivateRoute
                exact
                path="/reports/salesStockReports"
                component={() => <SalesStockReport />}
              />

              <PrivateRoute
                exact
                path="/reports/:reportType"
                component={() => <Report />}
              />
              <PrivateRoute
                exact
                path="/salesDistrictReports/salesDistrictHistoryQueryViewer"
                component={() => <HistoryQueryViewer />}
              />

              <PrivateRoute
                exact
                path="/account/:iniStateuser"
                component={() => <ProfileEdit />}
              />

              <Route exact path="/Legals/:iniState" component={LegalHome} />
            </Switch>
          </Container>
          <Footer />
        </Router>
      </div>
    </React.Fragment>
  );
};
export default App;
