import React, { useState} from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import PersonalDetails from './PersonalDetails'
import ChangePassword from './ChangePassword'
import './personalDetails.scss'


const MyAccount = (props) => {
  const [selectedTab, setSelectedTab] = useState('personalDetails')
  const handleSelect = (e) => {
    setSelectedTab(e);

}
  return (
        <Tabs
          activeKey={selectedTab ? selectedTab : 'personalDetails'}
          transition={false}
          id="noanim-tab-example"
          className="mb-3 account-tab"
          onSelect={(e) => handleSelect(e)}
        >
          <Tab eventKey="personalDetails" className='account-tab-heading' title="Personal Details">
          < PersonalDetails />
          </Tab>
          <Tab eventKey="passwordChange"  className='account-tab-heading' title="Password Change">
          < ChangePassword />
          </Tab>
          
</Tabs>
  )
}

export default MyAccount