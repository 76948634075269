import {

     RUN_TOUR_SALES,
     RUN_TOUR_GOODS,
     RUN_TOUR_REQUEST_MAIN,
     RUN_TOUR_ADD_REQUEST,
     RUN_TOUR_CANCEL_REQUEST,
     RUN_TOUR_STOCK_TAKE
   
   } from "../types";

export const runTour =(data:any)=>{

    return{
      type:RUN_TOUR_SALES,
      payload:data
    }
}

export const runTourGoods =(data:any)=>{
    
    return{
      type:RUN_TOUR_GOODS,
      payload:data
    }
}

export const runTourRequestMain =(data:any)=>{
    
    return{
      type:RUN_TOUR_REQUEST_MAIN,
      payload:data
    }
}

export const runTourAddRequest =(data:any)=>{
    
    return{
      type:RUN_TOUR_ADD_REQUEST,
      payload:data
    }
}
export const runTourCancelRequest =(data:any)=>{
    
    return{
      type:RUN_TOUR_CANCEL_REQUEST,
      payload:data
    }
}
export const runTourStockTake =(data:any)=>{
    
  return{
    type:RUN_TOUR_STOCK_TAKE,
    payload:data
  }
}