import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import './editPopup.scss';

const CaseDtl = props => {
    const parser = new DOMParser();
const parsedDocument = parser.parseFromString(props.caseDtl.case_caseDetails, "text/html");

console.log("parsedDocument" , parsedDocument);

    return (
        <div className="popup-box">
            <Container className="box">
           
                <Row className='popup_header'>
                    <Col md="6"><span style={{ color: '#96A8AE' }}>Case Details</span></Col>
                    <Col md="4"></Col>
                    {/* <Col md="1" style={{ margin: '-30px 0px' }}><img src={Editicon} /></Col> */}
                    <Col md="2"><button className='close-icon' onClick={props.handleClose}>close x</button></Col>
                </Row>
                <form className='edit-user-form'>
                    <Row>
                    <Col md="6">
                  
            
                             <div dangerouslySetInnerHTML={{ __html: props.caseDtl.case_caseDetails }} />
                          
                            
                    </Col>
                    <Col md="6"></Col>
                       
                    </Row>
                </form>
            </Container>
           
        </div>
    );
};

export default CaseDtl;