import React from 'react';
import Button from "@mui/material/Button";
import SaveDraftIcon from "../../assets/images/save.svg";
import DraftRtLayout from "../../assets/images/rt_layout_draft.svg";

const SaveDraftButton = (props: any) => {
  return (
    <Button
        // type="submit"
        onClick={() => props.onClick()}
        fullWidth
        variant="contained"
        disabled = {props.isButtonDisabled}
        sx={{
            backgroundColor: "white",
            textTransform: "none",
            maxWidth: "160px",
            padding: "8px",
            color: '#624963 !important',
            borderRadius: "0px",
            "&:hover": {
                backgroundColor: "white",
                //you want this to be the same as the backgroundColor above
                p: {
                    paddingLeft: "14px",
                },
            },
        }}
        // className="btn-right"
        className={"btn-right draft-btn-dimensions "+ props.customClasssName}

    >
        <p >SAVE DRAFT</p>
        <span>
            <img src={SaveDraftIcon} className="arrow-i" alt="arrow" />
            <img src={DraftRtLayout} className="draft-rt-layout" alt="layout" />
        </span>
    </Button>
  )
}

export default SaveDraftButton