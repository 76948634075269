//in useActions.ts file
import {
  SET_HOME_STACKS,
  LOADING_UI,
  SET_ERRORS,
  SET_HOME_STOCKS
} from "../types";
import axios from '../../_helpers/axios'

export const getDashboardData = (data:any) => async (dispatch: any) => {
  dispatch({type:LOADING_UI});
  const params = {
    accountSfid: data.account_sfid
  }
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  }
 // dispatch(getStats(data.account_sfid))
  axios.get("util/stockdetails", {headers,params})
  .then((res) => {
    if(res.data.message!=='Success'){
      dispatch({
        type: SET_ERRORS,
       // payload:res.data.message,
       payload:"Invalid login details"
      });
    }else {
      dispatch(
        {
          type: SET_HOME_STOCKS,
          payload: res.data
        }
      )
  }   
    //history.push(from); //redirecting to index page after login success
  })
  .catch((err) => {
    console.log(err);
    dispatch({
      type: SET_ERRORS,
      payload: {message:"Something went wrong please contact Administrator"}
    });
  });
  
}

 export const getStats = (data:any) =>  (dispatch: any) => {
  const params = {
    accountSfid: data.account_sfid
  }
  axios.get("util/productinventory", {params})
    .then((res) => {
      dispatch({
        type: SET_HOME_STACKS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
