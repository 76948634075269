import React, { useState, useEffect } from 'react'
import shoppingIcon from '../../assets/images/shopping_cart_black_24dp.png';
import reportIcon from '../../assets/images/description_black_24dp.png';
import transportImg from '../../assets/images/local_shipping_black_24dp.png';
import { Link } from 'react-router-dom';




const DashBoardNotification = (props) => {
    const [APIData, setAPIData] = useState([])
    // const [searchInput, setSearchInput] = useState('');
    const today = new Date().toISOString().slice(0, 10)

    useEffect(() => {
        setAPIData([])
       const notifications = [];
        let today = new Date().toISOString().slice(0, 10)
        if(props.stocks.data.RequestForStock.status==='red'){
            const notes = {
                notification: props.stocks.data.RequestForStock.message,
                type:'request_stock',
                date: today
            }
            notifications.push(notes);
        }
        if(props.stocks.data.GoodsMovement.status==='red'){
            const notes = {
                // notification: props.stocks.data.GoodsMovement.message,
                notification:`A Bayer Agency product was dispatched to your branch. If you have received the delivery, please <a href='#'>Test</a>`,
                type:'goods_movement',
                date: today
            }
            notifications.push(notes);
        }
        if(props.stocks.data.SalesReport.status==='red'){
            const notes = {
                notification: props.stocks.data.SalesReport.messaage,
                type:'sales_report',
                date: today
            }
            notifications.push(notes);
        }
        if(props.stocks.data.StockTakeEntry.status==='red'){
            const notes = {
                notification: props.stocks.data.StockTakeEntry.message,
                type:'stock_stake',
                date: today
            }
            notifications.push(notes);
        }
        setAPIData(notifications);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


        // const getNumberOfDays = (start) => {
        
        //     let date = new Date()
        //     let day = date.getDate();
        //     let month = date.getMonth()+1;
        //     let year = date.getFullYear();

        // let fullDate = `${month}/${day}/${year}`;
        // const date1 = new Date(start);
        // const date2 = new Date(fullDate);
        
        //     // One day in milliseconds
        //     const oneDay = 1000 * 60 * 60 * 24;
        
        //     // Calculating the time difference between two dates
        //     const diffInTime = date2.getTime() - date1.getTime();
        
        //     // Calculating the no. of days between two dates
        //     const diffInDays = Math.round(diffInTime / oneDay);
        
        //     return diffInDays;
        // }
    
    return (
        
        <div className="notificationCard ">
        <div className="d-flex justify-content-between align-items-center">
        <div className="notification-head mb-3">Notifications</div>
        
        <div className="searchBox">
        {/* <div className="form-group has-search">
    <span className="fa fa-search form-control-feedback"></span>
    <CustomInput
						type="text"
						placeHolder="Search"
						id="component-error"
						isMultiline
						name="username"
                        onChange={(e) => searchItems(e.target.value)}
						
					/>
    
  </div> */}
        </div>
        </div>
        <div className="mb-2">Today is: {today}</div>
       { APIData.length > 0 ? APIData.map((note,index) => 
             <React.Fragment key={index}>
             <div className="d-flex bd-highlight notification-alignment">
         <div className="p-2  bd-highlight notificationLogo"><img className="img-mt" alt='report type icon' src={note.type==='request_stock'?shoppingIcon:reportIcon && note.type==='goods_movement'?transportImg:reportIcon} /></div>
         <div style={{width:400}} className="p-2 flex-grow-1 noteContent">
             {note.type==="request_stock" &&
             <p>You have
                 <Link style={{textDecoration:"none"}} to = '/StockManagement/RequestForStock'> submitted stock requests</Link> that have not been approved yet.
             </p>
             }
             {note.type==="goods_movement" &&
             <p>A Bayer Agency product was dispatched to your branch. If you have received the delivery, please 
                 <Link style={{textDecoration:"none"}} to = '/StockManagement/GoodsMovement'> goods receipt your incoming stock.</Link>
             </p>
             }
             {note.type==="sales_report" &&
             
             <p>Don't forget to <Link style={{textDecoration:"none"}} to = '/StockManagement/SalesReport'>  submit your sales report </Link> for this month.</p>
             }
             {note.type==="stock_stake" &&
             <p>Please <Link style={{textDecoration:"none"}} to = '/StockManagement/StocktakeEntries'> complete your quarterly stock reconciliation </Link> for Bayer agency products.</p>
             }
             
             </div>
         {/* <div  style={{width:120,textAlign:'end'}}  className="p-2 bd-highlight notiFicatinTimeLine "> {getNumberOfDays(note.date)} days ago</div> */}
           </div>
         <hr className="hr-notification" />
         </React.Fragment>
        ): <div style={{textAlign:'center', font: 'normal normal bold 20px/24px Helvetica Neue' ,color: 'rgb(150, 168, 174)'}}>No Notification Found</div>} 
        </div>

    )
}

export default DashBoardNotification;