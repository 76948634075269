import React , {useEffect, useState} from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import AddToRequest from './AddToRequest'
import CancelRequest from './CancelRequest';
// import Tabs from 'react-bootstrap/Tabs'
// import Tab from 'react-bootstrap/Tab'
import ErrorPopup from '../../../components/common/ErrorPopup';
import { getAddStocks } from "../../../redux/actions/stockManagementActions/requestActions";
import { getCancelStocks } from '../../../redux/actions/stockManagementActions/requestActions';
// import SubmitButton from '../../../components/common/SubmitButton';
import SubmitButtonNew from '../../../components/common/SubmitButtonNew';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import {runTourRequestMain } from "../../../redux/actions/tourGuideActions";
import { Steps } from 'intro.js-react';
import '../../../assets/scss/tourguide.scss'
import "intro.js/introjs.css";


const RequestStocks = () => {

    const [selectedTab, setSelectedTab] = useState('');
    const [showStockPanel , setStockPanel ] = useState(true);
    const stocksData = useSelector((state: RootStateOrAny) => state.CancelStcoks);

    const [switchEnable, setSwitchEnable] = useState(true);
    const currentAgency = JSON.parse(localStorage.getItem("SelectedAgency") || "[]");

    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorHeading, setErrorHeading] = useState('');
    const dispatch  = useDispatch(); 
    // const [errorMsgBlue, setErrorMsgBlue] = useState('');
    const [run, setRun] = useState(true);
    // const [requestStockvisit, setrequestStockvisit] = useState('')
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [initialStep, setInitialStep] = useState(0);
    // const [addStock, setStatus] = useState(true);
    const tourRunRequestStockMain = useSelector((state:RootStateOrAny) => state.TourGuide.tourInfo.value)
    const currentPage = useSelector((state:RootStateOrAny) => state.TourGuide.tourInfo.currentPage)
    

    const [tour, setTour] = useState({
        steps: [
            {
                element: '.requestMain-tg1',
                title:'Step 01',
                intro: <h6  className='guide-text'>Lets start by selecting either of the two by pressing on the buttons.</h6>,
                position: 'left',
              }
      ]
    })

    useEffect(() => {
            if(stocksData.stocksData.data && stocksData.stocksData.data.customerOrders && stocksData.stocksData.data.customerOrders.length === 0){
                setStockPanel(false)
                setSelectedTab('addStock');
            }else if (!stocksData.stocksData.data ||  !stocksData.stocksData.data.customerOrders) {
                window.location.href = "/login";
            }
        
    }, [stocksData])

    useEffect(() => {
        localStorage.setItem("currentPage", 'requestStockMain');
        const  SalesReportvisited = JSON.parse(localStorage.getItem("SalesReportvisited")|| "false");
        setTimeout(
            () => {
                if(SalesReportvisited===true){
                    setStepsEnabled(false);
                }else{
                    // if(data){
                    setStepsEnabled(true);
                // }
                    localStorage.setItem("SalesReportvisited", 'true');
                }
            }, 
            1000
          );
        },[])

    useEffect(() => {
        let data={
            value:false,
            currentPage:'requestStockMain'
            }
        if(tourRunRequestStockMain && currentPage==="requestStockMain"){
            setStepsEnabled(true);
        }
        dispatch(runTourRequestMain(data))

    }, [tourRunRequestStockMain]);

    const handleSelect = (e: any) => {
        setStockPanel(false)
        setSelectedTab(e);
        if(switchEnable){
          
            dispatch(getAddStocks(currentAgency));
            dispatch(getCancelStocks(currentAgency));
        }else{
            setErrorMsg("Do you wish to proceed without submitting your stock?")
            if(selectedTab==="addStock"){
                setErrorHeading("You are naviagting to 'Cancel Existing Request'");
                setShowError(true);
            }else if(selectedTab==="cancelStock"){
                setErrorHeading("You are naviagting to 'Add to my current Request'");
                setShowError(true);
            }   
        }
    }

    // const switchScreen = (event: any) => {
    //     if(event && switchEnable){
    //         setStatus(true);
    //     }
    //     else if(!event && switchEnable){
    //         setStatus(false);
    //     }else{
    //         console.log(addStock, event);
    //         setErrorMsg("Do you wish to proceed without submitting your stock?")
    //         if(addStock && !event){
    //             setErrorHeading("You are naviagting to 'Cancel Existing Request'");
    //             setShowError(true);
    //         }else if(!addStock && event){
    //             setErrorHeading("You are naviagting to 'Add to my current Request'");
    //             setShowError(true);
    //         }   
    //     }
    // };

    const discardAndSwitchScreen = (event: any) => {
        setShowError(false);
        setSwitchEnable(true);
        setSelectedTab(event);
        dispatch(getAddStocks(currentAgency));
        dispatch(getCancelStocks(currentAgency));
    };

    const goBackHandller = () =>{
        dispatch(getAddStocks(currentAgency));
        dispatch(getCancelStocks(currentAgency));
        setStockPanel(true);
        setSelectedTab("")
    
    }
    const tourSteps= tour.steps;
    const onExit = () => {
        setStepsEnabled(false);
      };

    
    return (
        <><Steps
        enabled={stepsEnabled}
        steps={tourSteps}
        initialStep={initialStep}
        onExit={onExit}
        options={{
            // nextLabel: 'test next',
            prevLabel: 'Prev',
            skipLabel: 'Skip',
            showStepNumbers:true
          }}
        />
            <Container>
                
             {showStockPanel && 
             <div>
              <span className='req-page-title'>You already have an outstanding request. What would you like to do?</span>
               <div className='requestMain-tg1'>
               <Row style={{marginTop:18}} >
              <Col>
              <SubmitButtonNew className="btn-req-main" fullWidth={false}   onClick= {() => handleSelect('addStock')}  title='ADD TO CURRENT REQUEST'  />
              </Col>
              </Row>
              {stocksData.stocksData.data &&  stocksData.stocksData.data.customerOrders && stocksData.stocksData.data.customerOrders.length > 0 && 
              <Row style={{marginTop:10}}>
              <Col>
              <SubmitButtonNew fullWidth={false} className="btn-req"  onClick= {() => handleSelect('cancelStock')}  title='CANCEL EXISTING REQUEST'  />
              </Col>
              </Row>
              }
              </div>
              </div>
              }
               {selectedTab !== "" &&
                <Row>
                     {(selectedTab==='addStock')?<AddToRequest handleGoBack={goBackHandller} setSwitchEnable={setSwitchEnable} />:<CancelRequest handleGoBack={goBackHandller}  setSwitchEnable={setSwitchEnable} /> } 
                </Row>
                }
                {/* <Row>
                    {(addStock)?<AddToRequest setSwitchEnable={setSwitchEnable} />:<CancelRequest setSwitchEnable={setSwitchEnable} /> }
                </Row> */}
            </Container>
            {/* <Tabs
                activeKey={selectedTab ? selectedTab : 'addStock'}
                transition={false}
                id="noanim-tab-example"
                className="mb-3 account-tab"
                onSelect={(e) => handleSelect(e)}
                >
                <Tab eventKey="addStock" title="Add to my current Request">
                < AddToRequest setSwitchEnable={setSwitchEnable} />
                </Tab>
                <Tab eventKey="cancelStock" title="Cancel Existing Request">
                < CancelRequest setSwitchEnable={setSwitchEnable} />
                </Tab>
            </Tabs> */}
            <ErrorPopup
            show={showError}
            yesbtn='true'
            nobtn='true'
            errormsg={errorMsg}
            heading={errorHeading}
            infoSign={true}
            onclickno={() => setShowError(false)}
            onclickyes={() => selectedTab==="addStock"? discardAndSwitchScreen("cancelStock"): discardAndSwitchScreen("addStock")} 
            />
        </>
    )
}

export default RequestStocks
