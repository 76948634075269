//in useActions.ts file
import {
    HV_AGENCY_BRANCH_OBJ, HV_HISTORY_LINES_DATA, HV_HISTORY_LINES_FILTER, HV_OUTPUT_DATA, HV_OUTPUT_FILTER, HV_PAGE_REPORT, HV_PAGE_REPORT_DATE, HV_SAP_FILTER_OBJ, HV_SELECTED_AGENCY_BRANCH_FILTER, HV_SELECTED_AGENCY_BRANCH_OBJ, HV_SELECTED_DATE_FILTER, HV_SELECTED_FILTER_VALUE, HV_SELECTED_REPORT_FILTER
  } from "../../types";
  import axios from '../../../_helpers/axios';

  export const fetchAgencyBranch= (SelectedAgency) => (dispatch) => {
    // const SelectedAgency = JSON.parse(localStorage.getItem("SelectedAgency") || "[]");
    const salesDistrict= SelectedAgency.salesDistrict;

    axios.get('ordermanagement/salesdistrictaccounts?salesDistrict='+salesDistrict)
        .then((res) =>{
            if(res.data.status===200){
                dispatch({
                  type: HV_AGENCY_BRANCH_OBJ,
                  payload:res.data.data
                });
            }else if(res.data.status===208){
              dispatch({
                type: HV_AGENCY_BRANCH_OBJ,
                payload:[]
              });
              }
        })
        .catch((err) => {
            console.log(err.message);
              
          });
         
  }
  
  export const fetchReport= (slctdAgencyBranchFilter) => (dispatch) => {
    const sapData = {accountSfids : []}
    slctdAgencyBranchFilter?.map((rs) => { sapData.accountSfids.push(rs.accountValue); return null; } )
    axios.post("ordermanagement/productaccessdata", sapData)
    .then((res) => {
        if(res.data.status===200){
        dispatch({
          type: HV_PAGE_REPORT,
          payload:res.data.data
        });
        }else if(res.data.status===208){
        dispatch({
          type: HV_PAGE_REPORT,
          payload:[]
        });
        }
    })
    .catch((err) => {
      console.log(err.message);
        
    });
         
  }
  export const selectedReportFilter =(data)=>{
      
    return{
      type:HV_SELECTED_REPORT_FILTER,
      payload:data
    }
  }

  export const selectedAgencyBranchFilter = (data) => {
    return{
        type: HV_SELECTED_AGENCY_BRANCH_FILTER,
        payload:data
    }
  }

  export const setSelectedAgencyBranchObj = (data) => {
    return{
        type: HV_SELECTED_AGENCY_BRANCH_OBJ,
        payload:data
    }
  }
  
  export const setSapFilterObj =(data)=>{
      
    return{
      type:HV_SAP_FILTER_OBJ,
      payload:data
    }
  }
  
  
  export const fetchFilterDate =(data)=>{
      
    return{
      type:HV_PAGE_REPORT_DATE,
      payload:data
    }
  }
  export const selectedFilter =(data)=>{
      
    return{
      type:HV_SELECTED_FILTER_VALUE,
      payload:data
    }
  }
  
  export const selectedFilterDate =(data)=>{
      
    return{
      type:HV_SELECTED_DATE_FILTER,
      payload:data
    }
  }
  
  export const fetchHistoryLinesData =(data)=>{
    return{
      type:HV_HISTORY_LINES_DATA,
      payload:data
    }
  }
  
  export const getHistoryLinesSelectedField =(data)=>{
      
    return{
      type:HV_HISTORY_LINES_FILTER,
      payload:data
    }
  }
  
  
  export const fetchOutputData =(data)=>{
    return{
      type:HV_OUTPUT_DATA,
      payload:data
    }
  }
  
  export const getOutputSelectedField =(data)=>{
      
    return{
      type:HV_OUTPUT_FILTER,
      payload:data
    }
  }