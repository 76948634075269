import { Col, Row , Spinner } from 'react-bootstrap';
import DropdownBlue from "../../assets/images/dropdown_blue.png";
import  { useDispatch } from 'react-redux';
import {useState} from 'react';
import { SET_ACCOUNT_SFID , RENDER_COM } from "../../redux/types";
import { getSalesData  } from "../../redux/actions/stockManagementActions/salesActions";
import { getDashboardData , getStats } from "../../redux/actions/dashboardActions";
import { getAddStocks, getCancelStocks } from '../../redux/actions/stockManagementActions/requestActions';
import { getStocktakeEntries } from '../../redux/actions/stockManagementActions/stockEntriesActions';
import React, { useRef, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from "../layouts/Responsive.jsx";
import { getSITFBData } from '../../redux/actions/stockManagementActions/goodsMovmtSITFBActions';
import { getSITFOData } from '../../redux/actions/stockManagementActions/goodsMovmtSITFOActions';
import { fetchAgencyBranch, selectedAgencyBranchFilter, selectedReportFilter, setSapFilterObj, setSelectedAgencyBranchObj } from '../../redux/actions/reports/historyViewerActions';
import { fetchAgencyBranchData, selectedSAPReportFilter, setAgencyBranchObj, setSapFilterObjSS, updateBranchFilter } from '../../redux/actions/salesStockReport/salesStockActions';
import slashIcon from '../../assets/images/slash.svg'

const AgencyDropDown = () => {
    const dispatch = useDispatch();
    const  UserInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
    const  currentAgency = JSON.parse(localStorage.getItem("SelectedAgency") || "[]");
    const [selectedAgency , setAgency ] = useState(currentAgency);
    const [showDroDown,setDropDown] = useState(false);
    const [selectedRadio , setSlected ]=useState(currentAgency.account_sfid);
    const ref = useRef();
    const isMobile = useMediaQuery({ maxWidth: DeviceSize.tablet });
    const [loading , setLoading] = useState(false);
    // const [clickedOutside, setClickedOutside] = useState(false);

    /**
         * Alert if clicked on outside of element
         */
     function handleClickOutside(event) {
      if (!ref.current?.contains(event.target)) {
       // setClickedOutside(true);
       hideDropDown();
     }
   }

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      });

    const updateAgency = (agency) => {


        //setAgency(sfid);
        const agencyData = {
            account_sfid: agency.account_sfid,
            agencyName: agency.account_name,
            salesDistrict:agency.salesArea_salesDistrict,
            account_sapAccountNumber:agency.account_sapAccountNumber,
            salesArea_salesDistrictName:agency.salesArea_salesDistrictName,
            salesArea_salesGroup:agency.salesArea_salesGroup,
            grp_name:agency.grp_name
          }
          setSlected(agency.account_sfid)
          setAgency(agencyData)
    }

    const changeAgency = ()=> {
       setLoading(true)
        dispatch({ type: RENDER_COM});
        dispatch({ type: SET_ACCOUNT_SFID,payload: selectedAgency });
        dispatch(getSalesData(selectedAgency));
        dispatch(getAddStocks(selectedAgency));
        dispatch(getCancelStocks(selectedAgency));
        dispatch(getDashboardData(selectedAgency));
        dispatch(getStats(selectedAgency));
        dispatch(getStocktakeEntries(selectedAgency));
        dispatch(getSITFBData(selectedAgency));
        dispatch(getSITFOData(selectedAgency));
        // History Query Viewer Report
        dispatch(fetchAgencyBranch(selectedAgency));
        dispatch(selectedAgencyBranchFilter([]));
        dispatch(setSelectedAgencyBranchObj([]));
        dispatch(selectedReportFilter([]));
        dispatch(setSapFilterObj([]));
        // Sales Stock Report
        dispatch(fetchAgencyBranchData(selectedAgency));
        dispatch(updateBranchFilter([]));
        dispatch(setAgencyBranchObj([]));
        dispatch(selectedSAPReportFilter([]));
        dispatch(setSapFilterObjSS([]));
        localStorage.setItem("SelectedAgency", JSON.stringify(selectedAgency));
        hideDropDown();
    }

    const showDropDown = ()=> {
        // ref.current=true;
        setDropDown(true)
    }

    const hideDropDown = () => {
        setDropDown(false)
       // setLoading(false)
       setTimeout(()=> { 
        setLoading(false)
       },200)
    }
  return(
      <div ref={ref}>
          {/* <Row className="justify-content-md-end">
          <Col xs lg="4">
        <p  className="align-self-center" style={{fontSize: '10px'}}>
      {!isMobile && <span>Agency Name:<b>{currentAgency.agencyName}({currentAgency.account_sapAccountNumber})</b>
      <span style={{display: 'inline', fontSize: '20px', paddingLeft: '5px'}}>/</span></span>}
      <button onClick={showDropDown} className='Transparentbtn'>Change<img src={DropdownBlue} height="6" />
      </button>
      </p>
    </Col>
    </Row> */}
    <div className="justify-content-md-end agency-drop">
    {loading &&  <div className="centeredSpin"><Spinner animation="border" role="status" ></Spinner></div> }
      <div className='agency-name'>
          <p  className="align-self-center agency-name-title">
        {!isMobile && <span>Agency Name:<b style={{marginLeft:'5px'}}>{currentAgency.agencyName}({currentAgency.account_sapAccountNumber})</b>
        <img className='agency-slash' alt="slash icon" src={slashIcon}/>

        </span>}
        <button onClick={showDropDown} className='Transparentbtn'>Change<img src={DropdownBlue} alt='dropdown icon' className='agency-caret' style={{marginLeft:'3px'}}/>
        </button>
        </p>
      </div>
    </div>
        {showDroDown && 
        <Row>
    <Col md="4" ></Col>
    <div className="col-md-8 align-self-center">
    <div className="agencyDropDown agencyDropdownLocation">
    <div className="row justify-content-between">
<div className="col-9">
 <div className="agencyHead">Select the branch you wish to administrator</div>
 <div style={{marginTop:10}}>
     {UserInfo.data.customerInfo.map((agencies,index) =>  
     <label key={index} className='agency-dropwon-label'>
        <input type="radio" name="radio-button" checked={selectedRadio===agencies.account_sfid?true:false}    value={agencies.account_sfid} onChange={()=> updateAgency(agencies)} />
     <span className='agency-input'>{agencies.account_sapAccountNumber} - {agencies.account_name}</span>
    </label> )}
  
   </div>
 <button className="applybtn" onClick={changeAgency} >Apply</button>
</div>
<div className="col-3 ">
 <div className="agencyCloseBtn text-align-center" onClick={hideDropDown}>   Close X</div>

</div>
</div>
    </div>
    </div>
    
    </Row>
    }

      </div>
    
  )
}
export default AgencyDropDown