import React, { useState } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import AddNewUser from './addNewUser'
import UserList from './UserList'
import './personalDetails.scss';
import axios from '../../_helpers/axios';


const ManageUsers = () => {
  const [selectedTab, setSelectedTab] = useState('addNewUser')
  const userInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");

  const handleSelect = (e:any) => {
    setSelectedTab(e);

}
const getReport = (param:any , uType:any) => {
  axios.get("oauth/sfdctoken")
  .then((res) => {
      if(res.data.status===200){
       const access_token = res.data.access_token;
       //const rootUrl = "https://anz-cropcentre-api-dev.herokuapp.com/services/api/reportmanagement/"
       const rootUrl = process.env.REACT_APP_BASE_URL ? (process.env.REACT_APP_BASE_URL + "reportmanagement/cropCentreAgencyReport") : 'https://anz-cropcentre-api-int.herokuapp.com/services/api/reportmanagement/cropCentreAgencyReport';
       var reportCrmUrl = process.env.REACT_CRMUrl || 'https://monsanto-anz--test.lightning.force.com';
       const url =rootUrl  +"?access_token="+access_token+"&conId="+userInfo.data.userDetails['user_contactSfid']+"&accId="+userInfo.data.userDetails['cont_accSfid']+"&uType="+uType+"&dName="+param+"&CRMUrl="+reportCrmUrl;
       window.open(url, "_blank")
      }
      
  })
  .catch((err) => {
     console.log(err.message);
      
  });
}
  return (
    <>
    <div style={{marginBottom:5 , marginLeft:'70%' }}> <button style={{textDecoration:'none', color:'#00BCFF', background:'None', border:'None'}}  onClick={()=> getReport("BranchAgencyCaseHistoryCMP","1")}  >User Management Tickets</button></div>
    <Tabs
      activeKey={selectedTab ? selectedTab : 'addNewUser'}
      transition={false}
      id="noanim-tab-example"
      className="mb-3 account-tab"
      onSelect={(e) => handleSelect(e)}
    >
      <Tab eventKey="addNewUser" title="Add New Users">
      < AddNewUser />
      </Tab>
      <Tab eventKey="userList" title="Modify Existing User">
      < UserList />
      </Tab>
      
</Tabs>
</>
)
}

export default ManageUsers