import {
    OUTPUT_DATA_SS,FILTER_SELECTED_SS,OUTPUT_FILTER_SS,GROUP_FILTER_SS,GROUP_DATA_SS,
    SHOW_STOCKS_SS,SHOW_STOCKS_FILTER_SS,FKA_DATA_SS,FKA_FILTER_SS,SOR_DATA_SS,SOR_DATA_FILTER_SS,
    SOH_DATA_SS,SOH_DATA_FILTER_SS,YEAR_DATA_SS,YEAR_DATA_FILTER_SS,START_MONTH_DATA_SS,
    START_MONTH_DATA_FILTER_SS,END_MONTH_DATA_SS,END_MONTH_DATA_FILTER_SS,BRANCH_DATA_SS,BRANCH_DATA_FILTER_SS,
    AGENCY_BRANCH_OBJ_SS,
    SAP_MATERIAL_DATA_SS,
    SAP_MATERIAL_FILTER_SS,
    SAP_MATERIAL_OBJ_SS
  } from "../../types";

  import axios from '../../../_helpers/axios';

  export const salesStockFilterSelected =(data)=>{
    
    return{
      type:FILTER_SELECTED_SS,
      payload:data
    }
  }

//Group

export const fetchGroupData =(data)=>{
    
    return{
      type:GROUP_DATA_SS,
      payload:data
    }
  }

  export const updateGroupFilter =(data)=>{
    
    return{
      type:GROUP_FILTER_SS,
      payload:data
    }
  }


//Output
export const fetchOutputData =(data)=>{
    return{
      type:OUTPUT_DATA_SS,
      payload:data
    }
  }

export const updateOutputFilter =(data)=>{
    return{
      type:OUTPUT_FILTER_SS,
      payload:data
    }
  }

//Show stock
export const fetchStockData =(data)=>{
    return{
      type:SHOW_STOCKS_SS,
      payload:data
    }
  }

export const updateStockFilter =(data)=>{
    return{
      type:SHOW_STOCKS_FILTER_SS,
      payload:data
    }
  }

//FKA
export const fetchFkaData =(data)=>{
    return{
      type:FKA_DATA_SS,
      payload:data
    }
  }

export const updateFkaFilter =(data)=>{
    return{
      type:FKA_FILTER_SS,
      payload:data
    }
  }

//Sor data
export const fetchSORData =(data)=>{
    return{
      type:SOR_DATA_SS,
      payload:data
    }
  }

export const updateSORFilter =(data)=>{
    return{
      type:SOR_DATA_FILTER_SS,
      payload:data
    }
  }

//Soh data
export const fetchSOHData =(data)=>{
    return{
      type:SOH_DATA_SS,
      payload:data
    }
  }

export const updateSOHFilter =(data)=>{
    return{
      type:SOH_DATA_FILTER_SS,
      payload:data
    }
  }

//Year data
export const fetchYearData =(data)=>{
    return{
      type:YEAR_DATA_SS,
      payload:data
    }
  }

export const updateYearFilter =(data)=>{
    return{
      type:YEAR_DATA_FILTER_SS,
      payload:data
    }
  }

//Start month data
export const fetchStartMonthData =(data)=>{
    return{
      type:START_MONTH_DATA_SS,
      payload:data
    }
  }

export const updateStartMonthFilter =(data)=>{
    return{
      type:START_MONTH_DATA_FILTER_SS,
      payload:data
    }
  }

//End month data
export const fetchEndMonthData =(data)=>{
    return{
      type:END_MONTH_DATA_SS,
      payload:data
    }
  }

export const updateEndMonthFilter =(data)=>{
    return{
      type:END_MONTH_DATA_FILTER_SS,
      payload:data
    }
  }

  
//Materials data

export const fetchSAPReport= (branchDataFilter) => (dispatch) => {
  const sapData = {accountSfids : []}
  branchDataFilter?.map((rs) => { sapData.accountSfids.push(rs.accountValue); return null; } )
    axios.post("ordermanagement/productaccessdata", sapData)
    .then((res) => {
        if(res.data.status===200){
        dispatch({
          type: SAP_MATERIAL_DATA_SS,
          payload:res.data.data
        });
        }else if(res.data.status===208){
          dispatch({
            type: SAP_MATERIAL_DATA_SS,
            payload:[]
          });
          }
    })
    .catch((err) => {
      console.log(err.message);
        
    });
         
  }
  export const selectedSAPReportFilter =(data)=>{
      
    return{
      type:SAP_MATERIAL_FILTER_SS,
      payload:data
    }
  }
  
  export const setSapFilterObjSS =(data)=>{
      
    return{
      type:SAP_MATERIAL_OBJ_SS,
      payload:data
    }
  }

  
// export const fetchMaterialsData =(data)=>{
//     return{
//       type:MATERIALS_DATA_SS,
//       payload:data
//     }
//   }

// export const updateMaterialsFilter =(data)=>{
//     return{
//       type:MATERIALS_DATA_FILTER_SS,
//       payload:data
//     }
//   }

//Branch data

export const updateBranchFilter =(data)=>{
    return{
      type:BRANCH_DATA_FILTER_SS,
      payload:data
    }
  }

  export const setAgencyBranchObj = (data) => {
    return{
        type: AGENCY_BRANCH_OBJ_SS,
        payload:data
    }
  }

  export const fetchAgencyBranchData= (SelectedAgency) => (dispatch) => {
    // const SelectedAgency = JSON.parse(localStorage.getItem("SelectedAgency") || "[]");
    const salesDistrict= SelectedAgency.salesDistrict;

    axios.get('ordermanagement/salesdistrictaccounts?salesDistrict='+salesDistrict)
        .then((res) =>{
            if(res.data.status===200){
                dispatch({
                  type: BRANCH_DATA_SS,
                  payload:res.data.data
                });
                }else if(res.data.status===208){
                  dispatch({
                    type: BRANCH_DATA_SS,
                    payload:[]
                  });
                  }
        })
        .catch((err) => {
            console.log(err.message);
              
          });
         
  }