import React from 'react'

const PrivacyStatement = () => {
  return (
      <div className='privacy-statement'>
        <article className="grid-col">
					<h1>Privacy Statement</h1>
<p><span>This website (hereinafter the “<strong><em>Website</em></strong>“) is provided by </span><span>Bayer Australia Ltd ABN 87 000 226 022, (hereinafter “<em>Bayer</em>” “<strong><em>us</em></strong>” or “<strong><em>we</em></strong>”). For further information regarding the provider of the Website, please refer to our <a href="../Imprint/"><span>imprint</span></a>.</span></p>
<p><span>&nbsp;</span></p>
<h1>A. Handling of personal information</h1>
<p><span>We wish to provide you with information on how we handle your personal information when you use our Website. Unless otherwise indicated below, the legal basis for the handling of your personal information results from the fact that such handling is required to make available the functionality of the Website requested by you.<sup>1</sup></span></p>
<p><span>Bayer is required to comply with the Australian Privacy Act 1988 (Cth) (“Privacy Act”) and our privacy policy sets out how Bayer seeks to ensure that any personal information we hold about you is collected, used, stored and disclosed in accordance with the Australian Privacy Principles in the Privacy Act. You can download a copy of our complete <a target="_blank" href="/resources/pdf/PrivacyPolicy.pdf">privacy policy here</a>.</span></p>
<p><span>&nbsp;</span></p>
<h2>I. Using our Website</h2>
<h3>1. Accessing our Website</h3>
<p><span>When you use our Website, your browser will transfer the data set out below to our web server. This is done for technical reasons and required to make available to you the requested information. To facilitate your access to the Website, the following data is collected, briefly stored and used: </span></p>
<p>&nbsp;</p>
<table className='privacy-table'>
    <tbody>
        <tr>
            <td className='privacy-table-td1' width="333">
            <p>-&nbsp; &nbsp;IP address</p>
            <p>-&nbsp; &nbsp;date and time of access</p>
            <p>-&nbsp; &nbsp;time zone difference to Greenwich Mean Time (GMT)</p>
            <p>-&nbsp; &nbsp;content of request (specific site)</p>
            </td>
            <td className='privacy-table-td2' width="333">
            <p>-&nbsp; &nbsp;status of access/HTTP status code</p>
            <p>-&nbsp; &nbsp;transferred volume of data</p>
            <p>-&nbsp; &nbsp;website requesting access</p>
            <p>-&nbsp; &nbsp;browser, language settings, version of browser software operating system and surface</p>
            </td>
        </tr>
    </tbody>
</table>
<p>&nbsp;</p>
<p><span>We will store such data for a limited period of time in order to be able to initiate a tracking of personal data in the event of actual or attempted unauthorized access to our servers.<sup>2</sup></span></p>
<h3>2. Setting of cookies</h3>
<p><strong><span>a. What are cookies?</span></strong></p>
<p><span>This Website uses “cookies”. Cookies are small text files that are stored in the on your hard drive via your browser. They store certain information (e.g. your preferred language or site settings) which your browser may retransmit to us upon your next visit to our Website (depending on the lifespan of the cookie).</span></p>
<p><strong><span>b. What cookies do we use on this website?</span></strong></p>
<p><span>We use two categories of cookies: (1) cookies required for technical purposes, without which the functionality of our Website would be reduced, and (2) <strong>optional cookies </strong>used for e.g. website analysis and marketing purposes. No Optional Cookies are used on this website.</span></p>
<p><strong><span>c. How to prevent the setting of cookies</span></strong></p>
<p><span>Of course you may use our Website without any cookies being used at all (including Cookies for technical purposes). You can configure your browser to reject, restrict or deactivate the use of cookies, or to inform you when they are being set. </span><span>This may, however, have a certain negative impact on the functionality and user-friendliness of our Website.</span><span>You may at any time object to the setting of optional cookies by using the respective objection option indicated in the table above.</span></p>
<h3><span>3. Use of contact forms</span></h3>
<p><span>You can contact us directly via the contact forms available on our Website. </span></p>
<p><span>We collect, process and use the information provided by you via the contact forms exclusively for the processing of your specific request.</span></p>
<h3><span>4. Subscription to our newsletter</span></h3>
<p><span>On our Website, you may subscribe to receive our newsletter. Based on your prior consent, we will collect and use the email address you indicate for providing you with the newsletter.<sup>3</sup></span></p>
<p><span>If you wish to receive a customised newsletter, you may, on a voluntary basis, provide us with the following additional information: </span></p>
<p><span>- name, email, phone number</span></p>
<p><span>For subscription to our newsletter we use the so-called double opt-in procedure. After you have subscribed to the newsletter on our Website, we will send you a message to the indicated email address asking for your confirmation. If you do not confirm your subscription, your subscription will automatically be deleted. In order to prevent any misuse of your personal data, we will log your subscription and confirmation, filing the IP address you use when subscribing, the time of your subscription and confirmation, the messages sent by us regarding your subscription, and the wording of your subscription and confirmation. </span></p>
<p><span>You may at any time with future effect revoke your consent to receive our newsletter. To declare that you wish to unsubscribe, you may use the respective link included in all newsletters, or refer to the contacts indicated below.</span></p>
<h3><span>5. External services or content on our Website</span></h3>
<p><span>We include third-party services and/or content on our Website. When you use such third-party services or when third-party content is displayed, communication data are exchanged between you and the respective provider for technical reasons. </span></p>
<p><span>The respective provider of the services or content may also process your data for own additional purposes. To the best of our knowledge, we have configured the services and content of providers known to process data for own purposes in such a way that either any communication for other purposes than to present their services or content on our Website is blocked, or communication only takes place once you have actively opted to use the respective service. However, since we have no control over data collected and processed by third parties, we are not in a position to provide binding information regarding the scope and purpose of such processing of your data.</span></p>
<p><span>For further information regarding the scope and purpose of such collection and processing of your data, please consult the privacy statements of the providers whose services and/or content we may include and who are responsible for the protection of your data in this context: &nbsp;</span></p>
<table className='privacy-table's>
    <tbody>
        <tr>
            <td className='privacy-table-td1' width="333">
            <p><span>-&nbsp; &nbsp;Twitter share button&nbsp;</span></p>
            </td>
            <td className='privacy-table-td2' width="333">
            <p><span>-&nbsp; &nbsp;YouTube videos</span></p>
            <p><span>-&nbsp; &nbsp;maps of Google Maps</span></p>
            </td>
        </tr>
    </tbody>
</table>
<p>&nbsp;</p>
<h3><span>6. Use of the recommendation function</span></h3>
<p><span>If you wish to recommend us to others, you can do so by using the recommendation function on our Website. You may, in your and our legitimate interest, send a message to any person if you assume that the recipient wishes to receive such information. </span></p>
<p><span>Persons who do not wish to receive messages via our recommendation function, may request to be included in a list of blocked recipients by following the directions included in the respective message. &nbsp;</span></p>
<h2>II.	Transfer of data for commissioned processing</h2>
<p><span>For the processing of your data we will to some extent use specialised service contractors. Such service contractors are carefully selected and regularly monitored by us. Based on respective data processor agreements (or similar agreements), they will only process personal data upon our instruction and strictly in accordance with our directives.</span></p>
<p><span>&nbsp;</span></p>
<h1><span>B. Information regarding your rights</span></h1>
<p><strong><span>Right of access and correction</span></strong></p>
<p><span>You have the right to review and amend any personal data stored in our system if you believe it may be out of date or incorrect. Just send an e-mail to the address given in the imprint or contact the Data Privacy Officer&nbsp;at the address below.</span></p>
<p><strong><span>Right of cancellation</span></strong></p>
<p><span>You have the right at any time to withdraw your consent to the use of your personal data in the future. Again, just send an e-mail to the address given in the imprint or contact the Data Privacy Officer&nbsp;at the address below.</span></p>
<p><strong><span>Data retention</span></strong></p>
<p><span>We only retain personal data for as long as is necessary for us to render a service you have requested or to which you have given your consent, except where otherwise provided by law (e.g. in connection with pending litigation).</span></p>
<p><strong><span>Overseas Recipients</span></strong></p>
<p><span>Bayer is a member of the Bayer Group of Companies, a global organization with offices within and outside of Australia.</span></p>
<p><span>Information held by Bayer about you may in some circumstances be stored on Bayer databases housed in overseas locations including but not limited to Germany, China, Singapore, the Philippines and the United States of America. For example, in order to facilitate the receipt and payment of accounts, Bayer must transfer personal information overseas to the Philippines. All Bayer companies are committed to compliance with internal data protection policies which contain provisions substantially similar to the Australian Privacy Principles and in many cases to similar laws in the other jurisdictions.</span></p>
<p><span>Bayer also deals with external service providers located in countries within and outside of Australia, such as in Singapore, United States and Ireland and in such cases, Bayer takes reasonable steps, including by contract provisions, to ensure that these service providers do not breach the Australian Privacy Principles.</span></p>
<p><strong><span>Children</span></strong></p>
<p><span>In light of the importance of protecting children’s privacy, we do not collect, process or use on our website any information relating to an individual whom we know to be under 13 years old without the prior, verifiable consent of his or her legal representative. Such legal representative has the right, upon request, to view the information provided by the child and/or to require that it be deleted.</span></p>
<p><span>&nbsp;</span></p>
<h1><span>C. Contact</span></h1>
<p><span>All enquiries or complaints regarding your personal informational should be made in writing to:</span></p>
<p><span>The Privacy Officer<br />
875 Pacific Highway<br />
Pymble NSW 2073 <br />
Australia<br />
Phone: +61 2 9391 6000<br />
Or by email: privacy.officer@bayer.com</span></p>
<p><span>Bayer will respond to your complaint within 30 days of receipt of your correspondence or within any further time notified to you in writing. If you are not satisfied with the outcome of the response you receive, we can refer you to the Office of the Australian Information Commissioner (as applicable) for further investigation.</span></p>
<p><span>&nbsp;</span></p>
<h1><span>D. Amendment of Privacy Statement</span></h1>
<p><span>Bayer reserves the right to make changes to this Privacy Statement and our Privacy Policy from time to time, but will ensure that they are posted to our websites without delay<span>.</span></span><span> Any amendments become effective upon publication on our Website. We therefore recommend that you regularly visit the site to keep yourself informed on possible updates</span></p>
<p><span>&nbsp;</span></p>
<p><span>Last updated: May 31, 2018</span></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><sup>1</sup>(Art. 6(1)(b) General Data Protection Regulation)</p>
<p><sup>2</sup>(Art. 6(1)(f) General Data Protection Regulation)</p>
<p><sup>3</sup>(Art. 6(1)(a) General Data Protection Regulation)</p>
				</article>




      </div>
  )
}

export default PrivacyStatement