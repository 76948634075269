import { useEffect, useRef } from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { axisBottom, axisLeft, ScaleBand, scaleBand, ScaleLinear, scaleLinear, select } from "d3";
import type { IData } from "./types";
import './salesAndforecast.scss'

interface BarChartProps {
  data: IData[];
}

interface AxisBottomProps {
  scale: ScaleLinear<number, number, never>;
  transform: string;
}

interface AxisLeftProps {
  scale: ScaleBand<string>;
}

var tooltip = select("body").append("div").attr("class", "toolTip");

interface BarsProps {
  data: BarChartProps["data"];
  height: number;
  scaleX: AxisBottomProps["scale"];
  scaleY: AxisLeftProps["scale"];
}

function AxisBottom({ scale, transform }: AxisBottomProps) {
  const ref = useRef<SVGGElement>(null);

  useEffect(() => {
    if (ref.current) {
      select(ref.current).call(axisBottom(scale));
    }
  }, [scale]);

  return <g ref={ref} transform={transform} />;
}

function AxisLeft({ scale }: AxisLeftProps) {
  const ref = useRef<SVGGElement>(null);

  useEffect(() => {
    if (ref.current) {
      select(ref.current).call(axisLeft(scale));
    }
  }, [scale]);

  return <g ref={ref} />;
}

function Bars({ data, height, scaleX, scaleY }: BarsProps) {
  return (
    <>
      {data.map(({ salesVolume, salesValue, label, forecastVolume, forecastValue }) => (
        <>
        <rect
          key={`ForecastBar-${label}`}
          x={scaleX(0)}
          y={scaleY(label)!-10}
          width={scaleX(forecastVolume)-scaleX(0)}
          height={scaleY?.bandwidth()+20}
          fill="#EBF0F2"
          onMouseMove={(event) => {
            tooltip
              .style("left", event.pageX - 50 + "px")
              .style("top", event.pageY - 120 + "px")
              .style("display", "inline-block")
              .html('<b>Forecast</b> <br/>' + '&emsp;&emsp;&emsp;Value($): ' + forecastValue + '<br/>Volume(Kg/L): '+forecastVolume)}}
          onMouseOut={(d) => { tooltip.style("display", "none");}}
        />
        <rect
          key={`SalesBar-${label}`}
          x={scaleX(0)}
          y={scaleY(label)}
          width={scaleX(salesVolume)-scaleX(0)}
          height={scaleY.bandwidth()}
          fill="#443247"
          onMouseMove={(event) => {
            tooltip
              .style("left", event.pageX - 50 + "px")
              .style("top", event.pageY - 120 + "px")
              .style("display", "inline-block")
              .html('<b>Sales</b> <br>' + '&emsp;&emsp;&emsp;Value($): ' +salesValue + '<br>Volume(Kg/L): ' +salesVolume)}}
          onMouseOut={(d) => { tooltip.style("display", "none");}}
        /></>
      ))}
    </>
  );
}

export function BarChart({ data }: BarChartProps) {
    console.log(data);
    
  const margin = { top: 10, right: 20, bottom: 20, left: 80 };
  console.log(window.innerWidth, window.innerHeight);
  
  // const width = (window.innerWidth<1600 ? 350 : 600)  - margin.left - margin.right;
  // const height = (window.innerWidth<1600 ? 150 : 290) - margin.top - margin.bottom;
  let width = (window.innerWidth<1164 ? 400 : 600)  - margin.left - margin.right;
  let height = (window.innerWidth<1164 ? 240 : 290) - margin.top - margin.bottom;
  let maxSale = Math.max(...data.map(({ salesVolume }) => salesVolume));
  let maxForecast = Math.max(...data.map(({ forecastVolume }) => forecastVolume));

  const scaleY = scaleBand()
    .domain(data.map(({ label }) => label))
    .range([0, height])
    .padding(0.5);
  const scaleX = scaleLinear()
    .domain([0, Math.max(maxSale, maxForecast)])
    .range([0, width]);

  return (
    <svg
      width={width + margin.left + margin.right}
      height={height + margin.top + margin.bottom}
    >
      <g transform={`translate(${margin.left}, ${margin.top})`}>
        <AxisBottom scale={scaleX} transform={`translate(0, ${height})`} />
        <AxisLeft scale={scaleY} />
        <Bars data={data} height={height} scaleX={scaleX} scaleY={scaleY} />
      </g>
    </svg>
  );
}
