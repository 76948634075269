import React from 'react'
import axios from '../../_helpers/axios';

const QuickLinks = () => {
  const userInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
  const getReport = (param , uType) => {
    axios.get("oauth/sfdctoken")
    .then((res) => {
        if(res.data.status===200){
         const access_token = res.data.access_token;
         //const rootUrl = "https://anz-cropcentre-api-dev.herokuapp.com/services/api/reportmanagement/"
         const rootUrl = process.env.REACT_APP_BASE_URL ? (process.env.REACT_APP_BASE_URL + "reportmanagement/cropCentreAgencyReport") : 'https://anz-cropcentre-api-int.herokuapp.com/services/api/reportmanagement/cropCentreAgencyReport';
         var reportCrmUrl = process.env.REACT_CRMUrl || 'https://monsanto-anz--test.lightning.force.com';
         const url =rootUrl  +"?access_token="+access_token+"&conId="+userInfo.data.userDetails['user_contactSfid']+"&accId="+userInfo.data.userDetails['cont_accSfid']+"&uType="+uType+"&dName="+param+"&CRMUrl="+reportCrmUrl;
         window.open(url, "_blank")
        }
        
    })
    .catch((err) => {
       console.log(err.message);
        
    });
  }
  return (
    <div className='quick-link-main'>
        <div className='quick-link-heading'><p> Quick Links</p></div>
        <div className='quick-link'>
            <span className='quick-links'><p className='quick-link-slash'>/</p> <button className="quick-link-false-btn" onClick={()=> getReport("BranchProductPricingSOHCMP" , "1")}><p>Stock on Hand Pricing</p></button></span>
            <span className='quick-links'><p className='quick-link-slash'>/ </p> <button className="quick-link-false-btn" onClick={()=> getReport("BranchProductForecast" , "1")}><p>Product Forecast Report</p></button></span>
            <span className='quick-links'><p className='quick-link-slash'>/ </p> <button className="quick-link-false-btn" onClick={()=> getReport("BranchInventoryReportCMP", "1")}><p>Inventroy Report</p></button></span>

        </div>

    </div>
  )
}

export default QuickLinks