import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./editPopup.scss";
import Dropdown from "../../assets/images/Dropdown.png";
import DropdownBlue from "../../assets/images/dropdown_blue.png";
import errorLogo from "../../../src/assets/images/error_outline_dp.svg";
import axios from "../../_helpers/axios";
import SearchIcon from "../../assets/images/search_icon.svg";
import SubmitButton from "../../components/common/SubmitButton";
import ResetButton from "../../components/common/ResetButton";
import ErrorPopup from "../../components/common/ErrorPopup";
import NotificationPopup from "../../components/common/NotificationPopup";
import { Spinner } from "react-bootstrap";

const EditPopup = (props) => {
  const [showError, setShowError] = useState(false);
  const [okBtn, setokbtn] = useState(false);
  const [yesBtn, setYesBtn] = useState(true);
  const [closeBtn, setCloseBtn] = useState(true);
  const UserInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
  // const accounts = UserInfo.data.customerInfo;
  const [isSaveMsg, setSaveMsg] = useState(false);
  const [isRoleMsg, setRoleMsg] = useState(false);
  const [isBranchUpdate, setBranchUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(
    props.row.customerInfo
  );
  const getRolesUrl =
    "https://anz-cropcentre-api-dev.herokuapp.com/services/api/userroles/roles";
  const [showAccounts, setShowAccounts] = useState(false);

  window.scrollTo({
    top: 0,
    behavior: "instant",
  });

  const SelectedAgency = JSON.parse(
    localStorage.getItem("SelectedAgency") || "[]"
  );

  const accountName = useState(
    SelectedAgency.account_sapAccountNumber + "-" + SelectedAgency.agencyName
  );

  var saveMsg = [
    {
      notificationType: "success",
      header: "User updated successfully",
      message: ``,
    },
  ];
  var roleAcMsg = [
    {
      notificationType: "success",
      header:
        "Role or Account updation needs approval. Request sent to Bayer customer service.",
      message: ``,
    },
  ];
  var branchMsg = [
    {
      notificationType: "success",
      header:
        "Not associated with this branch changed for update Request sent to Bayer customer service.",
      message: ``,
    },
  ];
  const submitData = {
    userData: {
      userDetails: {},
      userRole: {
        new: [],
        existing: [],
      },
    },
  };

  // const States = ["australian capital territory", "new south wales", "northern territory", "queensland", "south australia", "tasmania", "victoria", "western australia"];

  let initialValues = {
    cont_salutation: props.row.userDetails.cont_salutation,
    editUser_firstName: props.row.userDetails.cont_firstName,
    editUser_lastName: props.row.userDetails.cont_lastName,
    mname: "",
    cont_status: "Active",
    editUser_userName: props.row.userDetails.cont_email,
    editUser_phone: props.row.userDetails.cont_phone,
    description: props.row.caseDesc,
    // editUser_fax: props.row.userDetails.cont_fax,
    // editUser_mobile: props.row.userDetails.cont_mobile,
    editUser_mailingStreet: UserInfo.data.userDetails.cont_mailingStreet,
    editUser_mailingCity: UserInfo.data.userDetails.cont_mailingCity,
    editUser_mailingState: UserInfo.data.userDetails.cont_mailingState,
    addNew_mailingCountry: UserInfo.data.userDetails.cont_mailingCountry,
    cont_mailingPostalCode: UserInfo.data.userDetails.cont_mailingPostalCode,
    cont_sfid: props.row.userDetails.user_contactSfid,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  // const [unsavedChanges, setUnsavedChanges] = useState(false);
  // const [submitClicked, setSubmitClicked] = useState(false);
  const [showNotiPopup, setShowNotiPopup] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorHeading, setErrorHeading] = useState("");
  const [errorMsgBlue, setErrorMsgBlue] = useState("");
  const [showOkBtn, setShowOkBtn] = useState(false);
  const [showYesBtn, setShowYesBtn] = useState(false);
  const [showNoBtn, setShowNoBtn] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [showSaluations, setShowSaluations] = useState(false);
  const [saluation, setSaluation] = useState(props.row.saluation);
  const [showRoles, setShowRoles] = useState(false);
  const [searchRole, setSearchRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(props.row.userRole);
  const [isBranchAss, setBranchAss] = useState(false);

  document.body.style.overflow = "hidden";

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({ ...formValues, [name]: value });
  };

  const handleRoleChange = (id, name, e) => {
    if (e.target.checked === true) {
      if (!selectedRole.includes(name)) {
        setSelectedRole([...selectedRole, name]);
      }
    } else {
      let newArr = selectedRole.filter((el) => el !== name);
      setSelectedRole(newArr);
    }
  };

  const removeRoles = (name) => {
    let newArr = selectedRole.filter((el) => el !== name);
    setSelectedRole(newArr);
  };

  // const removeAccounts = (id)=> {
  //   let letNewAr = selectedAccount.filter(ar => ar.account_sapAccountNumber!=id);
  //   setSelectedAccount(letNewAr);
  // }
  // const handleAccountChange = (id, name , e ) => {
  //   if(e.target.checked==true){
  //     const found = selectedAccount.some(el => el.account_name === name);
  //       if(!found)
  //       {
  //         setSelectedAccount([...selectedAccount,{account_name:name,account_sapAccountNumber:id}]);
  //       }

  //   }else{
  //      let letNewAr = selectedAccount.filter(ar => ar.account_sapAccountNumber!=id);
  //      setSelectedAccount(letNewAr);
  //   }

  // };

  function submitAndfetchData() {
    // if(submitClicked) submitStockRequest();
    // else fetchData();

    setErrorHeading("Changes to Personal Details");
    setErrorMsg("Are you sure you want to change the details?");
    setShowError(true);
  }

  useEffect(() => {
    axios
      .get(getRolesUrl)
      .then((res) => {
        setRoles(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handleSubmit = () => {
    setShowError(false);
    setFormErrors(validate(formValues));
    const error = validate(formValues);
    let formObj = {};
    let roles;

    formObj = {
      cont_firstName: formValues.editUser_firstName,
      cont_lastName: formValues.editUser_lastName,
      cont_email: formValues.editUser_userName,
      cont_accSfid: SelectedAgency.account_sfid,
      cont_phone: formValues.editUser_phone,
      cont_status: "Active",
      cont_mailingStreet: formValues.editUser_mailingStreet,
      cont_mailingCity: formValues.editUser_mailingCity,
      cont_mailingState: formValues.editUser_mailingState,
      cont_mailingCountry: formValues.addNew_mailingCountry,
      cont_mailingPostalCode: formValues.cont_mailingPostalCode,
      // cont_mobile: formValues.editUser_mobile,
      cont_sfid: formValues.cont_sfid,
    };
    submitData.userData.userDetails = formObj;
    submitData.userData.caseDesc = formValues.description;
    submitData.userData.salesArea_salesGroup =
      SelectedAgency.salesArea_salesGroup;
    roles = Object.keys(selectedRole).map((key, value) => {
      return selectedRole[key];
    });
    //let accounts = Object.keys(selectedAccount).map((key, value)=> {return [{account_name:key,account_sapAccountNumber:value}]});
    if (JSON.stringify(selectedRole) !== JSON.stringify(props.row.userRole)) {
      submitData.userData.userRole.new = selectedRole;
      submitData.userData.roleChanged = true;
    } else {
      submitData.userData.roleChanged = false;
      submitData.userData.userRole.new = [];
    }
    submitData.userData.customerInfo = [
      {
        acc_Name: SelectedAgency.agencyName,
        acc_SAPId: SelectedAgency.account_sapAccountNumber,
      },
    ];
    //  if(JSON.stringify(selectedAccount)!=JSON.stringify(props.row.customerInfo)){
    //   submitData.userData.customerInfo.new = selectedAccount;
    //   submitData.userData.acrChanged=true;
    //  }else{
    //   submitData.userData.customerInfo.new=[];
    //   submitData.userData.acrChanged=false;
    //  }

    submitData.userData.userRole.existing = props.row.userRole;
    submitData.userData.deactivatePortalUser = isBranchAss;

    if (Object.keys(error).length !== 0) {
      console.log("ERROR", error);
    } else {
      setIsSubmit(true);
      postData("usermanagement/manageupdateuser", submitData);
    }
  };

  const handleReset = () => {
    setFormValues(initialValues);
    setErrorHeading("Reset filters");
    setErrorMsg("All your selected options will be cleared.");
    setErrorMsgBlue("");
    setShowOkBtn(false);
    setShowYesBtn(true);
    setShowNoBtn(true);
    setModalShow(true);
    setShowNotiPopup(false);
    setSelectedAccount(props.row.customerInfo);
    setSelectedRole(props.row.userRole);
    setShowAccounts(false);
    setShowRoles(false);
  };
  // const handleChangeDropdown = (e, type) => {
  //   const { value } = e.target;
  //   setFormValues({ ...formValues, [type]: value });
  //   console.log('formvalues', formValues)
  // }

  const validate = (values) => {
    const errors = {};
    const regex = /^[A-Za-z]+$/;
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (props.type === "profile") {
      if (!values.editUser_firstName) {
        errors.editUser_firstName = "First Name is required!";
      } else if (!regex.test(values.editUser_firstName)) {
        errors.editUser_firstName = "Incorrect format";
      }
      if (!values.editUser_lastName) {
        errors.editUser_lastName = "Last Name is required!";
      } else if (!regex.test(values.editUser_lastName)) {
        errors.editUser_lastName = "Incorrect format";
      }
      if (!values.editUser_phone) {
        errors.editUser_phone = "Phone Number is required!";
      } else if (!/^[0-9]+$/.test(values.editUser_phone)) {
        errors.editUser_phone = "Phone should be in number";
      }
    }

    if (selectedRole.length <= 0) {
      errors.roles = "Select atleast one role";
    }
    return errors;
  };

  const checkIsAsBranch = (e) => {
    setBranchAss(e.target.checked === true ? true : false);
  };

  const closeErrorPopup = () => {
    setShowError(false);
    setShowNoBtn(false);
    setShowOkBtn(false);
    setShowYesBtn(false);
    setokbtn(false);
    setModalShow(false);
    setYesBtn(true);
    setCloseBtn(true);
  };
  async function postData(url = "", data = {}) {
    setLoading(true);
    await axios
      .post(url, data)
      .then((res) => {
        if (
          submitData.userData.acrChanged === true ||
          submitData.userData.roleChanged === true
        ) {
          setRoleMsg(true);
        }
        if (isBranchAss) {
          setBranchUpdate(true);
        }
        setShowNotiPopup(true);
        setSaveMsg(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setErrorHeading("Error While Submit");
        setErrorMsg("There is something went wrong at server side.");
        setShowError(true);
        setCloseBtn(false);
        setYesBtn(false);
        setokbtn(true);
      });
  }

  return (
    <div className="popup-box">
      <Container className="box">
        {loading && (
          <div className="centeredSpin">
            {" "}
            <Spinner animation="border" role="status"></Spinner>{" "}
          </div>
        )}
        <Row className="popup_header">
          <Col md="6">
            <span style={{ color: "#96A8AE" }}>Edit User's Information</span>
          </Col>
          <Col md="4"></Col>
          {/* <Col md="1" style={{ margin: '-30px 0px' }}><img src={Editicon} /></Col> */}
          <Col md="2">
            <button className="close-icon" onClick={props.handleClose}>
              close x
            </button>
          </Col>
        </Row>
        <form className="edit-user-form">
          <Row>
            {isSaveMsg && (
              <NotificationPopup
                showNotiPopup={showNotiPopup}
                notifications={saveMsg}
                className="noti-popup mb-3"
              />
            )}
            {isRoleMsg && (
              <NotificationPopup
                showNotiPopup={showNotiPopup}
                notifications={roleAcMsg}
                className="noti-popup mb-3"
              />
            )}
            {isBranchUpdate && (
              <NotificationPopup
                showNotiPopup={showNotiPopup}
                notifications={branchMsg}
                className="noti-popup mb-3"
              />
            )}
            <Col md="12">
              <div className="add-new-user">
                <span className="add-new-label ">
                  <label htmlFor="editUser_userName" className="padd-new-label">
                    Account: {accountName}{" "}
                  </label>
                </span>
              </div>
            </Col>
            {props.type === "profile" && (
              <Col md="12">
                {/* First Name */}
                <div className="add-new-user">
                  <span className="add-new-label required">
                    <label
                      htmlFor="editUser_firstName"
                      className="padd-new-label"
                    >
                      First Name{" "}
                    </label>
                    :
                  </span>
                  <span className="add-new-input">
                    <input
                      type="text"
                      className={
                        "form-control padd-new-form " +
                        (formErrors.editUser_firstName
                          ? "error-form-input"
                          : "")
                      }
                      id="editUser_firstName"
                      name="editUser_firstName"
                      value={formValues.editUser_firstName}
                      onChange={handleChange}
                      placeholder="Enter user's first name here"
                      autoComplete="off"
                      required
                    />
                  </span>
                  <span className="WarningMsg padd-new-Error">
                    {formErrors.editUser_firstName ? (
                      <img
                        src={errorLogo}
                        alt="error icon"
                        className="error-logo"
                      />
                    ) : (
                      ""
                    )}
                    {formErrors.editUser_firstName}
                  </span>
                </div>
                {/* Last Name */}
                <div className="add-new-user">
                  <span className="add-new-label required">
                    <label
                      htmlFor="editUser_lastName"
                      className="padd-new-label"
                    >
                      Last Name{" "}
                    </label>
                    :
                  </span>
                  <span className="add-new-input">
                    <input
                      type="text"
                      className={
                        "form-control padd-new-form " +
                        (formErrors.editUser_lastName ? "error-form-input" : "")
                      }
                      id="editUser_lastName"
                      name="editUser_lastName"
                      placeholder="Enter user's last name here"
                      onChange={handleChange}
                      value={formValues.editUser_lastName}
                      autoComplete="off"
                      required
                    />
                  </span>
                  <span className="WarningMsg padd-new-Error">
                    {formErrors.editUser_lastName ? (
                      <img
                        src={errorLogo}
                        alt="error icon"
                        className="error-logo"
                      />
                    ) : (
                      ""
                    )}
                    {formErrors.editUser_lastName}
                  </span>
                </div>

                <div className="add-new-user">
                  <span className="add-new-label required">
                    <label htmlFor="editUser_phone" className="padd-new-label">
                      Phone No{" "}
                    </label>
                    :
                  </span>
                  <span className="add-new-input">
                    <input
                      type="text"
                      className={
                        "form-control padd-new-form " +
                        (formErrors.editUser_lastName ? "error-form-input" : "")
                      }
                      id="editUser_phone"
                      name="editUser_phone"
                      placeholder="xx-xxxx-xxxx"
                      onChange={handleChange}
                      value={formValues.editUser_phone}
                      autoComplete="off"
                      required
                    />
                  </span>
                  <span className="WarningMsg padd-new-Error">
                    {formErrors.editUser_phone ? (
                      <img
                        src={errorLogo}
                        alt="error icon"
                        className="error-logo"
                      />
                    ) : (
                      ""
                    )}
                    {formErrors.editUser_phone}
                  </span>
                </div>

                <div className="add-new-user">
                  <span className="add-new-label required">
                    <label
                      htmlFor="editUser_userName"
                      className="padd-new-label"
                    >
                      Email Id{" "}
                    </label>
                    :
                  </span>
                  <span className="add-new-input">
                    <input
                      type="text"
                      className={
                        "form-control padd-new-form " +
                        (formErrors.editUser_lastName ? "error-form-input" : "")
                      }
                      id="editUser_userName"
                      name="editUser_userName"
                      onChange={handleChange}
                      value={formValues.editUser_userName}
                      autoComplete="off"
                      required
                    />
                  </span>
                  <span className="WarningMsg padd-new-Error">
                    {formErrors.editUser_userName ? (
                      <img
                        src={errorLogo}
                        alt="error icon"
                        className="error-logo"
                      />
                    ) : (
                      ""
                    )}
                    {formErrors.editUser_userName}
                  </span>
                </div>

                {/* Email ID */}
              </Col>
            )}
            {props.type === "roles" && (
              <Col md="12">
                <div className="add-new-user">
                  <span className="add-new-label required">
                    <label htmlFor="addNew_roles" className="padd-new-label">
                      Role{" "}
                    </label>
                    :
                  </span>

                  <span
                    style={{ marginLeft: -95 }}
                    className={
                      "add-new-input" +
                      (showRoles ? " input-dropdown-active" : " input-dropdown")
                    }
                  >
                    <span className="dropdown-input">
                      <input
                        type="text"
                        id="addNew_roles"
                        name="addNew_roles"
                        className="form-control"
                        placeholder="Search Roles"
                        onChange={(e) => setSearchRole(e.target.value)}
                      />
                    </span>

                    <span
                      className="search-btn"
                      onClick={() => setShowRoles(true)}
                    >
                      <img src={SearchIcon} alt="search icon" />
                    </span>
                    <span
                      className="dropdown-input-btn"
                      onClick={() => setShowRoles(!showRoles)}
                    >
                      <img
                        src={showRoles ? DropdownBlue : Dropdown}
                        alt="dropdown icon"
                      />
                    </span>
                  </span>
                </div>

                <div className="add-new-user">
                  <span className="add-new-label">
                    <span className="WarningMsg padd-new-Error">
                      {formErrors.roles ? (
                        <img
                          src={errorLogo}
                          alt="error icon"
                          className="error-logo"
                        />
                      ) : (
                        ""
                      )}
                      {formErrors.roles}
                    </span>
                  </span>

                  <span className="add-new-input" style={{ marginLeft: -95 }}>
                    {showRoles && (
                      <div className="checkboxOptionsDiv ">
                        {roles
                          .filter(function (item) {
                            return item.role_name
                              .toLowerCase()
                              .includes(searchRole.toLowerCase());
                          })
                          .map((role) => (
                            <label for={role.role_sfid}>
                              <input
                                className=""
                                type="checkbox"
                                checked={
                                  selectedRole.includes(role.role_name) && true
                                }
                                id={role.role_sfid}
                                name={role.role_sfid}
                                onClick={(e) =>
                                  handleRoleChange(
                                    role.role_sfid,
                                    role.role_name,
                                    e
                                  )
                                }
                              />
                              &nbsp;{role.role_name}
                            </label>
                          ))}
                      </div>
                    )}
                    <div className="selectedRoles">
                      {Object.keys(selectedRole).map((key, value) => {
                        return (
                          <div className="rolesSpan">
                            {selectedRole[key]}
                            <span
                              className="closeRoleBtn"
                              onClick={() => removeRoles(selectedRole[value])}
                            >
                              X
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </span>
                </div>
              </Col>
            )}

            {props.type === "case" && (
              <Col md="12">
                <div className="add-new-user">
                  <span className="add-new-label ">
                    <label
                      htmlFor="editUser_userName"
                      className="padd-new-label"
                    >
                      Not associated with this branch:{" "}
                    </label>
                  </span>
                  <span
                    className="add-new-input"
                    style={{ marginTop: 13, marginLeft: 112 }}
                  >
                    <input type="checkbox" onChange={checkIsAsBranch} />
                  </span>
                </div>
                {/* <div className="add-new-user">
                  <span className="add-new-label ">
                    <label
                      htmlFor="editUser_userName"
                      className="padd-new-label"
                    >
                      Case Number:{" "}
                    </label>
                  </span>
                  <span
                    className="add-new-input"
                    style={{ color: "#00617F", fontSize: 14, marginTop: 8 }}
                  >
                    {props.caseNumber}
                  </span>
                </div> */}

                <div
                  className="account-btn"
                  style={{ paddingTop: "20px", justifyContent: "left" }}
                ></div>
              </Col>
            )}
            {(props.type === "roles" || props.type === "case") && (
              <Col md="12">
                <div className="add-new-user">
                  <span className="add-new-label ">
                    <label
                      htmlFor="editUser_userName"
                      className="padd-new-label"
                    >
                      Email Id{" "}
                    </label>
                    :
                  </span>
                  <span className="add-new-input">
                    <input
                      type="text"
                      className={
                        "form-control padd-new-form " +
                        (formErrors.editUser_lastName ? "error-form-input" : "")
                      }
                      id="editUser_userName"
                      name="editUser_userName"
                      onChange={handleChange}
                      value={formValues.editUser_userName}
                      autoComplete="off"
                      required
                    />
                  </span>
                  <span className="WarningMsg padd-new-Error">
                    {formErrors.editUser_userName ? (
                      <img
                        src={errorLogo}
                        alt="error icon"
                        className="error-logo"
                      />
                    ) : (
                      ""
                    )}
                    {formErrors.editUser_userName}
                  </span>
                </div>

                <div className="add-new-user" style={{ marginTop: 10 }}>
                  <span className="add-new-label">
                    <label htmlFor="Description" className="padd-new-label">
                      Description{" "}
                    </label>
                    :
                  </span>
                  <span style={{ marginLeft: -95 }} className="add-new-input">
                    <textarea
                      onChange={handleChange}
                      name="description"
                      className="form-control"
                      rowspan="30"
                      cols="40"
                    >
                      {formValues.description}
                    </textarea>
                  </span>
                </div>

                <div
                  className="account-btn"
                  style={{ paddingTop: "20px", justifyContent: "left" }}
                ></div>
              </Col>
            )}
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col md="6"></Col>
            <Col md="2">
              <ResetButton onClick={() => handleReset()} title="RESET" />
            </Col>
            <Col md="4">
              <SubmitButton
                onClick={() => submitAndfetchData()}
                title="UPDATE USER"
              />
            </Col>
          </Row>
        </form>
      </Container>
      <ErrorPopup
        show={showError}
        okbtn={okBtn}
        yesbtn={yesBtn}
        nobtn={closeBtn}
        errormsg={errorMsg}
        heading={errorHeading}
        infoSign={true}
        onhide={() => closeErrorPopup()}
        onclickno={() => setShowError(false)}
        onclickyes={() => handleSubmit()}
      />
    </div>
  );
};

export default EditPopup;
