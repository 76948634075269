import React, { useState, useEffect } from 'react'
import '../../reports/report.scss';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import {
  updateOutputFilter, updateGroupFilter, updateSORFilter, updateSOHFilter, updateYearFilter, updateStartMonthFilter,
  updateEndMonthFilter, updateFkaFilter, updateStockFilter, selectedSAPReportFilter, setSapFilterObjSS, setAgencyBranchObj, updateBranchFilter, fetchSAPReport
} from "../../../redux/actions/salesStockReport/salesStockActions";
import { useDispatch, useSelector } from 'react-redux';

const SalesStockFilterPopup = props => {
  document.body.style.overflow = "hidden"
  const [tabData, setTabData] = useState([]);
  const [data, setData] = useState([]);
  const [selectedTab, setSelectedTab] = useState('All');
  const [searchForBranch, setSearchForBranch] = useState(true);
  const [branchToSearch, setBranchToSearch] = useState('');
  // const [checkAll , setCheckAll] =  useState(false);

  const dispatch = useDispatch();
  // to get selected filter Value on button click
  const selectedFilterSales = useSelector((state) => state.SalesStock.selectedFilter);

  //output filter
  const SalesOutputData = useSelector((state) => state.SalesStock.outputData);  //to fetch all values of filter
  const outputFilterSales = useSelector((state) => state.SalesStock.outputDataFilter); //to show selected value of filter

  //SOR
  const salesSORData = useSelector((state) => state.SalesStock.sorData); //to show selected value of filter
  const salesSORfilterData = useSelector((state) => state.SalesStock.sorDataFilter); //to show selected value of filter

  //SOH
  const salesSOHData = useSelector((state) => state.SalesStock.sohData); //to show selected value of filter
  const salesSOHFilterData = useSelector((state) => state.SalesStock.sohDataFilter); //to show selected value of filter

  //Year
  const salesYearData = useSelector((state) => state.SalesStock.yearData); //to show selected value of filter
  const salesYearFilterData = useSelector((state) => state.SalesStock.yearDataFilter); //to show selected value of filter

  //Start month
  const salesStartMonthData = useSelector((state) => state.SalesStock.startMonthData); //to show selected value of filter
  const salesStartMonthFilterData = useSelector((state) => state.SalesStock.startMonthDataFilter); //to show selected value of filter

  //End Month
  const salesEndMonthData = useSelector((state) => state.SalesStock.endMonthData); //to show selected value of filter
  const salesEndMonthFilterData = useSelector((state) => state.SalesStock.endMonthDataFilter); //to show selected value of filter

  //End Month
  const salesFkaData = useSelector((state) => state.SalesStock.fkaData); //to show selected value of filter
  const salesFkaFilterData = useSelector((state) => state.SalesStock.fkaDataFilter); //to show selected value of filter

  //show stocks
  const salesStocksData = useSelector((state) => state.SalesStock.stockData); //to show selected value of filter
  const salesStocksFilterData = useSelector((state) => state.SalesStock.stockDataFilter); //to show selected value of filter



  //group filter
  const SalesGroupData = useSelector((state) => state.SalesStock.groupData);  //to fetch all values of filter
  const groupFilterSales = useSelector((state) => state.SalesStock.groupDataFilter); //to show selected value of filter

  // material data
  const materialsData = useSelector((state) => state.SalesStock.materialsData);
    const materialsDataFilter = useSelector((state) => state.SalesStock.materialsDataFilter);
    const sapMaterialsObj = useSelector((state) => state.SalesStock.sapMaterialsObj);

  // agency branch data
  const agencyBranchObj = useSelector((state) => state.SalesStock.agencyBranchObj); //to fetch all values of filter
  const branchData = useSelector((state) => state.SalesStock.branchData);
  const branchDataFilter = useSelector((state) => state.SalesStock.branchDataFilter); //to show selected value of filter
 


  const getSections = (words) => {
    if (selectedFilterSales === "material") {
      return Object.values(
        words.reduce((acc, word) => {
          let firstLetter;
          if (selectedFilterSales === "material") {
            firstLetter = Array.from(word['prod_name'])[0];

          }
          if (!acc[firstLetter]) {
            acc[firstLetter] = { title: firstLetter, data: [word] };
          } else {
            acc[firstLetter].data.push(word);
          }
          return acc;
        }, {})
      );
    }
  }

  const handleSelect = (e) => {
    setSelectedTab(e);
  }

  const handleClick = () => {
    if (selectedFilterSales === 'branch'){
      dispatch(fetchSAPReport(agencyBranchObj))
  }
    props.handleClose()
  }

  useEffect(() => {
    if (selectedFilterSales === 'material') {
      const allData = getSections(materialsData);
      setTabData(allData)
    } else if (selectedFilterSales === 'branch') {
      const allData = getSections(branchData);
      setTabData(allData)
      setData(branchData)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialsData])

  // Onchanging filter value  
  const handleChangeFilterSales = (filterValue, accountValue) => {
    if (selectedFilterSales === "output") {
      // const removeItems = outputFilterSales.filter(item => item !== filterValue) 
      dispatch(updateOutputFilter(filterValue))
    } else if (selectedFilterSales === "group") {
      // const removeItems = groupFilterSales.filter(item => item !== filterValue) 
      dispatch(updateGroupFilter(filterValue))
    } else if (selectedFilterSales === "sor") {
      // const removeItems = salesSORfilterData.filter(item => item !== filterValue) 
      dispatch(updateSORFilter(filterValue))
    } else if (selectedFilterSales === "soh") {
      // const removeItems = salesSOHFilterData.filter(item => item !== filterValue) 
      dispatch(updateSOHFilter(filterValue))
    } else if (selectedFilterSales === "year") {
      // const removeItems = salesYearFilterData.filter(item => item !== filterValue) 
      dispatch(updateYearFilter(filterValue))
    } else if (selectedFilterSales === "sMonth") {
      // const removeItems = salesStartMonthFilterData.filter(item => item !== filterValue) 
      dispatch(updateStartMonthFilter(filterValue))
    } else if (selectedFilterSales === "eMonth") {
      // const removeItems = salesEndMonthFilterData.filter(item => item !== filterValue) 
      dispatch(updateEndMonthFilter(filterValue))
    } else if (selectedFilterSales === "fka") {
      // const removeItems = salesFkaFilterData.filter(item => item !== filterValue) 
      // alert(filterValue)
      dispatch(updateFkaFilter(filterValue))
    } else if (selectedFilterSales === "stocks") {
      dispatch(updateStockFilter(filterValue))
    } else if (selectedFilterSales === "branch") {
      let objFilterValue = { filterValue, accountValue }
      let alreadyInobj = false;
      agencyBranchObj.map((item) => {
        if (item.filterValue === filterValue) {
          alreadyInobj = true;
        }
        return null;
      })
      if (!alreadyInobj)
        dispatch(setAgencyBranchObj([...agencyBranchObj, objFilterValue]))
      else
        dispatch(setAgencyBranchObj(
          agencyBranchObj.filter(function (temp) {
            return temp.filterValue !== filterValue;
          })
        ))
     const removeItems = branchDataFilter.filter(item => item !== filterValue)
     dispatch(updateBranchFilter(branchDataFilter.includes(filterValue) ? removeItems : [...branchDataFilter, filterValue]))
    }



  }
  // function getKeyByValue(object, value) {
  //   return Object.keys(object).find(key => object[key] === value);
  // }

  const handleChangeFilter = (filterValue, accountValue) => {

    let objFilterValue = { filterValue, accountValue }
    //to update filter array
    const removeItems = materialsDataFilter.filter(item => item !== filterValue)
    dispatch(selectedSAPReportFilter(materialsDataFilter.includes(filterValue) ? removeItems : [...materialsDataFilter, filterValue]))

    //to update object of filter array
    let newObj = sapMaterialsObj.filter(function (obj) {
      return obj.filterValue !== filterValue;
    });

    let checkFilterExist = sapMaterialsObj.some(objData => objData.filterValue === filterValue)
    dispatch(setSapFilterObjSS(checkFilterExist ? newObj : [...newObj, objFilterValue]))

  }

  const selectAllChecks = (event) => {
    //let checkbox = document.querySelector(".branch_all");
     let agencyObj = [];
     let branchFilter = [];
     let isChecked = event.target.checked === true?true:false;
    var checkboxes=document.getElementsByClassName("branch_all");
    for (let checkbox of checkboxes) {
      checkbox.checked  = isChecked; 
      let item  = data.find(item => item.account_sfid === checkbox.value); 
      let branchName=item.account_name+' ('+item.account_sapAccountNumber+', '+item.salesArea_salesGroup+', '+item.salesArea_salesDistrict+', '+item.salesArea_salesOffice+')';
      let accountValue = item.account_sfid;
      agencyObj.push({'filterValue':branchName , 'accountValue': accountValue});
      branchFilter.push(branchName);
      } 
      
      if(isChecked){
        dispatch( setAgencyBranchObj(agencyObj))
        dispatch(updateBranchFilter(branchFilter))
      }else{
        dispatch( setAgencyBranchObj([]));
        dispatch(updateBranchFilter([]));
      }
     

      
      //updateStore(agencyObj);
    }

    const selectAllMaterial = (event) => { 
      let isChecked = event.target.checked === true?true:false;
      let  materailAllObj = []
      let selectedMaterial = [];
      var checkboxes=document.getElementsByClassName("mat_all");
      for (let checkbox of checkboxes) {
   
         let item  = materialsData.find(item => item.prod_sapMaterialNumber === checkbox.value); 
         let materialName = item.prod_name+' ('+item.prod_sapMaterialNumber+')';
         let prod_name = item.prod_sfid;
         let objFilterValue =  {filterValue:materialName , accountValue:prod_name };
         materailAllObj.push(objFilterValue);
         selectedMaterial.push(materialName)
   
       } 
   
       if(isChecked){
         dispatch(selectedSAPReportFilter(selectedMaterial))
         dispatch(setSapFilterObjSS(materailAllObj))
         
       }else{
        dispatch(selectedSAPReportFilter([]))
        dispatch(setSapFilterObjSS([]))
       }
       
     }

    // const updateStore =   (agencyObj)=> {
    //   console.log("Here");
       
        
    // }

  return (
    <div className="popup-box">
      <div className="box">
        <div className='popup-title'>
          <h4>{props.title}</h4>
          <span className='closeX' onClick={handleClick}>X</span>
        </div>

        {selectedFilterSales === "branch" ? <div style={{ alignContent: 'center', padding: '10px', marginBottom: '20px', display: 'flex' }}>
          <textarea rows={1} className='d-inline-block search-product' placeholder='Key Words Search...' onChange={e => { setBranchToSearch(e.target.value); }} />
          <button className='searchbtnreport' onClick={() => { setSearchForBranch(true); }}>Search</button>
        </div> :
          <div className="popup-head">
            <p>Note: use Ctrl + F to search within the results</p>
          </div>}
        <div className='filter-box'>
          {(selectedFilterSales === "material") && <Tabs
            activeKey={selectedTab ? selectedTab : '2'}
            transition={false}
            textColor="#838383"
            onSelect={(e) => handleSelect(e)}>
            {tabData.map((e, k) => {
              return (
                <Tab eventKey={e.title} title={e.title} k={k+''}>
                  <ul className='list-report'>
                    {e.data.filter((value, index, self) =>
                      index === self.findIndex((t) => (
                        t.prod_name === value.prod_name && t.prod_sfid === value.prod_sfid
                      ))
                    ).map((filterData, key) => {
                      let materialName = filterData.prod_name+' ('+filterData.prod_sapMaterialNumber+')';
                      return (
                        <li key={key+''}><label className='report-label'><input className='check-report' type="checkbox" value={materialName} onChange={() => handleChangeFilter(materialName, filterData.prod_sfid)} checked={materialsDataFilter.includes(materialName) ? true : false} />{materialName}</label></li>
                      )
                    })}
                  </ul>
                </Tab>
              );
            })}
            <Tab eventKey="All" title="All" >
              
              <ul className='list-report'>
              <li><label className='report-label'><input className='check-report' onChange={selectAllMaterial} type="checkbox"   />Select All</label></li>
                {
                  materialsData.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                      t.prod_name === value.prod_name && t.prod_sfid === value.prod_sfid
                    ))
                  ).map((allData, key) => {
                    let materialName = allData.prod_name+' ('+allData.prod_sapMaterialNumber+')';
                    return (
                      <li key={key+''}><label className='report-label'><input className='check-report mat_all' type="checkbox" value={allData.prod_sapMaterialNumber} onChange={() => handleChangeFilter(materialName, allData.prod_sfid)} checked={materialsDataFilter.includes(materialName) ? true : false} />{materialName}</label></li>
                    )
                  })
                }
              </ul>
            </Tab>
          </Tabs>}
          {selectedFilterSales === 'group' && <ul className='history-filter'>
            {
              SalesGroupData.map((allData, key) => {
                return (
                  <li key={key+''}><label className='report-label'><input className='check-report' type="radio" value={allData} checked={groupFilterSales === allData ? true : false} onChange={() => handleChangeFilterSales(allData)} />{allData}</label></li>
                )
              })
            }
          </ul>
          }
          {(selectedFilterSales === "branch") && searchForBranch && <Tabs
            activeKey={selectedTab ? selectedTab : '2'}
            transition={false}
            textColor="#838383"
            onSelect={(e) => handleSelect(e)}>
            {tabData?.map((e, k) => {
              return (
                <Tab eventKey={e.title} title={e.title} k={k+''}>
                  <ul className='list-report'>
                  <li><label className='report-label'><input className='check-report' type="checkbox"   />Select All</label></li>
                    {e.data.filter(function (item) {
                      return item.account_name.toLowerCase().includes(branchToSearch.toLowerCase());
                    })
                      .map((filterData, key) => {
                        let branchName=filterData.account_name+' ('+filterData.account_sapAccountNumber+', '+filterData.salesArea_salesGroup+', '+filterData.salesArea_salesDistrict+', '+filterData.salesArea_salesOffice+')';
                        return (
                          <li key={key+''}><label className='report-label'><input className='check-report' type="checkbox" value={filterData.account_sfid} onChange={() => { selectedFilterSales === "branch" ? handleChangeFilterSales(branchName, filterData.account_sfid) : handleChangeFilter(branchName, 'na') }} checked={(branchDataFilter.includes(branchName)) ? true : false} />{branchName}</label></li>
                        )
                      })}
                  </ul>
                </Tab>
              );
            })}
            <Tab eventKey="All" title="All" >
              
              <ul className='list-report'>
              <li><label className='report-label'><input className='check-report' onChange={selectAllChecks} type="checkbox"   />Select All</label></li>
                {
                  data?.filter(function (item) {
                    return item.account_name.toLowerCase().includes(branchToSearch.toLowerCase());
                  }).map((allData, key) => {
                    let branchName=allData.account_name+' ('+allData.account_sapAccountNumber+', '+allData.salesArea_salesGroup+', '+allData.salesArea_salesDistrict+', '+allData.salesArea_salesOffice+')';
                    return (
                      <li><label className='report-label'><input className='check-report branch_all' type="checkbox" value={allData.account_sfid} onChange={() => { handleChangeFilterSales(branchName, allData.account_sfid) }} checked={(branchDataFilter.includes(branchName)) ? true : false} />{branchName}</label></li>
                    )
                  })
                }
              </ul>
            </Tab>
          </Tabs>}
          {selectedFilterSales === 'output' && <ul className='history-filter'>
            {
              SalesOutputData.map((allData, key) => {
                return (
                  <li key={key+''}><label className='report-label'><input className='check-report' type="radio" value={allData} checked={outputFilterSales === allData ? true : false} onChange={() => handleChangeFilterSales(allData)} />{allData}</label></li>
                )
              })
            }
          </ul>
          }
          {selectedFilterSales === 'sor' && <ul className='history-filter'>
            {
              salesSORData.map((allData, key) => {
                return (
                  <li key={key+''}><label className='report-label'><input className='check-report' type="radio" value={allData} checked={salesSORfilterData === allData ? true : false} onChange={() => handleChangeFilterSales(allData)} />{allData}</label></li>
                )
              })
            }
          </ul>
          }
          {selectedFilterSales === 'soh' && <ul className='history-filter'>
            {
              salesSOHData.map((allData, key) => {
                return (
                  <li key={key+''}><label className='report-label'><input className='check-report' type="radio" value={allData} checked={salesSOHFilterData === allData ? true : false} onChange={() => handleChangeFilterSales(allData)} />{allData}</label></li>
                )
              })
            }
          </ul>
          }
          {selectedFilterSales === 'year' && <ul className='history-filter'>
            {
              salesYearData.map((allData, key) => {
                return (
                  <li key={key+''}><label className='report-label'><input className='check-report' type="radio" value={allData} checked={salesYearFilterData === allData ? true : false} onChange={() => handleChangeFilterSales(allData)} />{allData}</label></li>
                )
              })
            }
          </ul>
          }
          {selectedFilterSales === 'sMonth' && <ul className='history-filter list-report'>
            {
              salesStartMonthData.map((allData, key) => {
                return (
                  <li key={key+''}><label className='report-label'><input className='check-report' type="radio" value={allData} checked={salesStartMonthFilterData === allData ? true : false} onChange={() => handleChangeFilterSales(allData)} />{allData}</label></li>
                )
              })
            }
          </ul>
          }
          {selectedFilterSales === 'eMonth' && <ul className='history-filter list-report'>
            {
              salesEndMonthData.map((allData, key) => {
                return (
                  <li key={key+''}><label className='report-label'><input className='check-report' type="radio" value={allData} checked={salesEndMonthFilterData === allData ? true : false} onChange={() => handleChangeFilterSales(allData)} />{allData}</label></li>
                )
              })
            }
          </ul>
          }
          {selectedFilterSales === 'fka' && <ul className='history-filter'>
            {
              salesFkaData.map((allData, key) => {
                return (
                  <li key={key+''}><label className='report-label'><input className='check-report' type="radio" value={allData} checked={salesFkaFilterData === allData ? true : false} onChange={() => handleChangeFilterSales(allData)} />{allData}</label></li>
                )
              })
            }
          </ul>
          }
          {selectedFilterSales === 'stocks' && <ul className='history-filter'>
            {
              salesStocksData.map((allData, key) => {
                return (
                  <li key={key+''}><label className='report-label'><input className='check-report' type="radio" value={allData} checked={salesStocksFilterData===allData} onChange={() => handleChangeFilterSales(allData)} />{allData}</label></li>
                )
              })
            }
          </ul>
          }
          {/* {selectedFilterSales== 'material' &&<ul className='history-filter'>
                  {
                    salesStocksData.map((allData)=>{
                      return(
                        <li><label className='report-label'><input className='check-report' type="checkbox" value={allData} checked={salesStocksFilterData.includes(allData) ? true :false} onChange={()=>handleChangeFilterSales(allData)}/>{allData}</label></li>
                      )
                    })
                  }
              </ul>
            } */}
          {selectedFilterSales === 'sap' && <ul className='history-filter'>
            {
              salesStocksData.map((allData, key) => {
                return (
                  <li key={key+''}><label className='report-label'><input className='check-report' type="checkbox" value={allData} checked={salesStocksFilterData.includes(allData) ? true : false} onChange={() => handleChangeFilterSales(allData)} />{allData}</label></li>
                )
              })
            }
          </ul>
          }
        </div>
        <div className='popup-bottom'>
          <button className='closebtnreport' onClick={handleClick}>Close</button>
        </div>
      </div>
    </div>
  )
}

export default SalesStockFilterPopup