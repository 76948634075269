import React, { useState, useEffect } from "react";
import SubmitButton from "../../components/common/SubmitButton";
import ResetButton from "../../components/common/ResetButton";
import "./personalDetails.scss";
import errorLogo from "../../../src/assets/images/error_outline_dp.svg";
import axios from "../../_helpers/axios";
import ErrorPopup from "../../components/common/ErrorPopup";
import { Spinner } from "react-bootstrap";
import NotificationPopup from "../../components/common/NotificationPopup";
import NotiIcon from "../../assets/images/info_noti.svg";

const PersonalDetails = () => {
  const [showError, setShowError] = useState(false);
  const [okBtn, setokbtn] = useState(false);
  const [yesBtn, setYesBtn] = useState(true);
  const [closeBtn, setCloseBtn] = useState(true);
  const UserInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
  const [isSaveMsg, setSaveMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  var saveMsg = [
    {
      notificationType: "success",
      header: "Profile Update",
      message: `Your profile has been saved successfully`,
    },
  ];

  const add = {
    mailingStreet: UserInfo
      ? UserInfo.data.customerInfo[0].account_billingStreet
      : "",
    mailingState: UserInfo
      ? UserInfo.data.customerInfo[0].account_billingState
      : "",
    mailingCity: UserInfo
      ? UserInfo.data.customerInfo[0].account_billingCity
      : "",
    mailingCountry: UserInfo
      ? UserInfo.data.customerInfo[0].account_billingCountry
      : "",
    mailingPostalCode: UserInfo
      ? UserInfo.data.customerInfo[0].account_billingPostalCode
      : "",
  };
  // const States = ["australian capital territory","new south wales","northern territory" , "queensland" , "south australia" , "tasmania" , "victoria" , "western australia"];

  let initialValues = {
    user_contactSfid: UserInfo
      ? UserInfo.data.userDetails.user_contactSfid
      : "",
    cont_salutation: "",
    cont_firstName: UserInfo ? UserInfo.data.userDetails.cont_firstName : "",
    cont_lastName: UserInfo ? UserInfo.data.userDetails.cont_lastName : "",
    mname: "",
    cont_status: "Active",
    cont_mobile: "291392139291",
    user_userName: UserInfo ? UserInfo.data.userDetails.user_userName : "",
    cont_fax: "",
    std: UserInfo ? UserInfo.data.userDetails.cont_phone : "",
    phone: UserInfo ? UserInfo.data.userDetails.cont_phone : "",
    cont_phone: "1223213232",
    cont_mailingStreet: UserInfo
      ? UserInfo.data.userDetails.cont_mailingStreet
      : "",
    cont_mailingCity: UserInfo
      ? UserInfo.data.userDetails.cont_mailingCity
      : "",
    cont_mailingState: UserInfo
      ? UserInfo.data.userDetails.cont_mailingState
      : "",
    cont_mailingCountry: UserInfo
      ? UserInfo.data.userDetails.cont_mailingCountry
      : "",
    cont_mailingPostalCode: UserInfo
      ? UserInfo.data.userDetails.cont_mailingPostalCode
      : "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  // const [submitClicked, setSubmitClicked] = useState(false);
  const [showNotiPopup, setShowNotiPopup] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorHeading, setErrorHeading] = useState("");
  const [errorMsgBlue, setErrorMsgBlue] = useState("");
  const [showOkBtn, setShowOkBtn] = useState(false);
  const [showYesBtn, setShowYesBtn] = useState(false);
  const [showNoBtn, setShowNoBtn] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  function submitAndfetchData() {
    // if(submitClicked) submitStockRequest();
    // else fetchData();

    setErrorHeading("Changes to Personal Details");
    setErrorMsg("Are you sure you want to change the details?");
    setShowError(true);
  }

  useEffect(() => {
    let contactSFID = UserInfo.data.userDetails.user_contactSfid;
    let cont_firstName = UserInfo.data.userDetails.cont_firstName;
    setFormValues({ ...formValues, ["user_contactSfid"]: contactSFID });
    setFormValues({ ...formValues, ["cont_mobile"]: "72622662362" });
    setFormValues({ ...formValues, ["cont_firstName"]: cont_firstName });
    setFormValues({
      ...formValues,
      ["cont_lastName"]: UserInfo.data.userDetails.cont_lastName,
    });
    setFormValues({
      ...formValues,
      ["cont_email"]: UserInfo.data.userDetails.cont_email,
    });
    setFormValues({
      ...formValues,
      ["cont_mailingCity"]: UserInfo.data.customerInfo[0].account_billingCity,
    });
    setFormValues({
      ...formValues,
      ["cont_mailingCountry"]:
        UserInfo.data.customerInfo[0].account_billingCountry,
    });
    setFormValues({
      ...formValues,
      ["cont_mailingState"]: UserInfo.data.customerInfo[0].account_billingState,
    });
    setFormValues({
      ...formValues,
      ["cont_mailingStreet"]:
        UserInfo.data.customerInfo[0].account_billingStreet,
    });
    setFormValues({
      ...formValues,
      ["user_userName"]: UserInfo.data.userDetails.user_userName,
    });
  }, []);

  const handleSubmit = () => {
    setShowError(false);

    let std = formValues.std;
    let phone = formValues.phone;
    let phonestd = std.concat(phone);
    setFormErrors(validate(formValues));
    const error = validate(formValues);
    setFormValues(
      { ...formValues, ["cont_phone"]: phonestd },
      console.log("formvalues", formValues)
    );
    if (Object.keys(error).length !== 0) {
      console.log("ERROR", error);
    } else {
      setIsSubmit(true);
      console.log("formvalues", formValues);
      postData("usermanagement/updateuser", formValues);
    }
  };
  const handleReset = () => {
    setFormValues(initialValues);
    if (unsavedChanges) {
      setErrorHeading("Reset filters");
      setErrorMsg("All your selected options will be cleared.");
      setErrorMsgBlue("");
      setShowOkBtn(false);
      setShowYesBtn(true);
      setShowNoBtn(true);
      setModalShow(true);
      setShowNotiPopup(false);
    }
  };
  // const handleChangeDropdown =(e,type)=> {
  //   const {value } = e.target;
  //   setFormValues({ ...formValues, [type]: value });
  // }

  // useEffect(() => {
  //   console.log(formErrors);
  //   if (Object.keys(formErrors).length === 0 && isSubmit) {
  //     console.log(formValues);
  //   }
  // }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[A-Za-z]+$/;
    // const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.cont_firstName) {
      errors.cont_firstName = "First Name is required!";
    } else if (!regex.test(values.cont_firstName)) {
      errors.cont_firstName = "Incorrect format";
    }
    if (!values.cont_lastName) {
      errors.cont_lastName = "Last Name is required!";
    } else if (!regex.test(values.cont_lastName)) {
      errors.cont_lastName = "Incorrect format";
    }
    // if (!values.email) {
    //   errors.email = "Email id is required!";
    // } else if (!regexEmail.test(values.email)) {
    //   errors.email = "Email Incorrect format";
    // }
    if (!values.phone) {
      errors.phone = "Phone Number is required!";
    }

    return errors;
  };

  const closeErrorPopup = () => {
    setShowError(false);
    setShowNoBtn(false);
    setShowOkBtn(false);
    setShowYesBtn(false);
    setokbtn(false);
    setModalShow(false);
    setYesBtn(true);
    setCloseBtn(true);
  };
  async function postData(url = "", data = {}) {
    setLoading(true);
    await axios
      .post(url, formValues)
      .then((res) => {
        setShowNotiPopup(true);
        setSaveMsg(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setErrorHeading("Error While Submit");
        setErrorMsg("There is something went wrong at server side.");
        setShowError(true);
        setCloseBtn(false);
        setYesBtn(false);
        setokbtn(true);
      });
  }
  return (
    <div>
      {/* <Container> */}
      {isSaveMsg && (
        <NotificationPopup
          showNotiPopup={showNotiPopup}
          notifications={saveMsg}
          className="noti-popup mb-3"
        />
      )}
      {loading && (
        <div className="centeredSpin">
          {" "}
          <Spinner animation="border" role="status"></Spinner>{" "}
        </div>
      )}
      <form className="personal-details-form" onSubmit={handleSubmit}>
        <div className="personal-details">
          <span className="detail-label required">
            <label htmlFor="cont_firstName" className="pdetail-label">
              First Name{" "}
            </label>
            :
          </span>
          <span className="detail-input">
            <input
              type="text"
              className={
                "form-control pdetail-form " +
                (formErrors.cont_firstName ? "error-form-input" : "")
              }
              id="cont_firstName"
              name="cont_firstName"
              value={formValues.cont_firstName}
              onChange={handleChange}
              placeholder="Enter your first name here"
              autoComplete="off"
              required
            />
          </span>
          <span className="WarningMsg pdetal-Error">
            {formErrors.cont_firstName ? (
              <img src={errorLogo} alt="error icon" className="error-logo" />
            ) : (
              ""
            )}
            {formErrors.cont_firstName}
          </span>
        </div>
        <div className="personal-details">
          <span className="detail-label required">
            <label htmlFor="cont_lastName" className="pdetail-label">
              Last Name{" "}
            </label>
            :
          </span>
          <span className="detail-input">
            <input
              type="text"
              className={
                "form-control pdetail-form " +
                (formErrors.cont_lastName ? "error-form-input" : "")
              }
              id="cont_lastName"
              name="cont_lastName"
              placeholder="Enter your last name here"
              onChange={handleChange}
              value={formValues.cont_lastName}
              autoComplete="off"
              required
            />
          </span>
          <span className="WarningMsg pdetal-Error">
            {formErrors.cont_lastName ? (
              <img src={errorLogo} alt="error icon" className="error-logo" />
            ) : (
              ""
            )}
            {formErrors.cont_lastName}
          </span>
        </div>
        {/* <div className='personal-details'>
          <span className="detail-label"><label htmlFor="mname" className='pdetail-label'>Middle Name : </label></span>
          <span className="detail-input"><input type="text" className="form-control pdetail-form" id="mname" name='mname' placeholder="Enter your middle name here" onChange={handleChange} value={formValues.mname} autoComplete="off" /></span>
        </div> */}
        <div className="personal-details">
          <span className="detail-label">
            <label htmlFor="user_userName" className="pdetail-label">
              Username{" "}
              <img
                width="20"
                height="20"
                src={NotiIcon}
                alt="error icon"
                title="To change email , Please contact bayer admin"
              />{" "}
              :{" "}
            </label>
          </span>
          <span className="detail-input">
            <input
              type="text"
              disabled={true}
              className="form-control pdetail-form"
              id="user_userName"
              name="user_userName"
              placeholder="Enter your username  here"
              onChange={handleChange}
              value={formValues.user_userName}
            />
          </span>
        </div>
        {/* <div className='personal-details'>
          <span className="detail-label required"><label htmlFor="email" className='pdetail-label'>Email ID </label>:</span>
          <span className="detail-input"><input type="text" className={'form-control pdetail-form ' + (formErrors.email ? 'error-form-input':'')} id="email" name='email' placeholder="Enter your email id  here"   value={formValues.email} autoComplete="off" readonly/></span>
          <span className='WarningMsg pdetal-Error'>{formErrors.email ? <img src={errorLogo} className="error-logo" />:''}{formErrors.email}</span>

        </div> */}
        <div className="personal-details">
          <span className="detail-label required">
            <label htmlFor="phone" className="pdetail-label">
              Phone No{" "}
            </label>
            :
          </span>

          <span className="detail-input mobile">
            <input
              type="text"
              className={
                "form-control pdetail-form " +
                (formErrors.phone ? "error-form-input" : "")
              }
              id="phone"
              name="phone"
              placeholder="Enter your phone no here"
              onChange={handleChange}
              value={formValues.phone}
              autoComplete="off"
              required
            />
          </span>
          <span className="WarningMsg pdetal-Error">
            {formErrors.phone ? (
              <img src={errorLogo} alt="error icon" className="error-logo" />
            ) : (
              ""
            )}
            {formErrors.phone}
          </span>
        </div>
        <div className="personal-details">
          <span className="detail-label">
            <label htmlFor="cont_mailingStreet" className="pdetail-label">
              Mailing Address{" "}
            </label>
            :
          </span>
          <span style={{ width: "50%", marginBottom: 5 }}>
            <p className="detail-input personalDtl">{add.mailingStreet} , </p>
            <p className="detail-input personalDtl">{add.mailingCity} ,</p>
            <p className="detail-input personalDtl">{add.mailingState} ,</p>
            <p className="detail-input personalDtl">
              {add.mailingPostalCode} ,
            </p>
            <p className="detail-input personalDtl">{add.mailingCountry}</p>
          </span>
        </div>
      </form>
      <div
        className="account-btn"
        style={{
          paddingTop: "20px",
          marginBottom: "50px",
          justifyContent: "left",
        }}
      >
        <div>
          <ResetButton onClick={() => handleReset()} title="RESET" />
        </div>
        <div style={{ width: "22%", marginLeft: "12%" }}>
          <SubmitButton
            onClick={() => submitAndfetchData()}
            title="SAVE CHANGES"
          />
        </div>
      </div>
      {/* </Container> */}
      <ErrorPopup
        show={showError}
        okbtn={okBtn}
        yesbtn={yesBtn}
        nobtn={closeBtn}
        errormsg={errorMsg}
        heading={errorHeading}
        infoSign={true}
        onhide={() => closeErrorPopup()}
        onclickno={() => setShowError(false)}
        onclickyes={() => handleSubmit()}
      />
    </div>
  );
};

export default PersonalDetails;
