import React from 'react';
import Button from "@mui/material/Button";
import ArrowIcon from "../../assets/images/btn_rt_arrow.svg";

const SubmitButtonNew = (props: any) => {
  return (
    <Button
        onClick={() => props.onClick()}
        fullWidth={props.fullWidth}
        variant="contained"
        disabled = {props.isButtonDisabled}
        sx={{
            backgroundColor: "#624863",
            textTransform: "none",
            maxWidth: "420px",
            padding: "8px 0px",
            borderRadius: "0px",
            "&:hover": {
                backgroundColor: "#624863",
                p: {
                    paddingLeft: "13px",
                },
            },
        }}
        className={"btn-right submit-new"}
    >
        <p className="submit-btn-title ">{props.title}</p>

        <img src={ArrowIcon} className={"arrow-submit arrow-submit-new"}alt="arrow" />
    </Button>
  )
}

export default SubmitButtonNew