import React, { useState, useEffect } from "react";
import SubmitButton from "../../components/common/SubmitButton";
import ResetButton from "../../components/common/ResetButton";
import "./personalDetails.scss";
import errorLogo from "../../../src/assets/images/error_outline_dp.svg";
import axios from "../../_helpers/axios";
import ErrorPopup from "../../components/common/ErrorPopup";
import { Spinner } from "react-bootstrap";
import NotificationPopup from "../../components/common/NotificationPopup";
import Dropdown from "../../assets/images/Dropdown.png";
import DropdownBlue from "../../assets/images/dropdown_blue.png";
import "./addNewUser.scss";
import SearchIcon from "../../assets/images/search_icon.svg";

const AddNewUser = () => {
  const UserInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
  const SelectedAgency = JSON.parse(
    localStorage.getItem("SelectedAgency") || "[]"
  );
  const [accountName, setAccontName] = useState(
    SelectedAgency.account_sapAccountNumber + "-" + SelectedAgency.agencyName
  );
  const [showError, setShowError] = useState(false);
  const [okBtn, setokbtn] = useState(false);
  const [yesBtn, setYesBtn] = useState(true);
  const [closeBtn, setCloseBtn] = useState(true);

  const [isSaveMsg, setSaveMsg] = useState(false);
  const [isWarMsg, setWarMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const getRolesUrl =
    "https://anz-cropcentre-api-dev.herokuapp.com/services/api/userroles/roles";
  const submitData = {
    userData: {
      userDetails: {},
      userRole: {
        new: [],
        existing: [],
      },
      customerInfo: {
        new: [],
        existing: [],
      },
    },
  };

  var saveMsg = [
    {
      notificationType: "success",
      header:
        "User created sucessfully,post approval from Bayer, user will receieve email to login into the system",
      message: "",
    },
  ];
  var warMsg = [
    {
      notificationType: "warning",
      header:
        "User already exists in the System.Please contact the Administrator",
      message: ``,
    },
  ];

  // const States = ["australian capital territory", "new south wales", "northern territory", "queensland", "south australia", "tasmania", "victoria", "western australia"];

  let initialValues = {
    cont_firstName: "",
    cont_lastName: "",
    cont_email: "",
    cont_phone: "",
    cont_status: "Active",
    cont_sfid: null,
    cont_mailingStreet: UserInfo
      ? UserInfo.data.customerInfo[0].account_billingStreet
      : "",
    cont_mailingState: UserInfo
      ? UserInfo.data.customerInfo[0].account_billingState
      : "",
    cont_mailingCity: UserInfo
      ? UserInfo.data.customerInfo[0].account_billingCity
      : "",
    cont_mailingCountry: UserInfo
      ? UserInfo.data.customerInfo[0].account_billingCountry
      : "",
    cont_mailingPostalCode: UserInfo
      ? UserInfo.data.customerInfo[0].account_billingPostalCode
      : "",
  };
  const add = {
    mailingStreet: UserInfo
      ? UserInfo.data.customerInfo[0].account_billingStreet
      : "",
    mailingState: UserInfo
      ? UserInfo.data.customerInfo[0].account_billingState
      : "",
    mailingCity: UserInfo
      ? UserInfo.data.customerInfo[0].account_billingCity
      : "",
    mailingCountry: UserInfo
      ? UserInfo.data.customerInfo[0].account_billingCountry
      : "",
    mailingPostalCode: UserInfo
      ? UserInfo.data.customerInfo[0].account_billingPostalCode
      : "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  // const [submitClicked, setSubmitClicked] = useState(false);
  const [showNotiPopup, setShowNotiPopup] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorHeading, setErrorHeading] = useState("");
  const [errorMsgBlue, setErrorMsgBlue] = useState("");
  const [showOkBtn, setShowOkBtn] = useState(false);
  const [showYesBtn, setShowYesBtn] = useState(false);
  const [showNoBtn, setShowNoBtn] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  // const [showSaluations, setShowSaluations] = useState(false);
  // const [saluation, setSaluation] = useState(null);
  const [showRoles, setShowRoles] = useState(false);
  const [showAccounts, setShowAccounts] = useState(false);
  const [searchRole, setSearchRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [selectedAccount, setSelectedAccount] = useState([]);

  // const accounts = UserInfo.data.customerInfo;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleRoleChange = (id, name, event) => {
    if (event.target.checked === false) {
      if (selectedRole.hasOwnProperty(id)) {
        delete selectedRole[id];
        setSelectedRole({ ...selectedRole });
      }
    } else setSelectedRole({ ...selectedRole, [id]: name });
  };

  // const handleAccountChange = (id, name , e ) => {

  //   if(e.target.checked==true){
  //     const found = selectedAccount.some(el => el.acc_Name === name);
  //       if(!found)
  //       {
  //         setSelectedAccount([...selectedAccount,{acc_Name:name,acc_SAPId:id}]);
  //       }

  //   }else{
  //      let letNewAr = selectedAccount.filter(ar => ar.acc_SAPId!=id);
  //      setSelectedAccount(letNewAr);
  //   }

  // };

  // const removeAccounts = (id)=> {
  //   let letNewAr = selectedAccount.filter(ar => ar.acc_SAPId!=id);
  //   setSelectedAccount(letNewAr);
  // }
  const removeRoles = (key) => {
    if (selectedRole.hasOwnProperty(key)) {
      delete selectedRole[key];

      setSelectedRole({ ...selectedRole });
    }
  };

  function submitAndfetchData() {
    // if(submitClicked) submitStockRequest();
    // else fetchData();

    setErrorHeading("Changes to Personal Details");
    setErrorMsg("Are you sure you want to change the details?");
    setShowError(true);
  }

  useEffect(() => {
    axios
      .get(getRolesUrl)
      .then((res) => {
        setRoles(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handleSubmit = () => {
    let formObj = {};
    let data = {};
    data.userData = {};
    // if (unsavedChanges){
    //       setSubmitClicked(true);
    //       setErrorHeading('Changes to Personal Details');
    //       setErrorMsg('Are you sure you want to submit the request?');
    //       setErrorMsgBlue('');
    //       setShowOkBtn(false);
    //       setShowYesBtn(true);
    //       setShowNoBtn(true);
    //       setModalShow(true);
    //   }

    setShowError(false);
    setFormErrors(validate(formValues));
    const error = validate(formValues);

    let roles;
    let accounts = [
      {
        acc_Name: SelectedAgency.agencyName,
        acc_SAPId: SelectedAgency.account_sapAccountNumber,
      },
    ];
    //accounts.push(SelectedAgency.account_sfid);

    formObj = {
      ...formValues,
      cont_accSfid: SelectedAgency.account_sfid,
    };

    submitData.userData.userDetails = formObj;
    roles = Object.keys(selectedRole).map((key, value) => {
      return selectedRole[key];
    });
    //let accounts = Object.keys(selectedAccount).map((key, value)=> {return [{acc_Name:key,acc_SAPId:value}]});
    submitData.userData.userRole.new = roles;
    submitData.userData.customerInfo = accounts;
    submitData.userData.salesArea_salesGroup =
      SelectedAgency.salesArea_salesGroup;
    //submitData.userDetails.cont_sfid = '';
    submitData.userData.roleChanged = true;
    submitData.userData.deactivatePortalUser = false;

    if (Object.keys(error).length !== 0) {
    } else {
      setIsSubmit(true);
      postData("usermanagement/createuser", submitData);
    }
  };
  const handleReset = () => {
    setFormValues(initialValues);
    setSelectedRole({});
    setSelectedAccount([]);
    setShowAccounts(false);
    setShowRoles(false);
    if (unsavedChanges) {
      setErrorHeading("Reset filters");
      setErrorMsg("All your selected options will be cleared.");
      setErrorMsgBlue("");
      setShowOkBtn(false);
      setShowYesBtn(true);
      setShowNoBtn(true);
      setModalShow(true);
      setShowNotiPopup(false);
    }
  };
  // const handleChangeDropdown = (e, type) => {
  //   const { value } = e.target;
  //   setFormValues({ ...formValues, [type]: value });
  // }

  const validate = (values) => {
    const errors = {};
    const regex = /^[A-Za-z]+$/;
    // const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.cont_firstName) {
      errors.cont_firstName = "First Name is required!";
    } else if (!regex.test(values.cont_firstName)) {
      errors.cont_firstName = "Incorrect format";
    }
    if (!values.cont_lastName) {
      errors.cont_lastName = "Last Name is required!";
    } else if (!regex.test(values.cont_lastName)) {
      errors.cont_lastName = "Incorrect format";
    }

    if (!values.cont_phone) {
      errors.phone = "Phone Number is required!";
    } else if (!/^[0-9]+$/.test(values.cont_phone)) {
      errors.phone = "Phone should be in number";
    }
    // if (!saluation) {
    //   errors.cont_salutation = "salutation is required!";
    // }

    return errors;
  };

  const addNewUser = () => {
    if (isSaveMsg) {
      setSaveMsg(!isSaveMsg);
    } else if (isWarMsg) {
      setWarMsg(!isWarMsg);
    }
  };

  const closeErrorPopup = () => {
    setShowError(false);
    setShowNoBtn(false);
    setShowOkBtn(false);
    setShowYesBtn(false);
    setokbtn(false);
    setModalShow(false);
    setYesBtn(true);
    setCloseBtn(true);
  };
  async function postData(url = "", data = {}) {
    setLoading(true);
    await axios
      .post(url, data)
      .then((res) => {
        setShowNotiPopup(true);
        setLoading(false);
        if (res.data.status === 208) {
          setSaveMsg(false);
          setWarMsg(true);
        } else if (res.status === 200) {
          setSaveMsg(true);
          // setHideForm(false)
          handleReset();
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setErrorHeading("Error While Submit");
        setErrorMsg("There is something went wrong at server side.");
        setShowError(true);
        setCloseBtn(false);
        setYesBtn(false);
        setokbtn(true);
      });
  }
  return (
    <div style={{ marginBottom: 15 }}>
      {isSaveMsg && (
        <NotificationPopup
          showNotiPopup={showNotiPopup}
          notifications={saveMsg}
          className="noti-popup mb-3"
        />
      )}
      {isWarMsg && (
        <NotificationPopup
          showNotiPopup={showNotiPopup}
          notifications={warMsg}
          className="noti-popup mb-3"
        />
      )}
      {isSaveMsg || isWarMsg ? (
        <div style={{ marginLeft: "10%", marginTop: "2%" }}>
          <SubmitButton onClick={() => addNewUser()} title="ADD NEW USER" />
        </div>
      ) : (
        <div className="addNewUser">
          {loading && (
            <div className="centeredSpin">
              {" "}
              <Spinner animation="border" role="status"></Spinner>{" "}
            </div>
          )}
          <form className="add-new-user-form" onSubmit={handleSubmit}>
            {/* Saluation */}
            {/* <div className='add-new-user'>
            <span className="add-new-label required">
              <label htmlFor="addNew_saluation" className='padd-new-label'>Saluation </label>:</span>
            <span className={"add-new-input"+ (showSaluations ? " input-dropdown-active" :" input-dropdown")}>
              <span className="dropdown-input">
                <input type="text" id="addNew_saluation" name="addNew_saluation" className='form-control' placeholder='Select Saluation' value={saluation}/>
              </span>
              <button className="dropdown-input-btn" onClick={() => setShowSaluations(!showSaluations)}><img src={showSaluations ? DropdownBlue : Dropdown} /></button>
            </span>
            <span className='WarningMsg padd-new-Error'>{formErrors.cont_salutation ? <img src={errorLogo} className="error-logo" /> : ''}{formErrors.cont_salutation}</span>
          </div>
          <div className='add-new-user'>
            <span className="add-new-label"></span>
            {showSaluations && <div className='radioOptionsDiv add-new-input' >
                <label for="Mr."><input type="radio" value="Mr." id="Mr." name="saluation" onChange={() => setSaluation('Mr.')} /> Mr.</label>
                <label for="Miss."><input type="radio" value="Miss." id="" name="saluation" onChange={() => setSaluation('Miss.')} /> Miss.</label>
                <label for="Mrs."><input type="radio" value="Mrs." id="" name="saluation" onChange={() => setSaluation('Mrs.')} /> Mrs.</label>
                
              </div>}
          </div> */}
            {/* First Name */}
            <div className="add-new-user">
              <span className="add-new-label required">
                <label htmlFor="cont_firstName" className="padd-new-label">
                  First Name{" "}
                </label>
                :
              </span>
              <span className="add-new-input">
                <input
                  type="text"
                  className={
                    "form-control padd-new-form " +
                    (formErrors.cont_firstName ? "error-form-input" : "")
                  }
                  id="cont_firstName"
                  name="cont_firstName"
                  value={formValues.cont_firstName}
                  onChange={handleChange}
                  placeholder="Enter user's first name here"
                  autoComplete="off"
                  required
                />
              </span>
              <span className="WarningMsg padd-new-Error">
                {formErrors.cont_firstName ? (
                  <img
                    src={errorLogo}
                    alt="error icon"
                    className="error-logo"
                  />
                ) : (
                  ""
                )}
                {formErrors.cont_firstName}
              </span>
            </div>
            {/* Last Name */}
            <div className="add-new-user">
              <span className="add-new-label required">
                <label htmlFor="cont_lastName" className="padd-new-label">
                  Last Name{" "}
                </label>
                :
              </span>
              <span className="add-new-input">
                <input
                  type="text"
                  className={
                    "form-control padd-new-form " +
                    (formErrors.cont_lastName ? "error-form-input" : "")
                  }
                  id="cont_lastName"
                  name="cont_lastName"
                  placeholder="Enter user's last name here"
                  onChange={handleChange}
                  value={formValues.cont_lastName}
                  autoComplete="off"
                  required
                />
              </span>
              <span className="WarningMsg padd-new-Error">
                {formErrors.cont_lastName ? (
                  <img
                    src={errorLogo}
                    alt="error icon"
                    className="error-logo"
                  />
                ) : (
                  ""
                )}
                {formErrors.cont_lastName}
              </span>
            </div>
            <div className="add-new-user">
              <span className="add-new-label required">
                <label htmlFor="cont_email" className="padd-new-label">
                  Email ID :{" "}
                </label>
              </span>
              <span className="add-new-input">
                <input
                  type="text"
                  className={
                    "form-control padd-new-form " +
                    (formErrors.cont_email ? "error-form-input" : "")
                  }
                  id="cont_email"
                  name="cont_email"
                  placeholder="Enter User's Email ID here"
                  onChange={handleChange}
                  value={formValues.cont_email}
                />
              </span>
              <span className="WarningMsg padd-new-Error">
                {formErrors.cont_email ? (
                  <img
                    src={errorLogo}
                    alt="error icon"
                    className="error-logo"
                  />
                ) : (
                  ""
                )}
                {formErrors.cont_email}
              </span>
            </div>

            {/* Roles */}
            <div className="add-new-user">
              <span className="add-new-label required">
                <label htmlFor="addNew_roles" className="padd-new-label">
                  Role{" "}
                </label>
                :
              </span>
              <span
                className={
                  "add-new-input" +
                  (showRoles ? " input-dropdown-active" : " input-dropdown")
                }
              >
                <span className="dropdown-input">
                  <input
                    type="text"
                    id="addNew_roles"
                    name="addNew_roles"
                    className="form-control"
                    placeholder="Search Roles"
                    onChange={(e) => setSearchRole(e.target.value)}
                  />
                </span>
                <span className="search-btn" onClick={() => setShowRoles(true)}>
                  <img src={SearchIcon} alt="error icon" />
                </span>
                <span
                  className="dropdown-input-btn"
                  onClick={() => setShowRoles(!showRoles)}
                >
                  <img
                    alt="error icon"
                    src={showRoles ? DropdownBlue : Dropdown}
                  />
                </span>
              </span>
            </div>
            {/* Roles Dropdown */}
            <div className="add-new-user">
              <span className="add-new-label"></span>
              {showRoles && (
                <div className="checkboxOptionsDiv add-new-input">
                  {roles
                    .filter(function (item) {
                      return item.role_name
                        .toLowerCase()
                        .includes(searchRole.toLowerCase());
                    })
                    .map((role) => (
                      <label for={role.role_sfid}>
                        <input
                          className=""
                          type="checkbox"
                          checked={
                            selectedRole.hasOwnProperty(role.role_sfid) && true
                          }
                          id={role.role_sfid}
                          name={role.role_sfid}
                          onClick={(e) =>
                            handleRoleChange(role.role_sfid, role.role_name, e)
                          }
                        />
                        &nbsp;{role.role_name}
                      </label>
                    ))}
                  {/* <label for="Mr."><input type="checkbox" value="Mr." id="Mr." name="saluation" onChange={() => setSaluation('Mr.')} /> Mr.</label>
                <label for="Miss."><input type="checkbox" value="Miss." id="" name="saluation" onChange={() => setSaluation('Miss.')} /> Miss.</label>
                <label for="Mrs."><input type="checkbox" value="Mrs." id="" name="saluation" onChange={() => setSaluation('Mrs.')} /> Mrs.</label>
                <label for="Dr."><input type="checkbox" value="Dr." id="" name="saluation" onChange={() => setSaluation('Dr.')} /> Dr.</label> */}
                </div>
              )}
              {showRoles && (
                <span className="selectedRoles">
                  {Object.keys(selectedRole).map((key, value) => {
                    return (
                      <div className="rolesSpan">
                        {selectedRole[key]}{" "}
                        <span
                          className="closeRoleBtn"
                          onClick={() => removeRoles(key)}
                        >
                          X
                        </span>
                      </div>
                    );
                  })}
                </span>
              )}
            </div>
            {/* Accounts */}

            <div className="add-new-user">
              <span className="add-new-label">
                <label htmlFor="addNew_roles" className="padd-new-label">
                  Account{" "}
                </label>
                :
              </span>

              <div className="add-new-input">
                {/* {accounts.map((agency) =>
                    <p>{agency.account_sapAccountNumber} - {agency.account_name}</p>
                )} */}

                <span className="add-new-input">
                  <p className="detail-input personalDtl">{accountName}</p>
                </span>
              </div>
            </div>
            {/* Temprary commented 
          <div className='add-new-user'>
            <span className="add-new-label">
              <label htmlFor="addNew_roles" className='padd-new-label'>Accounts </label>:</span>
            <span className={"add-new-input"+ (showAccounts ? " input-dropdown-active" :" input-dropdown")}>
              <span className="dropdown-input">
                <input type="text" id="addNew_roles" name="addNew_roles" className='form-control' placeholder='Search Accounts' onChange={e => setSearchRole(e.target.value)}/>
              </span>
              <button className="search-btn"><img src={SearchIcon} /></button> 
              <button className="dropdown-input-btn" onClick={() => setShowAccounts(!showAccounts)}><img src={showAccounts ? DropdownBlue : Dropdown} /></button>
            </span>
            
          </div>
          <div className='add-new-user'>
            <span className="add-new-label"></span>
            {showAccounts && <div className='checkboxOptionsDiv add-new-input' >
            {accounts.filter(function (item) {
                            return item.account_name.toLowerCase().includes(searchRole.toLowerCase());
                        }).map((agency) =>
                  <label for={agency.account_sapAccountNumber}>
                    <input className='' type="checkbox" checked={selectedAccount.some(e1=> e1.acc_SAPId==agency.account_sapAccountNumber) && true} id={agency.account_sapAccountNumber} name={agency.account_sapAccountNumber} onClick={(e) => handleAccountChange(agency.account_sapAccountNumber,agency.account_name ,e)} /> 
                    &nbsp; {agency.account_sapAccountNumber} - {agency.account_name}</label>
                )}
                
              </div>}
              {showAccounts &&
              <span className='selectedRoles'>
              {selectedAccount.map((acc) => { 
             return <div className='rolesSpan' >{acc.acc_Name} <span className="closeRoleBtn" onClick={()=>removeAccounts(acc.acc_SAPId)}>X</span></div>
             })}
              </span>
              }
          </div> */}

            {/* Phone No. */}
            <div className="add-new-user">
              <span className="add-new-label required">
                <label htmlFor="phone" className="padd-new-label">
                  Phone No{" "}
                </label>
                :
              </span>

              <span className="add-new-input mobile">
                <input
                  type="text"
                  className={
                    "form-control padd-new-form " +
                    (formErrors.phone ? "error-form-input" : "")
                  }
                  id="cont_phone"
                  name="cont_phone"
                  placeholder="xxxx-xxxx"
                  onChange={handleChange}
                  value={formValues.phone}
                  autoComplete="off"
                  required
                />
              </span>
              <span className="WarningMsg padd-new-Error">
                {formErrors.phone ? (
                  <img
                    src={errorLogo}
                    alt="error icon"
                    className="error-logo"
                  />
                ) : (
                  ""
                )}
                {formErrors.phone}
              </span>
            </div>
            {/* Fax No. */}
            {/* <div className='add-new-user'>
            <span className="add-new-label"><label htmlFor="fax" className='padd-new-label'>Fax No </label>:</span>
            <span className="add-new-input std-code"><input type="text" className={'form-control padd-new-form ' + (formErrors.phone ? 'error-form-input' : '')} id="faxstd" name='faxstd' placeholder="+xx" onChange={handleChange} value={formValues.faxstd} autoComplete="off" required /></span>
            <span className="add-new-input mobile"><input type="text" className={'form-control padd-new-form ' + (formErrors.phone ? 'error-form-input' : '')} id="fax" name='fax' placeholder="xxxx-xxxx" onChange={handleChange} value={formValues.fax} autoComplete="off" required /></span>
            <span className='WarningMsg padd-new-Error'>{formErrors.phone ? <img src={errorLogo} className="error-logo" /> : ''}{formErrors.phone}</span>
          </div> */}
            {/* Mobile No. */}
            {/* <div className='add-new-user'>
            <span className="add-new-label"><label htmlFor="mobile" className='padd-new-label'>Mobile No </label>:</span>
            <span className="add-new-input std-code"><input type="text" className={'form-control padd-new-form ' + (formErrors.phone ? 'error-form-input' : '')} id="mobilestd" name='mobilestd' placeholder="+xx" onChange={handleChange} value={formValues.mobilestd} autoComplete="off" required /></span>
            <span className="add-new-input mobile"><input type="text" className={'form-control padd-new-form ' + (formErrors.phone ? 'error-form-input' : '')} id="mobile" name='mobile' placeholder="xxxx-xxxx" onChange={handleChange} value={formValues.mobile} autoComplete="off" required /></span>
            <span className='WarningMsg padd-new-Error'>{formErrors.phone ? <img src={errorLogo} className="error-logo" /> : ''}{formErrors.phone}</span>
          </div> */}
            {/* mailing Address. */}
            <div className="add-new-user">
              <span className="add-new-label">
                <label htmlFor="cont_mailingStreet" className="padd-new-label">
                  Mailing Address{" "}
                </label>
                :
              </span>

              <span>
                <p className="detail-input personalDtl">
                  {add.mailingStreet} ,{" "}
                </p>
                <p className="detail-input personalDtl">{add.mailingCity} ,</p>
                <p className="detail-input personalDtl">{add.mailingState} ,</p>
                <p className="detail-input personalDtl">
                  {add.mailingPostalCode} ,
                </p>
                <p className="detail-input personalDtl">{add.mailingCountry}</p>
              </span>
              {/* <input type="text" readOnly={true} className="form-control padd-new-form"  id="cont_mailingCity" name="cont_mailingCity" value={add.mailingCity} onChange={handleChange}  autoComplete="off"  />
               <input type="text" readOnly={true} className="form-control padd-new-form"  id="cont_mailingPostalCode" name="cont_mailingPostalCode" value={add.mailingPostalCode} onChange={handleChange}  autoComplete="off"  />
             
              <input readOnly={true} type="text" className='form-control padd-new-form' id="cont_mailingStreet" name='cont_mailingState' value={add.mailingState} autoComplete="off" />
              <input readOnly={true} type="text" className='form-control padd-new-form' id="cont_mailingStreet" name='cont_mailingState' value={add.mailingCountry}  autoComplete="off" />
              */}

              <span className="WarningMsg padd-new-Error">
                {formErrors.address ? (
                  <img
                    src={errorLogo}
                    alt="error icon"
                    className="error-logo"
                  />
                ) : (
                  ""
                )}
                {formErrors.address}
              </span>
            </div>
          </form>
          <div
            className="account-btn"
            style={{ paddingTop: "20px", justifyContent: "left" }}
          >
            <div>
              <ResetButton onClick={() => handleReset()} title="RESET" />
            </div>
            <div style={{ width: "22%", marginLeft: "12%" }}>
              <SubmitButton
                onClick={() => submitAndfetchData()}
                title="ADD NEW USER"
              />
            </div>
          </div>
        </div>
      )}
      <ErrorPopup
        show={showError}
        okbtn={okBtn}
        yesbtn={yesBtn}
        nobtn={closeBtn}
        errormsg={errorMsg}
        heading={errorHeading}
        infoSign={true}
        onhide={() => closeErrorPopup()}
        onclickno={() => setShowError(false)}
        onclickyes={() => handleSubmit()}
      />
    </div>
  );
};

export default AddNewUser;
