import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import { Fragment } from "react";
const StyledLabel = styled(InputLabel)(
	({ theme }) => `
	&.Mui-focused{
		color:#00bcff
	},
	&.MuiInputLabel-root.Mui-error{
		color:#d0021b !important
	}
	  
  `
);
const StyledInputElement = styled(Input)(
	({ theme }) => `
	
	&.MuiInput-root:after {
		border-bottom: 2px solid #00bcff;
	},
	&.MuiInput-root.Mui-error:after {
		border-bottom-color: #d0021b !important;
	}
  `
);

type InputTypes = {
	name: string;
	label?: string | JSX.Element;
	placeHolder?: any;
	error?: any;
	onChange?: any;
	onKeyPress?: any;
	onKeyUp?: any;
	width?: string;
	disabled?: boolean;
	type?: string;
	isMultiline?: boolean;
	id?: string;
	value?:String;
	endAdornment?: any;
	ref?:any;
	handleChange?: any;
	onKeyDown?: any;
};
const CustomInput = (props: InputTypes) => {
	const { placeHolder, label, error, isMultiline, type, id, endAdornment, onChange, name, onKeyDown , value , ref} = props;
	return (
		<Fragment>
			<StyledLabel htmlFor={id} error={error}>
				{label}
			</StyledLabel>
			<StyledInputElement
				id={id}
				ref={ref}
				type={type}
				value={value}
				placeholder={placeHolder}
				multiline={isMultiline}
				onChange={onChange}
				endAdornment={endAdornment}
				name={name}
				onKeyDown={onKeyDown}
			/>
		</Fragment>
	);
};

export default CustomInput;
