import React from 'react';
import Button from "@mui/material/Button";
import ArrowIcon from "../../assets/images/btn_rt_arrow.svg";
import RtLayout from "../../assets/images/rt_layout_submit.svg";

const SubmitButton = (props: any) => {
  return (
    <Button
        onClick={() => props.onClick()}
        fullWidth={props.fullWidth}
        variant="contained"
        disabled = {props.isButtonDisabled}
        sx={{
            backgroundColor: "#624863 !important",
            textTransform: "none",
            maxWidth: "420px",
            padding: "8px 0px",
            borderRadius: "0px",
            "&:hover": {
                backgroundColor: "#624863 !important",
                p: {
                    paddingLeft: "15px",
                },
            },
        }}
        className={"btn-right btn-req submit-btn-dimensions "+ props.customClasssName}
    >
        <p className="submit-btn-title" style={{ paddingRight: "60px",color: 'white !important' }}>{props.title}</p>
        <span>
            <img src={ArrowIcon} className="arrow-i stock-arrow-i" alt="arrow" />
            <img src={RtLayout} className="rt-layout stock-rt-layout" alt="layout" />
        </span>
    </Button>
  )
}

export default SubmitButton