import React, {useEffect, useState} from 'react'
import { Container, Row } from 'react-bootstrap'
import ErrorPopup from '../../../components/common/ErrorPopup';
import GoodsMovementData from './GoodsMovementData';
import '../../../assets/scss/goodsmovement.scss';
import DownArrow from '../../../assets/images/expand_black_arrow.svg';
import UpArrow from '../../../assets/images/collapse_black_arrow.svg';
import { useDispatch, useSelector , RootStateOrAny} from 'react-redux';
import { runTourGoods } from "../../../redux/actions/stockManagementActions/goodsMovmtSITFBActions";
// import { getSITFOData } from "../../../redux/actions/stockManagementActions/goodsMovmtSITFOActions";
import NotificationPopup from '../../../components/common/NotificationPopup';
import { Steps } from 'intro.js-react';
import '../../../assets/scss/tourguide.scss'
import "intro.js/introjs.css";

const GoodsMovement = () => {

    const dispatch  = useDispatch(); 
    // const isrender = useSelector((state: RootStateOrAny) => state.user.renderCom);
    // const [renderCom , setrender]=useState(isrender);
    const SITFBData = useSelector((state: RootStateOrAny) => state.GoodsMovementSITFB);
    const SITFOData = useSelector((state: RootStateOrAny) => state.GoodsMovementSITFO);
    const SITFBErr = useSelector((state: RootStateOrAny) => state.GoodsMovementSITFB.errors);
    const SITFOErr = useSelector((state: RootStateOrAny) => state.GoodsMovementSITFO.errors);
    // const tourRunGoods = useSelector((state:RootStateOrAny) => state.Sales.runTourSales)

    // const [selectedTab, setSelectedTab] = useState('SITFB');
    const [expandSITFB, setExpandSITFB] = useState(true);
    const [expandSITFO, setExpandSITFO] = useState(true);

    // const [switchEnable, setSwitchEnable] = useState(true);
    var arr: {}[] = [];

    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorHeading, setErrorHeading] = useState('');
    // const [errorMsgBlue, setErrorMsgBlue] = useState('');
    const [showNotiPopup, setShowNotiPopup] = useState(false);
    const [notifications, setNotifications] = useState(arr);
    // const [goodsMovementVisited, setgoodsMovementVisited] = useState('')
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [initialStep, setInitialStep] = useState(0);
    // const [addStock, setStatus] = useState(true);
    const tourRunGoods = useSelector((state:RootStateOrAny) => state.TourGuide.tourInfo.value)
    const currentPage = useSelector((state:RootStateOrAny) => state.TourGuide.tourInfo.currentPage)
    
    // const [tourHints, settourHints] = useState({
    //     hints: [
    //       {
    //         element: ".tour-pon",
    //         hint: "Hello hint",
    //         hintPosition: "middle-right"
    //       }]})
          const [tourCollapse, setTourCollapse] = useState({
            steps: [
                {
                    element: '.goodsMovement-tg1',
                    title:'Step 01',
                    intro: <h6 className='guide-text'>You can simply press the arrow if you want to View the list</h6>,
                    position: 'right',
                    tooltipClass: 'myTooltipClass',
                    highlightClass: 'myHighlightClass',
                  }]})

    const [tour, setTour] = useState({
        steps: [
            {
                element: '.goodsMovement-tg1',
                title:'Step 01',
                intro: <h6 className='guide-text'>You can simply press the arrow if you want to hide the list</h6>,
                position: 'right',
                tooltipClass: 'myTooltipClass',
                highlightClass: 'myHighlightClass',
              },
              {
                element: '.goodsMovement-tg2',
                title:'Step 02',
                intro: <h6  className='guide-text'>Feed the 'Received Quantity' value in the input field</h6>,
                position: 'left',
              },
              {
                element: '.goodsMovement-tg3',
                title:'Step 03',
                intro:  <h6  className='guide-text'>You can use this switch to toggle the value between'Still Expecting' and 'Report as missing'</h6>,
              },
              {
                element: '.goodsMovement-tg4',
                title:'Step 04',
                intro:  <h6  className='guide-text'>Untick the checkbox if you don't wish to receive email confirmation</h6>,
              },
              {
                element: '.goodsMovement-tg5',
                title:'Step 05',
                intro:  <h6  className='guide-text'>After completing, process the receipt by pressing the button. Success message would be displayed once the goods are processed.</h6>,
              },
              {
                element: '.goodsMovement-tg6',
                title:'Step 06',
                intro:  <h6  className='guide-text'>In case of discrepancy, the red line will indicate where you were active last</h6>,
              },
              
              {
                element: '.tour-explore',
                // title:'Step 08',
                intro:  <h6  className='guide-text'>Click on the explore icon if you need to access the guided tour again</h6>,
              },
      ]
    })
    useEffect(() => {
        localStorage.setItem("currentPage", 'goodsMovement');
        const  goodsMovementVisited = JSON.parse(localStorage.getItem("goodsMovementVisited")|| "false");
        setStepsEnabled(false);
        setTimeout(
            () => {
                if(goodsMovementVisited===true){
                    // setRun(false)
                    setStepsEnabled(false);
                }else{
                    if(SITFBData){
                    // setRun(true)
                    setStepsEnabled(true);
                }
                    localStorage.setItem("goodsMovementVisited", 'true');
                }
            }, 
            1000
          );
	}, []);

    // const handleSelect = (e: any) => {
    //     setSelectedTab(e);
    // }
    const tourSteps= expandSITFB ? tour.steps : tourCollapse.steps;
    const onExit = () => {
        setStepsEnabled(false);
      };
    // const startTour =()=>{
    //     if(SITFBData && tourRunGoods){
    //         setStepsEnabled(true);

    //     }
    // }
    useEffect(() => {
        let data={
            value:false,
            currentPage:'goodsMovement'
          }
        if((SITFBData.Data.data.goodsMovement.length>0 || SITFOData.Data.data.goodsMovement.length) && tourRunGoods && currentPage==='goodsMovement'){
            setStepsEnabled(true);
        }
        dispatch(runTourGoods(data))

	}, [tourRunGoods]);
    return (
        <div className='goods-movement'>
            <Steps
                enabled={stepsEnabled}
                steps={tourSteps}
                initialStep={initialStep}
                onExit={onExit}
                options={{
                    prevLabel: 'Prev',
                    skipLabel: 'Skip',
                    showStepNumbers:true
                  }}
                />
            <NotificationPopup showNotiPopup={showNotiPopup} notifications={notifications} />
           <Container>
                <Row >
                    <p className='GoodsMovementBtn'>
                        Stock in transit from Bayer
                        <span>({SITFBData.Data.data.goodsMovement.length})</span>
                        <button className={"arrowStyle " + (SITFBData.Data.data.goodsMovement.length>0 ? 'goodsMovement-tg1' : '')}  onClick={() => setExpandSITFB(!expandSITFB)}>
                        {/* <img src={expandSITFB ? DownArrow : UpArrow} /></button></p> */}
                        <img className='arrow-img' alt="arrow icon" src={expandSITFB ? UpArrow : DownArrow} /></button></p>
                </Row>
                {!expandSITFB && <hr className='hzline'/>}
                <Row >
                { expandSITFB && <GoodsMovementData type='SITFB' data={SITFBData} error={SITFBErr} setShowNotiPopup={setShowNotiPopup} setNotifications={setNotifications} />}
                    {/* { !expandSITFB && <GoodsMovementData type='SITFB' data={SITFBData} error={SITFBErr} setShowNotiPopup={setShowNotiPopup} setNotifications={setNotifications} />} */}
                </Row>
                <Row>
                <p className='GoodsMovementBtn'>
                    Stock in transit from Other Branch
                    <span>({SITFOData.Data.data.goodsMovement.length})</span>
                    <button className={"arrowStyle " + ((SITFBData.Data.data.goodsMovement.length<1 && SITFOData.Data.data.goodsMovement.length>0) ? 'goodsMovement-tg1' : '')}  onClick={() => setExpandSITFO(!expandSITFO)}>
                        {/* <img src={expandSITFO ? DownArrow : UpArrow} /></button></p>  */}
                        <img className='arrow-img' alt="arrow icon"  src={expandSITFO ? UpArrow : DownArrow} /></button></p>
                </Row>
                {!expandSITFO && <hr className='hzline'/>}

                <Row>

                { expandSITFO && <GoodsMovementData type='SITFO' data={SITFOData} error={SITFOErr} setShowNotiPopup={setShowNotiPopup} setNotifications={setNotifications} />}
                {/* { !expandSITFO && <GoodsMovementData type='SITFO' data={SITFOData} error={SITFOErr} setShowNotiPopup={setShowNotiPopup} setNotifications={setNotifications} />} */}
                </Row>
            </Container> 
            {/* <Tabs
                activeKey={selectedTab ? selectedTab : 'SITFB'}
                transition={false}
                id="noanim-tab-example"
                className="mb-3 account-tab"
                onSelect={(e) => handleSelect(e)}
                >
                <Tab eventKey="SITFB" title="Stock in transit from Bayer">
                < GoodsMovementData type='SITFB' />
                </Tab>
                <Tab eventKey="SITFO" title="Stock in transit from Other Branch">
                <GoodsMovementData type='SITFO' />
                </Tab>
            </Tabs> */}
            <ErrorPopup
            show={showError}
            yesbtn='true'
            nobtn='true'
            errormsg={errorMsg}
            heading={errorHeading}
            onclickno={() => setShowError(false)}
            onclickyes={() => alert('yes')} 
            />
        </div>
    )
}

export default GoodsMovement
