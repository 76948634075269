import React,  {useState, useEffect} from 'react'
import { Container } from 'react-bootstrap';
import { useParams, Link } from "react-router-dom";
import ManageUsers from './ManageUsers'
import MyAccount from './myAccount'
const ProfileEdit = () => {
    const {iniStateuser} = useParams();
    const userInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
    // const iniStateuser = 'MyAccount'
    useEffect(() => {
        setState({...initialState, 
            MyAccount: (iniStateuser==='MyAccount'), 
            ManageUsers: (iniStateuser==='ManageUsers'),
            });
      }, [iniStateuser]);
    const initialState = {
        MyAccount: false,
        ManageUsers: false,
    }
    const [state, setState] = useState({...initialState, 
        MyAccount: (iniStateuser==='MyAccount'), 
        ManageUsers: (iniStateuser==='ManageUsers')});
  return (
    <div className=''>
        <Container style={{display:'flex'}}>
            <div className='left-menu'>
                    <ul>
                        <li className={state.MyAccount? 'link-clicked' : 'tab-title req-product-name'}><Link  to={"/account/MyAccount"} >My Account&ensp;</Link></li>
                        {(userInfo.data.userRole.includes("CropCentre - Agency Admin") ||  userInfo.data.userRole.length<=0)&&
                        <li className={state.ManageUsers? 'link-clicked' : 'tab-title req-product-name'}><Link  to={"/account/ManageUsers"} >Manage Users&ensp;</Link></li>
                        }
                    </ul>
                </div>
                <div className='account-right-menu'>
                {state.MyAccount && <MyAccount />}
                    {state.ManageUsers && (userInfo.data.userRole.includes("CropCentre - Agency Admin") ||  userInfo.data.userRole.length<=0 )&& <ManageUsers />}
                </div>            
        </Container>
        </div>
  )
}
export default ProfileEdit