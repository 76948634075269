//in userReducer.ts
import {
  SET_USER,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  SET_PASSWORD_HINTS,
  RENDER_COM,
  SET_HOME_STACKS,
  SET_ERRORS,
  SET_ACCOUNT_SFID,
  LOADING_USER,
} from "../types";
const initialState = {
  authenticated: false,
  loading: false,
  SelectedAgency:null,
  renderCom:false,
  passwordHint:null,
  stacks:null
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
      case SET_PASSWORD_HINTS:
        return {
          ...state,
          passwordHint:  action.payload,
        };
      case SET_ERRORS:
        return { ...state, loading: false, errors: action.payload };
      case SET_ACCOUNT_SFID:
      return {
        ...state,
        SelectedAgency: action.payload,
      };
      case SET_HOME_STACKS:
        return {
          ...state,
          stacks:action.payload
        }
    case SET_UNAUTHENTICATED:
      return initialState;
    case SET_USER:
      return {
        ...state,
        authenticated: true,
        loading: false
      };
      case RENDER_COM:
        return {
          ...state,
          renderCom:true
        };
      
    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
