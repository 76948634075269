//in CheckAuthentication.ts
 import jwtDecode from 'jwt-decode';//you must install jwt-decode using npm
import { logoutUser } from "../redux/actions/userActions";
import store from "../redux/stores";
import axios from '../_helpers/axios';
import { getSalesData  } from "../redux/actions/stockManagementActions/salesActions";
import { getStocktakeEntries } from '../redux/actions/stockManagementActions/stockEntriesActions';
import { getAddStocks, getCancelStocks } from '../redux/actions/stockManagementActions/requestActions';
import { SET_AUTHENTICATED ,SET_ACCOUNT_SFID } from "../redux/types";
import { getSITFBData } from '../redux/actions/stockManagementActions/goodsMovmtSITFBActions';
import { getSITFOData } from '../redux/actions/stockManagementActions/goodsMovmtSITFOActions';
import { fetchAgencyBranch } from '../redux/actions/reports/historyViewerActions';
import { fetchAgencyBranchData } from '../redux/actions/salesStockReport/salesStockActions';
export const CheckAuthentication = () => {
   
  let authToken:any = localStorage.getItem('token');
  let azurToken:any = localStorage.getItem('Azurtoken');
   if (authToken) {
    const decodedToken:any = jwtDecode(authToken);
    
      if (decodedToken.exp * 1000 < Date.now()) {
        store.dispatch(logoutUser());
      } else {
        axios.defaults.headers.common['cc-session-token'] = authToken;
        axios.defaults.headers.common['Authorization'] = azurToken;
        store.dispatch({ type: SET_AUTHENTICATED });
        	// call API on Page load
        const  SelectedAgency = JSON.parse(localStorage.getItem("SelectedAgency") || "[]");
        store.dispatch({ type: SET_ACCOUNT_SFID,payload: SelectedAgency });
        // on page refresh call All the API
	      store.dispatch(getSalesData(SelectedAgency)) // get sales report data
        store.dispatch(getAddStocks(SelectedAgency));
        store.dispatch(getCancelStocks(SelectedAgency));
        store.dispatch(getStocktakeEntries(SelectedAgency));
        store.dispatch(getSITFBData(SelectedAgency));
        store.dispatch(getSITFOData(SelectedAgency));
        store.dispatch(fetchAgencyBranch(SelectedAgency));
        store.dispatch(fetchAgencyBranchData(SelectedAgency));
      }
      //store.dispatch({ type: SET_AUTHENTICATED });
      
   }
  
};
