import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import ErrorPopup from '../../../components/common/ErrorPopup';
import ScrollButton from '../../../components/common/ScrollButton';
import NotificationPopup from '../../../components/common/NotificationPopup';
import SubmitButton from '../../../components/common/SubmitButton';
import ResetButton from '../../../components/common/ResetButton';
import axios from '../../../_helpers/axios';
import SearchIcon from '../../../assets/images/search_icon.svg';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { getAddStocks } from "../../../redux/actions/stockManagementActions/requestActions";
import backIcon from '../../../assets/images/back-icon.svg';
import { Steps } from 'intro.js-react';
import '../../../assets/scss/tourguide.scss'
import "intro.js/introjs.css";
import {runTourAddRequest } from "../../../redux/actions/tourGuideActions";

const AddToRequest = (props: any) => {
    const dispatch = useDispatch();

    const currentAgency = JSON.parse(localStorage.getItem("SelectedAgency") || "[]");
    const userInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
    const accountSfid = currentAgency['account_sfid'];
    // const url = `ordermanagement/stockrequest?type=add&accountSfid=`.concat(accountSfid);
    const isrender = useSelector((state: RootStateOrAny) => state.user.renderCom);

    // const [renderCom, setrender] = useState(isrender);
    const stocksData = useSelector((state: RootStateOrAny) => state.AddStocks);
    // const cancelData = useSelector((state: RootStateOrAny) => state.CancelStcoks);
    const errors = useSelector((state: RootStateOrAny) => state.AddStocks.errors);

    var stock: any[] = [];
    var str: any = {};
    var arr: {}[] = [];

    const [data, setStocksData] = useState(stock);
    const [stockRequestData, setStockRequestData] = useState(str);
    const [modalShow, setModalShow] = React.useState(false);

    const [errorMsg, setErrorMsg] = useState('');
    const [errorHeading, setErrorHeading] = useState('');
    const [errorMsgBlue, setErrorMsgBlue] = useState('');
    const [showOkBtn, setShowOkBtn] = useState(false);
    const [showYesBtn, setShowYesBtn] = useState(false);
    const [showNoBtn, setShowNoBtn] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [showNotiPopup, setShowNotiPopup] = useState(false);
    const [notifications, setNotifications] = useState(arr);
    const [loading, setLoading] = useState(true);
    const [searchForProduct, setSearchForProduct] = useState(false);
    const [productToSearch, setProductToSearch] = useState('');
    const [run, setRun] = useState(true);
    // const [requestStockvisit, setrequestStockvisit] = useState('')
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [initialStep, setInitialStep] = useState(0);
    // const [addStock, setStatus] = useState(true);
    const tourRunAddRequest = useSelector((state:RootStateOrAny) => state.TourGuide.tourInfo.value)
    const currentPage = useSelector((state:RootStateOrAny) => state.TourGuide.tourInfo.currentPage)
    
    const [tour, setTour] = useState({
        steps: [
            {
                element: '.add-request-tg1',
                title:'Step 01',
                intro: <h6 className='guide-text'>you can start by feeding your desired quantity in the input fields.</h6>,
                position: 'right',
                tooltipClass: 'myTooltipClass',
                highlightClass: 'myHighlightClass',
              },
              {
                element: '.add-request-tg2',
                intro: <h6 className='guide-text'>Requested quantity must be a multiple of the shipper Quantity. Shipper quantity is represented by(x/CTN).</h6>,
              },
              {
                element: '.add-request-tg3',
                intro: <h6 className='guide-text'>you can also choose to search for the product you want by typing here.</h6>,
              },
              {
                element: '.add-request-tg4',
                intro: <h6 className='guide-text'>Reset button will clear out all the inputs made.</h6>,
              },
              {
                element: '.add-request-tg5',
                intro: <h6 className='guide-text'>Press the submit button once you finish adding requests. Success message would be displayed once the stock request is completed.</h6>,
              },
              {
                element: '.add-request-tg6',
                intro: <h6 className='guide-text'>Press here for seeing the previous page.</h6>,
              },
              {
                element: '.add-request-tg7',
                intro: <h6 className='guide-text'>You can also tap on the Heading text to go back to the main page.</h6>,
              },
              {
                element: '.tour-explore',
                intro: <h6 className='guide-text'>Click on the explore icon if you need to access the guided tour again.</h6>,
              },
              
      ]
    })
    useEffect(() => {
        const  requestStockvisited = JSON.parse(localStorage.getItem("requestStockvisited")|| "false");
        setTimeout(
            () => {
                if(requestStockvisited===true){
                    setRun(false)
                    setStepsEnabled(false);
                }else{
                    setRun(true)
                    setStepsEnabled(true);
                    localStorage.setItem("requestStockvisited", 'true');
                }
            }, 
            1000
          );
	}, []);

    useEffect(() => {
        localStorage.setItem("currentPage", 'addToRequest');
	});

    useEffect(() => {
        if (errors.status > 0) {
            setNotifications([{ notificationType: 'warning', header: errors.message }]);
            setLoading(false);
            setShowNotiPopup(true);
        }
        if (stocksData.stocksData) {
            if (stocksData.stocksData.status === 200) {
                setLoading(false);
                setStocksData(stocksData.stocksData.data.customerOrders);
                setStockRequestData(stocksData.stocksData);
            } else if (stocksData.stocksData.status === 201 || stocksData.stocksData.status === 208) {
                setNotifications([{ notificationType: 'warning', header: stocksData.stocksData.message }]);
                setLoading(false);
                setShowNotiPopup(true);
            }
        }
    }, [stocksData]);

    function submitAndfetchData() {
        if (submitClicked) submitStockRequest();
        else fetchData();

        closeErrorPopup();
        setUnsavedChanges(false);
        setSubmitClicked(false);
    }

    async function fetchData() {
        setLoading(true);
        // await axios.get(url)
        //  .then((res: any) => {
        //      if(res.data.message=='Success'){
        //         setLoading(false);
        //         setStockRequestData(res.data); 
        //         setStocksData(res.data.data.customerOrders);
        //      }else if(res.data.status==208){
        //         setLoading(false);
        //         setNotifications([{notificationType: 'warning', header: res.data.message}]);
        //         setShowNotiPopup(true);
        //      }
        //     })
        //  .catch((err) => {
        //     console.log(err.message);
        //     let errorNoti = {notificationType: 'warning', header: err.message};
        //     setLoading(false)
        //     setNotifications([errorNoti]);
        //  });
        dispatch(getAddStocks(currentAgency));
        window.scrollTo({ top: 0, behavior: 'auto' });
    }

    const handleSubmit = () => {
        if (unsavedChanges) {
            setSubmitClicked(true);
            setErrorHeading('Confirmation to submit Request Stock');
            setErrorMsg('Are you sure you want to submit the request?');
            setErrorMsgBlue('');
            setShowOkBtn(false);
            setShowYesBtn(true);
            setShowNoBtn(true);
            setModalShow(true);
        }
    };

    const handleFocus = (e: any) => e.target.select();

    const handleReset = () => {
        if (unsavedChanges) {
            setSubmitClicked(false);
            setErrorHeading('Reset filters');
            setErrorMsg('All your selected options will be cleared.');
            setErrorMsgBlue('');
            setShowOkBtn(false);
            setShowYesBtn(true);
            setShowNoBtn(true);
            setModalShow(true);
            setShowNotiPopup(false);
        }

    }
    const closeErrorPopup = () => {
        setShowNoBtn(false)
        setShowOkBtn(false)
        setShowYesBtn(false)
        setModalShow(false)
    };

    function submitStockRequest() {
        setShowNotiPopup(false);
        var temp = stockRequestData.data.customerOrders;
        stockRequestData.data.customerOrders = [];
        for (let i = 0; i < data.length; i++) {
            if (temp[i].productSfid === data[i].productSfid) {
                if (temp[i].adjustedQty !== data[i].adjustedQty) {
                    stockRequestData.data.customerOrders.push(data[i]);
                }
            }
        }
        stockRequestData.data.userDetails = userInfo.data.userDetails;
        postData(`ordermanagement/stockrequest?type=add`, stockRequestData);

    }

    async function postData(url = '', data = {}) {
        await axios.post(url, data).then((res) => {
            if (res.data.status === 200) {
                let to = (res.data.data.emailReceipents && res.data.data.emailReceipents.TO.length>0) ? res.data.data.emailReceipents.TO[0] : '';
                let cc = (res.data.data.emailReceipents && res.data.data.emailReceipents.CC.length>0) ? res.data.data.emailReceipents.CC[0] : '';
                let msg = '';
                if(to === userInfo.data.userDetails.cont_email) msg = 'Email was sent to you';
                else msg = 'Email was sent to '.concat(to);
                if (cc !== '') msg = msg + ' and a copy to ' + cc;
                let noti = [{ notificationType: 'success', header: msg },
                { notificationType: 'success', header: 'Your Request for Stock has been submitted to Bayer. You will be notified when the stock has been dispatched. If you want to make any changes please use the request for stock tab and update the numbers to resubmit your request. ' }];

                setNotifications(noti);
                setShowNotiPopup(true);
                fetchData();
            }

        })
            .catch((err) => {
                console.log(err);
                let noti = [{ notificationType: 'warning', header: err }];
                setNotifications(noti);
                setShowNotiPopup(true);
                fetchData();
            });
    }

    const changeAdjQty = (event: any) => {
        if (null !== event.e.target.value && !isNaN(event.e.target.value)) {
            stock = data.map(o => {
                var temp = Object.assign({}, o);
                if (temp.productSfid === event.row.productSfid) {
                    temp.adjustedQty = event.e.target.value;
                }
                return temp;
            });
            setStocksData(stock);
        }
    };

    // const resetInput = (event: any) => {
    //     // event.target.value="";
    // };

    const goBAck = () => {
        props.handleGoBack()
    
    }

    const updateStock = (event: any) => {
        if (null !== event.e.target.value && !isNaN(event.e.target.value)) {
            const value = parseInt(event.e.target.value);
            
            stock = data.map(o => {
                var temp = Object.assign({}, o);
                if (temp.productSfid === event.row.productSfid) {
                    let tempNote = "Quantity Changed from " + temp.totalRequestQty + " to " ;
                    if (value % temp.shipperQty === 0) {
                        temp.adjustedQty = value;
                        if (!unsavedChanges) setUnsavedChanges(true);
                        if (event.e.target.value !== "")
                            temp.totalRequestQty = o.currentQty + value;
                        else {
                            temp.adjustedQty = 0;
                            temp.totalRequestQty = temp.currentQty;
                        }
                    }
                    else {
                        if (event.e.target.value === "") {
                            temp.adjustedQty = 0;
                            temp.totalRequestQty = temp.currentQty;
                        }
                        else {
                            event.e.target.style.border = "1px solid red";
                            event.e.target.style.color = "red";
                            setShowYesBtn(false);
                            setShowNoBtn(false);
                            setErrorHeading('The Shipper Quantity for this product is ' + (temp.shipperQty));
                            setErrorMsgBlue((event.key + 1) + '. ' + temp.productName);
                            setErrorMsg('Requested Quantity must be a multiple of Shipper Quantity. Your Requested quantity has been rounded up to the nearest complete shipper.(You may still adjust the requested quantity if you wish).');
                            setShowOkBtn(true);
                            setModalShow(true);
                            if (!unsavedChanges) setUnsavedChanges(true);

                            setTimeout(() => {
                                event.e.target.style.border = "1px solid #89D329";
                                event.e.target.style.color = "black";

                            }, 2000);

                            var i = Math.floor(value / temp.shipperQty);
                            temp.adjustedQty = temp.shipperQty * (i + 1);
                            temp.totalRequestQty = o.currentQty + temp.adjustedQty;

                        }
                    }
                    tempNote = tempNote + temp.totalRequestQty + " by " + userInfo.data.userDetails.cont_firstName + " " + userInfo.data.userDetails.cont_lastName;
                    temp.notes = tempNote;
                }
                props.setSwitchEnable(false);
                return temp;
            });
            setStocksData(stock);
        }
    };
    const tourSteps= tour.steps;
    const onExit = () => {
        setStepsEnabled(false);
      };
    // const startTour =()=>{
    //     setStepsEnabled(true);
    // }

    useEffect(() => {
        let data={
            value:false,
            currentPage:'addToRequest'
            }
        if(tourRunAddRequest && currentPage==="addToRequest"){
            setStepsEnabled(true);
        }
        dispatch(runTourAddRequest(data))

    }, [tourRunAddRequest]);
    return (
        <form onSubmit={handleSubmit}>
            <NotificationPopup showNotiPopup={showNotiPopup} notifications={notifications} />
            <Steps
                enabled={stepsEnabled}
                steps={tourSteps}
                initialStep={initialStep}
                onExit={onExit}
                options={{
                    prevLabel: 'Prev',
                    skipLabel: 'Skip',
                    showStepNumbers:true
                  }}
                />
            <Container>
                <div style={{display:'flex' , justifyContent:'space-between'  }}>
                <div><h5 className='addreqtitle'>Add to current Request</h5></div>
                <div><button className='go-back-btn add-request-tg6' onClick={goBAck} ><img className="back-btn-icon" alt="back btn icon" src={backIcon}/>Go Back</button></div>
                </div>
                <div className='table-header table-responsive'>
                <span className='wrapping col-sn'><p><b>Sr. No.</b></p></span>
                    <span  className='search-product-main wrapping col-product '>
                        {searchForProduct ?
                            <div><textarea rows={1} className='d-inline-block search-product' onChange={e => { setProductToSearch(e.target.value); }} />
                                <button className='close_search d-inline-block' onClick={() => { setSearchForProduct(false); setProductToSearch(''); }}>x</button>
                            </div> :
                            <p className='d-inline-block add-request-tg3'><b>Product Pack &emsp;
                                <img className='d-inline-block' alt="search icon" src={SearchIcon} onClick={() => { setSearchForProduct(true); }
                                }></img> &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b></p>}
                    </span>
                    <span style={{ textAlign: 'center' }} className='wrapping col-qty'><p><b> Requested Qty</b></p></span>
                    <span  style={{ textAlign: 'center' }} className='wrapping col-qty-add' ><p><b>Qty. to add</b></p></span>
                    <span  style={{ textAlign: 'center' }} className='wrapping col-ttl-qty'><p><b>Total Requested Qty</b></p></span>
                </div>
                {loading ?
                    <Row className='loader'><Spinner animation="border" role="status" ></Spinner></Row> :
                    <>
                        {data.filter(function (item: any) {
                            return item.productName.toLowerCase().includes(productToSearch.toLowerCase());
                        }).map((row, key) => {
                            var bgColor = (key + 1) % 2 === 0 ? 'white' : '#89d3290d';
                            let currentQty = "0";
                            if (row.currentQty !== null) {
                                currentQty = row.currentQty;
                            }
                            let isSystemGeneratedRow = (null === row.lineType && row.lineType==="RequestForStock-AutomatedFillUp");

                            return (
                                <div style={{ backgroundColor: bgColor }} className="table-content table-row-req" key={key+''}>
                                    <span className='col-sn'><span className="req-product-name">{key + 1}.</span></span>
                                    {!isSystemGeneratedRow ? <span className='col-product'><span className="req-product-name">{row.productName} - {row.productSapMaterialNumber} </span></span> :
                                    <span className='col-product'><span className="req-product-name">{row.productName} - {"(System generated request)"} - {row.productSapMaterialNumber} </span></span>}
                                    <span className='col-qty' style={{ textAlign: 'center' }}><span className="req-product-name" style={{ textAlign: 'center' }}>{currentQty}</span></span>
                                    <span className='col-qty-add ' style={{ textAlign: 'center' }}>
                                        <input disabled={isSystemGeneratedRow} type="text" value={row.adjustedQty} onFocus={(e) => handleFocus(e)} placeholder={"0"} className="req-product-name req-product-input add-request-tg1 add-request-tg2" style={{ textAlign: 'end', border: '1px solid #89D329', borderRadius: '2px' }}
                                            onChange={e => changeAdjQty({ row, key, e })}
                                            onBlur={e => updateStock({ row, key, e })}
                                        />
                                    </span>
                                    <span className='col-ttl-qty' style={{ textAlign: 'center' }}><span className="req-product-name" style={{ textAlign: 'center' }}>{row.totalRequestQty}</span></span>
                                </div>
                            )
                        })}
                    </>}
                <Row style={{ paddingTop: '20px', justifyContent: 'right' }}>
                    <div className='btn-reset-submit'>
                    <ResetButton  customClasssName="reset-btn-resposive add-request-tg4" onClick={() => handleReset()} isButtonDisabled={!unsavedChanges} title='RESET'/>
                    <SubmitButton classsName="submit-request-stock add-request-tg5" customClasssName="btn-resposive add-request-tg5" arrowClass="btn-req-media" onClick={() => handleSubmit()} title='SUBMIT STOCK REQUEST' isButtonDisabled={!unsavedChanges} />
                    </div>
                    {/* <Col md="3">
                        <ResetButton onClick={() => handleReset()} isButtonDisabled={!unsavedChanges} />
                    </Col>
                    <Col md="5"></Col>
                    <Col md="4">
                        <SubmitButton onClick={() => handleSubmit()} title='SUBMIT STOCK REQUEST' isButtonDisabled={!unsavedChanges} />
                    </Col> */}
                </Row>
                <Row style={{ marginTop: '20px', justifyContent: 'right', marginBottom: '20px' }}>
                    <Col md="11"></Col>
                    <Col md="1"><ScrollButton /></Col>
                </Row>
            </Container>
            <ErrorPopup
                show={modalShow}
                onhide={() => closeErrorPopup()}
                okbtn={showOkBtn}
                errormsg={errorMsg}
                yesbtn={showYesBtn}
                nobtn={showNoBtn}
                errormsgblue={errorMsgBlue}
                heading={errorHeading}
                infoSign={true}
                onclickno={() => setModalShow(false)}
                onclickyes={() => submitAndfetchData()}
            />
        </form>
    )
}

export default AddToRequest

