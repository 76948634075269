import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import LeftArrow from "../../../assets/images/left_arrow.png";
import RightArrow from "../../../assets/images/right_arrow.png";
import ErrorPopup from '../../../components/common/ErrorPopup';
import NotificationPopup from '../../../components/common/NotificationPopup';
import SubmitButton from '../../../components/common/SubmitButton';
import axios from '../../../_helpers/axios';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { getSITFBData } from "../../../redux/actions/stockManagementActions/goodsMovmtSITFBActions";
import { getSITFOData } from "../../../redux/actions/stockManagementActions/goodsMovmtSITFOActions";

const GoodsMovementData = (props: any) => {

    const currentAgency = JSON.parse(localStorage.getItem("SelectedAgency") || "[]");
    const userInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
    const accountSfid = currentAgency['account_sfid'];
    let getUrl = '';
    let postUrl = '';
    // if(props.type=='RTT'){
    //     getUrl = 'ordermanagement/goodsmovementrtt?accountSfid=';
    //     postUrl = `ordermanagement/goodsmovementrtt?accountSfid=`;
    // }
    // else {
    getUrl = `ordermanagement/goodsmovement?accountSfid=`;
    postUrl = `ordermanagement/goodsmovementdelivery?accountSfid=`;
    // }

    getUrl = getUrl.concat(accountSfid).concat(`&type=`).concat(props.type);
    postUrl = postUrl.concat(accountSfid);

    var goods: any[] = [];
    // var goods = Data;
    var str: any = {};
    var arr: {}[] = [];

    const [data, setStocksData] = useState(goods);
    const [goodsMovementData, setGoodsMovementData] = useState(str);
    const [modalShow, setModalShow] = React.useState(false);

    const [errorMsg, setErrorMsg] = useState('');
    const [errorHeading, setErrorHeading] = useState('');
    const [errorMsgBlue, setErrorMsgBlue] = useState('');
    const [showOkBtn, setShowOkBtn] = useState(false);
    const [showYesBtn, setShowYesBtn] = useState(false);
    const [showNoBtn, setShowNoBtn] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [showNotiPopup, setShowNotiPopup] = useState(false);
    const [notifications, setNotifications] = useState(arr);
    const [loading, setLoading] = useState(true);
    const [processGoods, setProcessGoods] = useState(false);
    const [reportMissing, setReportMissing] = useState(false);
    const [partial, setPartial] = useState(false);
    // const [selectedSection, setSelectedSection] = useState('');
    const [emailConfirmation, setEmailConfirmation] = useState(str);
    const [showEmailNoti, setShowEmailNoti] = useState(false);

    const dispatch = useDispatch();
    const isrender = useSelector((state: RootStateOrAny) => state.user.renderCom);
    // const [renderCom, setrender] = useState(isrender);
    const goodsMovmtData = props.data;
    const errors = props.error;

    useEffect(() => {
        if (errors.status > 0) {
            console.log("errors", errors.message);
            setNotifications([{ notificationType: 'warning', header: errors.message }]);
            setLoading(false);
            setShowNotiPopup(true);
        }
        if (goodsMovmtData.Data) {
            if (goodsMovmtData.Data.status === 200) {
                setLoading(false);
                setGoodsMovementData(goodsMovmtData.Data);
                setStocksData(goodsMovmtData.Data.data.goodsMovement);
            } else if (goodsMovmtData.Data.status === 201 || goodsMovmtData.Data.status === 208) {
                setLoading(false);
                setNotifications([{ notificationType: 'warning', header: goodsMovmtData.Data.message }]);
                setShowNotiPopup(true);
                setStocksData([]);
            }
        }
    }, [goodsMovmtData]);

    function submitAndfetchData() {
        if (submitClicked) submitGoodsMovement();
        else fetchData();

        closeErrorPopup();
        setSubmitClicked(false);
    }

    async function fetchData() {
        setShowEmailNoti(false);
        setEmailConfirmation(str);
        setLoading(true);
        // await axios.get(getUrl)
        //     .then((res: any) => {
        //         console.log(res);

        //         if (res.data.message == 'Success') {
        //             setLoading(false);
        //             setGoodsMovementData(res.data);
        //             setStocksData(res.data.data.goodsMovement);
        //         } else if (res.data.status == 208) {
        //             setLoading(false);
        //             setNotifications([{ notificationType: 'warning', header: res.data.message }]);
        //             setShowNotiPopup(true);
        //         }
        //     })
        //     .catch((err) => {
        //         console.log(err.message);
        //         let errorNoti = { notificationType: 'warning', header: err.message };
        //         setLoading(false)
        //         setNotifications([errorNoti]);
        //         setShowNotiPopup(true);
        //     });
        if(props.type==="SITFB") dispatch(getSITFBData(currentAgency));
        else dispatch(getSITFOData(currentAgency));
        window.scrollTo({ top: 0, behavior: 'auto' });
    }

    const closeErrorPopup = () => {
        setShowNoBtn(false)
        setShowOkBtn(false)
        setShowYesBtn(false)
        setModalShow(false)
    };

    function submitGoodsMovement() {
        setShowNotiPopup(false);
        let noti = [];
        noti.push({ notificationType: 'success', header: 'Your Goods Movement has been successfully processed.' })
        if (showEmailNoti) {
            if (reportMissing) {
                noti.push({ notificationType: 'success', header: 'Your SUI Goods Movement email has been sent' });
            }
            if (partial && processGoods) {
                noti.push({ notificationType: 'success', header: 'Your partially processed Goods Movement email has been sent' });
            } else if (!partial && processGoods) {
                noti.push({ notificationType: 'success', header: 'Your processed Goods Movement email has been sent' })
            }
        }

        setNotifications(noti);
        goodsMovementData.data.userDetails = userInfo.data.userDetails;
        postData(postUrl, goodsMovementData);
    }

    async function postData(url = '', data = {}) {
        await axios.post(url, data).then((res) => {
            if (res.data.status === 200) {
                setShowNotiPopup(true);
                fetchData();
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }


    const changeReceivedNow = (event: any) => {
        //event.e.preventDefault();
        const re = /^[0-9\b]+$/;
        if (event.e.target.value === '' || re.test(event.e.target.value)) {

            goods = data.map((o: any) => {
                var section = Object.assign({}, o);
                if (section.referenceNum === event.row.referenceNum) {
                    let rows = section.data.map((p: any) => {
                        var row = Object.assign({}, p);
                        if (row.prodSfid === event.r.prodSfid) {
                            if (event.e.target.value !== '') {
                                if (event.e.target.value > row.totalReceived) {
                                    setShowYesBtn(false);
                                    setShowNoBtn(false);
                                    setErrorHeading('The Received now quantity should be between 0 and ' + row.totalReceived);
                                    setErrorMsgBlue(row.prodName);
                                    setErrorMsg('You can not receive more than the outstanding quantity.');
                                    setShowOkBtn(true);
                                    setModalShow(true);
                                    row.receivedNow = row.totalReceived;
                                    row.stillExpecting = '0';
                                    row.reportasMissing = '0';
                                }
                                else {
                                    let temp=0;
                                    row.receivedNow = parseInt(event.e.target.value);
                                    if (isNaN(row.previouslyReceived) || row.previouslyReceived == null) temp = parseInt(row.totalReceived) - parseInt(row.receivedNow);
                                    else temp = parseInt(row.totalReceived) - parseInt(row.receivedNow) - parseInt(row.previouslyReceived);
                                    if(row.reportasMissing>0){
                                        row.reportasMissing = temp;
                                        row.stillExpecting = '0';
                                    }else if(row.stillExpecting>0){
                                        row.reportasMissing = '0';
                                        row.stillExpecting = temp;
                                    }else {
                                        row.reportasMissing = '0';
                                        row.stillExpecting = temp;
                                    }
                                }
                            }
                            else {
                                row.receivedNow = '';
                                row.reportasMissing = '0';
                                if (isNaN(row.previouslyReceived || row.previouslyReceived == null)) row.stillExpecting = parseInt(row.totalReceived);
                                else row.stillExpecting = parseInt(row.totalReceived) - parseInt(row.previouslyReceived);
                            }

                        }
                        return row;
                    })
                    section.data = rows;
                }
                return section;
            });
            setStocksData(goods);
        }
    };

    function setEmailConfObj(event: any): void {
        setEmailConfirmation({ ...emailConfirmation, [event.row.referenceNum]: event.e.target.checked });
    }

    const onArrowClick = (event: any) => {
        goods = data.map((o: any) => {
            var section = Object.assign({}, o);
            if (section.referenceNum === event.row.referenceNum) {
                let rows = section.data.map((p: any) => {
                    var row = Object.assign({}, p);
                    if (row.prodSfid === event.r.prodSfid) {
                        if (row.stillExpecting > row.reportasMissing) {
                            row.reportasMissing = row.stillExpecting;
                            row.stillExpecting = "0";
                        }
                        else if (row.reportasMissing > row.stillExpecting) {
                            row.stillExpecting = row.reportasMissing;
                            row.reportasMissing = "0";
                        }
                    }
                    return row;
                })
                section.data = rows;
            }
            return section;
        });
        setStocksData(goods);
    }

    const processGoodsReceipt = (event: any) => {
        setSubmitClicked(true);
        let missingErrMsg : any;
        let processErrMsg : any;
        let missing = false;
        let unsavedChanges = false;
        data.map((o: any) => {
            var section = Object.assign({}, o);
            if (section.referenceNum === event.row.referenceNum) {

                goodsMovementData.data.goodsMovement = [section];
                goodsMovementData.data.emailConfirmation = emailConfirmation[section.referenceNum];
                missingErrMsg = <br/>;
                processErrMsg = <br/>;
                let rows = section.data.map((p: any) => {
                    var row = Object.assign({}, p);

                    if (emailConfirmation[section.referenceNum] === true) {

                        setShowEmailNoti(true);
                        row = { ...row, emailConfirmation: true };
                    }
                    if (row.reportasMissing > 0) {
                        missing = true;
                        setReportMissing(true);
                        missingErrMsg = <p>{missingErrMsg} {row.prodName}: <b>{row.reportasMissing}</b></p> ;    
                    }
                    if (row.receivedNow > 0) {
                        unsavedChanges = true;
                        setProcessGoods(true);
                        processErrMsg = <p>{processErrMsg} {row.prodName}: <b>{row.receivedNow}</b></p> ;
                        if (row.previouslyReceived == null) {
                            if (row.receivedNow < row.totalReceived) {
                                setPartial(true);
                            }
                        } else {
                            if (row.receivedNow + row.previouslyReceived < row.totalReceived) {
                                setPartial(true);
                            }
                        }

                    }
                    return row;
                })
                section.data = rows;
            }
            return null;
        });
        if (missing || unsavedChanges) {
            if (!unsavedChanges) {
                setErrorHeading('Report missing  Stock');
                setErrorMsgBlue('Do you wish to report missing stocks of : ');
                setErrorMsg(missingErrMsg);
            } else if (!missing) {
                setErrorHeading('Process Goods Receipt');
                setErrorMsgBlue('Do you wish to process goods receipt for:');
                setErrorMsg(processErrMsg);
            } else {
                setErrorHeading('Process Goods Receipt and Report missing  Stock');
                let message : any= <><br/><p>{'Do you wish to process goods receipt for:'}</p> {processErrMsg} <br/><p>{'Do you wish to report missing stocks of : '}</p> {missingErrMsg} </>;
                setErrorMsg(message)
            }
            setShowOkBtn(false);
            setShowYesBtn(true);
            setShowNoBtn(true);
            setModalShow(true);
        } else if (!unsavedChanges) {
            setErrorMsg('You have to make changes to the receipt before processing');
            setErrorMsgBlue('Reference number ' + event.row.referenceNum);
            setErrorHeading('Nothing to process in this receipt');
            setShowOkBtn(true);
            setShowYesBtn(false);
            setShowNoBtn(false);
            setModalShow(true);
        }
    }

    // const updateRow = (event: any) => {
    //     if (null !== event.e.target.value && !isNaN(event.e.target.value)) {
    //         goods = data.map((o: any) => {
    //             var section = Object.assign({}, o);
    //             if (section.referenceNum === event.row.referenceNum) {
    //                 let rows = section.data.map((p: any) => {
    //                     var row = Object.assign({}, p);
    //                     if (row.prodSfid === event.r.prodSfid) {
    //                         if (parseInt(row.reportasMissing) > parseInt(row.receivedNow)) {
    //                             var temp = parseInt(row.reportasMissing) - parseInt(row.receivedNow);
    //                             row.reportasMissing = temp;

    //                         }
    //                         //else show error popup
    //                     }
    //                     return row;
    //                 })
    //                 section.data = rows;
    //             }
    //             return section;
    //         });
    //         setStocksData(goods);
    //     }
    // };

    const handleFocus = (e: any) => e.target.select();
    
    return (
        <div style={{ marginTop: '10px' }}>
            <NotificationPopup showNotiPopup={showNotiPopup} notifications={notifications} />
            {loading ?
                <Row className='loader'><Spinner animation="border" role="status" ></Spinner></Row> :
                <>
                    {
                        data.map((row: any, key: any) => {
                            // let refNum = row.referenceNum;
                            let ISOdate = new Date(row.transactionDate);
                            // let date = ('0' + ISOdate.getUTCDate()).slice(-2) + '-' + ('0' + (ISOdate.getUTCMonth() + 1)).slice(-2) + '-' + ISOdate.getUTCFullYear();
                            let date = (ISOdate.getUTCFullYear() + '/' + ('0' + (ISOdate.getUTCMonth() + 1)).slice(-2) + '/' + ('0' + ISOdate.getUTCDate()).slice(-2));
                            return (
                                <Container key={key+''}>
                                    <Row className="header goodsMovement-tg6">
                                        <Col md="10">
                                            <p>Sending Branch: &emsp;&emsp;&nbsp;&nbsp;{row.data[0]?.sourceAccName + ' ('+ row.data[0]?.sourceAccSAPId + ')'}</p>
                                        </Col>
                                        <Col md="4">
                                            <p>Reference Number: &emsp;&nbsp;{row.referenceNum}</p>
                                            <p>Transaction Date: &emsp;&emsp;{date}</p>
                                        </Col>
                                        <Col md="1">
                                            {/* <p>Comments: </p> */}
                                        </Col>
                                        <Col md="5">
                                            {/* <p>{row.comments}</p> */}
                                        </Col>
                                    </Row>
                                    <div className='table-header table-gm'>
                                        <div className='product-gm'><b>Product Name</b></div>
                                        <div className='total-gm'><b>Total Received</b></div>
                                        <div className='pre-gm'><b>Previously Received</b></div>
                                        <div className='rec-gm'><b>Received Now</b></div>
                                        <div className='expe-gm'><b>Still Expecting</b></div>
                                        <div className='blank-gm' >&emsp;<b>&emsp;&emsp;&emsp;&emsp;</b></div>
                                        <div className='missing-gm'><b>Report as Missing</b></div>
                                        <div className='case-gm'><b>Case Number</b></div>

                                    </div>
                                    {/* <Row className='table-header'>
                                    <Col md="3"><p><b>Product Name</b></p></Col>
                                        <Col md="1"><p style={{ textAlign: 'right' }}><b>Total Received</b></p></Col>
                                        <Col md="2"><p style={{ textAlign: 'right' }}><b>Previously Received</b></p></Col>
                                        <Col md="2"><p style={{ textAlign: 'center' }}><b>Received Now</b></p></Col>
                                        <Col md="1"><p><b>Still Expecting</b></p></Col>
                                        <Col md="1"><p style={{ textAlign: 'center' }}>&emsp;<b>&emsp;&emsp;&emsp;&emsp;</b></p></Col>
                                        <Col md="1"><p style={{ textAlign: 'right' }}><b>Report as Missing</b></p></Col>
                                        <Col md="1"><p style={{ textAlign: 'right' }}><b>Case Number</b></p></Col>

                                    </Row> */}
                                    {row.data.map((r: any, k: any) => {
                                        var bgColor = (k + 1) % 2 === 0 ? 'white' : '#89d3290d';
                                        return (
                                            <div className='table-content table-gm' style={{ backgroundColor: bgColor }} key={k+''}>
                                                <div className='product-gm'><p>{r.prodName} - {r.prodSapMaterialNumber} </p></div>
                                                <div className='total-gm'><p >{r.totalReceived}</p></div>
                                                <div className='pre-gm'><p>{r.previouslyReceived}</p></div>
                                                <div className='rec-gm goodsMovement-tg2' >
                                                <input type="text" value={r.receivedNow} className='input-gm'
                                                        onChange={e => changeReceivedNow({ row, r, e })} onFocus={(e) => handleFocus(e)}
                                                    //onBlur={e => updateRow({row, r, e})}
                                                    />
                                                </div>
                                                <div className='expe-gm'><p>{r.stillExpecting}</p></div>
                                                <div className='blank-gm goodsMovement-tg3' style={{position:'relative'}}>
                                                <div className="profile" onClick={e => onArrowClick({ row, r, e })} style={{position:'absolute', right:'25%'}}><img alt="arrow icon" src={r.stillExpecting > r.reportasMissing ? RightArrow : LeftArrow} height="8" /></div>
                                                </div>
                                                <div className='missing-gm '><p style={{ textAlign: 'center' }}>{r.reportasMissing}</p></div>
                                                <div className='case-gm'><p>{r.caseNumber}</p></div>

                                             </div>

                                            // <Row className='table-content' style={{ backgroundColor: bgColor }} key={k+''}>
                                            //     <Col md="3"><p>{r.prodName} - {r.prodSapMaterialNumber} </p></Col>
                                            //     <Col md="1"><p style={{ textAlign: 'right' }}>{r.totalReceived}</p></Col>
                                            //     <Col md="2" ><p style={{ textAlign: 'right' }}>{r.previouslyReceived}</p></Col>
                                            //     <Col md="2" className='goodsMovement-tg2'style={{ paddingLeft: '4.6%' }}>
                                            //         <input type="text" value={r.receivedNow} style={{ width: '80px', textAlign: 'right', border: '1px solid #89D329', borderRadius: '2px', height: '25px' }}
                                            //             onChange={e => changeReceivedNow({ row, r, e })} onFocus={(e) => handleFocus(e)}
                                            //         //onBlur={e => updateRow({row, r, e})}
                                            //         />
                                            //     </Col>
                                            //     <Col md="1"><p style={{ textAlign: 'right' }}>{r.stillExpecting}</p></Col>
                                            //     <Col md="1" className='goodsMovement-tg3' style={{ paddingLeft: '4%' }}>
                                            //         <div className="profile" onClick={e => onArrowClick({ row, r, e })}><img src={r.stillExpecting > r.reportasMissing ? RightArrow : LeftArrow} height="8" /></div>
                                            //     </Col>
                                            //     <Col md="1"><p style={{ textAlign: 'right' }}>{r.reportasMissing}</p></Col>
                                            //     <Col md="1"><p>{r.caseNumber}</p></Col>
                                            // </Row>

                                            
                                        )
                                    })}
                                    <Row className='process-row'>
                                        <Col md="6"></Col>
                                        <Col md="3"><span className='unstyled centered checkbox-gm'>
                                            <input className="batch-styled-checkbox" id={row.referenceNum} type="checkbox" value="value1" onClick={e => setEmailConfObj({ e, row })} />
                                            <label  className='goodsMovement-tg4 email-confirmation-gm' htmlFor={row.referenceNum}>Send me an email confirmation</label></span>
                                        </Col>
                                        <Col md="3" className='goodsMovement-tg5'>
                                            <SubmitButton onClick={(e: any) => processGoodsReceipt({ row, e })} title='PROCESS GOODS RECEIPT' isButtonDisabled={false} />
                                        </Col>
                                    </Row>
                                </Container>
                            )
                        })}
                </>}
            <ErrorPopup
                show={modalShow}
                onhide={() => closeErrorPopup()}
                okbtn={showOkBtn}
                errormsg={errorMsg}
                yesbtn={showYesBtn}
                nobtn={showNoBtn}
                errormsgblue={errorMsgBlue}
                heading={errorHeading}
                infoSign={true}
                onclickno={() => setModalShow(false)}
                onclickyes={() => submitAndfetchData()}
            />
        </div>
    )
}

export default GoodsMovementData
