import FormHelperText from "@mui/material/FormHelperText";
import ErrorImg from "../assets/images/error_outline.svg";
/**
 * Function to display the input errors
 * @param {*} object
 */
export const displayError = (message: any) => {
	return (
		message && (
			<FormHelperText className="error-txt">
				<img src={ErrorImg} height="25" alt="error" className="error-img-creds" /> {message}
			</FormHelperText>
		)
	);
};
