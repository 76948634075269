import Carousel from "react-material-ui-carousel";
import "../../assets/scss/layout.scss";
import SusTree from "../../assets/images/tree_carzl.png";
import DigitTransform from "../../assets/images/digit_trans.png";
import Innovation from "../../assets/images/innova.png";
import CarouselBg from "../../assets/images/carousel_bg_new.svg";

const CarouselSlider = () => {
	return (
		<div className="carousel-container">
			<div className="bg-img" style={{width:'100%'}}>
				<img src={CarouselBg} alt="Carouser Bg" />
			</div>
			<div>
				<Carousel
					autoPlay
					className="carousel login-carousel"
					indicators
					cycleNavigation
					swipe
					// indicatorContainerProps={{
					// 	style: {
					// 		marginTop: "30px", // 5
					// 		textAlign: "center", // 4
					// 	},
					// }}
				>
					<img src={SusTree} alt="tree" />
					<img src={DigitTransform} alt="digital" />
					<img src={Innovation} alt="innovation" />
				</Carousel>
			</div>
		</div>
	);
};
export default CarouselSlider;
