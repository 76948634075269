import React from 'react'

const Imprints = () => {
  return (
    <div className='imprints'><article class="grid-col">
    <h1>Imprint</h1>
<p>Published by:</p>
<p>Bayer CropScience Australia Pty Ltd</p>
<p>Represented by:<br />
Joerg Ellmanns<br />
Level 1, 8 Redfern Road<br />
Hawthorn East <br />
Victoria 3123<br />
Australia</p>
<p>Phone:  +61 (0)3 9248 6888<br />
Fax:  +61 (0)3 9248 6802<br />
Email: <a href="mailto:enquiries.australia@bayer.com" target="_top">enquiries.australia@bayer.com</a></p>
<p>© Copyright Bayer CropScience Pty Ltd. Hawthorn East, VIC 3123,   Australia</p>
<p>&nbsp;</p>
</article></div>
  )
}

export default Imprints