import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
// import PropTypes from 'prop-types';

const StocktakeEntriesPrint = React.forwardRef((props: any, ref: any) => {
    const data = props.data[0];

  return (
    <div ref={ref}>
        <Container className='print_popup'>
            <Row>
                <Col md="6" xs="6"><p><b>Product</b></p></Col>
                <Col md="1" xs="1"><p><b>CC. SOH</b></p></Col>
                <Col md="2" xs="2"><p><b>Physical Count</b></p></Col>
                <Col md="3" xs="3"><p><b>Comments</b></p></Col>
            </Row>
            {data.map((row: any, key: any) => {
                return (
                    <>
                        <Row>
                            <Col md="6" xs="6"><p>{row.productName}</p></Col>
                            <Col md="1" xs="1"><p style={{ textAlign: 'center' }} className='NoBottomMargin'>{row.CCSOH}</p></Col>
                            <Col md="2" xs="2">
                                <p style={{ textAlign: 'center' }}>{row.agencyCount ? row.agencyCount : ''}</p>
                            </Col>
                            <Col md="3" xs="3">
                                <p>{row.comments}</p>
                            </Col>
                        </Row>
                    </>
                )
            })}
        </Container>
    </div>
  )
});

export default StocktakeEntriesPrint