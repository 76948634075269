import React from 'react'
import { Modal } from 'react-bootstrap'
import "../../App.css"
import '../../assets/scss/errorPopup.scss'

const ErrorPopup = (props: any) => {
    
    return (
        <div>
            <Modal
        {...props}
        // size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {props.infoSign ? <p className='info-heading'>
        &#9432; {props.heading}
          </p> : <p className='heading'>{props.heading}
          </p>}
        <Modal.Body>
          {props.errormsgblue && <p className='error-msg-blue'>{props.errormsgblue}</p>}
          <p className='error-msg' id="msg">
          {props.errormsg}
          </p>
        </Modal.Body>
        <Modal.Footer style={{alignItems: 'center'}}>
          {props.yesbtn && <button onClick={props.onclickyes} className='btn-white'>Yes</button>}
          {props.nobtn &&<button onClick={props.onclickno} className='btn-red'>No</button>}
          {props.okbtn && <button onClick={props.onhide} className='btn-red'>Ok</button>}
        </Modal.Footer>
      </Modal>
        </div>
    )
}

export default ErrorPopup
