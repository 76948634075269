import Header from './Header';
import NavigationBar from './Navbar';
import { useSelector , RootStateOrAny} from 'react-redux';
import { Container } from 'react-bootstrap';
import Nav from './Nav';
import "../../assets/scss/layout.scss";
import NavigationBarResponsive from './NavbarHamburger.jsx';
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from "./Responsive";
import AgencydroDown from "../common/AgencydropDown"

const MainHeader = () => {
	const  UserInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
	const  currentAgency = JSON.parse(localStorage.getItem("SelectedAgency") || "[]");
	const userInfo = useSelector((state: RootStateOrAny) => state.user);
	//const isMultipleEgn = UserInfo.data.us > 0 ? true: false;
	let ismulAg = false;
	if(userInfo.authenticated){
		 ismulAg = UserInfo.data.customerInfo.length > 1 ? true: false;
	}
	const isMobile = useMediaQuery({ maxWidth: DeviceSize.tablet });
	
	return (
      <div className="main-header">	
	   <Header />
	   { userInfo.authenticated && (isMobile ? <NavigationBarResponsive /> : <NavigationBar />) }
		{ userInfo.authenticated && <Nav /> }
		<Container className="float-end" style={{display:'inline-block',backgroundColor:'#FAFAFA'}}>

		{ userInfo.authenticated   && ismulAg &&     (!isMobile ?<AgencydroDown />:'')}
		{ userInfo.authenticated   && !ismulAg &&
		<div style={{float:'right', marginRight:26}}>
		<p  className="align-self-end agency-name-title" >Agency Name:<b>{currentAgency.agencyName}({currentAgency.account_sapAccountNumber})</b></p>
		</div>	
		}
		</Container>
   
	 </div>
	);
};
export default MainHeader;
