import { GET_ADD_STOCKS } from "../../types";

const initialState = {
    stocksData: [],
    isAgencyChnage:false,
    loading:false,
    errors:[]
}

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_ADD_STOCKS:
			return {
                ...state,
                stocksData:action.payload,
                errors:[],
                isAgencyChnage:true
              }
        // case SET_ERRORS:
        //         return {
        //             ...state,
        //             errors:action.payload
        //           }
		default:
			return state;
	}
}
