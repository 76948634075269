import { Link } from 'react-router-dom';


const DashBoardStacks = (props:any) => {
    // const dashboardData = useSelector((state: RootStateOrAny) => state.dashboard);
    const stacks = props.stacks;

    return (
        <>
        
        <div className="stacks">
        <div className="d-flex justify-content-between align-items-center">
            <div className="stockBoxContent">Stock On hand</div> 
            <a style={{textDecoration:'none'}} rel="noreferrer" target="_blank" href={props.sohReportUrl}>   <div  className="stockBox">&nbsp;</div></a> 
    
        </div>
         <div className="stockQty">{stacks.data.stockOnHand.qty}</div>
         <div className='stack-qty'>Qty In Packs</div>
         <div className="stockBoxContent stock-hr">-----------------</div>
         
         
        <div className="stockPrice">${stacks.data.stockOnHand.price}</div>
         <div className="d-flex justify-content-between align-items-center stack-list">
         <div className="listPrice">List Price</div>
         {/* <div className="track">Track</div> */}
         </div>
        
      </div>
        <div className="stacks mr-3">
        <div className="d-flex justify-content-between align-items-center">
            <div className="stockBoxContent">Stock In Transit</div> 
            <Link style={{textDecoration:"none"}} to = '/StockManagement/GoodsMovement'> <div  className="stockBox">&nbsp;</div></Link>
    
        </div>
         <div className="stockQty">{stacks.data.stockInTransit.qty}</div>
         <div className='stack-qty'>Qty In Packs</div>
         <div className="stockBoxContent stock-hr">-----------------</div>
         
         
         <div className="stockPrice">${stacks.data.stockInTransit.price}</div>
         <div className="d-flex justify-content-between align-items-center stack-list">
         <div className="listPrice">List Price</div>
         <div className="track">Track</div>
         </div>
        </div>
        <div className="stacks ">
        <div className="d-flex justify-content-between align-items-center">
            <div className="stockBoxContent">Stock On Request </div> 
                {/* <div  className="stockBox">&nbsp;</div> */}
    
        </div>
         <div className="stockQty">{stacks.data.stockOnRequest.qty}</div>
         <div className='stack-qty'>Qty In Packs</div>
         <div className="stockBoxContent stock-hr">-----------------</div>
         
         
         <div className="stockPrice">${stacks.data.stockOnRequest.price}</div>
         <div className="d-flex justify-content-between align-items-center stack-list">
         <div className="listPrice">List Price</div>
         {/* <div className="track">Track</div> */}
         </div>
        </div>
        <div className="stacks mt-3 stacks-big" style={{height:142}}>
        <div className="d-flex justify-content-between align-items-center">
            <div className="stockBoxContent">Stock Under Investigation </div> 
                {/* <div  className="stockBox">&nbsp;</div> */}
    
        </div>
        <div className="d-flex justify-content-between align-items-center">
         <div>
         <div className="stockQty">{stacks.data.stockUnderInvestigation.qty}</div>
        <   div className="StockPacks stack-qty">Qty In Packs</div>
         </div>
        
        <div className="divider"></div>
        <div>
        <div className="stockPrice">${stacks.data.stockUnderInvestigation.price}</div>
        <div className="listPrice">List Price</div>
        </div>
        
        </div>

        </div>
        <div className="stacks mt-3 stacks-big" style={{height:142}}>
        <div className="d-flex justify-content-between align-items-center">
            <div className="stockBoxContent">Stock Being Picked </div> 
            <a style={{textDecoration:'none'}} rel="noreferrer" target="_blank" href={props.invReportUrl}> <div  className="stockBox">&nbsp;</div></a>    
    
        </div>
        <div className="d-flex justify-content-between align-items-center">
         <div>
         <div className="stockQty">{stacks.data.stockBeingPicked.qty}</div>
        <   div className="StockPacks stack-qty">Qty In Packs</div>
         </div>
        
        <div className="divider"></div>
        <div>
        <div className="stockPrice">${stacks.data.stockBeingPicked.price}</div>
        <div className="listPrice">List Price</div>
        </div>
        
        </div>

        </div>
        </>
    
    )
}

export default DashBoardStacks;