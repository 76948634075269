import { Component } from 'react'
import * as d3 from "d3";
import { select, pointer, scaleOrdinal } from "d3";
class StatusDonutChart extends Component {
    state ={
        data: this.props.data,
        totalCnt: this.props.totalCnt
    };
    
    drawChart(){
        const width = 150;
        const height = 150;
        const radius = 75;
        
        var color = scaleOrdinal()
  .domain(["Completed", "In Enrolled", "Incomplete", "Unenrolled", "Auto"])
  .range(["#0091DF" , "#89D329" , "#EBF0F2" , "#343738", "#343738"]);
        
        var arc = d3.arc()  
            .outerRadius(radius -30)
            .innerRadius(radius - 10); //Changes width of the slices of the pie
    
        // var arcOver = d3.arc()  
        //     .outerRadius(radius +40)
        //     .innerRadius(0);
        
            var svg = select("#svgContent").append("svg")
            .attr("width",width)
            .attr("height",height)
            .append("g")
            .attr("transform","translate("+width/2+","+height/2+")");
            const div = select("body")
            .append("div") 
            .attr("class", "tooltip");
    
        var pie = d3.pie()
              .sort(null)
              .value(function(d){return d.Value;});
         var texts = svg.selectAll("text").data(pie(this.props.data)).enter()
              .append("text")
              .attr("class","label1")
              .attr("dy", "0.35em")
              .attr("text-anchor", "middle");
              texts.text(this.props.totalCnt);    
    
        var g = svg.selectAll(".arc")
            .data(pie(this.props.data))
            .enter()
            .append("g")
            .attr("class","arc")
            .on("mousemove",function(event, d){

                var mouseVal = pointer(this);
                div.style("display","none");
                div
                .html("Date:"+d.data.Status+"</br>"+"Value:"+d.data.Value)
                .style("left", (event.pageX+12) + "px")
                .style("top", (event.pageY-10) + "px")
                .style("opacity", 1)    
                .style("display","block");
            })
            .on("mouseout",function(){div.html(" ").style("display","none");})
            .on("click",function(d){
                texts.text(d.value);
                if(select(this).attr("transform") == null){
                    select(this).attr("transform","translate(2,-2)");
                }else{
                    select(this).attr("transform",null);
                }
            }
        );
          
        g.append("path")
            .attr("d",arc)
            .style("fill",function(d){return color(d.data.Status);}); 
       
      }
    componentDidMount() {    
        this.drawChart();   
      }
      componentDidUpdate()
      {
        select("#svgContent").selectAll("svg").remove();
        this.drawChart();   
      }
   
      
    render() {
        return (
            <div>
             <div id = "svgContent"></div>
        </div>
        )
       
       
      }
}

export default StatusDonutChart;