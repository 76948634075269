import React, { useState, useEffect } from 'react'
import '../../reports/report.scss'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { selectedReportFilter, setSapFilterObj, fetchReport, selectedFilterDate, getHistoryLinesSelectedField, getOutputSelectedField, selectedAgencyBranchFilter, setSelectedAgencyBranchObj } from "../../../redux/actions/reports/historyViewerActions";
import { useDispatch, useSelector } from 'react-redux';

const HistoryQueryFilterPopup = props => {
    document.body.style.overflow = "hidden"
    const [selectedTab, setSelectedTab] = useState('All');
    const [data, setData] = useState([]);
    // const [filteredData, setfilteredData] = useState([]);
    const [tabData, setTabData] = useState([]);
    // const [isChecked, setIsChecked] = useState();
    const dispatch = useDispatch();
    const selectedFilterData = useSelector((state) => state.HistoryQueryViewer.selectedFilter); // sap filter data from redux
    const reportDataObj = useSelector((state) => state.HistoryQueryViewer.sapFilterObj);
    const reportData = useSelector((state) => state.HistoryQueryViewer.ReportData);
    const agencyBranchObj = useSelector((state) => state.HistoryQueryViewer.agencyBranchObj);
    const selectedAgencyBranchObj = useSelector((state) => state.HistoryQueryViewer.selectedAgencyBranchObj);
    const slctdAgencyBranchFilter = useSelector((state) => state.HistoryQueryViewer.selectedAgencyBranchFilter);
    const selectedFilterName = useSelector((state) => state.HistoryQueryViewer.selectedFilterValue);
    const slectedDateRange = useSelector((state) => state.HistoryQueryViewer.selectedFilterDate);
    const selectedDate = useSelector((state) => state.HistoryQueryViewer.selectedFilterDate);
    const reportDataDate = useSelector((state) => state.HistoryQueryViewer.DateFilterDate);
    const historyFilterData = useSelector((state) => state.HistoryQueryViewer.historyLinesData);
    const historyFilterSelected = useSelector((state) => state.HistoryQueryViewer.SelectedhistoryLinesFilter);
    const outputData = useSelector((state) => state.HistoryQueryViewer.outputData);
    const outputDataFilter = useSelector((state) => state.HistoryQueryViewer.outputFilter);
    const [searchForBranch, setSearchForBranch] = useState(true);
    const [branchToSearch, setBranchToSearch] = useState('');

    const handleSelect = (e) => {
        setSelectedTab(e);
    }

    const handleClick = () => {
        if (props.filterType === 'BRANCH'){
            dispatch(fetchReport(selectedAgencyBranchObj))
        }
        props.handleClose()
    }

    const getSections = (words) => {
        if (words && (props.filterType === "SAP" || props.filterType === "DateRange" || props.filterType === "BRANCH")) {
            return Object.values(
                words?.reduce((acc, word) => {
                    let firstLetter;
                    if (props.filterType === "SAP") {
                        firstLetter = Array.from(word['prod_name'])[0];

                    } else if (props.filterType === "DateRange") {
                        firstLetter = word['prod_name'].slice(0, 4)

                    } else if (props.filterType === "BRANCH") {
                        firstLetter = Array.from(word['account_name'])[0];
                    }
                    if (!acc[firstLetter]) {
                        acc[firstLetter] = { title: firstLetter, data: [word] };
                    } else {
                        acc[firstLetter].data.push(word);
                    }
                    return acc;
                }, {})
            );
        }
    }

    const handleChangeFilter = (filterValue, accountValue) => {
        if (selectedFilterName === "SAP") {
            let objFilterValue = { filterValue, accountValue }
            let alreadyInobj = false;
            // dispatch(selectedReportFilter([...selectedFilterData, filterValue]))
            dispatch(setSapFilterObj([...reportDataObj, objFilterValue]))
            reportDataObj.map((item) => {
                if (item.filterValue === filterValue) {
                    alreadyInobj = true;
                }
                return null;
            })
            if (!alreadyInobj)
                dispatch(setSapFilterObj([...reportDataObj, objFilterValue]))
            else
                dispatch(setSapFilterObj(
                    reportDataObj.filter(function (temp) {
                        return temp.filterValue !== filterValue;
                    })
                ))

            const removeItems = selectedFilterData.filter(item => item !== filterValue)
            dispatch(selectedReportFilter(selectedFilterData.includes(filterValue) ? removeItems : [...selectedFilterData, filterValue]))

        } else if (selectedFilterName === "BRANCH") {
            let objFilterValue = { filterValue, accountValue }
            let alreadyInobj = false;
            // dispatch(selectedAgencyBranchFilter([...selectedAgencyBranchFilter, filterValue]))
            selectedAgencyBranchObj.map((item) => {
                if (item.filterValue === filterValue) {
                    alreadyInobj = true;
                }
                return null;
            })
            if (!alreadyInobj)
                dispatch(setSelectedAgencyBranchObj([...selectedAgencyBranchObj, objFilterValue]))
            else
                dispatch(setSelectedAgencyBranchObj(
                    selectedAgencyBranchObj.filter(function (temp) {
                        return temp.filterValue !== filterValue;
                    })
                ))
            const removeItems = slctdAgencyBranchFilter.filter(item => item !== filterValue)
            if(removeItems.length===0){
                dispatch(selectedReportFilter([]));
                dispatch(setSapFilterObj([]));
            }
            dispatch(selectedAgencyBranchFilter(slctdAgencyBranchFilter.includes(filterValue) ? removeItems : [...slctdAgencyBranchFilter, filterValue]))

        } else if (selectedFilterName === "DateRange") {
            dispatch(selectedFilterDate(filterValue))
        } else if (selectedFilterName === "history") {
            // dispatch(getHistoryLinesSelectedField([...historyFilterSelected,filterValue]))
            const removeItems = historyFilterSelected.filter(item => item !== filterValue)
            dispatch(getHistoryLinesSelectedField(historyFilterSelected.includes(filterValue) ? removeItems : [...historyFilterSelected, filterValue]))

        } else if (selectedFilterName === "output") {
            dispatch(getOutputSelectedField(filterValue))
        }
    }

    const selectAllMaterial = (event) => { 
        let isChecked = event.target.checked === true?true:false;
         let  materailAllObj = []
         let selectedMaterial = [];
         var checkboxes=document.getElementsByClassName("mat_all");
         for (let checkbox of checkboxes) {
     
           let item  = data.find(item => item.prodAcc_product2Sfid === checkbox.value); 
           let materialName = item.prod_name+' ('+item.prod_sapMaterialNumber+')';
           let prod_name = item.prod_sfid;
           let objFilterValue =  {filterValue:materialName , accountValue:prod_name };
           materailAllObj.push(objFilterValue);
            selectedMaterial.push(materialName)
     
         } 
     
         if(isChecked){
            dispatch(setSapFilterObj(materailAllObj));
            dispatch(selectedReportFilter(selectedMaterial));
           
         }else{
            dispatch(setSapFilterObj([]));
            dispatch(selectedReportFilter([]));
      
         }
         
       }

    const selectAllChecks = (event) => {
        //let checkbox = document.querySelector(".branch_all");
         let agencyObj = [];
         let branchFilter = [];
         let isChecked = event.target.checked === true?true:false;
        var checkboxes=document.getElementsByClassName("branch_all");
        for (let checkbox of checkboxes) {
          checkbox.checked  = isChecked; 
          let item  = data.find(item => item.account_sfid === checkbox.value); 
          let branchName=item.account_name+' ('+item.account_sapAccountNumber+', '+item.salesArea_salesGroup+', '+item.salesArea_salesDistrict+', '+item.salesArea_salesOffice+')';
          let accountValue = item.account_sfid;
          agencyObj.push({'filterValue':branchName , 'accountValue': accountValue});
          branchFilter.push(branchName);
          } 
          
          if(isChecked){
            dispatch( setSelectedAgencyBranchObj(agencyObj))
            dispatch(selectedAgencyBranchFilter(branchFilter))
          }else{
            dispatch( setSelectedAgencyBranchObj([]));
            dispatch(selectedAgencyBranchFilter([]));
          }
         
          //updateStore(agencyObj);
        }

    useEffect(() => {
        if (selectedFilterName === 'SAP') {
            dispatch(fetchReport(selectedAgencyBranchObj))
            const allData = getSections(reportData);
            setTabData(allData)
            setData(reportData)

        } else if (selectedFilterName === 'BRANCH') {
            if(slctdAgencyBranchFilter.length>0){
                setSearchForBranch(true);
            }
            const allData = getSections(agencyBranchObj);
            setTabData(allData)
            setData(agencyBranchObj)
        } else if (selectedFilterName === 'DateRange') {
            const allData = getSections(reportDataDate);
            setTabData(allData)
            setData(reportDataDate)
        } else if (selectedFilterName === 'history') {
            setData(historyFilterData)

        } else if (selectedFilterName === 'outlines') {
            setData(outputData)

        }
    }, [])

    return (
        <div className="popup-box">
            <div className="box">
                <div className='popup-title'>
                    {(() => {
                        switch (props.filterType) {
                            case 'BRANCH':
                                return <h4>Agency Branch</h4>;
                            case 'SAP':
                                return <h4>Agency SAP Materials</h4>;
                            case 'DateRange':
                                return <h4>From Year-Month</h4>;
                            case 'history':
                                return <h4>Hide History Lines</h4>;
                            case 'output':
                                return <h4>Output format</h4>;
                            default:
                                return null;
                        }
                    })()}
                    <span className='closeX' onClick={handleClick}>X</span>
                </div>

                {props.filterType === "BRANCH" ? <div className='search-main-container'>
                    <textarea rows={1} className='d-inline-block search-product' placeholder='Key Words Search...' onChange={e => { setBranchToSearch(e.target.value); }} />
                    <button className='searchbtnreport' onClick={() => { setSearchForBranch(true); }}>Search</button>
                </div> :
                    <div style={{ textAlign: 'center', padding: '10px' }}>
                        <p className='report-title'>Note: use Ctrl + F to search within the results</p>
                    </div>}
                <div></div>
                <div className="filter-box" >
                    {(props.filterType === "SAP") && <Tabs
                        activeKey={selectedTab ? selectedTab : '2'}
                        transition={false}
                        textColor="#838383"
                        onSelect={(e) => handleSelect(e)}>
                        {tabData.map((e, k) => {
                            return (
                                <Tab eventKey={e.title} title={e.title} k={k+''}>
                                    <ul className='list-report'>
                                        {e.data.filter((value, index, self) =>
                                            index === self.findIndex((t) => (
                                                t.prod_name === value.prod_name && t.prod_sfid === value.prod_sfid
                                            ))
                                        ).map((filterData, key) => {
                                            let materialName = filterData.prod_name+' ('+filterData.prod_sapMaterialNumber+')';
                                            return (
                                                <li key={key+''}><label className='report-label'><input className='check-report' type="checkbox" value={filterData.prod_name} onChange={() => { props.filterType === "SAP" ? handleChangeFilter(materialName, filterData.prodAcc_product2Sfid) : handleChangeFilter(materialName, 'na') }} checked={(selectedFilterData.includes(materialName) || selectedDate.includes(materialName)) ? true : false} />{materialName}</label></li>
                                            )
                                        })}
                                    </ul>
                                </Tab>
                            );
                        })}
                        <Tab eventKey="All" title="All" >
                            <ul className='list-report'>
                                <li><label className='report-label'><input className='check-report' onChange={selectAllMaterial} type="checkbox"   />Select All</label></li>
                                {
                                    data.filter((value, index, self) =>
                                    index === self.findIndex((t) => (
                                        t.prod_name === value.prod_name && t.prod_sfid === value.prod_sfid
                                    ))
                                ).map((allData, key) => {
                                    let materialName = allData.prod_name+' ('+allData.prod_sapMaterialNumber+')';
                                        return (
                                            <li key={key+''}><label className='report-label'><input className='check-report mat_all' type="checkbox" value={allData.prodAcc_product2Sfid} onChange={() => { props.filterType === "SAP" ? handleChangeFilter(materialName, allData.prodAcc_product2Sfid) : handleChangeFilter(materialName, 'na') }} checked={(selectedFilterData.includes(materialName) || selectedDate.includes(materialName)) ? true : false} />{materialName}</label></li>
                                        )
                                    })
                                }
                            </ul>
                        </Tab>
                    </Tabs>}

                    {(props.filterType === "BRANCH") && searchForBranch && <Tabs
                        activeKey={selectedTab ? selectedTab : '2'}
                        transition={false}
                        textColor="#838383"
                        onSelect={(e) => handleSelect(e)}>
                        {tabData?.map((e, k) => {
                            return (
                                <Tab eventKey={e.title} title={e.title} k={k+''}>
                                    <ul className='list-report'>
                                        {e.data.filter(function (item) {
                                            return item.account_name.toLowerCase().includes(branchToSearch.toLowerCase());
                                        })
                                            .map((filterData, key) => {
                                                let branchName=filterData.account_name+' ('+filterData.account_sapAccountNumber+', '+filterData.salesArea_salesGroup+', '+filterData.salesArea_salesDistrict+', '+filterData.salesArea_salesOffice+')';
                                                return (
                                                    <li key={key+''}><label className='report-label'><input className='check-report' type="checkbox" value={filterData.account_sfid} onChange={() => { props.filterType === "BRANCH" ? handleChangeFilter(branchName, filterData.account_sfid) : handleChangeFilter(branchName, 'na') }} checked={(slctdAgencyBranchFilter.includes(branchName)) ? true : false} />{branchName}</label></li>
                                                )
                                            })}
                                    </ul>
                                </Tab>
                            );
                        })}
                        <Tab eventKey="All" title="All" >
                            <ul className='list-report'>
                            <li><label className='report-label'><input className='check-report' onChange={selectAllChecks} type="checkbox"   />Select All</label></li>
                                {
                                    data?.filter(function (item) {
                                        return item.account_name.toLowerCase().includes(branchToSearch.toLowerCase());
                                    }).map((allData, key) => {
                                        let branchName=allData.account_name+' ('+allData.account_sapAccountNumber+', '+allData.salesArea_salesGroup+', '+allData.salesArea_salesDistrict+', '+allData.salesArea_salesOffice+')';
                                        return (
                                            <li key={key+''}><label className='report-label'><input className='check-report branch_all' type="checkbox" value={allData.account_sfid} onChange={() => { handleChangeFilter(branchName, allData.account_sfid) }} checked={(slctdAgencyBranchFilter.includes(branchName)) ? true : false} />{branchName}</label></li>
                                        )
                                    })
                                }
                            </ul>
                        </Tab>
                    </Tabs>}

                    {(props.filterType === "DateRange") && <Tabs
                        activeKey={selectedTab ? selectedTab : '2'}
                        transition={false}
                        textColor="#838383"
                        onSelect={(e) => handleSelect(e)}>
                        {tabData.map((e, k) => {
                            return (
                                <Tab eventKey={e.title} title={e.title} k={k+''} >
                                    <ul className='list-report'>
                                        {e.data.map((filterData, key) => {
                                            return (
                                                <li key={key+''}><label className='report-label'><input className='check-report' type="radio" value={filterData.prod_name} onChange={() => { handleChangeFilter(filterData.prod_name) }} checked={(slectedDateRange === filterData.prod_name) ? true : false} />{filterData.prod_name}</label></li>
                                            )
                                        })}
                                    </ul>
                                </Tab>
                            );
                        })}
                        <Tab eventKey="All" title="All" >
                            <ul className='list-report'>
                                {
                                    data.map((allData, key) => {
                                        return (
                                            <li key={key+''}><label className='report-label'><input className='check-report' type="radio" value={allData.prod_name} onChange={() => { handleChangeFilter(allData.prod_name) }} checked={(slectedDateRange === allData.prod_name) ? true : false} />{allData.prod_name}</label></li>
                                        )
                                    })
                                }
                            </ul>
                        </Tab>
                    </Tabs>}

                    {props.filterType === 'history' && <ul className='history-filter'>
                        {
                            historyFilterData.map((allData, key) => {
                                return (
                                    <li key={key+''}><label className='report-label'><input className='check-report' type="checkbox" value={allData} checked={historyFilterSelected.includes(allData) ? true : false} onChange={() => handleChangeFilter(allData)} />{allData}</label></li>
                                )
                            })
                        }
                    </ul>
                    }
                    {props.filterType === 'output' && <ul className='history-filter'>
                        {
                            outputData.map((allData, key) => {
                                return (
                                    <li key={key+''}><label className='report-label'><input className='check-report' type="radio" name="outPutFilter" value={allData} checked={(outputDataFilter === allData) ? true : false} onChange={() => handleChangeFilter(allData)} />{allData}</label></li>
                                )
                            })
                        }
                    </ul>
                    }

                </div>
                <div style={{}}></div>
                <div className='popup-bottom'>
                    <button className='closebtnreport' onClick={handleClick}>Close</button>
                </div>
            </div>
        </div>
    )
}

export default HistoryQueryFilterPopup