import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTasks } from '@fortawesome/free-solid-svg-icons'
import SubmitButton from '../../../components/common/SubmitButton'
import HistoryQueryFilterPopup from './HistoryQueryFilterPopup'
import HistoryQueryViewerList from './HistoryQueryViewerList'
import DownLoadCsv from './downloadCsv';
import { selectedReportFilter, fetchAgencyBranch, fetchReport, fetchFilterDate, selectedFilterDate, selectedFilter, fetchHistoryLinesData, fetchOutputData, getOutputSelectedField, getHistoryLinesSelectedField, setSelectedAgencyBranchObj, selectedAgencyBranchFilter, setSapFilterObj, } from "../../../redux/actions/reports/historyViewerActions";
import axios from '../../../_helpers/axios';
import NotificationPopup from '../../../components/common/NotificationPopup';
import { Spinner } from 'react-bootstrap';
import '../../reports/report.scss';
import '../../../assets/scss/common.scss';

const HistoryQueryViewer = () => {

    const dispatch = useDispatch();
    // const [reportName, setReportName] = useState("");
    // const reportData = useSelector((state) => state.HistoryQueryViewer.ReportData);
    const agencyBranchObj = useSelector((state) => state.HistoryQueryViewer.agencyBranchObj);
    const sapFilterObj = useSelector((state) => state.HistoryQueryViewer.sapFilterObj);
    // const reportDataDate = useSelector((state) => state.HistoryQueryViewer.DateFilterDate);
    const selectedFilterData = useSelector((state) => state.HistoryQueryViewer.selectedFilter);
    const selectedFilterDateData = useSelector((state) => state.HistoryQueryViewer.selectedFilterDate);
    const historyFilterSelected = useSelector((state) => state.HistoryQueryViewer.SelectedhistoryLinesFilter);
    const outputFilterSelected = useSelector((state) => state.HistoryQueryViewer.outputFilter);
    const slctdAgencyBranchFilter = useSelector((state) => state.HistoryQueryViewer.selectedAgencyBranchFilter);
    const selectedAgencyBranchObj = useSelector((state) => state.HistoryQueryViewer.selectedAgencyBranchObj);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [filterType, setFilterType] = useState('');
    const [isShowCsv, setisShowCsv] = useState(false);
    const [ShowResult , setShowResult ] = useState(false);
    const [loading , setLoading] = useState(false);
    // const [showNotiPopup, setShowNotiPopup] = useState(true);
    const [showError , setshowError] = useState(false);
    const [response , setResponse]=useState([]);
    const [notification , setNotification] = useState([]);
    
    const SelectedAgency = JSON.parse(localStorage.getItem("SelectedAgency") || "[]");
    // const salesDistrict= SelectedAgency.salesDistrict;

    const monthsLong = {
        January: '01',
        February: '02',
        March: '03',
        April: '04',
        May: '05',
        June: '06',
        July: '07',
        August: '08',
        September: '09',
        October: '10',
        November: '11',
        December: '12',
      };

    const outPutFilter = () => {
        setFilterType('output')
        dispatch(selectedFilter('output'))

        const outputData = [
            'View in browser', 'Save as CSV file'
        ]
        dispatch(fetchOutputData(outputData))
        // setAgencyMaterial(outputData)
        setIsPopupOpen(true)
    }

    useEffect(() => {
        dispatch(fetchAgencyBranch(SelectedAgency))
      },[]);

    const togglePopup = (row) => {
        if (isPopupOpen) {
            document.body.style.overflow = "scroll";
        }
        setIsPopupOpen(!isPopupOpen);
    }

    const removeSelectedFilter = (filter, key) => {
        if (filter === 'selectedSapMaterial') {
            dispatch(selectedReportFilter(selectedFilterData.filter(item => item !== key)))
            dispatch(setSapFilterObj(
                sapFilterObj.filter(function (temp){
                    return temp.filterValue!==key;
                })
            ))
        } else if (filter === 'selectedDateRange') {
            dispatch(selectedFilterDate(selectedFilterDateData.filter(item => item !== key)))
        } else if(filter==='selectedHistoryLines'){
            dispatch(getHistoryLinesSelectedField(historyFilterSelected.filter(item => item !== key)))
        } else if(filter==='selectedOutputFormat'){
            dispatch(getOutputSelectedField(outputFilterSelected.filter(item => item !== key)))
        } else if (filter === 'selectedAgencyBranch') {
            let arr = selectedAgencyBranchObj.filter(temp =>temp.filterValue !== key)
            dispatch(setSelectedAgencyBranchObj(arr))
            dispatch(selectedAgencyBranchFilter(slctdAgencyBranchFilter.filter(item => item !== key)))
            if(arr.length === 0){
                dispatch(selectedReportFilter([]));
                dispatch(setSapFilterObj([]));
            }
            dispatch(fetchReport(arr))
        }
    }

    const  AgencyYearMonthFilter = () => {
        let months = Object.keys(monthsLong);
        let MonthYearData = []
       var date = new Date();
               var currentYear = date.getFullYear();
               for(let i=0;i<=3;i++){
                   for(let i=0;i<12;i++){
                     if(months[i]){
                         let monthYear =  currentYear+" "+months[i];
                         MonthYearData.push({'prod_name': monthYear}) 
                     }
                    
                   }
                  currentYear--
               }

        dispatch(selectedFilter('DateRange'))

        setFilterType('DateRange')
        dispatch(fetchFilterDate(MonthYearData))
           setIsPopupOpen(true)
    }

    const getAgencyMaterial = () => {
        setFilterType('SAP')
        dispatch(selectedFilter('SAP'))
        setIsPopupOpen(true)
        dispatch(fetchReport(selectedAgencyBranchObj))
    }

    const getAgencyBranch = () => {
        setFilterType('BRANCH')
        // dispatch(fetchAgencyBranch(SelectedAgency))
        dispatch(selectedFilter('BRANCH'))
        if(agencyBranchObj.length===0){
            setNotification([{notificationType: 'warning', header: 'Agency Branch Data not available'}])
            setshowError(true);
            window.scrollTo({top: 0, behavior: 'auto'});
        }else{
            setshowError(false);
            setIsPopupOpen(true)
        }
    }

    const resetReport = () =>{
        dispatch(selectedAgencyBranchFilter([]));
        dispatch(setSelectedAgencyBranchObj([]));
        dispatch(selectedReportFilter([]));
        dispatch(setSapFilterObj([]));
        dispatch(getHistoryLinesSelectedField([]));
        setResponse([]);
     }

    const historyFilter = () => {
        setFilterType('history')
        dispatch(selectedFilter('history'))

        const historyData = [
            'Hide Reversed Lines', 'Hide lines that has no effect on SOH'
        ]
        dispatch(fetchHistoryLinesData(historyData))
        // setAgencyMaterial(historyData)
        setIsPopupOpen(true)
    }

    const getReport = () => {
        // get date range filter data
        if(sapFilterObj.length <= 0 || selectedAgencyBranchObj.length<=0){
         if(sapFilterObj.length <= 0 && selectedAgencyBranchObj.length<=0){
            setNotification([{notificationType: 'warning', header: 'Warning',message: 'Please enter the Agnecy Branch and SAP Material'}]);  
         }else if(sapFilterObj.length <= 0 ){
            setNotification([{notificationType: 'warning', header: 'Warning',message: 'Please enter the SAP Material'}]);
         }else if(selectedAgencyBranchObj.length<=0){
            setNotification([{notificationType: 'warning', header: 'Warning',message: 'Please enter the Agnecy Branch'}]);
         }
         setshowError(true)
            setResponse([]);
            window.scrollTo({top: 0, behavior: 'auto'});
             return false;
        }
         setLoading(true);
         let prodId = "";
         let accId = "";
         let hideReverse  = historyFilterSelected.includes('Hide Reversed Lines');
         let hideSOH = historyFilterSelected.includes('Hide lines that has no effect on SOH');
         let dateMonth = selectedFilterDateData?.split(" ");
         let year = dateMonth[0];
         let month = monthsLong[dateMonth[1]];
         sapFilterObj.map(rs=> {
            prodId += rs.accountValue + ";";
            return null;
         })

         selectedAgencyBranchObj.map(rs=> {
            accId += rs.accountValue + ";";
            return null;
        })

         prodId = prodId.slice(0, -1);
         accId = accId.slice(0, -1);
        

        const sapData = {
            "productsId": prodId,
            "accountsId": accId,
            "year": year,
            "month": month,
            "hideSOH": hideSOH,
            "hideReverse": hideReverse
        }

        axios.post("ordermanagement/branchhistorydata", sapData)
        .then((res) => {
            if(res.data.status===200){
                setLoading(false);
                setshowError(false)
                if(res.data.data.length===0){
                    setNotification([{notificationType: 'warning', header: 'Info',message: 'Data Not Available for the selected filters'}])
                    setshowError(true);
                    window.scrollTo({top: 0, behavior: 'auto'});
                }
                setResponse(res.data.data);
                if(outputFilterSelected==="View in browser"){
                    setShowResult(true)
                    setisShowCsv(false)
                 }else{
                    setisShowCsv(true)
                    setShowResult(false)
                 }
            }
        })
        .catch((err) => {
           console.log(err.message);
           setLoading(false);
        });

    }

    return (
        <>
            <p className='histroy-query-view-title'>Sales District History Query Viewer</p>
            {showError && <div>  <NotificationPopup showNotiPopup={true} notifications={notification} className="notification_popup mb-3" /> </div>} 
            <div style={{ border: '1px solid', boxShadow: '0px 0px 1px 1px #aaaaaa', margin: "30px" }} className='report-filters-form'>
            {loading &&  <div className="centeredSpin"><Spinner animation="border" role="status" ></Spinner></div> }
                <p style={{ margin: '-12px 20px', fontSize: '14px', background: 'white', padding: '0px 8px', width: '110px', fontWeight: '600' }}>Select Options</p>
                <div className='report-filters'>
                    <div className='filter-label required'><label className='pfilter-label'>Agency Branch</label></div>
                    <div className='filter-select'><button onClick={() => getAgencyBranch()}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                    <div className='selected-filter'>
                    {slctdAgencyBranchFilter.map((key, value) => {
                            return <div key={key} ><span className="remove-filter-btn" onClick={() => removeSelectedFilter('selectedAgencyBranch', key)}>x&nbsp;</span>{key} </div>
                        })}
                    </div>
                </div>
                <div className='report-filters'>
                    <div className='filter-label required'><label className='pfilter-label'>Agency SAP Materials</label></div>
                    <div className='filter-select'><button onClick={() => getAgencyMaterial()}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                    <div className='selected-filter'>
                        {selectedFilterData.map((key, value) => {
                            return <div key={key} ><span className="remove-filter-btn" onClick={() => removeSelectedFilter('selectedSapMaterial', key)}>x&nbsp;</span>{key} </div>
                        })}
                    </div>
                </div>
                <div className='report-filters'>
                <div className='filter-label'><label className='pfilter-label'>From Year-Month</label></div>
                <div className='filter-select'><button onClick={() => AgencyYearMonthFilter()}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                <div className='selected-filter'>
                    <div  ><span className="remove-filter-btn" >x&nbsp;</span>{selectedFilterDateData} </div>
                </div>
            </div>
            <div className='report-filters'>
                <div className='filter-label'><label className='pfilter-label'>Hide History Lines</label></div>
                <div className='filter-select'><button onClick={() => historyFilter()}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                <div className='selected-filter'>{ historyFilterSelected.map((key, value) => { 
                    return <div key={key} ><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('selectedHistoryLines',key)}>x&nbsp;</span>{key} </div>
                    })}</div>
            </div>
            <div className='report-filters'>
                <div className='filter-label required'><label className='pfilter-label'>Output format</label></div>
                <div className='filter-select'><button onClick={() => outPutFilter()}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                <div className='selected-filter'>
                {/* {outputFilterSelected.map((key, value) => { 
                    return <div key={key} style={{fontSize: '18px', margin: '5px'}}><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('selectedOutputFormat',key)}>x&nbsp;</span>{key} </div>
                    })} */}
                    <div  ><span className="remove-filter-btn" >x&nbsp;</span>{outputFilterSelected} </div>
                </div>
            </div>
                
                <div className="report-btn" style={{ paddingTop: '20px', marginBottom: '50px', justifyContent: 'left' }}>
                    <div style={{ marginLeft: '20px' }}>
                        <SubmitButton onClick={() => getReport()} title='SUBMIT' />

                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <SubmitButton onClick= {() => resetReport()} title='RESET' />
                    </div>
                </div>
                {isShowCsv && <div style={{ margin: 20, fontSize: 22 }}>
                    <DownLoadCsv data={response} filename="historyQueryViewer.csv" /></div>}
            </div>
            {ShowResult && <HistoryQueryViewerList data={response} />}
            {isPopupOpen && <HistoryQueryFilterPopup filterType={filterType} handleClose={togglePopup} />}
        </>
    )
}

export default HistoryQueryViewer