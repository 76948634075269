import React, { useState, useEffect } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import ErrorPopup from "../../components/common/ErrorPopup";
import ScrollButton from "../../components/common/ScrollButton";
import SearchIcon from "../../assets/images/search_icon.svg";
import Editicon from "../../assets/images/EditIcon.png";
import Dropdown from "../../assets/images/Dropdown.png";
import arrowDown from "../../assets/images/blue_arrow_down.png";
import EditPopup from "./EditPopup";
import CastDtl from "./caseDtl";

import axios from "../../_helpers/axios";

const UserList = (props: any) => {
  const [clicked, setClicked] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);

  const [editClicked, setEditClicked] = useState(null);
  const [seledtedDropdownVal, setSeledtedDropdownVal] = useState("");

  const currentAgency = JSON.parse(
    localStorage.getItem("SelectedAgency") || "[]"
  );
  // const userInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
  // const accountSfid = currentAgency['account_sfid'];
  // const url = `ordermanagement/stockrequest?type=add&accountSfid=`.concat(accountSfid);
  // var str: any = {};
  // var arr: {}[] = [];

  const [modalShow, setModalShow] = React.useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [editRow, seteditRow] = useState("");
  const [errorHeading, setErrorHeading] = useState("");
  const [errorMsgBlue, setErrorMsgBlue] = useState("");
  const [showOkBtn, setShowOkBtn] = useState(false);
  const [showYesBtn, setShowYesBtn] = useState(false);
  const [showNoBtn, setShowNoBtn] = useState(false);
  const [popupType, setPopupType] = useState("profile");

  var str: any = {};
  const [loading, setLoading] = useState(false);
  const [searchForProduct, setSearchForProduct] = useState(false);
  const [listUsers, setUserList] = useState<any[]>([]);
  const SelectedAgency = JSON.parse(
    localStorage.getItem("SelectedAgency") || "[]"
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isShowCase, setShowCase] = useState(false);
  const [caseDtl, setCaseDtl] = useState("");
  const [caseNumber, setCaseNumber] = useState();

  const togglePopup = (row: any, caseNumber: any, popupType: any) => {
    if (isOpen) {
      document.body.style.overflow = "scroll";
    }
    setIsOpen(!isOpen);
    setPopupType(popupType);
    seteditRow(row);
    setCaseNumber(caseNumber);
    setSeledtedDropdownVal(popupType);
    setEditClicked(null);
  };

  const togglCase = () => {
    if (isShowCase) {
      document.body.style.overflow = "scroll";
    }
    setShowCase(!isShowCase);
    // seteditRow(row);
  };

  const toggleEdit = (index: any) => {
    if (editClicked === index) {
      //if clicked question is already active, then close it
      return setEditClicked(null);
    }

    setEditClicked(index);
  };

  const toggle = (index: any) => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };

  const closeErrorPopup = () => {
    setShowNoBtn(false);
    setShowOkBtn(false);
    setShowYesBtn(false);
    setModalShow(false);
  };
  // const confirmDeleteUser = () => {
  //     setErrorHeading('Confirmation to delete user');
  //     setErrorMsg('Are you sure you want to delete user?');
  //     setErrorMsgBlue('');
  //     setShowOkBtn(false);
  //     setShowYesBtn(true);
  //     setShowNoBtn(true);
  //     setModalShow(true);
  // }

  const deleteUser = () => {
    setModalShow(false);
  };

  const disPlayCase = (Case: any) => {
    setShowCase(true);
    setCaseDtl(Case);
  };

  function fetchData() {
    axios
      .get(
        `/usermanagement/manageusers?accountSfid=${SelectedAgency.account_sfid}`
      )
      .then((res: any) => {
        if (res.status === 200) {
          setUserList(res.data.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  return (
    <form>
      <Container>
        <Row className="table-header">
          {/* <Col md="1"></Col> */}
          <Col md="1">
            <p>
              <b>Sr. No.</b>
            </p>
          </Col>
          <Col md="2" className="search-product-main">
            {searchForProduct ? (
              <div>
                <textarea rows={1} className="d-inline-block search-product" />
                <button className="close_search d-inline-block">x</button>
              </div>
            ) : (
              <p className="d-inline-block">
                <b>
                  User Name &emsp;
                  <img
                    className="d-inline-block"
                    alt="search icon"
                    src={SearchIcon}
                  ></img>{" "}
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                </b>
              </p>
            )}
          </Col>
          <Col md="4">
            <p>
              <b>Agency Role</b>
            </p>
          </Col>
          <Col md="2">
            <p>
              <b>Status</b>
            </p>
          </Col>
          <Col md="3">
            <p>
              <b>Quick Actions</b>
            </p>
          </Col>
        </Row>
        {loading ? (
          <Row className="loader">
            <Spinner animation="border" role="status"></Spinner>
          </Row>
        ) : (
          <>
            {listUsers.map((row, key) => {
              var bgColor = (key + 1) % 2 === 0 ? "white" : "#89d3290d";
              return (
                <>
                  <Row
                    style={{
                      backgroundColor: bgColor,
                      color: "#00617F",
                      padding: 5,
                    }}
                    className="table-content"
                  >
                    {/* <Col md="1"><input className="batch-checkbox-checkbox" type="checkbox" /></Col> */}
                    <Col md="1">
                      <p>{key + 1}.</p>
                    </Col>
                    <Col md="2">
                      <p style={{ color: "#00617F" }}>
                        {row.userDetails.cont_firstName}{" "}
                        {row.userDetails.cont_lastName}
                      </p>
                    </Col>
                    <Col md="4">
                      <p style={{ color: "#00617F" }}>
                        {row.userRole.join(", ")}{" "}
                      </p>
                    </Col>
                    <Col md="2">
                      <p style={{ color: "#00617F" }}>
                        {row.userDetails.cont_status}{" "}
                      </p>
                    </Col>

                    {/* <Col md="2">
                      <span
                        onClick={() =>
                          togglePopup(
                            row,
                            row.cases.length > 0
                              ? row.cases[0].case_caseNumber
                              : null
                          )
                        }
                      >
                        <img
                          style={{ width: 50, height: 50 }}
                          alt="edit icon"
                          src={Editicon}
                        />
                      </span>
                      
                      <span onClick={() => toggle(key)}>
                        {
                          <img
                            style={{ width: 12, height: 10, marginLeft: 15 }}
                            alt="arrow icon"
                            src={clicked === key ? arrowDown : Dropdown}
                          />
                        }
                      </span>
                    </Col> */}
                    <Col md="3">
                      <span style={{ display: "flex", position: "relative" }}>
                        <button
                          className="btn dropdown-toggle btn-edit"
                          onClick={() => toggleEdit(key)}
                          type="button"
                          data-toggle="dropdown"
                        >
                          Edit
                          <span className="caret"></span>
                        </button>
                        {editClicked === key && (
                          <span className="drop-style">
                            <ul className="dropdown-edit">
                              <li
                                onClick={() =>
                                  togglePopup(
                                    row,
                                    row.cases.length > 0
                                      ? row.cases[0].case_caseNumber
                                      : null,
                                    "profile"
                                  )
                                }
                              >
                                Personal Information
                              </li>
                              <li
                                onClick={() =>
                                  togglePopup(
                                    row,
                                    row.cases.length > 0
                                      ? row.cases[0].case_caseNumber
                                      : null,
                                    "roles"
                                  )
                                }
                              >
                                Roles
                              </li>
                              <li
                                onClick={() =>
                                  togglePopup(
                                    row,
                                    row.cases.length > 0
                                      ? row.cases[0].case_caseNumber
                                      : null,
                                    "case"
                                  )
                                }
                              >
                                Unlink Branch
                              </li>
                            </ul>
                          </span>
                        )}
                        {/* <span onClick={confirmDeleteUser}><img style={{ width: 50, height: 50 }} src={DeleteIcon} /></span> */}
                        <span onClick={() => toggle(key)}>
                          {
                            <img
                              style={{ width: 12, height: 10, marginLeft: 15 }}
                              alt="arrow icon"
                              src={clicked === key ? arrowDown : Dropdown}
                            />
                          }
                        </span>
                      </span>
                    </Col>
                  </Row>

                  {clicked === key ? (
                    <Row
                      style={{
                        backgroundColor: bgColor,
                        color: "#00617F",
                        marginBottom: 5,
                      }}
                      className="table-content"
                    >
                      <Row style={{ marginLeft: -3 }}>
                        <Col md="1"></Col>
                        {/* <Col  md="4"><span style={{fontWeight: 'bold'}}>Saluation:</span> <span  style={{ color: "#00617F" }}>Mr.</span></Col> */}
                        {/* <Col md="4"><span style={{fontWeight: 'bold'}}>Fax Number:</span> <span style={{ color: "#00617F" }}>{row.userDetails.cont_fax}</span></Col> */}
                        <Col md="1">
                          <p></p>
                        </Col>
                      </Row>
                      <Row style={{ marginLeft: -3 }}>
                        <Col md="1"></Col>
                        <Col md="4">
                          <span style={{ fontWeight: "bold" }}>
                            Email Address:{" "}
                          </span>
                          <span style={{ color: "#00617F" }}>
                            {row.userDetails.cont_email}
                          </span>
                        </Col>
                        <Col md="4">
                          <span style={{ fontWeight: "bold" }}>
                            Mailing Address:{" "}
                          </span>
                          <span style={{ color: "#00617F" }}>
                            {row.userDetails.cont_mailingStreet}
                          </span>
                          <br />
                          <span style={{ color: "#00617F" }}>
                            {row.userDetails.cont_mailingCity} ,
                          </span>
                          <br />
                          <span style={{ color: "#00617F" }}>
                            {row.userDetails.cont_mailingState} ,
                          </span>
                          <br />
                          <span style={{ color: "#00617F" }}>
                            {row.userDetails.cont_mailingCountry}{" "}
                            {row.userDetails.cont_mailingPostalCode}
                          </span>{" "}
                        </Col>
                        <Col md="1">
                          <p></p>
                        </Col>
                      </Row>
                      <Row style={{ marginLeft: -3 }}>
                        <Col md="1"></Col>
                        {/* <Col md="4"><span style={{fontWeight: 'bold'}}>Mobile No:</span> <span style={{ color: "#00617F" }}>{row.userDetails.cont_mobile!=""?row.userDetails.cont_mobile:'Not Updated'}</span></Col> */}
                        <Col md="4">
                          <span style={{ fontWeight: "bold" }}>
                            Phone Number:
                          </span>{" "}
                          <span style={{ color: "#00617F" }}>
                            {row.userDetails.cont_phone}
                          </span>
                        </Col>

                        <Col md="4">
                          <span style={{ fontWeight: "bold" }}>
                            Case Number's:
                          </span>
                          {row.cases.length > 0 && (
                            <span style={{ color: "#00617F" }}>
                              {row.cases.map((r1: any) => {
                                return r1.case_caseNumber + ","
                              })}
                              {/* <a
                                href="javascript:void(0)"
                                onClick={() => disPlayCase(row.cases[0])}
                              >
                                (View Details)
                              </a> */}
                            </span>
                          )}
                        </Col>
                      </Row>
                      <hr />
                    </Row>
                  ) : null}
                </>
              );
            })}
          </>
        )}
        {isOpen && (
          <EditPopup
            handleClose={togglePopup}
            type={popupType}
            row={editRow}
            caseNumber={caseNumber}
          />
        )}
        {isShowCase && <CastDtl handleClose={togglCase} caseDtl={caseDtl} />}
        <Row style={{ paddingTop: "20px", justifyContent: "right" }}>
          <Col md="3"></Col>
          <Col md="6"></Col>
          <Col md="3"></Col>
        </Row>
        <Row
          style={{
            marginTop: "20px",
            justifyContent: "right",
            marginBottom: "20px",
          }}
        >
          <Col md="11"></Col>
          <Col md="1">
            <ScrollButton />
          </Col>
        </Row>
      </Container>
      <ErrorPopup
        show={modalShow}
        onhide={() => closeErrorPopup()}
        okbtn={showOkBtn}
        errormsg={errorMsg}
        yesbtn={showYesBtn}
        nobtn={showNoBtn}
        errormsgblue={errorMsgBlue}
        heading={errorHeading}
        infoSign={true}
        onclickno={() => setModalShow(false)}
        onclickyes={() => deleteUser()}
      />
    </form>
  );
};

export default UserList;
