import React, {useState, useEffect} from 'react';
import { useParams, Link } from "react-router-dom";
import GoodsMovement from './goodsMovement/GoodsMovement';
import RequestStocks from './reqForStock/RequestStocks'
import SalesReport from './salesReport/SalesReport';
import StocktakeEntries from './stocktakeEntries/StocktakeEntries';
import '../../assets/scss/stockmanagement.scss';
import '../../assets/scss/stockmanageMedis.scss';

const StockManagementHome = () => {

    const {iniState} : any = useParams();
    

    useEffect(() => {
        setState({...initialState, 
            reqForStocks: (iniState==='RequestForStock'), 
            goodsMovement: (iniState==='GoodsMovement'),
            salesReport: (iniState==='SalesReport'),
            stockTakeEntries: (iniState==='StocktakeEntries')});
      }, [iniState]);

    const initialState = {
        reqForStocks: false,
        goodsMovement: false,
        salesReport: false,
        stockTakeEntries: false,
    }
    const [state, setState] = useState({...initialState, 
        reqForStocks: (iniState==='RequestForStock'), 
        goodsMovement: (iniState==='GoodsMovement'),
        salesReport: (iniState==='SalesReport'),
        stockTakeEntries: (iniState==='StocktakeEntries')});

    return (
        <div className='stock-management'>
       <div className='stock-manage' style={{maxWidth:'100%',margin:'auto'}}>
                <div className='left-menu'>
                    <ul>
                        <li className={'add-request-tg7 cancel-request-tg8 '+(state.reqForStocks? 'link-clicked ' : '')}><Link className='wrapping tab-title req-product-name' to={"/StockManagement/RequestForStock"} >Request For Stock&ensp;</Link></li>
                        <li className={state.goodsMovement? 'link-clicked' : ''}><Link  className='wrapping tab-title req-product-name' to={"/StockManagement/GoodsMovement"} >Goods Movement&ensp;</Link></li>
                        <li className={state.salesReport? 'link-clicked' : ''}><Link className='wrapping tab-title req-product-name' to={"/StockManagement/SalesReport"} >Sales Report&ensp;</Link></li>
                        <li className={state.stockTakeEntries? 'link-clicked' : ''}><Link  className='wrapping tab-title req-product-name' to={"/StockManagement/StocktakeEntries"} >Stocktake Entries&ensp;</Link></li>
                    </ul>
                </div>
                <div className='stock-comp'>
                    {state.reqForStocks && <RequestStocks />}
                    {state.goodsMovement && <GoodsMovement />}
                    {state.salesReport && <SalesReport />}
                    {state.stockTakeEntries && <StocktakeEntries />}
                </div>
        </div>
        </div>
    )
}

export default StockManagementHome
