import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from '../../../_helpers/axios';
import '../report.scss';
import '../../../assets/scss/common.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTasks } from '@fortawesome/free-solid-svg-icons'
import SubmitButton from '../../../components/common/SubmitButton';
import SelectFilterPopup from './SelectFilterPopup';
import { selectedReportFilter,fetchReport, fetchFilterDate,selectedFilterDate, selectedFilter,fetchHistoryLinesData,fetchOutputData ,getOutputSelectedField,getHistoryLinesSelectedField , sapFilterObj as SapFilterObj} from "../../../redux/actions/reports/reportActions";
import { useDispatch, useSelector } from 'react-redux';
import DownLoadCsv from './downloadCsv';
import ReportList from './ReportList';
//import Response from './BranchHistoryResponse.json';
import {  Spinner } from 'react-bootstrap';
import NotificationPopup from '../../../components/common/NotificationPopup';


const Report = () => {
    const  SelectedAgency = JSON.parse(localStorage.getItem("SelectedAgency") || "[]");
    const warning = [{notificationType: 'warning', header: 'Warning',message: 'Please enter the SAP materials'}];
    // const userInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
    const [response , setResponse]=useState([]);
    const {reportType} = useParams();
    const [selectedSapMaterial, setSelectedSapMaterial] = useState([]);
    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const [selectedHistoryLines, setSelectedHistoryLines] = useState([]);
    const [selectedOutputFormat, setSelectedOutputFormat] = useState([]);
    const dispatch  = useDispatch(); 
    const reportData = useSelector((state) => state.ReportHistory.ReportData);
    // const reportDataObj = useSelector((state) => state.ReportHistory.sapFilterObj);
    const reportDataDate = useSelector((state) => state.ReportHistory.DateFilterDate);
    // const selectedFilterName= useSelector((state) => state.ReportHistory.selectedFilterValue);
    const sapFilterObj= useSelector((state) => state.ReportHistory.sapFilterObj);

    const selectedFilterData = useSelector((state) => state.ReportHistory.selectedFilter);
    const selectedFilterDateData = useSelector((state) => state.ReportHistory.selectedFilterDate);
    const historyFilterSelected = useSelector((state) => state.ReportHistory.SelectedhistoryLinesFilter);
    const outputFilterSelected = useSelector((state) => state.ReportHistory.outputFilter);
    const [reportName ,setReportName] = useState("");
    const [isShowCsv , setisShowCsv] =  useState(false);
    const [ShowResult , setShowResult ] = useState(false);
    const [loading , setLoading] = useState(false);
    const [showNotiPopup, setShowNotiPopup] = useState(true);
    const [showError , setshowError] = useState(false);

    const monthsLong = {
        January: '01',
        February: '02',
        March: '03',
        April: '04',
        May: '05',
        June: '06',
        July: '07',
        August: '08',
        September: '09',
        October: '10',
        November: '11',
        December: '12',
      };
    

    // const selectedFilterDataSAP = useSelector((state) => state.ReportHistory.selectedFilter);
    // const selectedFilterDataDate = useSelector((state) => state.ReportHistory.selectedFilterDate);
    // const historyFilterSelectedData = useSelector((state) => state.ReportHistory.SelectedhistoryLinesFilter);
    // const outputFilterSelectedData = useSelector((state) => state.ReportHistory.outputFilter);



    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [filterType, setFilterType] = useState('');

    // const [agencyMaterialFilter , setAgencyMaterial] = useState([]);
    const removeSelectedFilter = (filter, key)=> {
        if(filter==='selectedSapMaterial'){
            dispatch(selectedReportFilter(selectedFilterData.filter(item => item !== key)))
        }else if(filter==='selectedDateRange'){
            dispatch(selectedFilterDate(null))
        }else if(filter==='selectedHistoryLines'){
            dispatch(getHistoryLinesSelectedField(historyFilterSelected.filter(item => item !== key)))
        }else if(filter==='selectedOutputFormat'){
            dispatch(getOutputSelectedField(null))
        }
      }

    const selectedFilterValues=(data)=>{
        if(filterType==="SAP"){
            setSelectedSapMaterial([...selectedSapMaterial ,data])
        }else if(filterType==="DateRange"){
            setSelectedDateRange(data)

        }else if(filterType==="history"){
            setSelectedHistoryLines(data)

        }else if(filterType==="output"){
            setSelectedOutputFormat(data)
        }
    }

     const  getAgencyMaterial = () => {
        setFilterType('SAP')
        dispatch(selectedFilter('SAP'))
        setIsPopupOpen(true)

     }

     const resetReport = () =>{
        dispatch(selectedReportFilter([]));
        dispatch(SapFilterObj([]))
        dispatch(getHistoryLinesSelectedField([]))
        
     }

     const getReport = () =>{
         // get date range filter data
         if(sapFilterObj.length <= 0){
            setshowError(true)
             return false;
         }
         setLoading(true);
         let prodId = "";
         let accId = "";
         let hideReverse  = historyFilterSelected.includes('Hide Reversed Lines');
         let hideSOH  = historyFilterSelected.includes('Hide lines that has no effect on SOH');
         let dateMonth = selectedFilterDateData?.split(" ");
         let year = dateMonth?.[0];
         let month = monthsLong?.[dateMonth?.[1]];

        //  userInfo.data.customerInfo.map(rs=> {
        //     accId += rs.account_sfid + ",";
        //  });

        accId = SelectedAgency.account_sfid;

         sapFilterObj.map(rs=> {
            prodId += rs.accountValue + ";";
            return null;
         })

         prodId = prodId.slice(0, -1);
        

        const sapData = {
            "productsId": prodId,
            "accountsId": accId,
            "year": year,
            "month": month,
            "hideSOH": hideSOH,
            "hideReverse": hideReverse
        }
        setisShowCsv(false)
        axios.post("ordermanagement/branchhistorydata", sapData)
        .then((res) => {
            if(res.data.status===200){
                
                setLoading(false);
                setshowError(false)
                setResponse(res.data.data);
                if(outputFilterSelected==="View in browser"){
                    setShowResult(true)
                    setisShowCsv(false)
                 }else{
                    setisShowCsv(true)
                    setShowResult(false)
                 }
            }
        })
        .catch((err) => {
           console.log(err.message);
           setLoading(false);
        });
    
      }

      

     const  AgencyYearMonthFilter = () => {
        dispatch(selectedFilter('DateRange'))
        let months = Object.keys(monthsLong);
        let MonthYearData = []
       var date = new Date();
               var currentYear = date.getFullYear();
               for(let i=0;i<=3;i++){
                   for(let i=0;i<12;i++){
                     if(months[i]){
                         let monthYear =  currentYear+" "+months[i];
                         MonthYearData.push({'prod_name': monthYear}) 
                     }
                    
                   }
                  currentYear--
               }

        setFilterType('DateRange')
        // const MonthYearData = [
        //     {
        //         'prod_name':'2019 January'
        //     },
        //     {
        //         'prod_name':'2019 Febuary'
        //     },
        //     {
        //         'prod_name':'2019 March'
        //     },
        //     {
        //         'prod_name':'2019 April'
        //     },
        //     {
        //         'prod_name':'2020 January'
        //     },
        //     {
        //         'prod_name':'2020 February'
        //     },
        //     {
        //         'prod_name':'2020 March'
        //     },
        //     {
        //         'prod_name':'2020 April'
        //     },
        //     {
        //         'prod_name':'2021 January'
        //     },
        //     {
        //         'prod_name':'2021 Febuary'
        //     },
        //     {
        //         'prod_name':'2021 March'
        //     },
        //     {
        //         'prod_name':'2021 April'
        //     },
        //     {
        //         'prod_name':'2022 January'
        //     },
        //     {
        //         'prod_name':'2022 Febuary'
        //     },
        //     {
        //         'prod_name':'2022 March'
        //     },
        //     {
        //         'prod_name':'2022 April'
        //     },
        //     {
        //         'prod_name':'2023 January'
        //     },
        //     {
        //         'prod_name':'2023 Febuary'
        //     },
        //     {
        //         'prod_name':'2023 March'
        //     },
        //     {
        //         'prod_name':'2023 April'
        //     },
        // ];
        dispatch(fetchFilterDate(MonthYearData))
        //    setAgencyMaterial(MonthYearData)
           setIsPopupOpen(true)
    }

    const  historyFilter = () => {
        setFilterType('history')
        dispatch(selectedFilter('history'))

        const historyData=[
            'Hide Reversed Lines','Hide lines that has no effect on SOH'
        ]
        dispatch(fetchHistoryLinesData(historyData))
        // setAgencyMaterial(historyData)
           setIsPopupOpen(true)
    }
    const  outPutFilter = () => {
        setFilterType('output')
        dispatch(selectedFilter('output'))

        const outputData=[
            'View in browser','Save as CSV file'
        ]
        dispatch(fetchOutputData(outputData))
        // setAgencyMaterial(outputData)
           setIsPopupOpen(true)
    }


      const togglePopup = (row) => {
        if(isPopupOpen){
            document.body.style.overflow = "scroll";
        }
        setIsPopupOpen(!isPopupOpen);
      }

    useEffect(() => {
        dispatch(fetchReport())
        if(reportType.toLowerCase()==='branchhistory'){
            setReportName("Branch History");
        } else if(reportType.toLowerCase()==='salesdistricthistory'){
            setReportName("Sales District Query Viewer");
        }

      }, [reportType]);

    return (
        <>
        
        <p className='report-title'>{reportName}</p>
        {showError && <div>  <NotificationPopup showNotiPopup={showNotiPopup} notifications={warning} className="notification_popup mb-3" /> </div>} 
        <div className='report-filters-form'>
        {loading &&  <div className="centeredSpin"><Spinner animation="border" role="status" ></Spinner></div> }

            <p style={{margin: '-12px 20px', fontSize: '14px', background: 'white', padding: '0px 8px', width: '110px', fontWeight: '600'}}>Select Options</p>
            <div className='report-filters'>
                <div className='filter-label required'><label className='pfilter-label'>Agency SAP Materials</label></div>
                <div className='filter-select'><button onClick={() => getAgencyMaterial()}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                <div className='selected-filter'>
                    { selectedFilterData.map((key, value) => { 
                    return <div key={key} ><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('selectedSapMaterial',key)}>x&nbsp;</span>{key} </div>
                    })}
                </div>
            </div>
            <div className='report-filters'>
                <div className='filter-label'><label className='pfilter-label'>From Year-Month</label></div>
                <div className='filter-select'><button onClick={() => AgencyYearMonthFilter()}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                <div className='selected-filter'>
                {/* { selectedFilterDateData.map((key, value) => { 
                    return <div key={key} style={{fontSize: '18px', margin: '5px'}}><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('selectedDateRange',key)}>x&nbsp;</span>{key} </div>
                    })} */}
                    {selectedFilterDateData && <div ><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('selectedDateRange','')}>x&nbsp;</span>{selectedFilterDateData} </div>}
                </div>
            </div>
            <div className='report-filters'>
                <div className='filter-label'><label className='pfilter-label'>Hide History Lines</label></div>
                <div className='filter-select'><button onClick={() => historyFilter()}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                <div className='selected-filter'>{ historyFilterSelected.map((key, value) => { 
                    return <div key={key} ><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('selectedHistoryLines',key)}>x&nbsp;</span>{key} </div>
                    })}</div>
            </div>
            <div className='report-filters'>
                <div className='filter-label required'><label className='pfilter-label'>Output format</label></div>
                <div className='filter-select'><button onClick={() => outPutFilter()}><FontAwesomeIcon icon={faTasks} />&nbsp; &nbsp;Select</button></div>
                <div className='selected-filter'>
                {/* {outputFilterSelected.map((key, value) => { 
                    return <div key={key} style={{fontSize: '18px', margin: '5px'}}><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('selectedOutputFormat',key)}>x&nbsp;</span>{key} </div>
                    })} */}
                    {outputFilterSelected && <div ><span className="remove-filter-btn" onClick={()=>removeSelectedFilter('selectedOutputFormat',null)}>x&nbsp;</span>{outputFilterSelected} </div>}
                </div>
            </div>
            
            
            
                
            <div className="report-btn" style={{paddingTop: '20px', marginBottom:'50px', justifyContent: 'left'}}>
                <div style={{marginLeft:'20px'}}>
                    <SubmitButton onClick= {() => getReport()} title='SUBMIT'/>
                   
                </div>
                <div style={{marginLeft:'20px'}}>
                    <SubmitButton className="sbmt-report" onClick= {() => resetReport()} title='RESET' />
                </div>
                
            </div>
                {response.length <=0 && ShowResult &&  <div style={{margin:10,padding:10, fontSize:25, color:'red'}}>No Records Found For This Agency</div> }
            {isShowCsv && <div style={{margin:20, fontSize:22}}><DownLoadCsv data={response} filename="branchHistory.csv" /></div> }
        </div>
        
      {ShowResult && <ReportList data={response} /> }
        {isPopupOpen && <SelectFilterPopup agencyMaterialFilter={reportData} agencyMaterialDate={reportDataDate} filterType={filterType} handleClose={togglePopup} selectedFilterValues={selectedFilterValues}/>}
        </>
    )
}
export default Report;