//In types.ts we will define constants for user authentication and UI loading purpose

//user reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const LOADING_USER = "LOADING_USER";

//UI reducer types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

//Stock Management Reduces types
export const GET_ADD_STOCKS = "GET_ADD_STOCKS";
export const GET_CANCEL_STOCKS = "GET_CANCEL_STOCKS";
export const GET_STOCKTAKE_ENTRIES = "GET_STOCKTAKE_ENTRIES";
export const GET_GOODS_MOVEMENT_SITFB = "GET_GOODS_MOVEMENT_SITFB";
export const GET_GOODS_MOVEMENT_SITFO = "GET_GOODS_MOVEMENT_SITFO";

//dashboard types
export const GET_STOCKS_STACK = "GET_STOCKS_STACK";
export const SET_HOME_STACKS= "SET_HOME_STACKS";
export const SET_ACCOUNT_SFID= "SET_ACCOUNT_SFID";
export const SET_HOME_STOCKS= "SET_HOME_STOCKS";
export const RENDER_COM = "RENDER_COM";
export const GET_SALES = "GET_SALES";
export const SET_PASSWORD_HINTS = "SET_PASSWORD_HINTS";
export const RUN_TOUR_SALES ='RUN_TOUR_SALES'
export const RUN_TOUR_GOODS = 'RUN_TOUR_GOODS'
export const RUN_TOUR_REQUEST_MAIN = 'RUN_TOUR_REQUEST_MAIN'
export const RUN_TOUR_ADD_REQUEST = 'RUN_TOUR_ADD_REQUEST'
export const RUN_TOUR_CANCEL_REQUEST = 'RUN_TOUR_CANCEL_REQUEST'
export const RUN_TOUR_STOCK_TAKE = 'RUN_TOUR_STOCK_TAKE'

//report branch history

export const SELECTED_REPORT_FILTER ="SELECTED_REPORT_FILTER"
export const PAGE_REPORT = 'PAGE_REPORT'
export const SELECTED_FILTER_VALUE ='SELECTED_FILTER_VALUE'
export const PAGE_REPORT_DATE ='PAGE_REPORT_DATE'
export const SELECTED_DATE_FILTER='SELECTED_DATE_FILTER'
export const HISTORY_LINES_DATA = 'HISTORY_LINES_DATA'
export const HISTORY_LINES_FILTER = 'HISTORY_LINES_FILTER'
export const OUTPUT_FILTER='OUTPUT_FILTER'
export const OUTPUT_DATA = 'OUTPUT_DATA'
export const SAP_FILTER_OBJ = 'SAP_FILTER_OBJ'



// Report Sales Stock

export const OUTPUT_DATA_SS = 'OUTPUT_DATA_SS'
export const FILTER_SELECTED_SS = 'FILTER_SELECTED_SS'
export const OUTPUT_FILTER_SS = 'OUTPUT_FILTER_SS'

export const GROUP_DATA_SS = 'GROUP_DATA_SS'
export const GROUP_FILTER_SS = 'GROUP_FILTER_SS'

export const SHOW_STOCKS_SS = 'SHOW_STOCKS_SS'
export const SHOW_STOCKS_FILTER_SS = 'SHOW_STOCKS_FILTER_SS'

export const FKA_DATA_SS = 'FKA_DATA_SS'
export const FKA_FILTER_SS = 'FKA_FILTER_SS'

export const SOR_DATA_SS = 'SOR_DATA_SS'
export const SOR_DATA_FILTER_SS = 'SOR_DATA_FILTER_SS'

export const SOH_DATA_SS = 'SOH_DATA_SS'
export const SOH_DATA_FILTER_SS = 'SOH_DATA_FILTER_SS'

export const YEAR_DATA_SS = 'YEAR_DATA_SS'
export const YEAR_DATA_FILTER_SS = 'YEAR_DATA_FILTER_SS'

export const START_MONTH_DATA_SS = 'START_MONTH_DATA_SS'
export const START_MONTH_DATA_FILTER_SS = 'START_MONTH_DATA_FILTER_SS'

export const END_MONTH_DATA_SS = 'END_MONTH_DATA_SS'
export const END_MONTH_DATA_FILTER_SS = 'END_MONTH_DATA_FILTER_SS'

export const SAP_MATERIAL_DATA_SS = 'SAP_MATERIAL_DATA_SS'
export const SAP_MATERIAL_FILTER_SS = 'SAP_MATERIAL_FILTER_SS'
export const SAP_MATERIAL_OBJ_SS = 'SAP_MATERIAL_OBJ_SS'

export const BRANCH_DATA_SS = 'BRANCH_DATA_SS'
export const BRANCH_DATA_FILTER_SS = 'BRANCH_DATA_FILTER_SS'
export const AGENCY_BRANCH_OBJ_SS = 'AGENCY_BRANCH_OBJ_SS'
export const RESET_STOCK_DATA = 'RESET_STOCK_DATA'




// Report History Query Viewer
export const HV_SELECTED_REPORT_FILTER = 'HV_SELECTED_REPORT_FILTER'
export const HV_AGENCY_BRANCH_OBJ = 'HV_AGENCY_BRANCH_OBJ'
export const HV_SELECTED_AGENCY_BRANCH_OBJ = 'HV_SELECTED_AGENCY_BRANCH_OBJ'
export const HV_SELECTED_AGENCY_BRANCH_FILTER = 'HV_SELECTED_AGENCY_BRANCH_FILTER'
export const HV_SAP_FILTER_OBJ = 'HV_SAP_FILTER_OBJ'
export const HV_SELECTED_DATE_FILTER = 'HV_SELECTED_DATE_FILTER'
export const HV_PAGE_REPORT = 'HV_PAGE_REPORT'
export const HV_PAGE_REPORT_DATE = 'HV_PAGE_REPORT_DATE'
export const HV_SELECTED_FILTER_VALUE = 'HV_SELECTED_FILTER_VALUE'
export const HV_HISTORY_LINES_DATA = 'HV_HISTORY_LINES_DATA'
export const HV_HISTORY_LINES_FILTER = 'HV_HISTORY_LINES_FILTER'
export const HV_OUTPUT_DATA = 'HV_OUTPUT_DATA'
export const HV_OUTPUT_FILTER = 'HV_OUTPUT_FILTER'
