
import "./dashboard.css";
//import { getStockStacks  } from "../../redux/actions/dashboardActions"
import { useState , useEffect } from 'react';
import Stocks from './Stocks';
import Notification from './Notifications'
import Stacks from './Stacks';
import TrainingStatus from './TrainingStatus';
import { useDispatch, useSelector , RootStateOrAny} from 'react-redux';
import { getDashboardData , getStats } from "../../redux/actions/dashboardActions";
import {  Spinner } from 'react-bootstrap';
import QuickLinks from './QuickLinks.jsx';
import axios from '../../_helpers/axios';
import LineChart from './lineChart';
import SalesAndForecast from "./SalesAndForecast";



const Dashboard = () => {
   const dispatch  = useDispatch(); 
   const  UserInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
   const  SelectedAgency = JSON.parse(localStorage.getItem("SelectedAgency") || "[]");
   const [url , setUrl] = useState("");
   const [sohReportUrl , setsohReportUrl] = useState("");
   const [invReportUrl , setInvReportUrl] = useState("");
   const [graphType , setgraphType ] = useState("Sale");
   
   useEffect(() => {
    localStorage.setItem("currentPage", 'dashboard');
     dispatch(getDashboardData(SelectedAgency))
     dispatch(getStats(SelectedAgency))
     getReport("branchUserAccountReport");
     
   // 
  }, [])
  const dashboardData = useSelector((state: RootStateOrAny) => state.dashboard.stocks);
  // const loading = useSelector((state: RootStateOrAny) => state.dashboard.loading);
  const stacksData = useSelector((state: RootStateOrAny) => state.dashboard.stacks);
  // const selectedAgency =  useSelector((state: RootStateOrAny) => state.user.SelectedAgency); 
  // const tabParam = "AccountId (AccountContactRelation1)="+selectedAgency.account_sfid;

  const getReport = (param:any) => {
    axios.get("oauth/sfdctoken")
    .then((res) => {
        if(res.data.status===200){
         const access_token = res.data.access_token;
         //const rootUrl = "https://anz-cropcentre-api-dev.herokuapp.com/services/api/reportmanagement/"
         const rootUrl = process.env.REACT_APP_BASE_URL ? (process.env.REACT_APP_BASE_URL + "reportmanagement/") : 'https://anz-cropcentre-api-int.herokuapp.com/services/api/reportmanagement/';
         const url =rootUrl + param +"?access_token="+access_token+"&recId="+UserInfo.data.userDetails['user_contactSfid'];
         const sohReportUrl = rootUrl + "branchProductPricingSOHReport?access_token="+access_token+"&recId="+UserInfo.data.userDetails['user_contactSfid'];
         const InvReportUrl = rootUrl + "BranchInventoryReport?access_token="+access_token+"&recId="+UserInfo.data.userDetails['user_contactSfid'];
         
         setUrl(url)
         setsohReportUrl(sohReportUrl)
         setInvReportUrl(InvReportUrl)
        }
        
    })
    .catch((err) => {
       console.log(err.message);
        
    });
  }

  const switchGraph = (type:any)=> {
       setgraphType(type)
  }
      
    return (
        
           <div>
           <div className="dashContStock dashContStock-stock">     
            <span className="stock-card">
            { dashboardData.status===200?<Stocks stocks={dashboardData} />: 
            <><Spinner animation="border" role="status" ></Spinner> </> }
              </span>   
            <span className="stock-notification">
            { dashboardData.status===200?<Notification stocks={dashboardData} />: 
            <><Spinner style={{position: 'absolute', left: '70%', top: '50%'}} animation="border" role="status" ></Spinner></>  }
               
               </span>
            </div>
            <div className="dashContStock stock-card-mt dashContStock-stack">     
            <span className="stack-card">
            { stacksData.status===200? 
                        <Stacks stacks={stacksData} sohReportUrl={sohReportUrl} invReportUrl={invReportUrl} />: 
                        <div className="col-sm- row stacks" > Loading Stacks...... </div>
                        }
              </span>   
          {/* <span className="agencyCard agencyCard-tableau"> <Tableau /></span> */}
          {/* <span><iframe width="600" height="350" src={url} ></iframe></span> */}
          
          <div className="agencyCardgraph agencyCard-tableau">
          <div style={{margin:10 }} className="notification-head ">
            <span className={graphType==='Sale'?'reportActive':'reportInActive'} onClick={()=> switchGraph("Sale")}>Agency Sales History</span>
            <span className={graphType==='Forcast'?'reportActive':'reportInActive'} onClick={()=> switchGraph("Forcast")} style={{margin:25}}>Agency Forecast History</span>
           </div>
          <div><LineChart graphType={graphType} className="line-chart-dashboard"/></div>
            
          </div>

            </div> 
            

            <div className="dashContStock training-card dashContStock-training">     
            <span className="stack-card trainigStatus">
            <TrainingStatus />
              </span>   
          <span className="agencyCard trainigStatus" ><SalesAndForecast /></span>
            </div> 
            <hr style={{borderTop: "1px dashed #fff" }}/>
            <QuickLinks />
           </div>

    
    )
}

export default Dashboard;