import React,{ useState, useEffect }  from 'react'
import {useParams, Link } from "react-router-dom";
import ConditionsOfUse from './ConditionsOfUse'
import BayerLogo from "../../../assets/images/bayer_logo.svg";
import PrivacyStatement from './PrivacyStatement';
import Typography from "@mui/material/Typography";
import SalesTermsConditions from './SalesTermsConditions';
import TermsOfMembership from './TermsConditions';
import Imprints from './Imprints'
const LegalHome = () => {
    const {iniState} = useParams();
    

    useEffect(() => {
        setState({...initialState, 
            conditionsUse: (iniState==='GeneralConditionsOfUse'), 
            privacyStatement: (iniState==='PrivacyStatement'),
            termsConditios:(iniState==='SalesTermsAndConditions'),
         termsMembership:(iniState==='TermsOfMembership'),
         imprints:(iniState==='Imprint')
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [iniState]);

    const initialState = {
        conditionsUse: false,
        privacyStatement: false,
        termsConditios:false,
        termsMembership:false,
        imprints:false
    }
    const [state, setState] = useState({...initialState, 
        conditionsUse: (iniState==='GeneralConditionsOfUse'), 
        privacyStatement: (iniState==='PrivacyStatement'),
        termsConditios:(iniState==='SalesTermsAndConditions'),
        termsMembership:(iniState==='TermsOfMembership'),
        imprints:(iniState==='Imprint')
        });
  return (
      <div className='legal-main-container'>
          <div className='legal-header-logo'>
              <a href="default.asp">
                <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                  <img src={BayerLogo} className="bayer-logo-legal" alt="Bayer Logo" />
                </Typography>
              </a>
              <div className='nav-title'><h2><b>CropCentre</b></h2></div>
          </div>
          <div className='legal-container' >
                    <div className='legal-left'>
                        <ul>
                            <li className={(state.conditionsUse || state.privacyStatement || state.termsConditios || state.termsMembership || state.imprints) ? 'legal-link-clicked' : ''}><Link className='' to={""} >Legals&ensp;</Link></li>
                            <li className={''}><Link  className='' to={""} >Imprints&ensp;</Link></li>
                        </ul>
                    </div>
                    <div className='legal-right'>
                        {state.conditionsUse && <ConditionsOfUse />}
                        {state.privacyStatement && <PrivacyStatement /> }
                        {state.termsConditios && <SalesTermsConditions /> }
                        {state.termsMembership && <TermsOfMembership /> }
                        {state.imprints && <Imprints /> }
                    </div>
          </div>
      </div>
  )
}

export default LegalHome