import shoppingIcon from '../../assets/images/shopping_cart_black_24dp.png';
import reportIcon from '../../assets/images/description_black_24dp.png';
import transportImg from '../../assets/images/local_shipping_black_24dp.png';
import { Link } from 'react-router-dom';
const DashBoardStocks = (props:any) =>  {
    
    // const dashboardData = useSelector((state: RootStateOrAny) => state.dashboard.stocks);
    const userInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
    // const redirectTopage = ()=> {
        
    //     return  <Redirect to="/dashboard"/>
    // }
    
    return(
        
        
    <>
    <div className='card stockItem'>
            <div className='card-content'>
            <div className="card-body text-success cards-res">
            <div className="d-flex justify-content-between">
                <h5 className="cardTitle">Request For Stocks</h5>
                <div><img src={shoppingIcon} alt='shppoing icon' className='stock-icon' /></div>
            </div>
            <p className="cardContent">{props.stocks.data.RequestForStock.message}</p>
            </div>
            </div>
            <div className={`card-footer justify-content-between align-items-center cardFooter ${props.stocks.data.RequestForStock.status === 'green' ? "cardBgGreen" : "cardBgRed"} `}>
            {(userInfo.data.userRole.includes("CropCentre - Agency Admin") || userInfo.data.userRole.length<=0) &&
            <Link style={{textDecoration:"none"}} to = '/StockManagement/RequestForStock'>
            <span >Go To Request Of Stock</span> 
                <span className="bgImage">&nbsp;</span>
            </Link>
            }
            </div>
        </div>

     <div className='card stockItem'>
            <div className='card-content'>
            <div className="card-body text-success cards-res">
            <div className="d-flex justify-content-between">
                <h5 className="cardTitle">Goods Movement</h5>
                <div><img src={transportImg} alt='transport icon' className='stock-icon' /></div>
            </div>
            <p className="cardContent">{props.stocks.data.GoodsMovement.message}</p>
            </div>
            </div>
            <div className={`card-footer justify-content-between align-items-center cardFooter ${props.stocks.data.GoodsMovement.status === 'green' ? "cardBgGreen" : "cardBgRed"} `}>
            {(userInfo.data.userRole.includes("CropCentre - Agency Admin") || userInfo.data.userRole.length<=0) &&
            <Link style={{textDecoration:"none"}} to = '/StockManagement/GoodsMovement'>
            <span >Go To Goods Movement</span> 
                <span className="bgImage">&nbsp;</span>
            </Link>
            }
            </div>
        </div>

        <div className='card stockItem'>
            <div className='card-content'>
            <div className="card-body text-success cards-res">
            <div className="d-flex justify-content-between">
                <h5 className="cardTitle">Sales Report</h5>
                <div><img src={reportIcon} alt='report icon' className='stock-icon' /></div>
            </div>
                <p className="cardContent">{props.stocks.data.SalesReport.messaage}</p>
            </div>
            </div>
            <div className={`card-footer justify-content-between align-items-center cardFooter ${props.stocks.data.SalesReport.status === 'green' ? "cardBgGreen" : "cardBgRed"} `}>
            {(userInfo.data.userRole.includes("CropCentre - Agency Admin") || userInfo.data.userRole.length<=0) &&
            <Link style={{textDecoration:"none"}} to = '/StockManagement/SalesReport'>
                <span>Go To  Sales Report</span>
                <span className="bgImage">&nbsp;</span>
            </Link>
            }
            </div>
        </div>

     <div className='card stockItem'>
            <div className='card-content'>
            <div className="card-body text-success cards-res">
            <div className="d-flex justify-content-between">
                <h5 className="cardTitle">Stocktake Entries</h5>
                <div><img src={reportIcon} alt='report icon' className='stock-icon' /></div>
            </div>
                <p className="cardContent">{props.stocks.data.StockTakeEntry.message}</p>
            </div>
            </div>
            <div className={`card-footer justify-content-between align-items-center cardFooter ${props.stocks.data.StockTakeEntry.status === 'green' ? "cardBgGreen" : "cardBgRed"} `}>
            {(userInfo.data.userRole.includes("CropCentre - Agency Admin") || userInfo.data.userRole.length<=0 )&&
            <Link style={{textDecoration:"none"}} to = '/StockManagement/StocktakeEntries'>
                <span>Go To Stocktake Entries</span>
                <span className="bgImage">&nbsp;</span>
            </Link>
            }   
            </div>
        </div>
        
     </>
            
    
    )
}
export default DashBoardStocks;