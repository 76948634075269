//in useActions.ts file
import {
  SET_USER,
  SET_ERRORS,
  CLEAR_ERRORS,
  SET_UNAUTHENTICATED,
  SET_PASSWORD_HINTS,
  SET_ACCOUNT_SFID,
  LOADING_USER,
} from "../types";
import axios from '../../_helpers/axios';
import { getSalesData  } from "../../redux/actions/stockManagementActions/salesActions";
import { getAddStocks, getCancelStocks } from "./stockManagementActions/requestActions";
import { getSITFBData } from "./stockManagementActions/goodsMovmtSITFBActions";
import { getSITFOData } from "./stockManagementActions/goodsMovmtSITFOActions";
import { fetchAgencyBranch } from "./reports/historyViewerActions";

export const loginUser = (userData: any, from:any , history:any) => (dispatch: any) => {
  dispatch({ type: LOADING_USER });
  // user login data from login form
  const data ={
    userName:userData.email,
    password:userData.password,
    remember:userData.isRemember
  }

  // const params = {
  //   client_id:"98aa51b3-c973-4498-8314-6013993a81cb",
  //   client_secret:"a-R7Q~qRj_gFEgQdcSuFP9Pu8h6Z1O.g7SYUm",
  //   grant_type:"client_credentials",
  //   scope:"98aa51b3-c973-4498-8314-6013993a81cb/.default"
  // }

  // call for user actual login after gettting token from Azur server
  // const headers = {
  //   'Access-Control-Allow-Origin': '*',
  //   'Content-Type': 'application/json',
  // }

  //axios.defaults.headers.common = {'Authorization': `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6ImpTMVhvMU9XRGpfNTJ2YndHTmd2UU8yVnpNYyIsImtpZCI6ImpTMVhvMU9XRGpfNTJ2YndHTmd2UU8yVnpNYyJ9.eyJhdWQiOiI5OGFhNTFiMy1jOTczLTQ0OTgtODMxNC02MDEzOTkzYTgxY2IiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9mY2IyYjM3Yi01ZGEwLTQ2NmItOWI4My0wMDE0YjY3YTdjNzgvIiwiaWF0IjoxNjUwNDM3MzIyLCJuYmYiOjE2NTA0MzczMjIsImV4cCI6MTY1MDQ0MTIyMiwiYWlvIjoiRTJaZ1lDaWI4ZjNWOUNmL2p1aG5Xd1FzWTNXcUFnQT0iLCJhcHBpZCI6Ijk4YWE1MWIzLWM5NzMtNDQ5OC04MzE0LTYwMTM5OTNhODFjYiIsImFwcGlkYWNyIjoiMSIsImlkcCI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0L2ZjYjJiMzdiLTVkYTAtNDY2Yi05YjgzLTAwMTRiNjdhN2M3OC8iLCJvaWQiOiI5NmQzM2Y0Ni1hNDE0LTQzMjYtYWU4NS0xMTE5N2NjOGExN2UiLCJyaCI6IjAuQVFzQWU3T3lfS0JkYTBhYmd3QVV0bnA4ZUxOUnFwaHp5WmhFZ3hSZ0U1azZnY3NMQUFBLiIsInN1YiI6Ijk2ZDMzZjQ2LWE0MTQtNDMyNi1hZTg1LTExMTk3Y2M4YTE3ZSIsInRpZCI6ImZjYjJiMzdiLTVkYTAtNDY2Yi05YjgzLTAwMTRiNjdhN2M3OCIsInV0aSI6IjZnbkNTVnpwTzBhaEVmTWxuQUlEQUEiLCJ2ZXIiOiIxLjAiLCJlbWFpbF92ZXJpZmllZCI6InRydWUiLCJodHRwczovL2JheWVyLmNvbS9hcHBfZGlzcGxheW5hbWUiOiJBTlotQ1JPUENFTlRSRS1QT1JUQUwtTk9OLVBST0QifQ.h6eDkJFcqmvF87-y-zBNa7j_I5C4HteqS0f43UhGpk-w2pUOvaKuztpvrCBPjkdcnKVWCjAMX1qm3mvm566nOMdTb4WeL4tVuwyumqR3v-Mhjv-qC5tynWyXWPsIjFeiL9OrmveskkFpkbWMEtaxWnY2vljs4WP0UJCBdarkn7CeawA7rYuMQXVIVVge__Lc2zhwhfCflLgrum4PwAnN9hOwWmK4d_kO8AQrga0RxZpmmPY0Owlar8ic7socPyq8YsrHAlyFEwIyZQAXm40k9YiVGRZVuBBE6t1xTwGvQVGh62xkYvi_DOa0GjotBK4LIyO0BdL2JtKJUhZS5pACIQ`}
  dispatch(getLogin(data, from , history))
  // axios.post("https://login.microsoftonline.com/fcb2b37b-5da0-466b-9b83-0014b67a7c78/oauth2/v2.0/token",{headers ,params})
  // .then(res => {
  //   console.log("Azur Response" , res);
  //   if(res.data.access_token){
  //     axios.defaults.headers.common = {'Authorization': `Bearer ${res.data.access_token}`}
  //     localStorage.setItem("Azurtoken", `Bearer ${res.data.access_token}`);
  //     dispatch(getLogin(data, from , history))
  //   }
  // })
  // .catch((err) => {
  //   console.log(err);
  //   dispatch({
  //     type: SET_ERRORS,
  //     payload: "Something went wrong at API server , Please contact Administrator",
  //   });
  // });
  

  };

//for fetching authenticated user information
export const getLogin= (data:any,from:any ,history:any ) => (dispatch: any) => {
  axios
  .post("usermanagement/login", data)
  .then((res) => {
    if(res.data.message!=='login Success'){
        dispatch({
          type: SET_ERRORS,
        // payload:res.data.message,
        payload: res.data.message
        });
        
        if(res.data.message==='Invalid Password'){
          dispatch({
            type: SET_PASSWORD_HINTS,
          // payload:res.data.message,
          payload:res.data
          });
        }
    }else {
        // remember me code
        if(data.remember===true){
          localStorage.setItem("RememberData", JSON.stringify(data));
        }else{
          localStorage.removeItem("RememberData")
        }

        //end here 
        const token = `${res.data.token}`;
        //const token = Math.random().toString(36); // created dummy token 
        localStorage.setItem("UserInfo", JSON.stringify(res.data));
        const agencyData = {
          account_sfid: res.data.data.customerInfo[0].account_sfid,
          agencyName: res.data.data.customerInfo[0].account_name,
          salesDistrict: res.data.data.customerInfo[0].salesArea_salesDistrict,
          account_sapAccountNumber:res.data.data.customerInfo[0].account_sapAccountNumber,
          salesArea_salesDistrictName:res.data.data.customerInfo[0].salesArea_salesDistrictName,
          salesArea_salesGroup:res.data.data.customerInfo[0].salesArea_salesGroup,
          grp_name:res.data.data.customerInfo[0].grp_name
        }
        localStorage.setItem("SelectedAgency", JSON.stringify(agencyData));
        localStorage.setItem("isLogin", 'true');
        localStorage.setItem("token", `${token}`); //setting token to local storage
        axios.defaults.headers.common["cc-session-token"] = token; //setting authorize token to header in axios
      //  dispatch(getStockStacks(res.data.data.customerInfo[0]));
        //)
        dispatch({ type: CLEAR_ERRORS });
        dispatch({ type: SET_ACCOUNT_SFID,payload: agencyData});
        dispatch(getSalesData(agencyData));
        dispatch(getAddStocks(agencyData));
        dispatch(getCancelStocks(agencyData));
        dispatch(getSITFBData(agencyData));
        dispatch(getSITFOData(agencyData));
        dispatch({ type: SET_USER  });
        dispatch(fetchAgencyBranch(agencyData));
        
      // window.location.href = "/home"; 
         history.push(from);
  }
    
    //history.push(from); //redirecting to index page after login success
  })
  .catch((err) => {
    console.log(err);
    dispatch({
      type: SET_ERRORS,
      payload: "API server is not working",
    });
  });

};

export const logoutUser = () => (dispatch: any) => {
  localStorage.removeItem("token");
  localStorage.removeItem("UserInfo");
  localStorage.removeItem("SelectedAgency")
  localStorage.setItem("isLogin", 'false');
  delete axios.defaults.headers.common["Authorization"];
  dispatch({
    type: SET_UNAUTHENTICATED,
  });
  window.location.href = "/login"; //redirect to login page
};
