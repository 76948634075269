//in PrivateRoute.tsxo
/*
import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { connect } from "react-redux";
interface MyRouteProps extends RouteProps {
  component: any;
  authenticated: boolean;
  rest?: any;
}


const PrivateRoute: React.SFC<MyRouteProps> = ({
  component: Component,
  authenticated,
  ...rest
}: any) => ( 
  <Route
    {...rest}
    render={(props: any) =>
      authenticated ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);
const mapStateToProps = (state: any) => ({
  authenticated: state.user.authenticated,
});
export default connect(mapStateToProps)(PrivateRoute);
*/

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector , RootStateOrAny} from 'react-redux'


import store from "../redux/stores";
import jwtDecode from 'jwt-decode';//you must install jwt-decode using npm
const PrivateRoute = ({ component: Component, path }: any) => {
  let authToken:any = localStorage.getItem('token');
  const userInfo = useSelector((state: RootStateOrAny) => state.user);
  if(authToken){
    
    const decodedToken:any = jwtDecode(authToken);
    if (decodedToken.exp * 1000 < Date.now()) {
      userInfo.authenticated=false;
      localStorage.removeItem("token");
      localStorage.removeItem("UserInfo");
      localStorage.removeItem("SelectedAgency")
      store.dispatch({type:"SET_UNAUTHENTICATED"});
    }
  }

  if (userInfo.authenticated===false) {

    return <Redirect to="/login" />;
  }

  return <Route component={Component} path={path} />;
};

export default PrivateRoute;