import React from 'react'

const TermsConditions = () => {
  return (
    <div className='terms-conditions'>
        <article className="grid-col">
            <h1>Terms of Membership</h1>
            <p className="justify">I accept and acknowledge that my membership of the Bayer CropScience Pty Ltd (“Bayer”) CropCentre Website (“the Website”) is subject to my agreement to the following conditions:</p>
            <ol type="1" start="1" >
            <li>My membership of the Website can be terminated without notice by Bayer CropScience Pty Ltd at any time;</li>
            <br />
            <li>I agree that Bayer will collect and store personal information about me as a result of membership of the Website. I consent to &nbsp;Bayer collecting, storing and using that information for purposes such as:
            <ul type="disc">
            <li>Enhancing its business relationship with me;</li>
            <li>Providing information to me about its products and services.</li>
            </ul>
            I understand that I can view Bayer’s full privacy statement on its website. I also understand that I can request access to personal information that Bayer holds about me at any time.</li>
            <br />
            <li><strong>Use of the Website</strong>: I agree that my use of the Website is subject to the General Conditions Of Use as displayed on the Website</li>
            </ol>
        </article>
    </div>
  )
}

export default TermsConditions