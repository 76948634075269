import {
    OUTPUT_DATA_SS,FILTER_SELECTED_SS,OUTPUT_FILTER_SS,GROUP_FILTER_SS,GROUP_DATA_SS,
    SHOW_STOCKS_SS,SHOW_STOCKS_FILTER_SS,FKA_DATA_SS,FKA_FILTER_SS,SOR_DATA_SS,SOR_DATA_FILTER_SS,
    SOH_DATA_SS,SOH_DATA_FILTER_SS,YEAR_DATA_SS,YEAR_DATA_FILTER_SS,START_MONTH_DATA_SS,
    START_MONTH_DATA_FILTER_SS,END_MONTH_DATA_SS,END_MONTH_DATA_FILTER_SS,BRANCH_DATA_SS,BRANCH_DATA_FILTER_SS,RESET_STOCK_DATA,
    AGENCY_BRANCH_OBJ_SS,
    SAP_MATERIAL_DATA_SS,
    SAP_MATERIAL_FILTER_SS,
    SAP_MATERIAL_OBJ_SS
  } from "../../types";

  const initialState = {
    selectedFilter:'',            // Select3d Filter Name
    outputData:[],                // Out put filter all data
    outputDataFilter:'View in browser',          // selected data from output filter
    groupData:[],                // group filter all data
    groupDataFilter:'Product',          // selected data from group filter
    stockData:[],                // stock filter all data
    stockDataFilter: 'Sales Only',          // selected data from stock filter
    fkaData:[],                // fka filter all data
    fkaDataFilter:'Yes',          // selected data from fka filter
    sorData:"",                // sor filter all data
    sorDataFilter:"",          // selected data from sor filter
    sohData:"",                // group filter all data
    sohDataFilter:"",          // selected data from group filter
    yearData:[],                // year filter all data
    yearDataFilter:'2022',          // selected data from year filter
    startMonthData:[],                // start month filter all data
    startMonthDataFilter:'January',          // selected data from start month filter
    endMonthData:[],                // end month filter all data
    endMonthDataFilter:'December',          // selected data from end month filter
    branchData:[],                // branch filter all data
    branchDataFilter:[],
    agencyBranchObj: [],
    materialsData:[],                // materials filter all data
    materialsDataFilter:[],          // selected data from materials filter
    sapMaterialsObj: []
  };

  export default function (state = initialState, action) {
    switch (action.type) {
        case FILTER_SELECTED_SS:
            return {
              ...state,
              selectedFilter: action.payload,
            };
            case RESET_STOCK_DATA:
                return initialState;
            
        case OUTPUT_DATA_SS:
            return {
              ...state,
              outputData: action.payload,
            };
        case OUTPUT_FILTER_SS: // to get output seelcted filed
            return {
                ...state,
                outputDataFilter: action.payload,
            };
        case GROUP_DATA_SS:
            return {
                ...state,
                groupData: action.payload,
            };
        case GROUP_FILTER_SS: // to get output seelcted filed
            return {
                ...state,
                groupDataFilter: action.payload,
            };

        case SHOW_STOCKS_SS:
            return {
                ...state,
                stockData: action.payload,
            };
        case SHOW_STOCKS_FILTER_SS: // to get show stock seelcted filed
            return {
                ...state,
                stockDataFilter: action.payload,
            };
    
        case FKA_DATA_SS:
            return {
                ...state,
                fkaData: action.payload,
            };
        case FKA_FILTER_SS: // to get fka seelcted filed
            return {
                ...state,
                fkaDataFilter: action.payload,
            };

        case SOR_DATA_SS:
            return {
                ...state,
                sorData: action.payload,
            };
        case SOR_DATA_FILTER_SS: // to get fka seelcted filed
            return {
                ...state,
                sorDataFilter: action.payload,
            };
        case SOH_DATA_SS:
            return {
                ...state,
                sohData: action.payload,
            };
        case SOH_DATA_FILTER_SS: // to get fka seelcted filed
            return {
                ...state,
                sohDataFilter: action.payload,
            };
        case YEAR_DATA_SS:
            return {
                ...state,
                yearData: action.payload,
            };
        case YEAR_DATA_FILTER_SS: // to get year seelcted filed
            return {
                ...state,
                yearDataFilter: action.payload,
            };
        case START_MONTH_DATA_SS:
            return {
                ...state,
                startMonthData: action.payload,
            };
        case START_MONTH_DATA_FILTER_SS: // to get year seelcted filed
            return {
                ...state,
                startMonthDataFilter: action.payload,
            };
        case END_MONTH_DATA_SS:
            return {
                ...state,
                endMonthData: action.payload,
            };
        case END_MONTH_DATA_FILTER_SS: // to get year seelcted filed
            return {
                ...state,
                endMonthDataFilter: action.payload,
            };
        case SAP_MATERIAL_DATA_SS:  //to get all data for sap
            return {
                ...state,
                materialsData: action.payload,
            };
        case SAP_MATERIAL_FILTER_SS:  //to get checked filter sap
            return {
                ...state,
                materialsDataFilter: action.payload,
            };
        case SAP_MATERIAL_OBJ_SS:  //to get checked filter obj data for first btn
            return {
                ...state,
                sapMaterialsObj: action.payload,
            };
        case BRANCH_DATA_SS:
            return {
                ...state,
                branchData: action.payload,
            };
            
        case BRANCH_DATA_FILTER_SS: // to get year seelcted filed
            return {
                ...state,
                branchDataFilter: action.payload,
            };
        case AGENCY_BRANCH_OBJ_SS:
            return {
                ...state,
                agencyBranchObj: action.payload,
              };
        default:
            return state;
    }
}