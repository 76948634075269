import React from 'react';
import Button from "@mui/material/Button";
import ResetIcon from "../../assets/images/reset_icon.svg";
import ResetRtLayout from "../../assets/images/rt_layout_reset.svg";

const ResetButton = (props: any) => {
  return (
    <Button
        // type="submit"
        onClick={() => props.onClick()}
        // fullWidth
        variant="contained"
        disabled = {props.isButtonDisabled}
        sx={{
            backgroundColor: "white",
            textTransform: "none",
            // maxWidth: "120px",
            padding: "8px",
            color: '#624963 !important',
            borderRadius: "0px",
            "&:hover": {
                backgroundColor: "white",
                color:'#624963 !important',
                //you want this to be the same as the backgroundColor above
                p: {
                    paddingLeft: "14px",
                },
            },
        }}
        // className="btn-right btn-reset"
        className={"btn-right reset-btn-dimensions "+ props.customClasssName}
    >
        <p style={{ color: '#624963 !important'}}>{props.title}</p>
        <span>
            <img src={ResetIcon} className="arrow-i" alt="arrow" />
            <img src={ResetRtLayout} className="rt-layout" alt="layout" />
        </span>
    </Button>
  )
}

export default ResetButton