import { Col, Container, Row } from "react-bootstrap";
// import SaveDraftButton from '../../../components/common/SaveDraftButton';
import SubmitButton from "../../../components/common/SubmitButton";
// import ResetButton from '../../../components/common/ResetButton';
import ScrollButton from "../../../components/common/ScrollButton";

const ConfirmSale = (props) => {
  let totCnt = 0;
  const cancelSubmit = () => {
    props.cancelSubmit();
  }
  const confirmSubmit = () => {
    props.submitSales()
  }
  return (
    <Container style={{ marginTop: '10px' }}>
      <Row>
        <Col md="3" className="salesReportConfirm">
          Sales Report Confirmation
                <div className="referenceNo">Reference Number: {props.purchaseNumber}</div>
        </Col>
      </Row>
      <Row>
        <Col md="1" style={{ textAlign: 'center' }}><p><b>Sr. No.</b></p></Col>
        <Col md="2" style={{ textAlign: 'center' }}><p><b>Material Number</b></p></Col>
        <Col md="4" style={{ textAlign: 'center' }}><p><b>Description</b></p></Col>
        <Col md="1" style={{ textAlign: 'center' }}><p><b>Quantity</b></p></Col>
        <Col md="2" style={{ textAlign: 'center' }}><p><b>Price</b></p></Col>
        <Col md="2" style={{ textAlign: 'center' }}><p><b>Total</b></p></Col>
      </Row>
      <Row><Col><hr border-color="green" /></Col></Row>
      {

        props.data.map((row, key) => {
          var bgColor = (key + 1) % 2 === 0 ? 'white' : '#89d3290d';
          const rowTot = row.sales * row.productUnitPrice;
          totCnt = totCnt + rowTot
          return (
            <>
              <Row style={{ backgroundColor: bgColor }} className='Row'>
                <Col md="1" style={{ textAlign: 'center' }}><p>{key + 1}.</p></Col>
                <Col md="2" style={{ textAlign: 'center' }}><p>{row.productSAPMaterialNumber}</p></Col>
                <Col md="4" style={{ paddingLeft: '80px' }}><p>{row.productName}</p></Col>
                <Col md="1" style={{ textAlign: 'center' }}><p>{row.sales}</p></Col>
                <Col md="2" style={{ textAlign: 'center' }}><p>${row.productUnitPrice}</p></Col>
                <Col md="2" style={{ textAlign: 'center' }}><p>${rowTot}</p></Col>
              </Row>
            </>
          )
        })}
      <Row>

      </Row>
      <Row style={{ paddingTop: '20px', justifyContent: 'right' }}>
        <Col md="3">
        </Col>
        <Col md="3"></Col>
        <Col md="3">
        </Col>
        <Col md="3">
          <div><span className="totalTxt">Totals </span><span className="tax">(Excl. GST)</span> <span className="totalSales">${totCnt}</span></div>
        </Col>
      </Row>
      <Row style={{ paddingTop: '20px', justifyContent: 'right' }}>
        <Col md="3">
        </Col>
        <Col md="3"></Col>
        <Col md="3">
          <button onClick={cancelSubmit} className="cancelBtn">Cancel</button>
        </Col>
        <Col md="3">
          <SubmitButton onClick={confirmSubmit} title='CONFIRM' />
        </Col>
      </Row>
      <Row style={{ marginTop: '60px', justifyContent: 'right' }}>
        <Col md="11"></Col>
        <Col md="1"><ScrollButton /></Col>
      </Row>
    </Container>
  );
};

export default ConfirmSale;
