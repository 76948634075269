import {CSVLink} from 'react-csv';
const DownLoadCsv = (props) => {
    const obj1 = props.data;
    // const obj1= [ 
    //     {
    //     "userName" : "Parthiban Osl",
    //     "transactionDate" : "2022-May-02",
    //     "trackingnumber" : "100151001",
    //     "status" : "Processed",
    //     "SOHInfluence" : 0,
    //     "SAPMaterialNumber" : "79054815",
    //     "SAPDescription" : "ATLANTIS OD120 5L (4/CTN) AU",
    //     "salesGroup" : "152",
    //     "runningBalance" : 0,
    //     "referenceNumber" : "0807626369",
    //     "qty" : "[40]",
    //     "openingBalance" : -9.0,
    //     "lineType" : "StockInTransitFromBayer",
    //     "comments" : "StockInTransitFromBayer-Processed-NULL",
    //     "branchNumber" : "0003310520",
    //     "branchName" : "A.W. VATER & CO. SADDLEWORTH"
    //   }, {
    //     "userName" : "Parthiban Osl",
    //     "transactionDate" : "2022-Mar-25",
    //     "trackingnumber" : "100146429",
    //     "status" : "Processed",
    //     "SOHInfluence" : 0,
    //     "SAPMaterialNumber" : "79054815",
    //     "SAPDescription" : "ATLANTIS OD120 5L (4/CTN) AU",
    //     "salesGroup" : "152",
    //     "runningBalance" : 0,
    //     "referenceNumber" : "0807479613",
    //     "qty" : "[20]",
    //     "openingBalance" : -9.0,
    //     "lineType" : "StockInTransitFromBayer",
    //     "comments" : "StockInTransitFromBayer-Processed-NULL",
    //     "branchNumber" : "0003310520",
    //     "branchName" : "A.W. VATER & CO. SADDLEWORTH"
    //   }, {
    //     "userName" : "Parthiban Osl",
    //     "transactionDate" : "2022-May-05",
    //     "trackingnumber" : "",
    //     "status" : "Processed",
    //     "SOHInfluence" : 1,
    //     "SAPMaterialNumber" : "79054815",
    //     "SAPDescription" : "ATLANTIS OD120 5L (4/CTN) AU",
    //     "salesGroup" : "152",
    //     "runningBalance" : 0,
    //     "referenceNumber" : "0807626369",
    //     "qty" : "40",
    //     "openingBalance" : -9.0,
    //     "lineType" : "StockReceivedFromBayer",
    //     "comments" : "StockReceivedFromBayer-Processed-NULL",
    //     "branchNumber" : "0003310520",
    //     "branchName" : "A.W. VATER & CO. SADDLEWORTH"
    //   }, {
    //     "userName" : "Parthiban Osl",
    //     "transactionDate" : "2022-Apr-05",
    //     "trackingnumber" : "",
    //     "status" : "Processed",
    //     "SOHInfluence" : 1,
    //     "SAPMaterialNumber" : "79054815",
    //     "SAPDescription" : "ATLANTIS OD120 5L (4/CTN) AU",
    //     "salesGroup" : "152",
    //     "runningBalance" : 0,
    //     "referenceNumber" : "0807479613",
    //     "qty" : "20",
    //     "openingBalance" : -9.0,
    //     "lineType" : "StockReceivedFromBayer",
    //     "comments" : "StockReceivedFromBayer-Processed-NULL",
    //     "branchNumber" : "0003310520",
    //     "branchName" : "A.W. VATER & CO. SADDLEWORTH"
    //   }, {
    //     "userName" : "Parthiban Osl",
    //     "transactionDate" : "2022-Jun-30",
    //     "trackingnumber" : "",
    //     "status" : "Submitted",
    //     "SOHInfluence" : -1,
    //     "SAPMaterialNumber" : "79054815",
    //     "SAPDescription" : "ATLANTIS OD120 5L (4/CTN) AU",
    //     "salesGroup" : "152",
    //     "runningBalance" : 0,
    //     "referenceNumber" : "300622",
    //     "qty" : "10",
    //     "openingBalance" : -9.0,
    //     "lineType" : "SalesReport",
    //     "comments" : "SalesReport-Submitted-NULL",
    //     "branchNumber" : "0003310520",
    //     "branchName" : "A.W. VATER & CO. SADDLEWORTH"
    //   }, {
    //     "userName" : "Parthiban Osl",
    //     "transactionDate" : "2022-Jun-24",
    //     "trackingnumber" : "",
    //     "status" : "Processed",
    //     "SOHInfluence" : -1,
    //     "SAPMaterialNumber" : "79054815",
    //     "SAPDescription" : "ATLANTIS OD120 5L (4/CTN) AU",
    //     "salesGroup" : "152",
    //     "runningBalance" : 0,
    //     "referenceNumber" : "240622",
    //     "qty" : "0",
    //     "openingBalance" : -9.0,
    //     "lineType" : "SalesReport",
    //     "comments" : "SalesReport-Processed-NULL",
    //     "branchNumber" : "0003310520",
    //     "branchName" : "A.W. VATER & CO. SADDLEWORTH"
    //   }
    //   ]
      
      
     
     let data2 =  obj1.map(d=> {
        //data.push(d.reportDataWrapperList);
       return d.reportDataWrapperList;
      } );
     
     let finalDta = [];
      data2.map(rs=> {
        rs.map(rs2=> {
            let NewData = {
              'BranchName': rs2.branchName,
              'BranchNumber': rs2.branchNumber,
              'SalesGroup':rs2.salesGroup,
              'SAPMaterial':rs2.SAPMaterialNumber,
              'SAPDescription':rs2.SAPDescription,
              'TransactionDate':rs2.transacDate,
              'LineType':rs2.lineType,
              'OpeningBalance':rs2.openingBalance,
              'Qty':rs2.qty,
              'Inf':rs2.SOHInfluence,
              'RunningBalance':rs2.runningBalance,
              'Status':rs2.status,
              'ReferenceNumber':rs2.referenceNumber,
              'TrackingNumber':rs2.trackingnumber,
              'UserName':rs2.userName,
              'Comments':rs2.comments,
            }
           finalDta.push(NewData);
           return null;
        })
        return null;
      })

   
      const header = Object.keys(finalDta[0]);

    return (
        <>
        {finalDta.length > 0 && 
         <CSVLink  filename={props.filename} data={finalDta} headers={header}>
         Your file is available here: File - Right click and [save target as] or [save link as].... to download
        </CSVLink>
        }
          {finalDta.length <= 0 && <div style={{color:'red'}}>No records to Load CSV</div>}
        </>
    )
}

export default DownLoadCsv;