import React, { useState, useRef  , useEffect } from "react";
import { Carousel } from "react-bootstrap";
import NextBtn from '../../assets/images/next_icon.png';
import PrevBtn from '../../assets/images/previous_icon.png';

import StatusDonutChart from './StatusDonutChart';
import axios from '../../_helpers/axios'

const TrainingStatus = ()=> {
  const [data1 , setData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const  UserInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
  const params = {
    contactSfid: UserInfo.data.userDetails.user_contactSfid
  }

  useEffect(() => {
    axios.get("util/training", {params})
    .then((res) => {
      setData(res.data.data.trainingCourseEnrollmentDetails);
      setChartData(res.data.data.enrollmentStatus);
      settotalCourseCnt(res.data.data.totalCourseCount)
     
    })
    .catch((err) => {
      console.log(err);
    });
  },[])

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
//    const data = [
//     {
//         "trainingCourseName" : " Prosper@ 500 EC Fungicide in barley corp Stewardship 2022",
//         "enrollmentDate" : "12/03/2022",
//         "completionDate" : "12/03/2022",
//         "totalCourseCnt": "48",
//         "enrollmentStatus" : [
//             {
//             "Status":"Completed",
//             "Value":10,
//            },
//            {
//             "Status":"In Progress",
//             "Value":23,
//            },
//            {
//             "Status":"Not Started",
//             "Value":15,
//            }
//         ]
//     },
//     {
//         "trainingCourseName" : " Prosper@ 600 EC Fungicide in barley corp Stewardship 2021",
//         "enrollmentDate" : "10/03/2021",
//         "completionDate" : "12/03/2022",
//         "totalCourseCnt": "113",
//         "enrollmentStatus" : [
//             {
//             "Status":"Completed",
//             "Value":40,
//            },
//            {
//             "Status":"In Progress",
//             "Value":50,
//            },
//            {
//             "Status":"Not Started",
//             "Value":23,
//            }
//         ]
//     }
//  ];
 
 let varNextIndex = 0;
 const [index, setIndex] = useState(0);
 
 const [totalCourseCnt, settotalCourseCnt] = useState(0);
 const ref = useRef(null);
 const handleSelect = (selectedIndex, e) => {
   varNextIndex = selectedIndex;
   setIndex(selectedIndex);
 };

 const onPrevClick = () => {
   ref.current.prev();
   //setChartData(data[varNextIndex].enrollmentStatus);
   //settotalCourseCnt(data[varNextIndex].totalCourseCnt)
 };

 const onNextClick = () => {
   ref.current.next();
  //  const nextIndex = index + 1;
   
   //setChartData(data[varNextIndex].enrollmentStatus);
   //settotalCourseCnt(data[varNextIndex].totalCourseCnt)
   
 };
 
    return(
         
        <div style={{width: '100%'}}>
        <div className="notification-head m-2" style={{alignContent: 'center'}}>Training Status</div>
        <div className="row col-12">
        {chartData && 
        <div className="cardTitle col-4"> 
       <StatusDonutChart data={chartData} totalCnt={totalCourseCnt} />
        </div>
        }
      {data1 && 
        <div className="col-8 ">
        <div className="d-flex justify-content-md-between statusTitles">
        <div >Completed <span  className="comStatus">&nbsp;&nbsp;&nbsp;&nbsp;</span> </div>
         <div >Enrolled <span  className="inProgStatus">&nbsp;&nbsp;&nbsp;&nbsp;</span></div>
          <div>Incomplete <span  className="notStartStatus">&nbsp;&nbsp;&nbsp;&nbsp;</span></div> 

        </div>
       <div style={{color:'#96A8AE'}}>
           ....................................................................................................</div>
        <div>
        {/* <div style={{marginLeft:-14,position:"absolute"}}  onClick={onPrevClick}><img src={PrevBtn} />Prev</div> */}
      <div style={{marginLeft:-25,position:"absolute"}}  onClick={onPrevClick}><img style={{width:15, height:15}} alt='previous btn icon' src={PrevBtn} /></div>
         <div style={{marginLeft:400,position:"absolute"}}   onClick={onNextClick}><img  style={{width:15, height:15}} alt='next btn icon' src={NextBtn} /></div>
         
        <Carousel 
         activeIndex={index}
         onSelect={handleSelect}
         indicators={false}
         controls={true}
         ref={ref}
        interval={null}>
    {data1.map((data , index) => 
  <Carousel.Item interval={1000} key={index}>    
  <div className="d-block w-100  d-flex justify-content-md-between " >
      <div>
          <div className="ongoingCourse">Ongoing Course</div>
          <div className="courseDtl">
             {data.trainingCourse_title}
          </div>
      </div>
      <div>
      <div className="ongoingCourse"> Enrollment date</div>
    <div className="courseDate">{formatDate(data.trainCourseEnrollment_enrollDate)}</div>
       </div>
  </div>
  </Carousel.Item>
  )}

</Carousel>

        </div>
        
        </div>
}
{!data1 &&   <div className="no-training" style={{color: 'rgb(150, 168, 174)', textAlign: 'center'}}>No Courses Found</div>}
    </div>

    {/* {data1 && <div className="text-end"><span className="presumeCourese">Presume Course {'>'}</span></div>} */}
    
    </div>
    )
}
export default TrainingStatus;