//in userReducer.ts
import {
  SET_HOME_STACKS,
  LOADING_UI,
  SET_HOME_STOCKS
} from "../types";
const initialState = {
  stocks:{},
  loading:false,
  stacks: {
    status:200,
    data:{
    "stockOnRequest": {
      "qty": 0,
      "price": 0
  },
  "stockInTransit": {
      "qty": 0,
      "price": 0
  },
  "stockOnHand": {
      "qty": 0,
      "price": 0
  },
  "stockUnderInvestigation": {
      "qty": 0,
      "price": 0
  },
  "stockBeingPicked": {
      "qty": 0,
      "price": 0
  }
}
 }
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case SET_HOME_STACKS:
      return {
        ...state,
        stacks: action.payload.data.length===0?state.stacks: action.payload,
      };
    case SET_HOME_STOCKS:
      return {
        ...state,
        
        stocks:action.payload,
        loading:false,
      }
      case LOADING_UI:
        return {
          ...state,
          loading:true
        }
    default:
      return state;
  }
}
