import { GET_ADD_STOCKS, GET_CANCEL_STOCKS, SET_ERRORS, LOADING_UI } from "../../types";
import axios from '../../../_helpers/axios';

export const getAddStocks = (data:any) =>  async (dispatch: any) => {
   
  dispatch({type:LOADING_UI});
  axios.get(`ordermanagement/stockrequest?type=add&accountSfid=${data.account_sfid}`)
  .then((res:any) => {
      if(res.status===200 || res.status===208 || res.status===201 ){
        dispatch(
            {
              type: GET_ADD_STOCKS,
              payload: res.data
            }
          )
        
      }else {
        dispatch(
            {
              type: SET_ERRORS,
              payload: res.data
            }
          )
      }

      
   })
.catch((err) => {
    const status = err.response.status?err.response.status:400;
    let message = "Something went wrong please contact Administrator";
    if(err.response.status ===500){
        message="Fetch Failed , Please check resource";
    }else if(err.response.status ===404){
        message="Requested resource not found";
    }else{
      message="Something went wrong";
    }
    dispatch(
        {
          type: SET_ERRORS,
          payload: {status:status,message:message}
        }
      )

});

};


export const getCancelStocks = (data:any) =>  async (dispatch: any) => {
   
  dispatch({type:LOADING_UI});
  axios.get(`ordermanagement/stockrequest?type=adjust&accountSfid=${data.account_sfid}`)
  .then((res:any) => {
      if(res.status===200 || res.status===208 || res.status===201 ){
        dispatch(
            {
              type: GET_CANCEL_STOCKS,
              payload: res.data
            }
          )
        
      }else {
        dispatch(
            {
              type: SET_ERRORS,
              payload: res.data
            }
          )
      }

      
   })
.catch((err) => {
    const status = err.response.status?err.response.status:400;
    let message = "Something went wrong please contact Administrator";
    if(err.response.status ===500){
        message="Fetch Failed , Please check resource";
    }else if(err.response.status ===404){
        message="Requested resource not found";
    }else{
      message="Something went wrong";
    }
    dispatch(
        {
          type: SET_ERRORS,
          payload: {status:status,message:message}
        }
      )

});

};