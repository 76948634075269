import React ,{useState,useEffect} from 'react'
import './personalDetails.scss'
import SubmitButton from '../../components/common/SubmitButton';
import ResetButton from '../../components/common/ResetButton';
import errorLogo from '../../../src/assets/images/error_outline_dp.svg'
import info_blue from '../../../src/assets/images/info_blue.svg'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import axios from '../../_helpers/axios'
import errorImg from '../../../src/assets/images/error_password.svg'
import successImg from '../../../src/assets/images/success_password.svg'
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import Link from "@mui/material/Link";
import { Link } from 'react-router-dom';
import {  Spinner } from 'react-bootstrap';
import ErrorPopup from '../../components/common/ErrorPopup';
import NotificationPopup from '../../components/common/NotificationPopup';

const ChangePassword = () => {
  const  UserInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
  const [loading , setLoading ] = useState(false);
  const [okBtn, setokbtn] = useState(false);
  const [yesBtn , setYesBtn ] = useState(true);
  const [closeBtn , setCloseBtn ] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorHeading, setErrorHeading] = useState('');
  const [showError, setShowError] = useState(false);
  const [isSaveMsg , setSaveMsg ] = useState(false);
  const initialValues = {
  user_contactSfid:UserInfo ? UserInfo.data.userDetails.user_contactSfid:'',
  userName:UserInfo ? UserInfo.data.userDetails.user_userName:'',
  oldPassword:'',
  newPassword:'',
  newPasswordConfirm:'',
  newPasswordHint:'',
  currentPassword:''

}

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const [formValues, setFormValues] = useState(initialValues) 
const [formErrors, setFormErrors] = useState({})
const [isSubmit, setIsSubmit] = useState(false);
const [successCount, setSuccessCount] = useState(false)
const [successCharNum, setSuccessCharNum] = useState(false)
const [successUpperLower, setSuccessUpperLower] = useState(false)
const [successSpecial, setSuccessSpecial] = useState(false)
const [indicatorDisplay, setIndicatorDisplay] = useState(false)
const [checkedIndicatorCount, setCheckedIndicatorCount] = useState(0)
const [colorA, setColorA]= useState('#DCE8EB')
const [colorB, setColorB]= useState('#DCE8EB')
const [colorC, setColorC]= useState('#DCE8EB')
const [colorD, setColorD]= useState('#DCE8EB')
const [unsavedChanges, setUnsavedChanges] = useState(true);
var saveMsg  = [{notificationType: 'success', header: 'Password Changed', message: `Your password has been changed`}]


const handleChange = (e) => {
  const { name, value } = e.target;
  setFormValues({ ...formValues, [name]: value });
};


// const handleChangeCnfirmPass = (e) => {
//   const { name, value } = e.target;
//   setFormValues({ ...formValues, [newPasswordConfirm]: value });
// };

const handleChangePassword=(e)=>{
  const value = e.target.value;
  const regLower = /(?=.*[a-z])/
  const regUpper = /(?=.*[A-Z])/
  const regNumber = /(?=.*[0-9])/
  const regSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  let resNumber = regNumber.test(value)
  let resLower = ( regLower.test(value))
  let resUpper = ( regUpper.test(value))
  if(resNumber && (resLower || resUpper)){
    setSuccessCharNum(true)
  }else{setSuccessCharNum(false)
    }
  if(regSpecialChar.test(value)){setSuccessSpecial(true)}else{setSuccessSpecial(false)}
  if(resLower && resUpper){setSuccessUpperLower(true)}else{setSuccessUpperLower(false)}
  if(value.length>6){ setSuccessCount(true)}else{setSuccessCount(false)}
  
  setFormValues({ ...formValues, ['newPassword']: value });
}
async function postData(url = '', data = {}) {
  await axios.post(url, formValues).then((res) => {
     if(res.status===200){
      setSaveMsg(true)
     }else if(res.status===208){
      setSaveMsg(false)
      setErrorHeading('Password Error');
      setErrorMsg(res.data.message);
      setShowError(true)
      setokbtn(true);
      setYesBtn(false);
      setCloseBtn(false)
     }
     setLoading(false)
    })
    .catch((err) => {
      setSaveMsg(false)
      setLoading(false)
      console.log(err);
    });
} 


function submitAndfetchData() {
  // if(submitClicked) submitStockRequest();
  // else fetchData();
  setFormErrors(validate(formValues));
  const error = validate(formValues);
  if(Object.keys(error).length === 0){
    setYesBtn(true);
      setCloseBtn(true)
      setokbtn(false)
    setErrorHeading('Change Password');
    setErrorMsg('Are you sure you want to change the password?');
    setShowError(true)
  }
  
}

const handleReset = () => {
  setFormValues(initialValues)
  if(unsavedChanges){
      setErrorHeading('Reset filters');
      setErrorMsg('All your selected options will be cleared.');
      
      setokbtn(false);
      setYesBtn(true);
      setCloseBtn(true);
      
      
  }
  
}

const handleSubmit = () => {
  setShowError(false)
    setIsSubmit(true);
    setLoading(true)
    postData('usermanagement/changepassword', formValues);  
}

// const requestOTP = () => {
//     const  UserInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
//     let username= {'userName':UserInfo.data.userDetails.user_userName}
//     postDataOTP('usermanagement/generateOneTimePassword', username);
  
//   }

async function postDataOTP(url = '', data) {
  await axios.post(url, data).then((res) => {
    })
    .catch((err) => {
      console.log(err);
    });
} 

useEffect(() => {
  const PassIndicatorArr = [successCount, successCharNum, successUpperLower, successSpecial];
  if(successCount){let checkedIndicatorCount =(PassIndicatorArr.filter((value) => value).length)
  setCheckedIndicatorCount(checkedIndicatorCount)}
}, [successCount,successSpecial,successUpperLower,successCharNum]);

useEffect(() => {
  if (Object.keys(formErrors).length === 0 && isSubmit) {
    console.log(formValues);
  }
}, [formErrors]);

const validate =(values)=>{
  const errors ={}

   if (!values.oldPassword) {
    errors.oldPassword = "Current Password is required!";
   } 
  if (!values.newPassword) {
    errors.newPassword = "New password is required!";
  } 
  if (!values.newPasswordConfirm) {
    errors.newPasswordConfirm = "hint is required!";
  }
  if (values.newPasswordConfirm !== values.newPassword) {
    errors.newPasswordConfirm = "New Password and Confirm New Password Do not Match";
  } 
  // if (!values.currentPassword) {
  //   errors.currentPassword = "Current Password is required!";
  // } 
return errors

}




//password tooltip
const popover = (
  <Popover id="popover-basic">
    <Popover.Body className='popover-password'>
      In order to protect your account, make sure password is:
      <p className='popup-text'> <img src={successCount ? successImg :errorImg} alt='success/error icon' className='pass-indicator-img'/>Is atleast <strong className='password-strong'>7</strong> Characters</p>
      <p className='popup-text'> <img src={successCharNum ? successImg :errorImg} alt='success/error icon' className='pass-indicator-img'/>Include both <strong className='password-strong'> letters </strong> and <strong className='password-strong'> numbers </strong></p>
      <p className='popup-text'><img src={successUpperLower ? successImg :errorImg} alt='success/error icon' className='pass-indicator-img'/>include both <strong className='password-strong'> uppercase </strong> and <strong className='password-strong'> lowercase </strong></p>
      <p className='popup-text'><img src={successSpecial? successImg :errorImg} alt='success/error icon' className='pass-indicator-img'/>Include atleast one <strong className='password-strong'> special Characters </strong></p>
    </Popover.Body>
  </Popover>
);

useEffect(() => {
  if (checkedIndicatorCount===0){
    setColorA('#DCE8EB')
    setColorB('#DCE8EB')
    setColorC('#DCE8EB')
    setColorD('#DCE8EB')
  }else if(checkedIndicatorCount===1){
    setColorA('#D0021B')
    setColorB('#DCE8EB')
    setColorC('#DCE8EB')
    setColorD('#DCE8EB')
  }else if(checkedIndicatorCount===2){
    setColorA('#D0021B')
    setColorB('#D0021B')
    setColorC('#DCE8EB')
    setColorD('#DCE8EB')
  }else if(checkedIndicatorCount===3){
    setColorA('#89D329')
    setColorB('#89D329')
    setColorC('#89D329')
    setColorD('#DCE8EB')

  }else if(checkedIndicatorCount===4){
    setColorA('#66B512')
    setColorB('#66B512')
    setColorC('#66B512')
    setColorD('#66B512')
  }
}, [checkedIndicatorCount]);

// const labelTerms =()=>{
//   return <span><p>I agree to the site </p><p>Terms of Membership</p></span>
// }
  return (
    <div>
    {/* <Container> */}
    {isSaveMsg &&  <NotificationPopup showNotiPopup={true} notifications={saveMsg} className="noti-popup mb-3" />} 
    {loading && <div className="centeredSpin">	<Spinner animation="border" role="status" ></Spinner> </div> }
    <form className='personal-details-form' >
      <div className='personal-details'>
        <span className="detail-label changepass-label required"><label htmlFor="oldPassword" className='pdetail-label'>Current Password</label>:</span>
        <span className="detail-input detail-input-pass">
          <input type="password" className={'form-control pdetail-form ' + (formErrors.oldPassword ? 'error-form-input':'')} id="oldPassword" name="oldPassword"  value={formValues.oldPassword} onChange={handleChange} placeholder="Enter current password." autoComplete="off" required/>
          {/* EYE BUTTON NEEDS TO IMPLEMENT HERE */}
          {/* <span className='pass-hint'><p onClick={requestOTP}>Request OTP</p><p>Show Hint</p></span> */}
          {/* REQUEST OTP AND HINT FUNCTIONALITY REMAINING */}
          </span>

        <span className='WarningMsg pdetal-Error pdetal-Error-pass'>{formErrors.oldPassword ? <img src={errorLogo} alt='error icon' className="error-logo" />:''}{formErrors.oldPassword}</span>
      </div>
      <div className='personal-details'>
        <span className="detail-label changepass-label required"><label htmlFor="newPassword" className='pdetail-label'>New Password </label>:</span>
        <span className="detail-input pass-indicator-img detail-input-pass">
          <span className='pass-indicator-line'>
          <input type="password" className={'form-control pdetail-form ' + (formErrors.newPassword ? 'error-form-input':'')} id="newPassword" name='newPassword' onFocus={ ()=>{setIndicatorDisplay(true)} } onBlur={()=>{setIndicatorDisplay(false)}} placeholder="Enter new password here" onChange={handleChangePassword} value={formValues.newPassword}  autoComplete="off" 
          
          required/>
          {indicatorDisplay && <span className='line-display' >
          <span className="pass-line" style ={{backgroundColor: colorA}}></span>
          <span className="pass-line" style ={{backgroundColor: colorB}}  ></span>
          <span className="pass-line" style ={{backgroundColor: colorC}} ></span>
          <span className="pass-line" style ={{backgroundColor: colorD}} ></span>
          </span>}
          </span>
          <span>
          <OverlayTrigger trigger="click" placement="right" overlay={popover}>
        <img src={info_blue} alt='info icon' className="error-logo info-password" />
        </OverlayTrigger>
          </span>
        </span>
        <span className='WarningMsg pdetal-Error pdetal-Error-pass'>{formErrors.newPassword ? <img src={errorLogo} alt='error icon' className="error-logo" />:''}{formErrors.newPassword}</span>

      </div>
      <div className='personal-details'>
        <span className="detail-label changepass-label required"><label htmlFor="newPasswordConfirm" className='pdetail-label'>Confirm New Password: </label></span>
        <span className="detail-input detail-input-pass"><input type="password" className={'form-control pdetail-form ' + (formErrors.newPassword ? 'error-form-input':'')} id="newPasswordConfirm" name='newPasswordConfirm' placeholder="Re-Enter New Password" onChange={handleChange} value={formValues.newPasswordConfirm} autoComplete="off" /></span>
        <span className='WarningMsg pdetal-Error pdetal-Error-pass'>{formErrors.newPasswordConfirm ? <img src={errorLogo} alt='error icon' className="error-logo" />:''}{formErrors.newPasswordConfirm}</span>


      </div>
      <div className='personal-details'>
        <span className="detail-label changepass-label"><label htmlFor="newPasswordHint" className='pdetail-label'>Password Hint : </label></span>
        <span className="detail-input detail-input-pass"><input type="text" className="form-control pdetail-form" id="newPasswordHint" name="newPasswordHint" placeholder="Enter Password Hint" onChange={handleChange} value={formValues.newPasswordHint} autoComplete="off"/></span>
        <span className='WarningMsg pdetal-Error pdetal-Error-pass'>{formErrors.newPasswordHint ? <img src={errorLogo} alt='error icon' className="error-logo" />:''}{formErrors.newPasswordHint}</span>

      </div>
  </form>
      <div className='tnc-form'>
        <FormControlLabel
					sx={{ mt: 5, color: "#00617F" }}
					control={
						<Checkbox
							{...label}
              value="end"

							sx={{
								p: 0,
								m: 1,
								color: "#03eb7a",
								"&.Mui-checked": {
									color: "#03eb7a",
								},
								checked: {},
							}}
						/>
					}
					label=''
				/>
        <span className='terms'><p>I agree to the site </p><p className='tnc'><Link to={"/Legals/TermsOfMembership/"} >Terms of Membership</Link></p></span>
        </div>
        {/* <FormControlLabel control={<Checkbox defaultChecked />} /> */}
        <div className="account-btn" style={{paddingTop: '20px', justifyContent: 'left'}}>
          <div >
          <ResetButton onClick={ () => handleReset ()} title='RESET'/>
          </div>
          <div style={{width:'22%',marginLeft:'12%'}}>
          <SubmitButton onClick={() => submitAndfetchData()} title='SAVE CHANGES'/>
          </div>
        </div>
    {/* </Container> */}
    <ErrorPopup
            show={showError}
            okbtn={okBtn}
            yesbtn={yesBtn}
            nobtn={closeBtn}
            errormsg={errorMsg}
            heading={errorHeading}
            infoSign={true}
            onclickno={() => setShowError(false)}
            onhide={() => setShowError(false)}
            onclickyes={() => handleSubmit()} 
            />
  </div>
  )
}

export default ChangePassword