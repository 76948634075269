import React, { useState, useRef, useEffect } from 'react'
import ReactToPrint from "react-to-print";
import { Col, Row, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import "../../../App.css"
import ScrollButton from '../../../components/common/ScrollButton';
import NotificationPopup from '../../../components/common/NotificationPopup';
import StocktakeEntriesPrint from './StocktakeEntriesPrint';
import SubmitButton from '../../../components/common/SubmitButton';
import SaveDraftButton from '../../../components/common/SaveDraftButton';
import ResetButton from '../../../components/common/ResetButton';
import axios from '../../../_helpers/axios';
import PrintIcon from '../../../assets/images/print_icon.svg';
import ErrorPopup from '../../../components/common/ErrorPopup';
import { useDispatch, useSelector , RootStateOrAny} from 'react-redux';
import { getStocktakeEntries } from '../../../redux/actions/stockManagementActions/stockEntriesActions'; 
import { Steps } from 'intro.js-react';
import '../../../assets/scss/tourguide.scss'
import "intro.js/introjs.css";
import {runTourStockTake } from "../../../redux/actions/tourGuideActions";

const StocktakeEntries = () => {
    const dispatch  = useDispatch(); 
    var stocktake: any[] = [];
    var arr: {}[] = [];

    var str: any ={};
    var infoNoti = {notificationType: 'info', header: 'Message',message: 'Key-in your stocktake and provide comments as required.\nScroll down to the bottom of the page and click [Submit my entries for processing] or [Save Draft] button when you are done entering your stocks.\n\rProvide Comments: \nComments are required when the physical count is different from the stock reported by CropCentre. \nComments are also required when your batch number could not be verified by CropCentre.'};
    var infoNotiForBatchNo = {notificationType: 'info', header: 'Message',message: 'Key-in your stocktake and provide comments as required.\nScroll down to the bottom of the page and click [Submit my entries for processing] or [Save Draft] button when you are done entering your stocks.\n\r<b>Valid Batch Number Format:</b> \nMost of Bayer product batch number are 4 letters followed by 6 digits- e.g <b>PAIS007879</b>.\nSome products  follow a different format,  such as Sakura -  e.g. <b>E1H02J</b>, Roundup - e.g.  <b>Z21DF07014</b> or <b>AAC2E41251</b> or <b>TGRU006004</b>.\n\r\n\<b>Provide Comments:</b> \nComments are required when your physical stock count is different from the stock reported by CropCentre. \nComments are also required when your batch number is unreadable or could not be verified by CropCentre. Please tick the <b>[unreadable batch#]</b> checkbox and provide <b>your best guess and D.O.M in comments.</b>'};
    
    const [notifications, setNotifications]= useState(arr);
    const [draftNoti, setDraftNoti] = useState({});
    const [submitNoti, setSubmitNoti] = useState({});
    const [showOkBtn, setShowOkBtn] = useState(false);
    const [mailNoti, setMailNoti] = useState({});

    const currentAgency = JSON.parse(localStorage.getItem("SelectedAgency") || "[]");
    const  userInfo = JSON.parse(localStorage.getItem("UserInfo") || "[]");
    const accountSfid = currentAgency['account_sfid'];
    const [modalShow, setModalShow] = React.useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const url = `ordermanagement/stocktake?accountSfid=`;
    // const getUrl= url.concat(accountSfid).concat('&type=add');
    const postUrl = url.concat(accountSfid).concat('&type=Submitted');
    const draftUrl = url.concat(accountSfid).concat('&type=Draft');

    const [data, setStocktakeEntries] = useState(stocktake);
    const [stockTakeEntries, setStockTakeEntriesData] = useState(str);
    const [batchNumberRequired, setBatchNumberRequired]= useState(false);
    const componentRef = useRef(null);
    const [buttonClicked, setButtonClicked] = useState('');
    const [showNotiPopup, setShowNotiPopup] = useState(false);
    const [showSubmitPopup, setShowSubmitPopup] = useState(false);
    const [loading, setLoading] = useState(true);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [errorHeading, setErrorHeading] = useState('');
    const submitNotiar = {notificationType: 'success', header: 'Your stocktake entries has been successfully submitted.',message: ''}
    // const isrender = useSelector((state: RootStateOrAny) => state.user.renderCom);

    // const [renderCom , setrender]=useState(isrender);
    const stockTakeEntriesData = useSelector((state: RootStateOrAny) => state.StocktakeEntries);
    const errors = useSelector((state: RootStateOrAny) => state.StocktakeEntries.errors);
    // const [run, setRun] = useState(true);
    // const [requestStockvisit, setrequestStockvisit] = useState('')
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [initialStep, setInitialStep] = useState(0);
    // const [hintsEnabled, setHintsEnabled] = useState(true);
    // const [addStock, setStatus] = useState(true);
    const tourRunStockTake = useSelector((state:RootStateOrAny) => state.TourGuide.tourInfo.value)
    const currentPage = useSelector((state:RootStateOrAny) => state.TourGuide.tourInfo.currentPage)
    // const [tourRunOnData, setTourRunOnData] = useState(false)
    const [stocktakeConfirmation, setStocktakeConfirmation] = useState(false);
    const [showYesBtn, setShowYesBtn] = useState(false);
    const [showNoBtn, setShowNoBtn] = useState(false);
    let temp: any[] = [];
    const [delItems, setDelItems] = useState(temp);
    const [tourBatch, setTourBatch] = useState({
        steps: [
            {
                element: '.stocktake-tg1',
                title:'Step 01',
                intro: <h6 className='guide-text'>Let's start by putting physical count in the input fields.</h6>,
                position: 'right',
                tooltipClass: 'myTooltipClass',
                highlightClass: 'myHighlightClass',
              },
              {
                element: '.stocktake-tg-batch2',
                title:'Step 02',
                intro: <h6  className='guide-text'>Enter the batch number here. Note:batch number field will be Enabled or Disabled based on quarter (Q3 and Q4) of the year.</h6>,
                position: 'left',
              },
              {
                element: '.stocktake-tg-batch3',
                title:'Step 03',
                intro:  <h6  className='guide-text'>By clicking 'add batch', you can add multiple batch number per product pack.</h6>,
              },
              {
                element: '.stocktake-tg-batch4',
                title:'Step 04',
                intro:  <h6  className='guide-text'>Tick the checkbox if a product has an unreadable batch number.</h6>,
              },
              {
                element: '.stocktake-tg2',
                title:'Step 05',
                intro: <h6  className='guide-text'>Type comment in case of difference between Physical count, CC SOH, or unreadable batch number.</h6>,
                position: 'left',
              },
              {
                element: '.stocktake-tg3',
                title:'Step 06',
                intro:  <h6  className='guide-text'>Reset button will clear all the inputs made.</h6>,
              },
              {
                element: '.stocktake-tg4',
                title:'Step 07',
                intro:  <h6  className='guide-text'>Save a draft and come back later to finish where you left off.</h6>,
              },
              {
                element: '.stocktake-tg5',
                title:'Step 08',
                intro:  <h6  className='guide-text'>Press Submit My Entries for processing button. A message will be displayed once the stocktake entries have been submitted successfully.</h6>,
              },
              {
                element: '.stocktake-tg6',
                title:'Step 09',
                intro:  <h6  className='guide-text'>Print the form, if you need a physical copy of the report
                </h6>,
              },
              {
                element: '.tour-explore',
                intro:  <h6  className='guide-text'>Click on the explore icon if you need to access the guided tour again</h6>,
              },
      
      ]
    })
    
    const [tour, setTour] = useState({
        steps: [
            {
                element: '.stocktake-tg1',
                title:'Step 01',
                intro: <h6 className='guide-text'>Let's start by putting physical count in the input fields.</h6>,
                position: 'right',
                tooltipClass: 'myTooltipClass',
                highlightClass: 'myHighlightClass',
              },
              {
                element: '.stocktake-tg2',
                title:'Step 02',
                intro: <h6  className='guide-text'>Type comment in case of difference between Physical count CC SOH</h6>,
                position: 'left',
              },
              {
                element: '.stocktake-tg3',
                title:'Step 03',
                intro:  <h6  className='guide-text'>Reset button will clear out all the inputs made</h6>,
              },
              {
                element: '.stocktake-tg4',
                title:'Step 04',
                intro:  <h6  className='guide-text'>Save a draft and come back later to finish where you left</h6>,
              },
              {
                element: '.stocktake-tg5',
                title:'Step 05',
                intro:  <h6  className='guide-text'>Press the process button to view the receipt of the report</h6>,
              },
              {
                element: '.stocktake-tg6',
                title:'Step 05',
                intro:  <h6  className='guide-text'>Print the form, if you need a physical copy of the report
                </h6>,
              },
              {
                element: '.tour-explore',
                intro:  <h6  className='guide-text'>Click on the explore icon if you need to access the guided tour again</h6>,
              },
      
      ]
    })
    useEffect(() => {
        fetchData();
        
        localStorage.setItem("currentPage", 'StockTakeEntries');
        const  StockTakevisited = JSON.parse(localStorage.getItem("StockTakevisited")|| "false");
        setStepsEnabled(false);
        setTimeout(
            () => {
                if(StockTakevisited===true){
                    // setRun(false)
                    setStepsEnabled(false);
                }else{
                    // setRun(true)
                    if(stockTakeEntriesData.stocktakeEntriesData && stockTakeEntriesData.stocktakeEntriesData.data && Object.entries(stockTakeEntriesData.stocktakeEntriesData.data.customerStockTake)?.length>0){
                        setStepsEnabled(true);
                    localStorage.setItem("StockTakevisited", 'true');
                    }
                }
            }, 
            1000
          );
    }, []);

    //setSubmitNoti(submitNotiar) ;
    // useEffect(() => {
    //     localStorage.setItem("currentPage", 'StockTakevisited');
	// });
    useEffect(() => {
        if(errors.status > 0){
            setNotifications([{notificationType: 'warning', header: errors.message}]);
            setLoading(false);
            setShowNotiPopup(true);
        }
        if(stockTakeEntriesData.stocktakeEntriesData){
            if(stockTakeEntriesData.stocktakeEntriesData.status===200){
                setButtonClicked("");
                setLoading(false);
                setStocktakeEntries(stockTakeEntriesData.stocktakeEntriesData.data.customerStockTake);
                setStockTakeEntriesData(stockTakeEntriesData.stocktakeEntriesData);
                setBatchNumberRequired(stockTakeEntriesData.stocktakeEntriesData.data.batchNumberRequired);
                if(buttonClicked==='Draft') {
                    if(stockTakeEntriesData.stocktakeEntriesData.data.batchNumberRequired) setNotifications([draftNoti, infoNotiForBatchNo]) ;
                    else setNotifications([draftNoti, infoNoti]);
                }
                else if (buttonClicked==='') {
                    if(stockTakeEntriesData.stocktakeEntriesData.data.batchNumberRequired) setNotifications([infoNotiForBatchNo]) ;
                    else setNotifications([infoNoti]);
                }
                else if (buttonClicked==='Submit'|| buttonClicked === 'Confirm Submit') {
                    if(stockTakeEntriesData.stocktakeEntriesData.data.batchNumberRequired) setNotifications([submitNoti, mailNoti]) ;
                    else setNotifications([submitNoti, mailNoti]);
                    setStocktakeEntries(stocktake);
                }
                if(stockTakeEntriesData.stocktakeEntriesData.data.customerStockTake
                     && stockTakeEntriesData.stocktakeEntriesData.data.customerStockTake?.length>0 
                     && stockTakeEntriesData.stocktakeEntriesData.data.customerStockTake[0].stockdetails
                     && stockTakeEntriesData.stocktakeEntriesData.data.customerStockTake[0].stockdetails?.length>0
                     && stockTakeEntriesData.stocktakeEntriesData.data.customerStockTake[0].stockdetails[0].stockTake_status
                     && 'SUBMIT'===stockTakeEntriesData.stocktakeEntriesData.data.customerStockTake[0].stockdetails[0].stockTake_status.toUpperCase()){
                        setStocktakeEntries([]);
                     }
                
                setShowNotiPopup(true);
            }else if(stockTakeEntriesData.stocktakeEntriesData.status===201 || stockTakeEntriesData.stocktakeEntriesData.status===208){
                setNotifications([{notificationType: 'warning', header: stockTakeEntriesData.stocktakeEntriesData.message}]);
                setLoading(false);
                setShowNotiPopup(true);
                setStocktakeEntries([]);
            }
        }
        }, [stockTakeEntriesData]);

    useEffect(() => {
        if(buttonClicked?.trim()!==""){
            ProcessStocktakeEntries();
        }  
    }, [buttonClicked]);

    // function submitAndfetchData() {
    //     if(buttonClicked!='') ProcessStocktakeEntries();
    //     else fetchData();
    // }

    async function fetchData() {
        setStocktakeConfirmation(false);
        setShowNotiPopup(false);
        setShowSubmitPopup(false);
        setModalShow(false);
        setShowYesBtn(false);
        setShowNoBtn(false);
        setLoading(true);
        dispatch(getStocktakeEntries(currentAgency));
        setDelItems(temp);
        window.scrollTo({top: 0, behavior: 'auto'});
    }

    const closeErrorPopup = () => {

        setShowOkBtn(false)
        setModalShow(false)
        window.scrollTo({top: 0, behavior: 'auto'})
    };
    function ProcessStocktakeEntries() {
        // setNotifications(arr);
        let showSOHErrSub = false;
        let formsErrors = false;
        let batchNoErr= false;
        stockTakeEntries.data.customerStockTake = [];
        
        let msg:any;
        for(let i=0; i<data?.length; i++){
            var totalAgencyCount = 0;
            data[i].stockdetails.forEach(function(stock: any){
                if(null !== stock.stockTake_agencyNbr)
                totalAgencyCount = totalAgencyCount + parseInt(stock.stockTake_agencyNbr);
                if(!isBatchNumValid(data[i], stock.stockTake_batchNbr, stock.stockTake_agencyNbr)){
                    batchNoErr = true;
               }
            });
            if(data[i].CCSOH > 0 && totalAgencyCount<=0){
                msg = <p>{msg} {data[i].productName} - {data[i].productSAPMaterialNumber} </p>
                showSOHErrSub= true;
           }
           if(totalAgencyCount !== data[i].CCSOH){
            data[i].stockdetails.forEach(function(stock: any){
                if(stock.stockTake_agencyNbr==="" || null==stock.stockTake_agencyNbr){
                    formsErrors= true;
                }
                if(stock.stockTake_agencyNbr!=="" && stock.stockTake_agencyNbr !== data[i].CCSOH && undefined===stock.stockTake_agenyComment || null===stock.stockTake_agenyComment){
                // if(stock.stockTake_agencyNbr!=="" && stock.stockTake_agencyNbr != data[i].CCSOH && undefined===stock.stockTake_agenyComment || null===stock.stockTake_agenyComment || stock.stockTake_agenyComment?.length<10){
                    formsErrors= true;
                }
            });
           }
           
        }
        
        
        let url = '';
        if(buttonClicked==='Submit') { 
            url=postUrl;
        }
        else if (buttonClicked==='Draft') {
            url=draftUrl;
        }

        stockTakeEntries.data.userDetails = userInfo.data.userDetails;
        if(showSOHErrSub){
            setButtonClicked('Error');
            setStocktakeConfirmation(false);
            setErrorHeading('Warning! You have to add physical count if SOH is > 0 ');
            setErrorMsg(msg);
            setShowOkBtn(true);
            setModalShow(true);
            
        }else if(formsErrors){
            setButtonClicked('Error');
            setStocktakeConfirmation(false);
            setErrorHeading('Warning! Your stocktake entry have not been submitted for processing');
            setErrorMsg("There were issues found with your transaction. Please review them and update the required information (highlighted in red text and an asterisk) before submitting your stocktake for approval.");
            setModalShow(true);
            setShowOkBtn(true);
            
        }else if(batchNoErr){
            setButtonClicked('Error');
            setStocktakeConfirmation(false);
            setErrorHeading('Warning! Batch number is empty');
            setErrorMsg("If you still want to submit, Please check the Unreadable Batch # checkbox on the row which is highlighted and Submit the entry.");
            setModalShow(true);
            setShowOkBtn(true);
        }
        else{
            setStockTakeDateAndStatus();
            stockTakeEntries.data.customerStockTake = data;
            stockTakeEntries.data.delItems=delItems;
            if(buttonClicked==="Draft"){
                postData(url, stockTakeEntries);
            }
            else if (buttonClicked==="Submit"){
                postData(url, stockTakeEntries);
            }
            else if(buttonClicked === 'Confirm Submit'){
                window.scrollTo({top: 0, behavior: 'auto'});
                    setStocktakeConfirmation(true);
            }
        }
    }

    async function postData(url = '', data = {}) {
        setShowNotiPopup(false);
        setLoading(true);
        window.scrollTo({top: 0, behavior: 'auto'});
        axios.post(url, data).then((res) => {
            var today = new Date();
            var date = (today.getMonth()+1) +'.'+today.getDate()+'.'+today.getFullYear().toString().slice(2,4);
            var time = today.getHours() + ":" + today.getMinutes();
            let dateNtime= date + ' at ' + time;
            var msg = 'Draft Saved on ' + dateNtime;
            let to = (res.data.data.emailReceipents && res.data.data.emailReceipents.TO.length>0) ? res.data.data.emailReceipents.TO[0] : '';
            let cc = (res.data.data.emailReceipents && res.data.data.emailReceipents.CC.length>0) ? res.data.data.emailReceipents.CC[0] : '';
            let mailMsg = '';
            if(to === userInfo.data.userDetails.cont_email) mailMsg = 'Email was sent to you';
            else mailMsg = 'Email was sent to '.concat(to);
            if (cc !== '') mailMsg = mailMsg + ' and a copy to ' + cc;
            setMailNoti({ notificationType: 'success', header: mailMsg });
            if(res.data.status===200)
            {
                if (buttonClicked==='Draft'){
                    let draftNoti = {notificationType: 'success', header: 'Draft Saved',message: msg}
                    setDraftNoti(draftNoti) ;
                    setNotifications([draftNoti, infoNoti]);
                }
                if (buttonClicked==='Submit'){
                    let submitNoti = {notificationType: 'success', header: 'Your stocktake entries has been successfully submitted',message: date}
                    setSubmitNoti(submitNoti);
                    setNotifications([submitNoti]);
                }
            //    fetchData();
                setLoading(false);
                setShowNotiPopup(true);
            }
           
          })
          .catch((err) => {
            console.log(err);
          });
      }

      const setStockTakeDateAndStatus = () => {
        data.forEach(row => {
            row.stockdetails.forEach((r: any) => {r.stockTake_status = buttonClicked} )
        })
      }

      const handleReset = () => {
        if(unsavedChanges){
            setErrorHeading('Reset');
            setErrorMsg('All unsaved data will be cleared.');
            setShowOkBtn(false);
            setShowYesBtn(true);
            setShowNoBtn(true);
            setModalShow(true);
            setShowNotiPopup(false);
        }
      }

      const handleProcess = () => {
          if(stocktakeConfirmation) setButtonClicked('Submit')
          else setButtonClicked('Confirm Submit')
    };

    const handleSaveDraft = () => {
            if(unsavedChanges) setButtonClicked('Draft');
    };

    const handleFocus = (e:any) => e.target.select();

    const changePhysicalCount = (event: any) => {
        if (null !== event.e.target.value && !isNaN(event.e.target.value)) {
            stocktake = data.map(o => {
                var temp = Object.assign({}, o);
                if (temp.productSfid === event.row.productSfid) {
                    temp.stockdetails[event.k].stockTake_agencyNbr = event.e.target.value;
                    setUnsavedChanges(true);
                }
                return temp;
            });
            setStocktakeEntries(stocktake);
        }
    };

    const updateBatchNumber = (event: any) => {
        if (null !== event.e.target.value) {
            stocktake = data.map(o => {
                var temp = Object.assign({}, o);
                if (temp.productSfid === event.row.productSfid) {
                    temp.stockdetails[event.k].stockTake_batchNbr=event.e.target.value.toUpperCase();
                }
                return temp;
            });
            setStocktakeEntries(stocktake);
        }
    };

    const isBatchNumValid = (row: any, batchNum: any, agencyNum: any) => {
        // if(buttonClicked === ''){
        //     return true;
        // }
        if(row.CCSOH===0 && agencyNum===0 && batchNum===null){
            return true;
        }
        if(agencyNum===0){
            return true;
        }else {
            if(batchNumberRequired){
                if((null===batchNum || batchNum==='') && 'UNREADABLE'!==batchNum?.toUpperCase() && !row.productBatchNumber.includes(batchNum?.toUpperCase())){
                    return false;
                }else if (null!==batchNum && batchNum!=='' && ('UNREADABLE'===batchNum?.toUpperCase() || row.productBatchNumber.includes(batchNum?.toUpperCase()))){
                    return true;
                }
            }
            else {
                if(null===batchNum || batchNum==='' || row.productBatchNumber.includes(batchNum.toUpperCase()) || 'UNREADABLE'===batchNum.toUpperCase()){
                    return true;
                }else{
                    return false;
                }
            }
        }
        
        return false;
    };

    const updateComment = (event: any) => {
        if (null !== event.e.target.value) {
            stocktake = data.map(o => {
                var temp = Object.assign({}, o);
                if (temp.productSfid === event.row.productSfid) {
                    temp.stockdetails[event.k].stockTake_agenyComment = event.e.target.value;
                    setUnsavedChanges(true);
                }
                return temp;
            });
            setStocktakeEntries(stocktake);
        }
    };

    const addBatchNo = (event: any) => {
        let stockObj = {
            "stockTake_agencyNbr": 0,
            "stockTake_batchNbr": "",
            "stockTake_agenyComment": "",
            "stockTake_sfid": null
        }
        stocktake = data.map(o => {
            var temp = Object.assign({}, o);
            if (temp.productSfid === event.productSfid) {
                temp.stockdetails.push(stockObj);
            }
            
            return temp;
        });
        setUnsavedChanges(true);
        setStocktakeEntries(stocktake);
    };

    const removeBatchNo = (event: any) => {
        stocktake = data.map(o => {
            var temp = Object.assign({}, o);
            if (temp.productSfid === event.row.productSfid) {
                if(temp.stockdetails[event.k]?.stockTake_sfid?.trim()!=="" && null!==temp.stockdetails[event.k]?.stockTake_sfid){
                    setDelItems([...delItems, temp.stockdetails[event.k]?.stockTake_sfid])
                }
                temp.stockdetails.splice(event.k, 1);
            }
            
            return temp;
        });
        setUnsavedChanges(true);
        setStocktakeEntries(stocktake);
        
    };

    const setUnreadableBatch = (event: any) => {
        if (null !== event.e.target.checked) {
            stocktake = data.map(o => {
                var temp = Object.assign({}, o);
                if (temp.productSfid === event.row.productSfid) {
                    if (event.e.target.checked) {
                        temp.stockdetails[event.k].stockTake_batchNbr='UNREADABLE'; 
                        temp.stockdetails[event.k].unreadableBatch = true; 
                    }
                    else {
                        temp.stockdetails[event.k].stockTake_batchNbr='';
                        temp.stockdetails[event.k].unreadableBatch = false;
                    }
                }
                return temp;
            });
            setStocktakeEntries(stocktake);
        }
    };
    const tourSteps= batchNumberRequired ? tourBatch.steps : tour.steps;
    const onExit = () => {
        setStepsEnabled(false);
      };
    // const startTour =()=>{
    //     setStepsEnabled(true);
    // }
    useEffect(() => {
        let dataStockTake={
            value:false,
            currentPage:'StockTakeEntries'
          }
        if(data?.length>0){
            if(tourRunStockTake && currentPage==="StockTakeEntries"){
                setStepsEnabled(true);
            }
            dispatch(runTourStockTake(dataStockTake))
        }
	}, [tourRunStockTake]);
    return (
        <>
        <NotificationPopup showNotiPopup={showNotiPopup} notifications={notifications} />
        <NotificationPopup showNotiPopup={showSubmitPopup} notifications={[submitNotiar]} />
        <Steps
                enabled={stepsEnabled}
                steps={tourSteps}
                initialStep={initialStep}
                onExit={onExit}
                options={{
                    // nextLabel: 'test next',
                    prevLabel: 'Prev',
                    skipLabel: 'Skip',
                    showStepNumbers:true
                  }}
                />
        {loading ? 
        <Row className='loader'><Spinner animation="border" role="status" ></Spinner></Row> : 
        <>
        { (data?.length>0 && buttonClicked!=="Submit") && <><div className='stock-print mb-3'>
            {stocktakeConfirmation ? <h4>Stocktake Confirmation</h4> : <span></span>}
            <span className='stocktake-tg6'>
            <img className='d-inline-block' alt="print icon" src={PrintIcon}></img>
            <div className='d-inline-block '><ReactToPrint
            trigger={() => <p className='print'>Print this form</p>}
            content={() => componentRef.current}
            /></div>
            <div style={{ display: "none" }}><StocktakeEntriesPrint data={[data]} ref={componentRef} /> </div>
            </span>
        </div>
        <div className='stocktake-entries'>
        <div className='stocktake-table'>
            <div className='stock-take-tableheader'>
                <div className="stock-take-tableSr header-border"><p><b>Sr. No.</b></p></div>
                <div className="stock-take-tableProduct header-border"><p><b>Product Pack</b></p></div>
                <div className="stock-take-tableCC header-border"><p><b>CC. SOH</b></p></div>
                <div className="stock-take-tableCount header-border"><p><b>Physical Count</b></p></div>
                <div className="stock-take-tableBatch header-border"><p><b>Batch Number</b></p></div>
                <div className="stock-take-tableUn header-border"><p><b>Un-readable Batch #</b></p></div>
                <div className="stock-take-tableComment header-border"><p><b>Comments</b></p></div>
            </div>
        </div>
        
        {data.map((row, key) => {
            var bgColor = (key + 1) % 2 === 0 ? 'white' : '#89d3290d';
            var TextAreaBgColor = (key + 1) % 2 === 0 ? 'white' : 'rgba(137, 211, 41, 0.02)';
            var totalAgencyCount = 0;
            row.stockdetails.map((r: any,k: any) => {
                if(null===r.stockTake_agencyNbr || r.stockTake_agencyNbr === ''){
                    let e = {target : {value : 0}};
                    changePhysicalCount({row, k, e})
                }
                else totalAgencyCount = totalAgencyCount + parseInt(r.stockTake_agencyNbr);
                return null;
            });
            
            return (
                <div key={key+''}>
                {
                    row.stockdetails.map((r: any,k: any) => {
                        
                        var temp = (totalAgencyCount === row.CCSOH || (r.stockTake_agenyComment)) ? 'NumberInput' : 'NumberInputWarning';
                        // var temp = (totalAgencyCount == row.CCSOH || (r.stockTake_agenyComment && r.stockTake_agenyComment?.length>=10)) ? 'NumberInput' : 'NumberInputWarning';
                        var styles = buttonClicked==='' ? 'NumberInput' : temp;
                        // var showErr = (buttonClicked !== '') && (row.CCSOH !== totalAgencyCount) && (!r.stockTake_agenyComment || r.stockTake_agenyComment?.length<10);
                        var showErr = false;
                        if(buttonClicked===''){
                            if(totalAgencyCount!==0 && (row.CCSOH !== totalAgencyCount) && (!r.stockTake_agenyComment)){
                            // if(totalAgencyCount!==0 && (row.CCSOH !== totalAgencyCount) && (!r.stockTake_agenyComment || r.stockTake_agenyComment?.length<10)){
                                showErr = true;
                            }
                        }else{
                            if((row.CCSOH !== totalAgencyCount) && (!r.stockTake_agenyComment)){
                            // if((row.CCSOH !== totalAgencyCount) && (!r.stockTake_agenyComment || r.stockTake_agenyComment?.length<10)){
                                showErr = true;
                            }
                        }
                        var showCmntErr = (!r.stockTake_agenyComment) && 'UNREADABLE'===r.stockTake_batchNbr;
                        // var showCmntErr = (!r.stockTake_agenyComment || r.stockTake_agenyComment?.length<10) && 'UNREADABLE'===r.stockTake_batchNbr;
                        return (<>
                        <div style={{ backgroundColor: bgColor}} className="table-content table-row-req" key={k+''}>
                            {k>=1 ? <div className='add-batch'></div> : 
                            <>
                                <div className="stock-take-tableSr"><span className='NoBottomMargin req-product-name'>{key + 1}.</span></div>
                                <div className="stock-take-tableProduct"><span className='NoBottomMargin req-product-name'>{row.productName} - {row.productSAPMaterialNumber}</span>
                                {showErr && 
                                <p className='WarningMsg'>SOH different from physical count!</p>} </div>
                                <div className="stock-take-tableCC"><span  className='NoBottomMargin req-product-name ccsoh'>{row.CCSOH}</span></div>
                            </>}
                            <div className="stock-take-tableCount stocktake-tg1">
                                <input type="text" value={r.stockTake_agencyNbr} className={styles} placeholder='0'
                                    onFocus={(e)=>handleFocus(e)} onChange={e => changePhysicalCount({ row, k, e })}
                                />
                            </div>
                            <div className="stock-take-tableBatch">
                                {batchNumberRequired 
                                ?<>
                                    <div className="stocktake-tg-batch2 stocktake-tg-batch3" style={{width: '100%'}}>
                                        <input type="text" value={r.stockTake_batchNbr||""} className={isBatchNumValid(row, r.stockTake_batchNbr, r.stockTake_agencyNbr) ? 'BatchNumberInput' : 'BatchNumberInputWarning'}
                                    onChange={e => updateBatchNumber({row, k, e})} disabled={r.stockTake_batchNbr==='UNREADABLE'}
                                    />
                                    {k>=1? <><a className='batchBtn' onClick={e => removeBatchNo({row, k})}>-remove batch</a></> :<a className='batchBtn' onClick={e => addBatchNo(row)}>+add batch</a>}</div>
                                    { !isBatchNumValid(row, r.stockTake_batchNbr, r.stockTake_agencyNbr) && <><span className='WarningMsgMedium req-product-name'>Invalid Batch No.</span> 
                                        </>}
                                </> 
                                : <span className='req-product-name'>Not Required</span>}
                            </div>
                            <div className='stocktake-tg-batch4 stock-take-tableUn' style={{ textAlign: 'center' ,padding:'0%'}}>
                                {batchNumberRequired ? (<span className='unstyled centered'>
                                    <input className="batch-styled-checkbox" id={row.productSfid.concat(k)} type="checkbox" value="value1" onClick={e => setUnreadableBatch({row, k, e})}/>
                                    <label htmlFor={row.productSfid.concat(k)}></label></span>)
                                    : <span className='req-product-name'>-</span>}
                            </div>
                            <div className="stock-take-tableComment stocktake-tg2">
                                <ul className='NoBottomMargin stock-comments-main ' style={{paddingLeft: '0px'}}>
                                    <textarea rows={1} className="stock-comments" placeholder="Type comment here..." value={r.stockTake_agenyComment||""}
                                    style={{ backgroundColor: TextAreaBgColor, width: '100%' ,overflowY:'scroll', height: '35px'}}
                                    onChange={e => updateComment({ row, k, e })}>
                                    </textarea>
                                </ul>
                                { (showErr || showCmntErr) && <>
                                    {showCmntErr ? <p className='WarningMsg'>* Please provide DOM in comment field for unreadable batch number</p> : <><p className='WarningMsg'>* Please provide comments</p>
                                    {/* <p clas sName='WarningMsgSmall'>Min: 10ch</p> */}
                                    </>}
                                    </>}
                                </div>
                            </div>
                        </>)
                    })
                }
                    
                </div>
            )
        })}
        <Row style={{paddingTop: '20px', justifyContent: 'right'}}>
                    <Col md="3" className='stocktake-tg3'>
                        <ResetButton customClasssName="reset-btn-resposive" onClick={ () => handleReset ()} isButtonDisabled={!unsavedChanges} title='RESET'/>
                    </Col>
                    <Col md="3"></Col>
                    <Col md="3" className='stocktake-tg4'>
                        <SaveDraftButton customClasssName="reset-btn-resposive" onClick = {() => handleSaveDraft()} isButtonDisabled={!unsavedChanges} />
                    </Col>
                    <Col md="3" className='stocktake-tg5'>
                        {stocktakeConfirmation ? <SubmitButton customClasssName="btn-resposive" onClick={() => handleProcess()} title='SUBMIT STOCK REQUEST' />
                        : <SubmitButton customClasssName="btn-resposive" onClick={() => handleProcess()} title='SUBMIT MY ENTRIES FOR PROCESSING' />}
                    </Col>
                </Row>
        <Row style={{ marginTop: '90px', justifyContent: 'right' }}>
            <Col md="11"></Col>
            <Col md="1"><ScrollButton /></Col>
        </Row>
        </div></>} </>}
    <ErrorPopup
                show={modalShow}
                onhide={() => closeErrorPopup()}
                okbtn={showOkBtn}
                errormsg={errorMsg}
                heading={errorHeading}
                yesbtn={showYesBtn}
                nobtn={showNoBtn}
                infoSign={false}
                onclickno={() => {setButtonClicked('');setModalShow(false);setShowYesBtn(false);setShowNoBtn(false);}}
                onclickyes={() => fetchData()}
            />
    </>
    );
};

export default StocktakeEntries;
