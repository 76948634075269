import { Link } from 'react-router-dom';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import './footer.scss'
import store from '../../redux/stores';
import { logoutUser } from "../../redux/actions/userActions";
import React from "react";
import { useSelector , RootStateOrAny} from 'react-redux';


// import useWindowDimensions from './getWindowsDimensions'
const Item = styled(Typography)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1.5),
    textAlign: "center",
    color: "#ffffff",
    opacity: "1",
}));
// const list = [
//     { label: "Conditions of use", url: "/Legals/GeneralConditionsOfUse/" },
//     { label: "Sales Terms & Conditions", url: "/Legals/SalesTermsAndConditions/" },
//     { label: "Account", url: "/account/MyAccount" },
//     { label: "Privacy Statement", url: "/Legals/PrivacyStatement/" },
//     { label: "Terms of membership", url: "/Legals/TermsOfMembership/" },
//     { label: "Logout", url: "" },
//     { label: "Login", url: "" },

//     { label: "Contact us", url: "" },
//     { label: "Imprints", url: "/Legals/Imprint" },
// ];
const Footer = () => {

  const loggedIn =  useSelector((state: RootStateOrAny) => state.user.authenticated); 
 
    return (
        <footer >
            <Box
                sx={{
                    width: "100%",
                    height: '20vh',
                    backgroundColor: "#433146",
                    position:'relative',
                    bottom:'0px',
                    background:'-webkit-linear-gradient(93deg, #443247 calc(78% - 1px), #624963 78%)'
                }}
            >
                <Grid container component="main">
                    <Grid item xs={6} >
                        <Typography noWrap align="center" sx={{ pt: 12, color: "#ffffff", opacity: "1" }} className="footer-typo" style={{marginLeft:'24%',textAlign:'left',paddingTop:'50px'}}>
                            Copyright © Bayer Australia Ltd
                        </Typography>
                    </Grid>
                    <Grid className="footer-links" item xs={6} >
                        <Grid container item xs={12} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 12}}>
                            <Grid item xs={3} style={{paddingLeft:'0px',paddingRight:'0px'}} >
                                <Item style={{paddingLeft:'0px',paddingRight:'0px',textAlign:'left'}}>
                                    <Link  to={'/Legals/GeneralConditionsOfUse/' }>Conditions of use</Link>
                                </Item>
                            </Grid>
                            <Grid item xs={3} style={{paddingLeft:'0px',paddingRight:'0px'}} >
                                <Item style={{paddingLeft:'0px',paddingRight:'0px',textAlign:'left'}}>
                                    <Link  to={'/Legals/SalesTermsAndConditions/' } >Sales Terms & Conditions</Link>
                                </Item>
                            </Grid>
                            {
                                loggedIn ?
                            <Grid item xs={3} style={{paddingLeft:'0px',paddingRight:'0px'}} >
                                <Item style={{paddingLeft:'0px',paddingRight:'0px',textAlign:'left'}}>
                                    <Link  to={`/account/MyAccount`} >Account</Link>
                                </Item>
                            </Grid>
                            :
                            <Grid item xs={3} style={{paddingLeft:'0px',paddingRight:'0px'}} >
                                <Item style={{paddingLeft:'0px',paddingRight:'0px',textAlign:'left'}}>
                                    <Link  to={'/' } >Account</Link>
                                </Item>
                            </Grid>
                            }
                            <Grid item xs={3} style={{paddingLeft:'0px',paddingRight:'0px'}} >
                                <Item style={{paddingLeft:'0px',paddingRight:'0px',textAlign:'left'}}>
                                    <Link  to={'/Legals/PrivacyStatement/' } >Privacy Statement</Link>
                                </Item>
                            </Grid>
                            <Grid item xs={3} style={{paddingLeft:'0px',paddingRight:'0px'}} >
                                <Item style={{paddingLeft:'0px',paddingRight:'0px',textAlign:'left'}}>
                                    <Link  to={'/Legals/TermsOfMembership/' } >Terms of membership</Link>
                                </Item>
                            </Grid>
                            {
                                loggedIn ?
                                <Grid item xs={3} style={{paddingLeft:'0px',paddingRight:'0px'}} >
                                <Item style={{paddingLeft:'0px',paddingRight:'0px',textAlign:'left'}}>
                                    <p className='footer-logout' onClick={() => {store.dispatch(logoutUser())}} >Logout</p>
                                </Item>
                                </Grid>:
                                <Grid item xs={3} style={{paddingLeft:'0px',paddingRight:'0px'}} >
                                <Item style={{paddingLeft:'0px',paddingRight:'0px',textAlign:'left'}}>
                                    <Link  to={'/' } >Login</Link>
                                </Item>
                                </Grid>
                            }
                            <Grid item xs={3} style={{paddingLeft:'0px',paddingRight:'0px'}} >
                                <Item style={{paddingLeft:'0px',paddingRight:'0px',textAlign:'left'}}>
                                    {/* <Link  to={'/Legals/TermsOfMembership/' }target="_blank">Contact us</Link> */}
                                    <Link to={{pathname:"https://www.bayer.com/en/contact-us"}} target="_blank">Contact Us </Link>
                                </Item>
                            </Grid>
                            <Grid item xs={3} style={{paddingLeft:'0px',paddingRight:'0px'}} >
                                <Item style={{paddingLeft:'0px',paddingRight:'0px',textAlign:'left'}}>
                                    <Link  to={'/Legals/TermsOfMembership/' }target="_blank">Imprints</Link>
                                </Item>
                            </Grid>
                            {/* {list.map((value: any, index: number) => (
                                <Grid item xs={3} key={index} style={{paddingLeft:'0px',paddingRight:'0px'}} >
                                    <Item style={{paddingLeft:'0px',paddingRight:'0px',textAlign:'left'}}>
                                        <Link  to={value.url} target="_blank">{value.label}</Link>
                                    </Item>
                                </Grid>
                            ))} */}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </footer>
    );
};
export default Footer;