//in userReducer.ts
import {
    HV_OUTPUT_FILTER, HV_OUTPUT_DATA, HV_HISTORY_LINES_FILTER, HV_HISTORY_LINES_DATA, HV_SELECTED_FILTER_VALUE, HV_PAGE_REPORT_DATE, HV_PAGE_REPORT, HV_SELECTED_DATE_FILTER, HV_SAP_FILTER_OBJ, HV_SELECTED_AGENCY_BRANCH_FILTER, HV_AGENCY_BRANCH_OBJ, HV_SELECTED_REPORT_FILTER, HV_SELECTED_AGENCY_BRANCH_OBJ
  } from "../../types";
  
  const initialState = {
    ReportData:[],
    DateFilterDate:[],
    selectedFilter:[], //to get selected filter on first button
    sapFilterObj:[],
    branchFilterObj: [],
    selectedAgencyBranchFilter: [],
    agencyBranchObj: [],
    selectedAgencyBranchObj: [],
    selectedFilterValue:'',
    selectedFilterDate:'2022 January', //to get selected filter on date button
    historyLinesData:[], //to get history lines data
    SelectedhistoryLinesFilter:[], //to get selected history lines filter
    outputData:[], //to get history lines data
    outputFilter:['View in browser'] //to get selected history lines filter
  
  
  };
  
  export default function (state = initialState, action: any) {
    switch (action.type) {
      case HV_SELECTED_REPORT_FILTER:
        return {
          ...state,
          selectedFilter: action.payload,
        };
        case HV_AGENCY_BRANCH_OBJ:
        return {
          ...state,
          agencyBranchObj: action.payload,
        };
        case HV_SELECTED_AGENCY_BRANCH_OBJ:
          let selectedAgencyBranchObj = [...action.payload];
        return {
          ...state,
          selectedAgencyBranchObj: selectedAgencyBranchObj,
        };
        case HV_SELECTED_AGENCY_BRANCH_FILTER:
            return{
                ...state,
                selectedAgencyBranchFilter: action.payload,
            };
        case HV_SAP_FILTER_OBJ:  //to get checked filter obj data for first btn
        return {
          ...state,
          sapFilterObj: action.payload,
        };
        
      case HV_SELECTED_DATE_FILTER: //to get checked filter for date range
      return {
        ...state,
        selectedFilterDate: action.payload,
      };
        case HV_PAGE_REPORT:
          let data = [...action.payload]
          return {
            ...state,
            ReportData: data,
          };
          case HV_PAGE_REPORT_DATE: // to get aal filter values for date
            return {
              ...state,
              DateFilterDate: action.payload,
            };
          case HV_SELECTED_FILTER_VALUE:
            return {
              ...state,
              selectedFilterValue: action.payload,
            };
          case HV_HISTORY_LINES_DATA:  //to get history lines all data
            return {
              ...state,
              historyLinesData: action.payload,
            };
            case HV_HISTORY_LINES_FILTER: // to get hhistory lines seelcted filed
              return {
                ...state,
                SelectedhistoryLinesFilter: action.payload,
              };
  
              case HV_OUTPUT_DATA:  //to get output all data
              return {
                ...state,
                outputData: action.payload,
              };
              case HV_OUTPUT_FILTER: // to get output seelcted filed
                return {
                  ...state,
                  outputFilter: action.payload,
                };
        
      default:
        return state;
    }
  }